import {validateKeysSyncing} from "../utils";

const lc_en = {
    //general
    general__data_unavailable: "No Data Available",
    general__download_data: "Download Data",
    general__server_response: "Server Response",
    general__error: "Error",
    general__total: "Total",
    general__rows_per_page: "Rows per page",
    general__actions: "Actions",
    general__email: "Email",
    general__email_address: "Email Address",
    general__username: "Username",
    general__name: "Name",
    general__last_name: "Last Name",
    general__first_name: "First name",
    general__gender: "Gender",
    general__copy: "Copy",
    general__copied: "Copied!",
    general__unable_copy: "Unable to copy!",
    general__send: "Send",
    general__download: "Download",
    general__submit: "Submit",
    general__copyright: "Copyright",

    //navigation
    navigation__analytics_dashboard: "Analytics Dashboard",
    navigation__program_overview: "Program Overview",
    navigation__baselining: "National Overview",
    navigation__digital_competencies: "Digital Competencies",
    navigation__cyber_risks: "Cyber-Risks",
    navigation__assessment: "Assessment",
    navigation__enhancement: "Enhancement",
    navigation__content: "Content",
    navigation__reach: "Reach",
    navigation__efficacy: "Efficacy",
    navigation__in_depth_analysis: "In-Depth Analysis",
    navigation__progress: "Progress",
    navigation__schools: "Schools",
    navigation__classes: "Classes",
    navigation__students: "Students",
    navigation__employees: "Employees",
    navigation__licenses: "Licenses",
    navigation__licenses_info: "Licenses Info",
    navigation__add_licenses: "Add Licenses",
    navigation__pending_activations: "Pending Activations",
    navigation__program_resources: "Program Resources",
    navigation__contact_us: "Contact Us",

    //header
    header__change_password: "Change Password",
    header__dark_mode: "Dark Mode",
    header__sign_out: "Sign Out",

    //footer
    footer__support: "Support",
    footer__help_center: "Help Center",
    footer__privacy: "Privacy",
    footer__terms_of_service: "Terms of Service",
    footer__dq_lab_pte_ltd_all_rights_reserved: "DQ LAB Pte Ltd. All rights reserved.",

    //auth
    auth__login: "Login",
    auth__sign_in: "Sign in",
    auth__pass: "Password",
    auth__remember_me: "Remember me",
    auth__forgot_pass: "Forgot Password?",
    auth__dont_have_acc: "Don't have an account? Register!",
    auth__register: "Register",
    auth__sign_up: "Sign up",
    auth__reg_school: "Register [School]",
    auth__school_type: "School Type",
    auth__school_size: "School Size",
    auth__country: "Country",
    auth__city: "City",
    auth__your_first_name: "Your First Name",
    auth__your_last_name: "Your Last Name",
    auth__your_position: "Your Position",
    auth__your_email: "Your Email",
    auth__create_pass: "Create a Password",
    auth__confirm_pass: "Confirm Password",
    auth__keep_me_login: "Keep me logged in",
    auth__already_have_acc: "Already have an account? Sign in",
    auth__err_pass_mismatch: "Password Mismatch",
    auth__provide_same_pass: "Please provide the same password in both password fields!",
    auth__school_primary: "Primary",
    auth__school_secondary: "Secondary",
    auth__school_high: "High",
    auth__reg_company: "Register [Company]",
    auth__company_name: "Company Name",
    auth__indus_or_sec: "Industry / Sector",
    auth__company_size: "Company Size",
    auth__company_it: "IT",
    auth__company_edu: "Education",
    auth__company_corporate: "Corporate",
    auth__reg_as_company: "Register as Company",
    auth__reg_as_school: "Register as School",
    auth__req_reset_pass: "Request Reset Password",
    auth__send_reset_code: "Send Reset Code",
    auth__back_to_login: "Back to Login Page",
    auth__already_receive_code: "Already have received code?",
    auth__token_sent: "Token Sent",
    auth__token_sent_success: "Successfully emailed the reset-token",
    auth__reset_code: "Reset Code",
    auth__set_pass: "Set Password",
    auth__pass_updated: "Password Updated",
    auth__pass_change_success: "Successfully Changed Password",
    auth__activate_acc: "Activate Account",
    auth__enter_access_code: "Enter Access-Code",
    auth__check_email_code: "Please check your email, token has been sent!",
    auth__access_code: "Access Code",
    auth__resend_code: "Resend Code?",
    auth__resent_code: "Code Resent",
    auth__resend_code_success: "Successfully sent code to your email address",
    auth__confirmed: "Confirmed",
    auth__confirmed_email: "Successfully Confirmed the Email Address",
    auth__logout: "Logout",
    auth__logout_in_process: "Logging User Out...",

    //side panel
    side_panel__select_country: "Select a Program",
    side_panel__add_country: "Add Country",
    side_panel__select_dig_comp: "Select a Digital Competency",
    side_panel__select_cyber_risk: "Select a Cyber Risk",
    side_panel__select_time: "Select a Time Period",
    side_panel__start_month: "Starting Month",
    side_panel__end_month: "Ending Month",
    side_panel__select_comp_cat: "Select a Competency Category",
    side_panel__select_mod_cat: "Select a Module Category",
    side_panel__select_region: "Select a Region",
    side_panel__add_region: "Add Region",
    side_panel__select_province: "Select a Province",
    side_panel__add_province: "Add Province",
    side_panel__select_school: "Select a School",
    side_panel__add_school: "Add School",
    side_panel__select_class: "Select a Class",
    side_panel__add_class: "Add Class",
    side_panel__select_postal: "Select a Postal Code",
    side_panel__add_postal: "Add Postal",
    side_panel__select_dq_comp: "Select a DQ Competency",
    side_panel__select_inst: "Select an Institution Code",
    side_panel__add_inst: "Add Institute",
    side_panel__select_mod: "Select a Module",
    side_panel__add_mod: "Add Module",
    side_panel__dq_comp_score: `DQ Composite Score`,
    side_panel__entire_nation: `Entire Nation`,
    side_panel__all_year: `All Years`,
    side_panel__all_region: `All Regions`,
    side_panel__all_province: `All Provinces`,
    side_panel__all_postal: `All Postal Codes`,
    side_panel__all_inst: `All Institute Codes`,
    side_panel__all_mod: `All Modules`,
    side_panel__all_school: `All Schools`,
    side_panel__all_class: `All Classes`,
    side_panel__selected_region: `Selected Region`,
    side_panel__selected_province: `Selected Province`,
    side_panel__selected_postal: `Selected Postal`,
    side_panel__selected_inst: `Selected Institute Code`,
    side_panel__selected_time: `Selected Time`,
    side_panel__selected_mod: `Selected Module`,
    side_panel__selected_school: `Selected School`,
    side_panel__selected_class: `Selected Class`,
    side_panel__prog_mods: `Program Modules`,

    //graph
    stats__highly_excluded: `Highly Excluded`,
    stats__excluded: `Excluded`,
    stats__included: `Included`,
    stats__highly_included: `Highly Included`,
    stats__no: `No`,
    stats__yes: `Yes`,
    stats__unknown: `Unknown`,
    stats__female: `Female`,
    stats__male: `Male`,
    stats__other: `Other`,
    stats__junior: 'Junior',
    stats__senior: 'Senior',
    stats__below_9: `Below 9`,
    stats__below_9_years_old: `Below 9 Years Old`,
    stats__10_12_years_old: `10-12 Years Old`,
    stats__13_14_years_old: `13-14 Years Old`,
    stats__15_16_years_old: `15-16 Years Old`,
    stats__above_16_years_old: `Above 16 Years Old`,
    stats__jan: `Jan`,
    stats__feb: `Feb`,
    stats__mar: `Mar`,
    stats__apr: `Apr`,
    stats__may: `May`,
    stats__jun: `Jun`,
    stats__jul: `Jul`,
    stats__aug: `Aug`,
    stats__sep: `Sep`,
    stats__oct: `Oct`,
    stats__nov: `Nov`,
    stats__dec: `Dec`,

    //dq code and def
    dq_code__before_the_dq_world_edu: `Before the DQ World Education`,
    dq_code__after_the_dq_world_edu: `After the DQ World Education`,
    dq_code__digital_citizen_identity: `Digital Citizen Identity`,
    dq_code__balanced_use_of_technology: `Balanced Use of Technology`,
    dq_code__behavioural_cyber_risk_management: `Behavioural Cyber-Risk Management`,
    dq_code__personal_cyber_security_management: `Personal Cyber Security Management`,
    dq_code__digital_empathy: `Digital Empathy`,
    dq_code__digital_footprint_management: `Digital Footprint Management`,
    dq_code__media_and_information_literacy: `Media and Information Literacy`,
    dq_code__privacy_management: `Privacy Management`,
    dq_code__digital_co_creator_identity: `Digital Co-Creator Identity`,
    dq_code__healthy_use_of_technology: `Healthy Use of Technology`,
    dq_code__content_cyber_risk_management: `Content Cyber-Risk Management`,
    dq_code__network_security_management: `Network Security Management`,
    dq_code__self_awareness_and_management: `Self-Awareness and Management`,
    dq_code__online_communication_and_collaboration: `Online Communication and Collaboration`,
    dq_code__content_creation_and_computational_literacy: `Content Creation and Computational Literacy`,
    dq_code__intellectual_property_rights_management: `Intellectual Property Rights Management`,
    dq_code__digital_changemaker_identity: `Digital Changemaker Identity`,
    dq_code__civic_use_of_technology: `Civic Use of Technology`,
    dq_code__commercial_and_community_cyber_risk_management: `Commercial and Community Cyber-Risk Management`,
    dq_code__organisational_cyber_security_management: `Organisational Cyber Security Management`,
    dq_code__relationship_management: `Relationship Management`,
    dq_code__public_and_mass_communication: `Public and Mass Communication`,
    dq_code__data_and_ai_literacy: `Data and AI Literacy`,
    dq_code__participatory_rights_management: `Participatory Rights Management`,

    dq_def__digital_citizen_identity: `The ability to build and manage a healthy identity as a digital citizen with integrity.`,
    dq_def__balanced_use_of_technology: `The ability to manage one's life both online and offline in a balanced way by exercising self-control to manage screen time, multitasking, and one's engagement with digital media and devices.`,
    dq_def__behavioural_cyber_risk_management: `The ability to identify, mitigate, and manage cyber risks (e.g., cyberbullying, harassment, and stalking) that relate to personal online behaviors.`,
    dq_def__personal_cyber_security_management: `The ability to detect cyber threats (e.g., hacking, scams, and malware) against personal data and device, and to use suitable security strategies and protection tools.`,
    dq_def__digital_empathy: `The ability to be aware of, be sensitive to, and be supportive of one's own and other's feelings, needs and concerns online.`,
    dq_def__digital_footprint_management: `The ability to understand the nature of digital footprints and their real-life consequences, to manage them responsibly, and to actively build a positive digital reputation.`,
    dq_def__media_and_information_literacy: `The ability to find, organize, analyze, and evaluate media and information with critical reasoning.`,
    dq_def__privacy_management: `The ability to handle with discretion all personal information shared online to protect one's and others' privacy.`,
    dq_def__digital_co_creator_identity: `The ability to identify and develop oneself as a co-creator of the digital ecosystem.`,
    dq_def__healthy_use_of_technology: `The ability to understand the benefits and harms of technology on one's mental and physical health and to use technology use while prioritizing health and well-being.`,
    dq_def__content_cyber_risk_management: `The ability to identify, mitigate, and manage content cyber risks online (e.g., harmful usergenerated content, racist/hateful content, image-based abuse).`,
    dq_def__network_security_management: `The ability to detect, avoid, and manage cyber-threats to cloud-based collaborative digital environments.`,
    dq_def__self_awareness_and_management: `The ability to recognize and manage how one's value system and digital competencies fits with one's digital environment.`,
    dq_def__online_communication_and_collaboration: `The ability to use technology effectively to communicate and collaborate collectively, including at a distance.`,
    dq_def__content_creation_and_computational_literacy: `The ability to synthesise, create, and produce information, media, and technology in an innovative and creative manner.`,
    dq_def__intellectual_property_rights_management: `The ability to understand and manage intellectual property rights (e.g., copyrights, trademarks, and patents) when using and creating content and technology.`,
    dq_def__digital_changemaker_identity: `The ability to identify and develop oneself as a competent changemaker in the digital economy.`,
    dq_def__civic_use_of_technology: `The ability to engage in civic participation for the well-being and growth of local, national, and global communities using technology.`,
    dq_def__commercial_and_community_cyber_risk_management: `The ability to understand, mitigate, and manage commercial or community cyber-risks online, which is an organisational attempt to exploit individuals financially and/or through ideological persuasion (e.g., embedded marketing, online propaganda, and gambling).`,
    dq_def__organisational_cyber_security_management: `The ability to recognise, plan, and implement organisational cyber security defences.`,
    dq_def__relationship_management: `The ability to skilfully manage one's online relationships through collaboration, conflict management, and persuasion.`,
    dq_def__public_and_mass_communication: `The ability to communicate with an online audience effectively to exchange messages, ideas, and opinions reflecting wider business or societal discourses.`,
    dq_def__data_and_ai_literacy: `The ability to generate, process, analyze, present meaningful information from data and develop, use, and apply artificial intelligence (AI) and related algorithmic tools and strategies in order to guide informed, optimized, and contextually relevant decision-making processes.`,
    dq_def__participatory_rights_management: `The ability to understand and exercise one's powers and right to online participation (e.g., their rights to personal data protection, freedom of expression, or to be forgotten).`,

    //country
    dq_country__afghanistan: `Afghanistan`,
    dq_country__aland_islands: `Åland Islands`,
    dq_country__albania: `Albania`,
    dq_country__algeria: `Algeria`,
    dq_country__american_samoa: `American Samoa`,
    dq_country__andorra: `AndorrA`,
    dq_country__angola: `Angola`,
    dq_country__anguilla: `Anguilla`,
    dq_country__antarctica: `Antarctica`,
    dq_country__antigua_and_barbuda: `Antigua and Barbuda`,
    dq_country__argentina: `Argentina`,
    dq_country__armenia: `Armenia`,
    dq_country__aruba: `Aruba`,
    dq_country__australia: `Australia`,
    dq_country__austria: `Austria`,
    dq_country__azerbaijan: `Azerbaijan`,
    dq_country__bahamas: `Bahamas`,
    dq_country__bahrain: `Bahrain`,
    dq_country__bangladesh: `Bangladesh`,
    dq_country__barbados: `Barbados`,
    dq_country__belarus: `Belarus`,
    dq_country__belgium: `Belgium`,
    dq_country__belize: `Belize`,
    dq_country__benin: `Benin`,
    dq_country__bermuda: `Bermuda`,
    dq_country__bhutan: `Bhutan`,
    dq_country__bolivia: `Bolivia`,
    dq_country__bosnia_and_herzegovina: `Bosnia and Herzegovina`,
    dq_country__botswana: `Botswana`,
    dq_country__bouvet_island: `Bouvet Island`,
    dq_country__brazil: `Brazil`,
    dq_country__british_indian_ocean_territory: `British Indian Ocean Territory`,
    dq_country__brunei_darussalam: `Brunei Darussalam`,
    dq_country__bulgaria: `Bulgaria`,
    dq_country__burkina_faso: `Burkina Faso`,
    dq_country__burundi: `Burundi`,
    dq_country__cambodia: `Cambodia`,
    dq_country__cameroon: `Cameroon`,
    dq_country__canada: `Canada`,
    dq_country__cape_verde: `Cape Verde`,
    dq_country__cayman_islands: `Cayman Islands`,
    dq_country__central_african_republic: `Central African Republic`,
    dq_country__chad: `Chad`,
    dq_country__chile: `Chile`,
    dq_country__china: `China`,
    dq_country__christmas_island: `Christmas Island`,
    dq_country__cocos_keeling_islands: `Cocos (Keeling) Islands`,
    dq_country__colombia: `Colombia`,
    dq_country__comoros: `Comoros`,
    dq_country__congo: `Congo`,
    dq_country__congo_the_democratic_republic_of_the: `Congo, The Democratic Republic of the`,
    dq_country__cook_islands: `Cook Islands`,
    dq_country__costa_rica: `Costa Rica`,
    dq_country__cote_divoire: `Cote D'Ivoire`,
    dq_country__croatia: `Croatia`,
    dq_country__cuba: `Cuba`,
    dq_country__cyprus: `Cyprus`,
    dq_country__czech_republic: `Czech Republic`,
    dq_country__denmark: `Denmark`,
    dq_country__djibouti: `Djibouti`,
    dq_country__dominica: `Dominica`,
    dq_country__dominican_republic: `Dominican Republic`,
    dq_country__ecuador: `Ecuador`,
    dq_country__egypt: `Egypt`,
    dq_country__el_salvador: `El Salvador`,
    dq_country__equatorial_guinea: `Equatorial Guinea`,
    dq_country__eritrea: `Eritrea`,
    dq_country__estonia: `Estonia`,
    dq_country__ethiopia: `Ethiopia`,
    dq_country__falkland_islands_malvinas: `Falkland Islands (Malvinas)`,
    dq_country__faroe_islands: `Faroe Islands`,
    dq_country__fiji: `Fiji`,
    dq_country__finland: `Finland`,
    dq_country__france: `France`,
    dq_country__french_guiana: `French Guiana`,
    dq_country__french_polynesia: `French Polynesia`,
    dq_country__french_southern_territories: `French Southern Territories`,
    dq_country__gabon: `Gabon`,
    dq_country__gambia: `Gambia`,
    dq_country__georgia: `Georgia`,
    dq_country__germany: `Germany`,
    dq_country__ghana: `Ghana`,
    dq_country__gibraltar: `Gibraltar`,
    dq_country__greece: `Greece`,
    dq_country__greenland: `Greenland`,
    dq_country__grenada: `Grenada`,
    dq_country__guadeloupe: `Guadeloupe`,
    dq_country__guam: `Guam`,
    dq_country__guatemala: `Guatemala`,
    dq_country__guernsey: `Guernsey`,
    dq_country__guinea: `Guinea`,
    dq_country__guinea_bissau: `Guinea-Bissau`,
    dq_country__guyana: `Guyana`,
    dq_country__haiti: `Haiti`,
    dq_country__heard_island_and_mcdonald_islands: `Heard Island and Mcdonald Islands`,
    dq_country__holy_see_vatican_city_state: `Holy See (Vatican City State)`,
    dq_country__honduras: `Honduras`,
    dq_country__hong_kong: `Hong Kong`,
    dq_country__hungary: `Hungary`,
    dq_country__iceland: `Iceland`,
    dq_country__india: `India`,
    dq_country__indonesia: `Indonesia`,
    dq_country__iran_islamic_republic_of: `Iran, Islamic Republic Of`,
    dq_country__iraq: `Iraq`,
    dq_country__ireland: `Ireland`,
    dq_country__isle_of_man: `Isle of Man`,
    dq_country__israel: `Israel`,
    dq_country__italy: `Italy`,
    dq_country__jamaica: `Jamaica`,
    dq_country__japan: `Japan`,
    dq_country__jersey: `Jersey`,
    dq_country__jordan: `Jordan`,
    dq_country__kazakhstan: `Kazakhstan`,
    dq_country__kenya: `Kenya`,
    dq_country__kiribati: `Kiribati`,
    dq_country__korea_democratic_peoples_republic_of: `Korea, Democratic People's Republic of`,
    dq_country__korea_republic_of: `Korea, Republic of`,
    dq_country__kuwait: `Kuwait`,
    dq_country__kyrgyzstan: `Kyrgyzstan`,
    dq_country__lao_peoples_democratic_republic: `Lao People's Democratic Republic`,
    dq_country__latvia: `Latvia`,
    dq_country__lebanon: `Lebanon`,
    dq_country__lesotho: `Lesotho`,
    dq_country__liberia: `Liberia`,
    dq_country__libyan_arab_jamahiriya: `Libyan Arab Jamahiriya`,
    dq_country__liechtenstein: `Liechtenstein`,
    dq_country__lithuania: `Lithuania`,
    dq_country__luxembourg: `Luxembourg`,
    dq_country__macao: `Macao`,
    dq_country__macedonia_the_former_yugoslav_republic_of: `Macedonia, The Former Yugoslav Republic of`,
    dq_country__madagascar: `Madagascar`,
    dq_country__malawi: `Malawi`,
    dq_country__malaysia: `Malaysia`,
    dq_country__maldives: `Maldives`,
    dq_country__mali: `Mali`,
    dq_country__malta: `Malta`,
    dq_country__marshall_islands: `Marshall Islands`,
    dq_country__martinique: `Martinique`,
    dq_country__mauritania: `Mauritania`,
    dq_country__mauritius: `Mauritius`,
    dq_country__mayotte: `Mayotte`,
    dq_country__mexico: `Mexico`,
    dq_country__micronesia_federated_states_of: `Micronesia, Federated States of`,
    dq_country__moldova_republic_of: `Moldova, Republic of`,
    dq_country__monaco: `Monaco`,
    dq_country__mongolia: `Mongolia`,
    dq_country__montserrat: `Montserrat`,
    dq_country__morocco: `Morocco`,
    dq_country__mozambique: `Mozambique`,
    dq_country__myanmar: `Myanmar`,
    dq_country__namibia: `Namibia`,
    dq_country__nauru: `Nauru`,
    dq_country__nepal: `Nepal`,
    dq_country__netherlands: `Netherlands`,
    dq_country__netherlands_antilles: `Netherlands Antilles`,
    dq_country__new_caledonia: `New Caledonia`,
    dq_country__new_zealand: `New Zealand`,
    dq_country__nicaragua: `Nicaragua`,
    dq_country__niger: `Niger`,
    dq_country__nigeria: `Nigeria`,
    dq_country__niue: `Niue`,
    dq_country__norfolk_island: `Norfolk Island`,
    dq_country__northern_mariana_islands: `Northern Mariana Islands`,
    dq_country__norway: `Norway`,
    dq_country__oman: `Oman`,
    dq_country__pakistan: `Pakistan`,
    dq_country__palau: `Palau`,
    dq_country__palestinian_territory_occupied: `Palestinian Territory, Occupied`,
    dq_country__panama: `Panama`,
    dq_country__papua_new_guinea: `Papua New Guinea`,
    dq_country__paraguay: `Paraguay`,
    dq_country__peru: `Peru`,
    dq_country__philippines: `Philippines`,
    dq_country__pitcairn: `Pitcairn`,
    dq_country__poland: `Poland`,
    dq_country__portugal: `Portugal`,
    dq_country__puerto_rico: `Puerto Rico`,
    dq_country__qatar: `Qatar`,
    dq_country__reunion: `Reunion`,
    dq_country__romania: `Romania`,
    dq_country__russian_federation: `Russian Federation`,
    dq_country__rwanda: `RWANDA`,
    dq_country__saint_helena: `Saint Helena`,
    dq_country__saint_kitts_and_nevis: `Saint Kitts and Nevis`,
    dq_country__saint_lucia: `Saint Lucia`,
    dq_country__saint_pierre_and_miquelon: `Saint Pierre and Miquelon`,
    dq_country__saint_vincent_and_the_grenadines: `Saint Vincent and the Grenadines`,
    dq_country__samoa: `Samoa`,
    dq_country__san_marino: `San Marino`,
    dq_country__sao_tome_and_principe: `Sao Tome and Principe`,
    dq_country__saudi_arabia: `Saudi Arabia`,
    dq_country__senegal: `Senegal`,
    dq_country__serbia_and_montenegro: `Serbia and Montenegro`,
    dq_country__seychelles: `Seychelles`,
    dq_country__sierra_leone: `Sierra Leone`,
    dq_country__singapore: `Singapore`,
    dq_country__slovakia: `Slovakia`,
    dq_country__slovenia: `Slovenia`,
    dq_country__solomon_islands: `Solomon Islands`,
    dq_country__somalia: `Somalia`,
    dq_country__south_africa: `South Africa`,
    dq_country__south_georgia_and_the_south_sandwich_islands: `South Georgia and the South Sandwich Islands`,
    dq_country__spain: `Spain`,
    dq_country__sri_lanka: `Sri Lanka`,
    dq_country__sudan: `Sudan`,
    dq_country__suriname: `Suriname`,
    dq_country__svalbard_and_jan_mayen: `Svalbard and Jan Mayen`,
    dq_country__swaziland: `Swaziland`,
    dq_country__sweden: `Sweden`,
    dq_country__switzerland: `Switzerland`,
    dq_country__syrian_arab_republic: `Syrian Arab Republic`,
    dq_country__taiwan_province_of_china: `Taiwan, Province of China`,
    dq_country__tajikistan: `Tajikistan`,
    dq_country__tanzania_united_republic_of: `Tanzania, United Republic of`,
    dq_country__thailand: `Thailand`,
    dq_country__timor_leste: `Timor-Leste`,
    dq_country__togo: `Togo`,
    dq_country__tokelau: `Tokelau`,
    dq_country__tonga: `Tonga`,
    dq_country__trinidad_and_tobago: `Trinidad and Tobago`,
    dq_country__tunisia: `Tunisia`,
    dq_country__turkey: `Turkey`,
    dq_country__turkmenistan: `Turkmenistan`,
    dq_country__turks_and_caicos_islands: `Turks and Caicos Islands`,
    dq_country__tuvalu: `Tuvalu`,
    dq_country__uganda: `Uganda`,
    dq_country__ukraine: `Ukraine`,
    dq_country__united_arab_emirates: `United Arab Emirates`,
    dq_country__united_kingdom: `United Kingdom`,
    dq_country__united_states: `United States`,
    dq_country__united_states_minor_outlying_islands: `United States Minor Outlying Islands`,
    dq_country__uruguay: `Uruguay`,
    dq_country__uzbekistan: `Uzbekistan`,
    dq_country__vanuatu: `Vanuatu`,
    dq_country__venezuela: `Venezuela`,
    dq_country__viet_nam: `Viet Nam`,
    dq_country__virgin_islands_british: `Virgin Islands, British`,
    dq_country__virgin_islands_us: `Virgin Islands, U.S.`,
    dq_country__wallis_and_futuna: `Wallis and Futuna`,
    dq_country__western_sahara: `Western Sahara`,
    dq_country__yemen: `Yemen`,
    dq_country__zambia: `Zambia`,
    dq_country__zimbabwe: `Zimbabwe`,

    //partner program overview
    overview__dq_impact_rating: "DQ Impact Rating",
    overview__impact_rating: "Impact Rating",
    overview__out_of: "Out of",
    overview__program: "Program",
    overview__area: "Area",
    overview__organisation: "Organisation",
    overview__target_pop: "Target Population",
    overview__group: "Group",
    overview__age: "Age",
    overview__countries: "Countries",
    overview__content_quality: "Content Quality",
    overview__program_reach: "Program Reach",
    overview__comp_improve: "Competency Improvement",
    overview__program_name: "Name of the Program",
    overview__contact_persons: "Contact Person(s)",
    overview__objectives: "Objectives of the Program",
    overview__program_delivery: "Program Delivery",
    overview__venue: "Venue",
    overview__target_audiences: "Target Audiences",
    overview__time_taken: "Time Taken for the Program to be Completed",
    overview__cost_of_use: "Cost of Use",
    overview__year_of_implement: "Year of Implementation and Status",
    overview__ref_frameworks: "Reference Frameworks",
    overview__ref_frameworks_none: "None Used",
    overview__mod_dq24: "National Programs",
    overview__mod_dq24_desc: "The following is a list of programs in your nation focused on digital citizenship and digital literacy.",
    overview__learn_mod_id: "Org. Name",
    overview__learn_title: "Program Name",
    overview__learn_objectives: "Program Objective",
    overview__target_group_age: "Target Group Age",
    overview__dq24_align: "DQ32 Alignment",
    overview__about_report: "About This Dashboard",
    overview__dq_impact_report: "The DQ Impact Rating Dashboard",
    overview__report_intro: "is a summary report for nations to visualize their nation’s digital citizenship education/training programs that teaches digital competencies to understand its impact. The DQ Impact Rating is a compound score that indicates the level of content quality of the program and the impact generated so far in advancing digital skills of nations. It is a standardized impact measure based on the global benchmark using the IEEE DQ Global Standards for digital literacy, digital skills, and digital readiness (IEEE 3527.1). The program was reviewed and analysed by the DQ Institute researchers based on the materials submitted by the Program owner and/or publicly available materials.",
    overview__report_design: "The report is designed for",
    overview__prog_owner: "National Stakeholders:",
    overview__prog_owner_reason_1: "to understand the levels of your nation’s digital citizenship programs’ quality",
    overview__prog_owner_reason_2: "o have useful data-driven assessment on the program's quality and impact to facilitate their decision making on enhancing online safety and digital competencies of target population",
    overview__stakeholder: "Key stakeholders including policy makers, and funders/investors",
    overview__stakeholder_reason_1: "to have useful data-driven assessment on the program's quality and impact to facilitate their decision making on enhancing online safety and digital competencies of target population.",
    overview__report_composition: "The report composed of three sections",
    overview__section_1: "Section 1",
    overview__section_2: "Section 2",
    overview__section_3: "Section 3",
    overview__baselining_desc: "Understanding of your country’s digital literacy programs’ impact.",
    overview__assessment_desc: "Understanding the DQ Impact Rating of the Program",
    overview__enhancement_desc: "Understanding the Potential Areas for Enhancement of the Program",
    overview__key_terms: "Key Terms",
    overview__skill_acquire: "Skill Acquisition",
    overview__skill_acquire_desc: "when a module or assessment achieves a certain level or higher.",
    overview__skill_improve: "Skill Improvement",
    overview__skill_improve_desc: "is judged to have improved when it has improved to some extent as a result of comparing the pre/post questions of the module.",
    overview__intention: "Intention for Change",
    overview__intention_desc: "proceeds through the program and, at the end of each module, asks the user if this module makes him think about changing his/her behavior.",

    //school program overview
    overview__following_programs: "The following are details about the programs your school(s) is participating in.",
    overview__about: "About",
    overview__type: "Type",
    overview__time_to_complete: "Time to Complete",
    overview__dct_title: "Digital Citizenship Test (DCT)",
    overview__dct_desc: "The DCT is a quick and easy test to measure a child’s DQ. Students’ scores will be aggregated for schools to see how they compare to the national and global averages.",
    overview__dct_type: "Assessment",
    overview__dct_time: "30 Minutes",
    overview__dqw_title: "DQ World (DQW)",
    overview__dqw_desc: "DQ World is an online learning platform for children to learn and improve their 8 digital citizenship skills through educational lessons, animated videos, and mini-games.",
    overview__dqw_type: "Education",
    overview__dqw_time: "8-10 Hours",
    overview__about_dq_score: "About the DQ Score",
    overview__following_breakdown: "The following is a breakdown of the DQ Score meaning.",
    overview__knowledge: "Knowledge",
    overview__skills: "Skills",
    overview__attitude: "Attitude and values",
    overview__excellent: "Excellent",
    overview__satisfy: "Satisfactory",
    overview__less_satisfy: "Less than satisfactory",
    overview__attention: "Requires Attention",
    overview__excellent_criteria: "Above or equal to 115",
    overview__satisfy_criteria: "Above or equal to 100, but below 115",
    overview__less_satisfy_criteria: "Above or equal to 85, but below 100",
    overview__attention_criteria: "Below 85",
    overview__knowledge_excellent: "Excellent understanding of how the Internet and digital media operate and of their impact on building a healthy online identity.",
    overview__knowledge_satisfy: "Relatively strong understanding of how the Internet and digital media operate and of their impact on building a healthy online identity.",
    overview__knowledge_less_satisfy: "Relatively weak understanding of how the Internet and digital media operate and of their impact on building a healthy online identity.",
    overview__knowledge_attention: "Limited understanding of how the Internet and digital media operate and of their impact on building a healthy online identity.",
    overview__skills_excellent: "Highly capable of building a healthy, congruent online identity. Demonstrates thorough readiness to use technology to solve problems or accomplish goals.",
    overview__skills_satisfy: "Better able than peers to build a healthy, congruent online identity. Readier than peers to use technology to solve problems or accomplish goals.",
    overview__skills_less_satisfy: "Less able than peers to build a healthy, congruent online identity. Less ready than peers to use technology to solve problems or accomplish goals.",
    overview__skills_attention: "Lacks critical abilities to build a healthy, congruent online identity and/or demonstrates counterproductive habits online (i.e. behaving differently to be popular or to avoid accountability). Not ready to use technology to solve problems or accomplish goals.",
    overview__attitude_excellent: "Demonstrates outstanding integrity by cultivating a consistent and responsible online identity. Very likely to show tolerance and respect in online communication and to be cultivating the mindset of a global digital citizen.",
    overview__attitude_satisfy: "Has relatively greater integrity than peers with regards to online identity. More likely than peers to show tolerance and respect in online communication.",
    overview__attitude_less_satisfy: "Has relatively less integrity than peers with regards to online identity. Less likely than peers to show tolerance and respect in online communication.",
    overview__attitude_attention: "Has little integrity with regards to online identity. Unlikely to show tolerance and respect for others in online communication.",

    //partner baselining
    baselining_only_dig_desc: "Understanding the DQ Impact Rating of your nation’s digital citizenship programs.",
    baselining__dig_comp_lvl: "Digital Competencies Level",
    baselining__dig_comp_desc: "What is the level of the Program's participants' digital competencies compared to the national and global averages?",
    baselining__dig_comp_range: "Digital competency scores range from 65-135",
    baselining__covered_by: "covered by",
    baselining__prog_avg: "Program(s) Impact Score",
    baselining__countries_avg: "Average of selected countries",
    baselining__glob_avg: "Global Average",
    baselining__nation_avg: "National Average",
    baselining__company_avg: "Company Average",
    baselining__dq_comp_scores: "SCORES BY DQ COMPETENCIES",
    baselining__annual_score: "Annual Score Trend",
    baselining__cyber_risk_lvl: "Cyber-Risks Level",
    baselining__cyber_risk_desc: "What is the level of the exposure to cyber-risks of the target population compared to the national and global averages?",
    baselining__in_past_year: "in the past year (%)",
    baselining__annual_trend: "Annual Trend",
    baselining__no_risks: "No Risks",
    baselining__max_added_error: "Maximum adding limit reached. Remove first.",
    baselining__program: "Program",
    baselining__company: "Company",
    baselining__national: "National",
    baselining__global: "Global",

    //school baselining
    baselining__dig_skills_lvl: "Digital Skills Level",
    baselining__school_desc: "Understanding the Levels of Digital Skills and Cyber-Risks of the School's Target Population",
    baselining__school_dig_comp_desc: "What is your school's level of digital competencies compared to the national and global averages?",
    baselining__dq_score: "DQ Score",
    baselining__school_avg: "School Average",
    baselining__dq_score_break: "DQ Score Breakdown",
    baselining__mod_overview: "Modules Overview",
    baselining__mods: "Modules",
    baselining__avg_score: "Average Score",
    baselining__completion_rate: "Completion Rate",
    baselining__school_cyber_risk_desc: "What is the level of your students’ exposure to cyber-risks compared to the national and global averages?",
    baselining__cyber_risk_country_compare: "National Cyber-risks Compared to Other Countries",
    baselining__percent_risk: "% of Risk",
    baselining__school: "School",

    //assessment content
    assessment__content_desc: "What is the quality of the Program's learning content?",
    assessment__comprehensive: "Comprehensiveness",
    assessment__comprehensive_score: "Comprehensive Score",
    assessment__comprehensive_desc: "Does the program's learning content comprehensively and appropriately cover digital intelligence competencies in the IEEE DQ Global Standards (IEEE 3527.1)?",
    assessment__curriculum_cov: "Curriculum Coverage",
    assessment__less_cov: "Less Coverage",
    assessment__more_cov: "More Coverage",
    assessment__cov_percent: "Coverage %",
    assessment__dig_citizen: "Digital Citizenship",
    assessment__dig_creative: "Digital Creativity",
    assessment__footer: "Learn more the detailed results of the analysis, please check",
    assessment__align_analysis: "Section 3.1. Alignment Analysis",
    //assessment reach
    assessment__reach_desc: "How widely, efficiently, and inclusively the program has been adopted by target population",
    assessment__participation_desc: "How many individuals have completed at least one Module of the Program",
    assessment__participation: "Participation",
    assessment__total_participation: "Total Participation",
    assessment__participants: "Participants",
    assessment__inclusive_ses_desc: "Has the Program been used inclusively across different gender, age, and SES group?",
    assessment__inclusive_desc: "Has the Program been used inclusively across different gender and age?",
    assessment__by_adii: "By ADII",
    assessment__by_ses: "By SES",
    assessment__by_indigenous: "By Indigenous",
    assessment__by_gender: "By Gender",
    assessment__by_age: "By Age",
    assessment__by_school_lvl: "By School Level",
    assessment__enhancement_reach: "Section 3.2. Enhancement Reach",
    //assessment reach school
    assessment__school_participation_desc: "How many students have participated in the program?",
    assessment__inclusive_school_lvl_desc: "Has the Program been used inclusively across different gender, age and school levels?",

    //assessment efficacy
    assessment__efficacy_desc: "How well the Program helps improve participants' digital competence across knowledge, skills, and attitudes",
    assessment__acq: "Competency Acquisition",
    assessment__acq_desc: "What is the proportion of the participants who have acquired at least one digital competency through the Program?",
    assessment__acq_complete: "Competencies Acquired",
    assessment__acq_rate: "Competency Acquisition Rate",
    assessment__imp: "Competency Improvement",
    assessment__imp_desc: "What is the proportion of the participants who have shown a significant improvement of at least one digital competency through the Program?",
    assessment__imp_complete: "Competencies Improved",
    assessment__imp_rate: "Competency Improvement Rate",
    assessment__int: "Intention for Change",
    assessment__int_desc: "What is the proportion of the participants who have shown the intention to change behaviour in at least one digital competency positively after completing the Program?",
    assessment__int_complete: "Intention for Change",
    assessment__int_rate: "Intention for Change Rate",
    assessment__no_region: "No Region",
    assessment__no_province: "No Province",
    assessment__no_category: "No Category",
    //assessment efficacy school
    assessment__mod_score: "Module Scores",
    assessment__mod_score_desc: "What are the average scores of participants by modules?",
    assessment__mod_score_complete: "Module Score",

    //enhancement content
    enhancement__content_desc: "How well does the Program help improve participants' digital competence across knowledge, skills, and attitudes?",
    enhancement__degree_align: "The degree of alignment of the program's learning messages with DQ (%)",
    enhancement__total_align: "Total Alignment",
    enhancement__associate_mod: "Associated Modules",
    //enhancement reach
    enhancement__reach_desc: "Check the following detailed analyses of the Program reach and find the areas for improvement. The potential areas that require attention to enhance the reach are highlighted in pink.",
    enhancement__by_country: "By Program",
    enhancement__by_month: "By Month",
    enhancement__by_period: "By Period",
    enhancement__by_module: "By Module",
    enhancement__by_school: "By School",
    enhancement__participants_count: "# of Participants",
    enhancement__participants_percent: "% of Participants",
    enhancement__title: "Title",
    enhancement__region: "Region",
    enhancement__province: "Province",
    enhancement__postal: "Postal Code",
    enhancement__inst: "Institution Code",
    enhancement__month: "Month",
    enhancement__module: "Module",
    enhancement__gender: "Gender",
    enhancement__age_group: "Age Group",
    enhancement__adii: "ADII",
    enhancement__ses: "SES",
    enhancement__school: "School",
    enhancement__class: "Class",
    enhancement__region_need: "Select a region to display data",
    enhancement__province_need: "Select a province to display data",
    enhancement__postal_need: "Select a postal code to display data",
    enhancement__inst_need: "Select an institution code to display data",
    enhancement__mod_need: "Select a module to display data",
    enhancement__school_need: "Select a school to display data",
    enhancement__class_need: "Select a class to display data",
    enhancement__indigenous: "Indigenous Status",
    enhancement__school_level: "School Level",
    enhancement__by_region_footer: "if the number of participants in the region is less than 10% of the total participants",
    enhancement__by_province_footer: "if the number of participants in the province is less than 10% of the total participants",
    enhancement__by_month_footer: "if the number of participants in the specific month is less than 100",
    enhancement__by_module_footer: "if the number of participants of the specific module is less than 10% of the total participants",
    enhancement__by_gender_footer: "if the female or male participants are less than 30% of the total participants",
    enhancement__by_subgroup_footer: "if the number of participants within the sub-group of the specific module is less than 50",
    //enhancement efficacy
    enhancement__efficacy_desc: "Check the following detailed analyses of the Program efficacy.",
    enhancement__acq_desc: "Percentage of each demographic who have acquired one or more DQ competences through the Program.",
    enhancement__imp_desc: "Percentage of each demographic who have shown a significant improvement in one or more DQ competences through the Program.",
    enhancement__int_desc: "Percentage of each demographic who have shown the intention to positively change behaviour in one or more DQ competences after completing the Program.",
    enhancement__composite_total: "Composite Total",
    enhancement__totals: "Totals",
    //enhancement efficacy school
    enhancement__mod_score_desc: "What are the average module scores of participants by demographics?",

    //in-depth analysis
    analysis__in_depth: "In-depth Analysis",
    analysis__in_depth_desc: "The following is the in-depth analysis of your students from the DQ World program",
    analysis__in_depth_desc_2: "Click the following link to view the school results",
    analysis__view: "View Analysis",

    //progress
    progress__school_name: "School Name",
    progress__school_lvl: "School Level",
    progress__student_count: "Students Count",
    progress__school_view_detail: "View School Details",
    progress__school_info: "School Information",
    progress__school_head_name: "School Head Name",
    progress__school_head_email: "School Head Email",
    progress__class_name: "Class Name",
    progress__class_lvl: "Class Level",
    progress__class_download_data: "Download Class Data",
    progress__student_id: "Student ID",
    progress__student_view_detail: "View Student Details",
    progress__student_info: "Student Information",
    progress__parent_email: "Parent Email",
    progress__dob: "DOB",
    progress__reset_pass: "Reset Password",
    progress__link_gen: "Link Generated",
    progress__clipboard_to_copy: "Click clipboard to copy link!",

    //pending activation
    activation__activation_link: "Activation Link",
    activation__revoke: "Revoke",
    activation__resend_email: "Resend Email",
    activation__search_by_email: "Search by email",
    activation__unable_revoke: "Unable to revoke: No username associated with this user",
    activation__no_username: "No username associated with this user",

    //licenses
    licenses__total_licenses: "Total Licenses",
    licenses__used_licenses: "Used Licenses",
    licenses__unused_licenses: "Unused Licenses",
    licenses__add_users: "Add Licenses",
    licenses__download_csv: "Download File",
    licenses__upload_csv: "Upload File",
    licenses__download_the_csv_file: "1. Download the excel (.xlsx) file.",
    licenses__fill_out_the_csv_file_completely: "2. Fill out the excel file completely. Do not leave any fields blank. You will need your users first and last name, department, job title, birthdate, email address, gender and postal code.",
    licenses__fill_out_the_csv_file_completely__school: "2. Fill out the excel file completely. Do not leave any fields blank. You will need your students' first and last name, class name, class level, birthdate, parent email address, gender and postal code.",
    licenses__upload_the_csv_file: "3. Upload the excel (.xlsx) file.",
    licenses__the_system_will_then_send_out_activation_emails: "4. The system will then send out activation emails to the users you listed in the excel file. Once the user goes to the email, they will login with the activation link and set the password to proceed further.",
    licenses__the_system_will_then_send_out_activation_emails__school: "4. The system will then send out activation emails to the student’s parent you listed in the excel file. Once the parent receives the email, they will need to login with the activation link and reset a new password for their child to proceed further. Please remember to have the parent pass the username and password to the child to login.",
    licenses__as_long_as_you_have_unused_licenses_p1: '5. As long as you have unused licenses, you can just add new accounts to the old excel file and upload it. You can also adjust any user information in the excel file except the email addresses. For changes to email addresses, please contact us at',
    licenses__as_long_as_you_have_unused_licenses_p2: '.',
    licenses__in_case_you_no_longer_possess: "6. In case you no longer possess the old excel file, download the existing data from our website.",
    licenses__errors: 'Errors',
    licenses__invalid_records: "Invalid records found! Scroll down the page to see details.",
    licenses__upload_success: "File uploaded successfully",
    licenses__upload_invalid_type: "Invalid file type! Please upload .xlsx/.csv file only",
    licenses__allowed_domain_hint: "Only allowed Open-ID emails are from domains",

    //resources
    resources__title: "Resources",
    resources__dq_world: "DQ World Resources",
    resources__intro_to_dqw: "Intro to DQ World",
    resources__intro_to_dqw_full: "Introduction to DQ World",
    resources__parent_consent_letter: "Parent Consent Letter",
    resources__skill_packs: "Skill Packs",
    resources__teacher_mats: "Teacher's Materials",
    resources__goto_dqw_student: "Go to DQ World Student Site",
    resources__watch_short_vid: "Watch the short video on the right to find out what DQ World is all about. You can also share this video with your students to give them a brief introduction of what our programme does.",
    resources__vid_time: "Video Time",
    resources__dqw_curriculum: "The DQ World Curriculum - 8 Digital Life Skills",
    resources__dci: "Digital Citizen Identity",
    resources__dci_desc: "Ability to build and manage a healthy congruent identity online and offline with integrity.",
    resources__stm: "Screen Time Management",
    resources__stm_desc: "Ability to manage one’s screen time, multitasking, and one’s engagement in online games and social media with self-control.",
    resources__cbm: "Cyber Bullying Management",
    resources__cbm_desc: "Ability to detect cyber-bullying situations and handle them wisely.",
    resources__csm: "Cyber Security Management",
    resources__csm_desc: "Ability to protect one’s data by creating strong passwords and to manage various cyber attacks.",
    resources__pm: "Privacy Management",
    resources__pm_desc: "Ability to handle with discretion all personal information shared online to protect one’s and others’ privacy.",
    resources__ct: "Critical Thinking",
    resources__ct_desc: "Ability to distinguish between true and false info, good and harmful content, and trustworthy and questionable contacts online.",
    resources__df: "Digital Footprints",
    resources__df_desc: "Ability to understand the nature of digital footprints and their real-life consequences and to manage them responsibly.",
    resources__de: "Digital Empathy",
    resources__de_desc: "Ability to be empathetic towards one’s own and others’ needs and feelings online.",
    resources__parental_consent: "Parental Consent",
    resources__parental_consent_desc_p1: "The Parental Consent Letter is to make sure parents know what their children will be learning on DQ World. As part of an ongoing research, it is important that parents are clear on what the programme entails.",
    resources__parental_consent_desc_p2: "We have attached a Parental Consent Letter template to help explain. Teachers should print these out and get their students’ parents to sign and return if they choose to opt out. Teachers can also modify as needed for their school. Click the following button to download.",
    resources__get_started_guide: "Get Started Guide",
    resources__skill_packs_desc: "DQ Skill Packs help you to teach DQ to your students. Click on each Skill Pack to see an overview, key objectives, and estimated lesson time for each Zone. While we recommend teachers progress through Zones in order starting with Zone 1, teachers may choose to focus on any Zone according to their teaching needs.",
    resources__objective: "Objective",
    resources__learn_points: "Key Learning Points",
    resources__time_to_complete: "Time to Complete",

    resources__guide_title: "Getting started with DQ World",
    resources__guide_obj: "This guide helps lead educators set up and administer their school account DQWorld.net. After reading it, you should know how to manage classes, assign classes to teachers, reset passwords and more.",
    resources__guide_point_1: "1. Signing Up",
    resources__guide_point_2: "2. The Teacher’s Admin Panel",
    resources__guide_point_3: "3. Parental Information Letter and Consent Form",
    resources__guide_point_4: "4. Adding Classes",
    resources__guide_point_5: "5. Distributing Student Accounts",
    resources__guide_point_6: "6. Viewing Student Progress",
    resources__guide_point_7: "7. Adding Additional Students",
    resources__guide_point_8: "8. Resetting Student Passwords",
    resources__guide_point_9: "9. Troubleshooting and Frequently Asked Questions (FAQs)",

    resources__stm_obj: "is a must-have digital skill that empowers children to use digital technology with awareness and self-control. Students will learn about topics such as controlling multitasking, balancing screen time, preventing game addiction and more.",
    resources__stm_point_1: "1. Balance between the physical and virtual realities.",
    resources__stm_point_2: "2. Use digital technology with self-control and understand the various side-effects of excessive screen time, multi-tasking and addictive usage of digital media.",
    resources__stm_point_3: "3. Manage time and set limits on personal digital use; not allowing digital use to take over their lives.",

    resources__pm_obj: "is a must-have digital skill that empowers children to protect their and their contacts’ personal information. Students will learn about topics such as personal information, keeping privacy on social media, protecting others’ privacy and more.",
    resources__pm_point_1: "1. Handle personal information shared online with discretion.",
    resources__pm_point_2: "2. Ensure and protect the privacy of themselves and contacts.",
    resources__pm_point_3: "3. Understand the importance of privacy and that it is a basic human right.",

    resources__cbm_obj: "is a must-have digital skill that empowers children to detect cyber-bullying situations and to handle them wisely. Students will learn about topics such as detecting cyber-bullying, types of cyber-bullying, diffusing a cyber-bullying situation and more.",
    resources__cbm_point_1: "1. Handle digital media safely and responsibly.",
    resources__cbm_point_2: "2. Detect a cyber-bullying situation and handle it calmly.",
    resources__cbm_point_3: "3. Know how to safely seek help before a cyber-bullying problem gets out of control.",

    resources__dci_obj: "is a must-have digital skill that empowers children to build online personas with integrity. Students will learn about topics such as how the Internet works, digital leaders, congruent offline and online identities and more.",
    resources__dci_point_1: "1. Understand the nature of the digital world and use digital technology and media proficiently.",
    resources__dci_point_2: "2. Have the knowledge and skills to build and manage a healthy and congruent online and offline identity.",
    resources__dci_point_3: "3. Remain aware of the importance of global citizenship in the digital space.",

    resources__dfm_title: "Digital Footprint Management",
    resources__dfm_obj: "is a must-have digital skill that empowers children to be aware of and to manage the trails of information that they leave online. Students will learn about topics such as what digital footprints are, persistence of digital footprints, impacts on digital reputation and more.",
    resources__dfm_point_1: "1. Understand the nature of online communication.",
    resources__dfm_point_2: "2. Know that everything said and done online leaves trails called digital footprints.",
    resources__dfm_point_3: "3. Remain aware of the persistent nature of digital footprints and their real-life consequences, including creating an unintended online reputation.",
    resources__dfm_point_4: "4. Employ skills to manage digital footprints responsibly.",

    resources__csm_obj: "is a must-have digital skill that empowers children to create and maintain strong passwords and avoid cyber-attacks. Students will learn about topics such as creating strong passwords, managing SPAM and scams, observing mobile security and more.",
    resources__csm_point_1: "1. Spot and protect oneself and others from various cyber attacks such as SPAM, SCAMs, and phishing.",
    resources__csm_point_2: "2. Create and remember strong passwords and keep them confidential.",
    resources__csm_point_3: "3. Recognize safe practices for mobile device use, such as updating software and turning off location services when not in use.",

    resources__ct_obj: "is a must-have digital skill that empowers children to distinguish between good and harmful content as well as trustworthy and questionable contacts online. Students will learn about topics such as true vs. false info, online friends, how to critique content and more.",
    resources__ct_point_1: "1. Evaluate online information, content and contacts with discernment.",
    resources__ct_point_2: "2. Understand the harmful effects of false information, violence, inappropriate content and the risks associated with online strangers.",
    resources__ct_point_3: "3. Use critical thinking to distinguish true and false information, good and harmful content, and trustworthy and questionable contacts online.",

    resources__de_obj: "is a must-have digital skill that empowers children to be empathetic towards one’s own and others’ needs and feelings online. Students will learn about topics such as bystanders vs. upstanders, listening with empathy, empathy for cyber victims and more.",
    resources__de_point_1: "1. Be sensitive to the needs and feelings of self and others when online, even without face-to-face interaction.",
    resources__de_point_2: "2. Willingly lend a voice to those who need help, and to speak out for them.",
    resources__de_point_3: "3. Refrain from being judgemental online.",
    resources__de_point_4: "4. Build good relationships with parents, teachers and friends, both on-and off-line.",

    resources__upon_completion: "Upon completion, students should be able to:",
    resources__time_30: "30 Minutes",
    resources__time_40_60: "40-60 minutes of focused online participation",
    resources__time_50_75: "50-75 minutes of focused online participation",
    resources__time_60_90: "60-90 minutes of focused online participation",
    resources__time_90_120: "90-120 minutes of focused online participation",
    resources__lesson_pdf: "Download Lessons PDF",
    resources__ppt_slides: "Download PPT Slides",

    resources__dqw_intro_vid: "DQ World Intro Video",
    resources__student_workbook: "Full Student Workbook",
    resources__quiz_ans_key: "Quiz Answer Key",
    resources__workbook_ans_key: "Workbook Answer Key",

    contact__req_submit: "Request Submitted",
    contact__success: "Successfully Submitted, someone will contact back you soon regarding your query.\nThank you",

    //old translations
    home__analytics_dashboard: "Analytics Dashboard",
    main__overview: "Overview",
    contact_us: "Contact Us",
    home__company_percentile: "Company Percentile",
    home__overall_dq_score: "Overall DQ Score",
    home__cyber_risk_vulnerability: "Cyber-Risk Vulnerability",
    home__cyber_risk: "Cyber-Risks",
    home__dq_microbadges: "DQ MicroBadges",
    home__dq_modules: "DQ Modules",
    home__dq_assessments: "DQ Assessments",
    home__average_score: "Average Score",
    home__company_national: "(Company/National/Global)",
    home__school_national: "(School/National/Global)",
    home__completion_rate: "Completion Rate",
    main__dark_mode: "Dark Mode",
    main__sign_out: "Sign Out",
    main__change_password: "Change Password",
    home__details: "Details",
    main__footer_support: "Support",
    main__footer_help_center: "Help Center",
    main__employees: "Employees",
    main__list: "List",
    main__pending_approvals: "Pending Approvals",
    main__licenses: "Licenses",
    main__orders: "Orders",
    main__employee_list: "Employee List",
    main__name: "Name",
    employees__list: "List",
    employees__approve_all: "Approve All",
    employees__approve: "Approve",
    employees__reject: "Reject",
    orders__orders: "Orders",
    orders__order_id: "Order ID",
    orders__licenses_count: "Licenses Count",
    orders__status: "Status",
    orders__withdraw: "Withdraw",
    orders__not_applicable: "Not Applicable",
    orders__approved: "Approved",
    orders__create_order: "Create Order",
    orders__create: "Create",
    orders__create_new_order: "Create New Order",
    orders__order_details: "Order Details",
    contact_us__message: "Message",
    const__user_access_level_na: "N/A",
    const__user_access_level_no_access: "No Access",
    const__user_access_level_partial_access_admin: "Partial Access (Admin)",
    const__user_access_level_full_access_admin: "Full Access (Admin)",
    const__user_access_level_partial_access_analytics: "Partial Access (Analytics)",
    const__user_access_level_full_access_analytics: "Full Access (Analytics)",
    const__org_type_partner: "PARTNER",
    const__org_type_company: "COMPANY",
    const__org_type_school: "SCHOOL",
    const__licenses_order_status_not_set: "Not Set",
    const__licenses_order_status_pending: "Pending",
    const__licenses_order_status_approved: "Approved",
    const__licenses_order_status_rejected: "Rejected",
    const__licenses_order_status_withdrawn: "Withdrawn",
    home__company_avg: "Company Avg.",
    home__national_avg: "National Avg.",
    home__global_avg: "Global Avg.",
    home__singtel_avg: "Singtel Avg.",
    main__footer_privacy: "Privacy",
    main__footer_terms_of_service: "Terms of Service",
    main__footer_all_rights_reserved: "DQ LAB Pte Ltd. All rights reserved.",
    home__top: "Top",
    home__risk: "Risk",
    main__dq_stats: "DQ Stats",
    main__profiles: "Profiles",
    main__stats: "Stats",
    main__students: "Students",
    main__pending_activation: "Pending Activation",
    employees__identifier: "Identifier",
    employees__name: "Name",
    employees__gender: "Gender",
    employees__dq_score: "DQ Score",
    employees__grade: "Grade",
    employees__actions: "Actions",
    employees__email: "Email",
    employees__first_name: "First Name",
    employees__last_name: "Last Name",
    employees__country: "Country",
    employees__postal_code: "Postal Code",
    employees__employee: "User",
    employees__student: "Student",
    employees__rows_per_page: "Rows per page:",
    profiles__profile_list: "Profiles List",
    profiles__identifier: "Identifier",
    profiles__first_name: "First Name",
    profiles__last_name: "Last Name",
    profiles__country: "Country",
    profiles__year_of_birth: "Year of Birth",
    profiles__gender: "Gender",
    profiles__email: "Email",
    profiles__org: "Organization",
    profiles__rows_per_page: "Rows per page:",
    stats_dq_stats: "DQ Stats",
    stats_total_users: "Total Users",
    stats_users_without_demographics: "Users without Demographics",
    stats_male_users: "Male Users",
    stats_female_users: "Female Users",
    stats_users_by_country: "Users By Country",
    stats_country_name: "Country Name",
    stats_users: "Users",

}


const lc_ko = {
    navigation__analytics_dashboard: "분석 대시보드",
    header__change_password: "비밀번호 변경",
    header__dark_mode: "다크모드",
    header__sign_out: "로그아웃",
    //old
    home__analytics_dashboard: "분석 대시보드",
    main__overview: "개요",
    contact_us: "문의하기",
    home__company_percentile: "기업 백분위",
    home__overall_dq_score: "DQ 총점",
    home__cyber_risk_vulnerability: "디지털 위험 취약도",
    home__cyber_risk: "디지털 위험",
    home__dq_microbadges: "마이크로배지",
    home__dq_modules: "모듈",
    home__dq_assessments: "평가",
    home__average_score: "평균 점수",
    home__company_national: "(기업/국가)",
    home__completion_rate: "완료율",
    main__dark_mode: "다크모드",
    main__sign_out: "로그아웃",
    main__change_password: "비밀번호 변경",
    home__details: "상세정보",
    main__footer_support: "지원",
    main__footer_help_center: "문의 센터",
    main__employees: "직원",
    main__list: "목록",
    main__pending_approvals: "승인 보류",
    main__licenses: "라이선스",
    main__orders: "주문",
    main__employee_list: "직원 목록",
    main__name: "이름",
    employees__list: "목록",
    employees__approve_all: "모두 승인하기",
    employees__approve: "승인하기",
    employees__reject: "거부하기",
    licenses__total_licenses: "전체 라이선스",
    licenses__used_licenses: "사용된 라이선스",
    licenses__unused_licenses: "사용되지 않은 라이선스",
    licenses__add_users: "사용자 추가하기",
    licenses__download_csv: "파일 다운로드",
    licenses__upload_csv: "파일 업로드",
    licenses__download_the_csv_file: "1. excel (.xlsx) 파일을 다운로드 하세요.",
    licenses__fill_out_the_csv_file_completely: "2. excel 파일의 필수 정보를 하나도 빠짐 없이 기입하세요. 직원의 성명(성과 이름), 부서, 직함, 생년월일, 이메일 주소, 성별이, 우편 번호 필요합니다.",
    licenses__upload_the_csv_file: "3. excel (.xlsx) 파일을 업로드하세요.",
    licenses__the_system_will_then_send_out_activation_emails: "4. excel 파일에 포함된 직원에게 활성화 메일을 전송됩니다. 다음 단계 진행을 위해 이메일로 이동하여 활성화 링크를 통해 로그인하고 암호를 설정하세요.",
    licenses__as_long_as_you_have_unused_licenses_p1: '5. 사용하지 않은 라이선스가 있을 시, 이전 excel 파일에 새 계정을 추가하고 업로드할 수 있습니다. 또한, 이메일 정보를 제외한 나머지 정보는 수정이 가능합니다. 이메일 변경을 원하시면',
    licenses__as_long_as_you_have_unused_licenses_p2: '로 문의하십시오.',
    licenses__allowed_domain_hint: "허용된 Open-ID 이메일만 도메인에서 온 것입니다",
    licenses__errors: '오류',
    orders__orders: "주문",
    orders__order_id: "주문 아이디",
    orders__licenses_count: "라이선스 개수",
    orders__status: "현황",
    orders__withdraw: "철회하기",
    orders__not_applicable: "적용할 수 없음",
    orders__approved: "승인됨",
    orders__create_order: "주문하기",
    orders__create: "만들기",
    orders__create_new_order: "새 주문하기",
    orders__order_details: "주문 상세정보",
    contact_us__message: "메시지"
}

const lc_th = {
    general__data_unavailable: `ไม่มีข้อมูลที่สามารถใช้ได้`,
    general__download_data: `ดาวน์โหลดข้อมูล`,
    general__server_response: `การตอบสนองของเซิร์ฟเวอร์`,
    general__error: `ข้อผิดพลาด`,
    general__total: `รวมทั้งหมด`,
    general__rows_per_page: `จำนวนแถวต่อหน้า`,
    general__actions: `การกระทำ`,
    general__email: `อีเมล์`,
    general__email_address: `ที่อยู่อีเมล์`,
    general__username: `ชื่อผู้ใช้`,
    general__name: `ชื่ื่อ`,
    general__last_name: `นามสกุล`,
    general__first_name: `ชื่อจริง`,
    general__gender: `เพศ`,
    general__copy: `คัดลอก`,
    general__copied: `คัดลอกสำเร็จ!`,
    general__unable_copy: `ไม่สามารถคัดลอกได้!`,
    general__send: `ส่ง`,
    general__download: `ดาวน์โหลด`,
    general__submit: `ส่ง`,
    general__copyright: `ลิขสิทธิ์`,
    navigation__analytics_dashboard: `กระดานการวิเคราะห์`,
    navigation__program_overview: `ภาพรวมของโปรแกรม`,
    navigation__baselining: `พื้นฐาน`,
    navigation__digital_competencies: `ความสามารถด้านดิจิทัล`,
    navigation__cyber_risks: `ความเสี่ยงทางไซเบอร์`,
    navigation__assessment: `การประเมิน`,
    navigation__enhancement: `การเพิ่มประสิทธิภาพ`,
    navigation__content: `เนื้อหา`,
    navigation__reach: `เข้าถึง`,
    navigation__efficacy: `ประสิทธิภาพ`,
    navigation__in_depth_analysis: `การวิเคราะห์เชิงลึก`,
    navigation__progress: `ความคืบหน้า`,
    navigation__schools: `โรงเรียน`,
    navigation__classes: `ชั้นเรียน`,
    navigation__students: `นักเรียน`,
    navigation__employees: `พนักงาน`,
    navigation__licenses: `ใบอนุญาต`,
    navigation__add_licenses: `เพิ่มใบอนุญาต`,
    navigation__pending_activations: `การเปิดใช้งานที่รอดำเนินการ`,
    navigation__program_resources: `ทรัพยากรโปรแกรม`,
    navigation__contact_us: `ติดต่อเรา`,
    header__change_password: `เปลี่ยนรหัสผ่าน`,
    header__dark_mode: `โหมดมืด`,
    header__sign_out: `ออกจากระบบ`,
    footer__support: `สนับสนุน`,
    footer__help_center: `ศูนย์ช่วยเหลือ`,
    footer__privacy: `ความเป็นส่วนตัว`,
    footer__terms_of_service: `เงื่อนไขการให้บริการ`,
    footer__dq_lab_pte_ltd_all_rights_reserved: `DQ LAB Pte Ltd. สงวนลิขสิทธิ์`,
    auth__login: `เข้าสู่ระบบ`,
    auth__sign_in: `เข้าสู่ระบบ`,
    auth__pass: `รหัสผ่าน`,
    auth__remember_me: `จดจำฉัน`,
    auth__forgot_pass: `ลืมรหัสผ่าน?`,
    auth__dont_have_acc: `ไม่มีบัญชี? ลงทะเบียน!`,
    auth__register: `ลงทะเบียน`,
    auth__sign_up: `ลงทะเบียน`,
    auth__reg_school: `ลงทะเบียน [โรงเรียน]`,
    auth__school_type: `ประเภทโรงเรียน`,
    auth__school_size: `ขนาดโรงเรียน`,
    auth__country: `ประเทศ`,
    auth__city: `เมือง`,
    auth__your_first_name: `ชื่อจริงของคุณ`,
    auth__your_last_name: `นามสกุลของคุณ`,
    auth__your_position: `ตำแหน่งของคุณ`,
    auth__your_email: `อีเมล์ของคุณ`,
    auth__create_pass: `สร้างรหัสผ่าน`,
    auth__confirm_pass: `ยืนยันรหัสผ่าน`,
    auth__keep_me_login: `ให้ฉันอยู่ในระบบ`,
    auth__already_have_acc: `มีบัญชีอยู่แล้ว? เข้าสู่ระบบ`,
    auth__err_pass_mismatch: `รหัสผ่านไม่ตรงกัน`,
    auth__provide_same_pass: `โปรดระบุรหัสผ่านเดียวกันในช่องรหัสผ่านทั้งสองช่อง!`,
    auth__school_primary: `หลัก`,
    auth__school_secondary: `รอง`,
    auth__school_high: `สูง`,
    auth__reg_company: `ลงทะเบียน [บริษัท]`,
    auth__company_name: `ชื่อบริษัท`,
    auth__indus_or_sec: `อุตสาหกรรม / ภาค`,
    auth__company_size: `ขนาดของบริษัท`,
    auth__company_it: `ไอที`,
    auth__company_edu: `การศึกษา`,
    auth__company_corporate: `องค์กร`,
    auth__reg_as_company: `ลงทะเบียนเป็นบริษัท`,
    auth__reg_as_school: `ลงทะเบียนเป็นโรงเรียน`,
    auth__req_reset_pass: `ขอตั้งรหัสผ่านใหม่`,
    auth__send_reset_code: `ส่งรหัสที่ตั้งใหม่`,
    auth__back_to_login: `กลับไปที่หน้าเข้าสู่ระบบ`,
    auth__already_receive_code: `ได้รับรหัสแล้ว?`,
    auth__token_sent: `โทเค็นที่ส่ง`,
    auth__token_sent_success: `ส่งโทเค็นที่ตั้งใหม่ทางอีเมล์สำเร็จแล้ว`,
    auth__reset_code: `ตั้งรหัสใหม่`,
    auth__set_pass: `ตั้งรหัสผ่าน`,
    auth__pass_updated: `อัปเดตรหัสผ่านแล้ว`,
    auth__pass_change_success: `เปลี่ยนรหัสผ่านสำเร็จ`,
    auth__activate_acc: `เปิดใช้งานบัญชี`,
    auth__enter_access_code: `ป้อนรหัสการเข้าถึง`,
    auth__check_email_code: `โปรดตรวจสอบอีเมลของคุณ โทเค็นถูกส่งไปแล้ว!`,
    auth__access_code: `รหัสการเข้าถึง`,
    auth__resend_code: `ส่งรหัสอีกครั้ง?`,
    auth__resent_code: `ส่งรหัสอีกครั้งสำเร็จ`,
    auth__resend_code_success: `ส่งรหัสไปยังที่อยู่อีเมล์ของคุณเรียบร้อยแล้ว`,
    auth__confirmed: `ยืนยัน`,
    auth__confirmed_email: `ยืนยันที่อยู่อีเมล์สำเร็จ`,
    auth__logout: `ออกจากระบบ`,
    auth__logout_in_process: `กำลังออกจากระบบผู้ใช้...`,
    side_panel__select_country: `เลือกประเทศ`,
    side_panel__add_country: `เพิ่มประเทศ`,
    side_panel__select_dig_comp: `เลือกความสามารถด้านดิจิทัล`,
    side_panel__select_cyber_risk: `เลือกความเสี่ยงทางไซเบอร์`,
    side_panel__select_time: `เลือกช่วงเวลา`,
    side_panel__start_month: `เดือนเริ่มต้น`,
    side_panel__end_month: `เดือนสิ้นสุด`,
    side_panel__select_comp_cat: `เลือกประเภทความสามารถ`,
    side_panel__select_mod_cat: `เลือกหมวดหมู่โมดูล`,
    side_panel__select_region: `เลือกภูมิภาค`,
    side_panel__add_region: `เพิ่มภูมิภาค`,
    side_panel__select_province: `เลือกจังหวัด`,
    side_panel__add_province: `เพิ่มจังหวัด`,
    side_panel__select_school: `เลือกโรงเรียน`,
    side_panel__add_school: `เพิ่มโรงเรียน`,
    side_panel__select_class: `เลือกชั้นเรียน`,
    side_panel__add_class: `เพิ่มชั้นเรียน`,
    side_panel__select_postal: `เลือกรหัสไปรษณีย์`,
    side_panel__add_postal: `เพิ่มรหัสไปรษณีย์`,
    side_panel__select_dq_comp: `เลือกความสามารถ DQ`,
    side_panel__select_inst: `เลือกรหัสสถาบัน`,
    side_panel__add_inst: `เพิ่มสถาบัน`,
    side_panel__select_mod: `เลือกโมดูล`,
    side_panel__add_mod: `เพิ่มโมดูล`,
    side_panel__dq_comp_score: `คะแนนรวม DQ`,
    side_panel__entire_nation: `ทั้งประเทศ`,
    side_panel__all_year: `ทุกปี`,
    side_panel__all_region: `ทุกภาค`,
    side_panel__all_province: `All Provinces`,
    side_panel__all_postal: `รหัสไปรษณีย์ทั้งหมด`,
    side_panel__all_inst: `รหัสสถาบันทั้งหมด`,
    side_panel__all_mod: `โมดูลทั้งหมด`,
    side_panel__all_school: `โรงเรียนทั้งหมด`,
    side_panel__all_class: `ทุกชั้นเรียน`,
    side_panel__selected_region: `ภูมิภาคที่เลือก`,
    side_panel__selected_province: `Selected Province`,
    side_panel__selected_postal: `ไปรษณีย์ที่เลือก`,
    side_panel__selected_inst: `รหัสสถาบันที่เลือก`,
    side_panel__selected_time: `เวลาที่เลือก`,
    side_panel__selected_mod: `โมดูลที่เลือก`,
    side_panel__selected_school: `โรงเรียนที่เลือก`,
    side_panel__selected_class: `ชั้นที่เลือก`,
    side_panel__prog_mods: `โมดูลโปรแกรม`,
    stats__highly_excluded: `ยกเว้นอย่างมาก`,
    stats__excluded: `ยกเว้น`,
    stats__included: `รวมอยู่ด้วย`,
    stats__highly_included: `รวมสูง`,
    stats__no: `ไม่ใช่`,
    stats__yes: `ใช่`,
    stats__unknown: `ไม่ทราบ`,
    stats__female: `เพศหญิง`,
    stats__male: `เพศชาย`,
    stats__other: `อื่น ๆ`,
    stats__junior: 'Junior',
    stats__senior: 'Senior',
    stats__below_9: `ต่ำกว่า 9`,
    stats__below_9_years_old: `อายุต่ำกว่า 9 ปี`,
    stats__10_12_years_old: `อายุ 10-12 ปี`,
    stats__13_14_years_old: `อายุ 13-14 ปี`,
    stats__15_16_years_old: `อายุ 15-16 ปี`,
    stats__above_16_years_old: `อายุมากกว่า 16 ปี`,
    stats__jan: `ม.ค`,
    stats__feb: `ก.พ`,
    stats__mar: `มี.ค`,
    stats__apr: `เม.ย`,
    stats__may: `อาจ`,
    stats__jun: `มิ.ย`,
    stats__jul: `ก.ค`,
    stats__aug: `ส.ค`,
    stats__sep: `ก.ย`,
    stats__oct: `ต.ค`,
    stats__nov: `พ.ย`,
    stats__dec: `ธ.ค`,
    dq_code__before_the_dq_world_edu: `ก่อนงาน DQ การศึกษาระดับโลก`,
    dq_code__after_the_dq_world_edu: `หลังจากที่ DQ การศึกษาระดับโลก`,
    dq_code__digital_citizen_identity: `ตัวตนของพลเมืองดิจิทัล`,
    dq_code__balanced_use_of_technology: `การใช้เทคโนโลยีอย่างสมดุล`,
    dq_code__behavioural_cyber_risk_management: `การจัดการความเสี่ยงทางพฤติกรรมทางไซเบอร์`,
    dq_code__personal_cyber_security_management: `การจัดการความปลอดภัยทางไซเบอร์ส่วนบุคคล`,
    dq_code__digital_empathy: `การเอาใจใส่แบบดิจิทัล`,
    dq_code__digital_footprint_management: `การจัดการรอยเท้าดิจิทัล`,
    dq_code__media_and_information_literacy: `การรู้เท่าทันสื่อและสารสนเทศ`,
    dq_code__privacy_management: `การจัดการความเป็นส่วนตัว`,
    dq_code__digital_co_creator_identity: `ตัวตนผู้ร่วมสร้างดิจิทัล`,
    dq_code__healthy_use_of_technology: `การใช้เทคโนโลยีอย่างถูกสุขลักษณะ`,
    dq_code__content_cyber_risk_management: `การจัดการความเสี่ยงทางไซเบอร์ของเนื้อหา`,
    dq_code__network_security_management: `การจัดการความปลอดภัยเครือข่าย`,
    dq_code__self_awareness_and_management: `การตระหนักรู้ในตนเองและการจัดการ`,
    dq_code__online_communication_and_collaboration: `การสื่อสารออนไลน์และการทำงานร่วมกัน`,
    dq_code__content_creation_and_computational_literacy: `การสร้างเนื้อหาและความรู้ทางคอมพิวเตอร์`,
    dq_code__intellectual_property_rights_management: `การจัดการสิทธิ์ในทรัพย์สินทางปัญญา`,
    dq_code__digital_changemaker_identity: `เอกลักษณ์ของผู้สร้างการเปลี่ยนแปลงทางดิจิทัล`,
    dq_code__civic_use_of_technology: `การใช้เทคโนโลยีของพลเมือง`,
    dq_code__commercial_and_community_cyber_risk_management: `การจัดการความเสี่ยงทางไซเบอร์เชิงพาณิชย์และชุมชน`,
    dq_code__organisational_cyber_security_management: `การจัดการความปลอดภัยทางไซเบอร์ขององค์กร`,
    dq_code__relationship_management: `การจัดการความสัมพันธ์`,
    dq_code__public_and_mass_communication: `สาธารณะและสื่อสารมวลชน`,
    dq_code__data_and_ai_literacy: `ความรู้ด้านข้อมูลและ AI`,
    dq_code__participatory_rights_management: `การจัดการสิทธิ์แบบมีส่วนร่วม`,
    dq_def__digital_citizen_identity: `ความสามารถในการสร้างและจัดการอัตลักษณ์ที่ดีในฐานะพลเมืองดิจิทัลที่มีความซื่อสัตย์`,
    dq_def__balanced_use_of_technology: `ความสามารถในการจัดการชีวิตทั้งออนไลน์และออฟไลน์อย่างสมดุลโดยการควบคุมตนเองเพื่อจัดการเวลาหน้าจอ การทำงานหลายอย่างพร้อมกัน และการมีส่วนร่วมกับสื่อและอุปกรณ์ดิจิทัล`,
    dq_def__behavioural_cyber_risk_management: `ความสามารถในการระบุ บรรเทา และจัดการความเสี่ยงทางไซเบอร์ (เช่น การกลั่นแกล้งทางอินเทอร์เน็ต การคุกคาม และการสะกดรอยตาม) ที่เกี่ยวข้องกับพฤติกรรมส่วนบุคคลทางออนไลน์`,
    dq_def__personal_cyber_security_management: `ความสามารถในการตรวจจับภัยคุกคามทางไซเบอร์ (เช่น การแฮ็ก การหลอกลวง และมัลแวร์) ต่อข้อมูลส่วนบุคคลและอุปกรณ์ และใช้กลยุทธ์การรักษาความปลอดภัยและเครื่องมือป้องกันที่เหมาะสม`,
    dq_def__digital_empathy: `ความสามารถในการรับรู้ อ่อนไหว และสนับสนุนความรู้สึก ความต้องการ และความกังวลของตนเองและผู้อื่นทางออนไลน์`,
    dq_def__digital_footprint_management: `ความสามารถในการเข้าใจธรรมชาติของรอยเท้าทางดิจิทัลและผลที่ตามมาในชีวิตจริง การจัดการรอยเท้าอย่างมีความรับผิดชอบ และสร้างชื่อเสียงทางดิจิทัลในเชิงบวกอย่างแข็งขัน`,
    dq_def__media_and_information_literacy: `ความสามารถในการค้นหา จัดระเบียบ วิเคราะห์ และประเมินสื่อและข้อมูลอย่างมีเหตุผล`,
    dq_def__privacy_management: `ความสามารถในการจัดการข้อมูลส่วนบุคคลทั้งหมดที่แชร์ออนไลน์เพื่อปกป้องความเป็นส่วนตัวของตนเองและผู้อื่น`,
    dq_def__digital_co_creator_identity: `ความสามารถในการระบุและพัฒนาตนเองในฐานะผู้ร่วมสร้างระบบนิเวศดิจิทัล`,
    dq_def__healthy_use_of_technology: `ความสามารถในการเข้าใจประโยชน์และโทษของเทคโนโลยีต่อสุขภาพจิตและร่างกาย และใช้เทคโนโลยีในขณะที่ให้ความสำคัญกับสุขภาพและความเป็นอยู่ที่ดี`,
    dq_def__content_cyber_risk_management: `ความสามารถในการระบุ บรรเทา และจัดการความเสี่ยงทางไซเบอร์ของเนื้อหาทางออนไลน์ (เช่น เนื้อหาที่เป็นอันตรายซึ่งผู้ใช้สร้างขึ้น เนื้อหาเหยียดเชื้อชาติ/แสดงความเกลียดชัง การละเมิดเกี่ยวกับรูปภาพ)`,
    dq_def__network_security_management: `ความสามารถในการตรวจจับ หลีกเลี่ยง และจัดการภัยคุกคามทางไซเบอร์ต่อสภาพแวดล้อมดิจิทัลที่ทำงานร่วมกันบนคลาวด์`,
    dq_def__self_awareness_and_management: `ความสามารถในการรับรู้และจัดการว่าระบบคุณค่าและความสามารถทางดิจิทัลของตนเหมาะสมกับสภาพแวดล้อมทางดิจิทัลของตนอย่างไร`,
    dq_def__online_communication_and_collaboration: `ความสามารถในการใช้เทคโนโลยีอย่างมีประสิทธิภาพเพื่อสื่อสารและทำงานร่วมกันโดยรวม รวมถึงในระยะไกล`,
    dq_def__content_creation_and_computational_literacy: `ความสามารถในการสังเคราะห์ สร้าง และผลิตข้อมูล สื่อ และเทคโนโลยีในลักษณะที่เป็นนวัตกรรมและสร้างสรรค์`,
    dq_def__intellectual_property_rights_management: `ความสามารถในการทำความเข้าใจและจัดการสิทธิ์ในทรัพย์สินทางปัญญา (เช่น ลิขสิทธิ์ เครื่องหมายการค้า และสิทธิบัตร) เมื่อใช้และสร้างเนื้อหาและเทคโนโลยี`,
    dq_def__digital_changemaker_identity: `ความสามารถในการระบุและพัฒนาตนเองในฐานะผู้สร้างการเปลี่ยนแปลงที่มีความสามารถในเศรษฐกิจดิจิทัล`,
    dq_def__civic_use_of_technology: `ความสามารถในการมีส่วนร่วมในการมีส่วนร่วมของพลเมืองเพื่อความเป็นอยู่ที่ดีและการเติบโตของชุมชนท้องถิ่น ระดับชาติ และระดับโลกโดยใช้เทคโนโลยี`,
    dq_def__commercial_and_community_cyber_risk_management: `ความสามารถในการทำความเข้าใจ บรรเทา และจัดการความเสี่ยงทางไซเบอร์ในเชิงพาณิชย์หรือชุมชนทางออนไลน์ ซึ่งเป็นความพยายามขององค์กรในการแสวงหาประโยชน์จากบุคคลทางการเงินและ/หรือผ่านการโน้มน้าวใจทางอุดมการณ์ (เช่น การตลาดแบบฝังตัว การโฆษณาชวนเชื่อออนไลน์ และการพนัน)`,
    dq_def__organisational_cyber_security_management: `ความสามารถในการรับรู้ วางแผน และดำเนินการป้องกันความปลอดภัยทางไซเบอร์ขององค์กร`,
    dq_def__relationship_management: `ความสามารถในการจัดการความสัมพันธ์ทางออนไลน์อย่างชำนาญผ่านการทำงานร่วมกัน การจัดการความขัดแย้ง และการโน้มน้าวใจ`,
    dq_def__public_and_mass_communication: `ความสามารถในการสื่อสารกับผู้ชมออนไลน์อย่างมีประสิทธิภาพเพื่อแลกเปลี่ยนข้อความ ความคิด และความคิดเห็นที่สะท้อนถึงวาทกรรมทางธุรกิจหรือสังคมที่กว้างขึ้น`,
    dq_def__data_and_ai_literacy: `ความสามารถในการสร้าง ประมวลผล วิเคราะห์ นำเสนอข้อมูลที่มีความหมายจากข้อมูล และพัฒนา ใช้ และประยุกต์ใช้ปัญญาประดิษฐ์ (AI) และเครื่องมือและกลยุทธ์อัลกอริทึมที่เกี่ยวข้อง เพื่อเป็นแนวทางในกระบวนการตัดสินใจที่ได้รับข้อมูล เหมาะสมที่สุด และเกี่ยวข้องกับบริบท`,
    dq_def__participatory_rights_management: `ความสามารถในการทำความเข้าใจและใช้อำนาจและสิทธิ์ในการมีส่วนร่วมทางออนไลน์ (เช่น สิทธิ์ในการคุ้มครองข้อมูลส่วนบุคคล เสรีภาพในการแสดงออก หรือถูกลืม)`,
    dq_country__afghanistan: `อัฟกานิสถาน`,
    dq_country__aland_islands: `หมู่เกาะโอลันด์`,
    dq_country__albania: `แอลเบเนีย`,
    dq_country__algeria: `แอลจีเรีย`,
    dq_country__american_samoa: `อเมริกันซามัว`,
    dq_country__andorra: `อันดอร์เอ`,
    dq_country__angola: `แองโกลา`,
    dq_country__anguilla: `แองกวิลลา`,
    dq_country__antarctica: `แอนตาร์กติกา`,
    dq_country__antigua_and_barbuda: `แอนติกาและบาร์บูดา`,
    dq_country__argentina: `อาร์เจนตินา`,
    dq_country__armenia: `อาร์เมเนีย`,
    dq_country__aruba: `อารูบา`,
    dq_country__australia: `ออสเตรเลีย`,
    dq_country__austria: `ออสเตรีย`,
    dq_country__azerbaijan: `อาเซอร์ไบจาน`,
    dq_country__bahamas: `บาฮามาส`,
    dq_country__bahrain: `บาห์เรน`,
    dq_country__bangladesh: `บังกลาเทศ`,
    dq_country__barbados: `บาร์เบโดส`,
    dq_country__belarus: `เบลารุส`,
    dq_country__belgium: `เบลเยี่ยม`,
    dq_country__belize: `เบลีซ`,
    dq_country__benin: `เบนิน`,
    dq_country__bermuda: `เบอร์มิวดา`,
    dq_country__bhutan: `ภูฏาน`,
    dq_country__bolivia: `โบลิเวีย`,
    dq_country__bosnia_and_herzegovina: `บอสเนียและเฮอร์เซโก`,
    dq_country__botswana: `บอตสวานา`,
    dq_country__bouvet_island: `เกาะบูเวต์`,
    dq_country__brazil: `บราซิล`,
    dq_country__british_indian_ocean_territory: `บริติชอินเดียนโอเชียนเทร์ริทอรี`,
    dq_country__brunei_darussalam: `บรูไนดารุสซาลาม`,
    dq_country__bulgaria: `บัลแกเรีย`,
    dq_country__burkina_faso: `บูร์กินาฟาโซ`,
    dq_country__burundi: `บุรุนดี`,
    dq_country__cambodia: `กัมพูชา`,
    dq_country__cameroon: `แคเมอรูน`,
    dq_country__canada: `แคนาดา`,
    dq_country__cape_verde: `เคปเวิร์ด`,
    dq_country__cayman_islands: `หมู่เกาะเคย์เเมน`,
    dq_country__central_african_republic: `สาธารณรัฐแอฟริกากลาง`,
    dq_country__chad: `ชาด`,
    dq_country__chile: `ชิลี`,
    dq_country__china: `จีน`,
    dq_country__christmas_island: `เกาะคริสต์มาส`,
    dq_country__cocos_keeling_islands: `หมู่เกาะโคโคส (คีลิง)`,
    dq_country__colombia: `โคลอมเบีย`,
    dq_country__comoros: `คอโมโรส`,
    dq_country__congo: `คองโก`,
    dq_country__congo_the_democratic_republic_of_the: `คองโก สาธารณรัฐประชาธิปไตย`,
    dq_country__cook_islands: `หมู่เกาะคุก`,
    dq_country__costa_rica: `คอสตาริกา`,
    dq_country__cote_divoire: `โกตดิวัวร์`,
    dq_country__croatia: `โครเอเชีย`,
    dq_country__cuba: `คิวบา`,
    dq_country__cyprus: `ไซปรัส`,
    dq_country__czech_republic: `สาธารณรัฐเช็ก`,
    dq_country__denmark: `เดนมาร์ก`,
    dq_country__djibouti: `จิบูตี`,
    dq_country__dominica: `โดมินิกา`,
    dq_country__dominican_republic: `สาธารณรัฐโดมินิกัน`,
    dq_country__ecuador: `เอกวาดอร์`,
    dq_country__egypt: `อียิปต์`,
    dq_country__el_salvador: `เอลซัลวาดอร์`,
    dq_country__equatorial_guinea: `อิเควทอเรียลกินี`,
    dq_country__eritrea: `เอริเทรีย`,
    dq_country__estonia: `เอสโตเนีย`,
    dq_country__ethiopia: `เอธิโอเปีย`,
    dq_country__falkland_islands_malvinas: `หมู่เกาะฟอล์กแลนด์ (มัลวินาส)`,
    dq_country__faroe_islands: `หมู่เกาะแฟโร`,
    dq_country__fiji: `ฟิจิ`,
    dq_country__finland: `ฟินแลนด์`,
    dq_country__france: `ฝรั่งเศส`,
    dq_country__french_guiana: `เฟรนช์เกีย`,
    dq_country__french_polynesia: `เฟรนช์โปลินีเซีย`,
    dq_country__french_southern_territories: `เฟรนช์เซาเทิร์นเทร์ริทอรีส์`,
    dq_country__gabon: `กาบอง`,
    dq_country__gambia: `แกมเบีย`,
    dq_country__georgia: `จอร์เจีย`,
    dq_country__germany: `เยอรมนี`,
    dq_country__ghana: `กานา`,
    dq_country__gibraltar: `ยิบรอลตาร์`,
    dq_country__greece: `กรีซ`,
    dq_country__greenland: `กรีนแลนด์`,
    dq_country__grenada: `เกรนาดา`,
    dq_country__guadeloupe: `กวาเดอลูป`,
    dq_country__guam: `กวม`,
    dq_country__guatemala: `กัวเตมาลา`,
    dq_country__guernsey: `เกิร์นซีย์`,
    dq_country__guinea: `กินี`,
    dq_country__guinea_bissau: `กินีบิสเซา`,
    dq_country__guyana: `กายอานา`,
    dq_country__haiti: `เฮติ`,
    dq_country__heard_island_and_mcdonald_islands: `เกาะเฮิร์ดและหมู่เกาะแมกดอนัลด์`,
    dq_country__holy_see_vatican_city_state: `Holy See (นครรัฐวาติกัน)`,
    dq_country__honduras: `ฮอนดูรัส`,
    dq_country__hong_kong: `ฮ่องกง`,
    dq_country__hungary: `ฮังการี`,
    dq_country__iceland: `ไอซ์แลนด์`,
    dq_country__india: `อินเดีย`,
    dq_country__indonesia: `อินโดนีเซีย`,
    dq_country__iran_islamic_republic_of: `อิหร่าน สาธารณรัฐอิสลาม`,
    dq_country__iraq: `อิรัก`,
    dq_country__ireland: `ไอร์แลนด์`,
    dq_country__isle_of_man: `เกาะแมน`,
    dq_country__israel: `อิสราเอล`,
    dq_country__italy: `อิตาลี`,
    dq_country__jamaica: `จาเมกา`,
    dq_country__japan: `ญี่ปุ่น`,
    dq_country__jersey: `เจอร์ซีย์`,
    dq_country__jordan: `จอร์แดน`,
    dq_country__kazakhstan: `คาซัคสถาน`,
    dq_country__kenya: `เคนยา`,
    dq_country__kiribati: `คิริบาส`,
    dq_country__korea_democratic_peoples_republic_of: `เกาหลี สาธารณรัฐประชาธิปไตยประชาชนเกาหลี`,
    dq_country__korea_republic_of: `เกาหลี, สาธารณรัฐเกาหลี`,
    dq_country__kuwait: `คูเวต`,
    dq_country__kyrgyzstan: `คีร์กีซสถาน`,
    dq_country__lao_peoples_democratic_republic: `สาธารณรัฐประชาธิปไตยประชาชนลาว`,
    dq_country__latvia: `ลัตเวีย`,
    dq_country__lebanon: `เลบานอน`,
    dq_country__lesotho: `เลโซโท`,
    dq_country__liberia: `ประเทศไลบีเรีย`,
    dq_country__libyan_arab_jamahiriya: `สาธารณรัฐอาหรับลิเบีย`,
    dq_country__liechtenstein: `ลิกเตนสไตน์`,
    dq_country__lithuania: `ลิทัวเนีย`,
    dq_country__luxembourg: `ลักเซมเบิร์ก`,
    dq_country__macao: `มาเก๊า`,
    dq_country__macedonia_the_former_yugoslav_republic_of: `มาซิโดเนีย อดีตสาธารณรัฐยูโกสลาเวีย`,
    dq_country__madagascar: `มาดากัสการ์`,
    dq_country__malawi: `มาลาวี`,
    dq_country__malaysia: `มาเลเซีย`,
    dq_country__maldives: `มัลดีฟส์`,
    dq_country__mali: `มาลี`,
    dq_country__malta: `มอลตา`,
    dq_country__marshall_islands: `หมู่เกาะมาร์แชลล์`,
    dq_country__martinique: `มาร์ตินีก`,
    dq_country__mauritania: `มอริเตเนีย`,
    dq_country__mauritius: `มอริเชียส`,
    dq_country__mayotte: `มายอต`,
    dq_country__mexico: `เม็กซิโก`,
    dq_country__micronesia_federated_states_of: `ไมโครนีเซีย สหพันธรัฐ`,
    dq_country__moldova_republic_of: `มอลโดวา สาธารณรัฐ`,
    dq_country__monaco: `โมนาโก`,
    dq_country__mongolia: `มองโกเลีย`,
    dq_country__montserrat: `มอนต์เซอร์รัต`,
    dq_country__morocco: `โมร็อกโก`,
    dq_country__mozambique: `โมซัมบิก`,
    dq_country__myanmar: `พม่า`,
    dq_country__namibia: `นามิเบีย`,
    dq_country__nauru: `นาอูรู`,
    dq_country__nepal: `เนปาล`,
    dq_country__netherlands: `เนเธอร์แลนด์`,
    dq_country__netherlands_antilles: `หมู่เกาะอินเดียตะวันตกของเนเธอร์แลนด์`,
    dq_country__new_caledonia: `นิวแคลิโดเนีย`,
    dq_country__new_zealand: `นิวซีแลนด์`,
    dq_country__nicaragua: `นิการากัว`,
    dq_country__niger: `ไนเจอร์`,
    dq_country__nigeria: `ไนจีเรีย`,
    dq_country__niue: `นีอูเอ`,
    dq_country__norfolk_island: `เกาะนอร์ฟอล์ก`,
    dq_country__northern_mariana_islands: `หมู่เกาะนอร์เทิร์นมาเรียนา`,
    dq_country__norway: `นอร์เวย์`,
    dq_country__oman: `โอมาน`,
    dq_country__pakistan: `ปากีสถาน`,
    dq_country__palau: `ปาเลา`,
    dq_country__palestinian_territory_occupied: `ดินแดนปาเลสไตน์, ยึดครอง`,
    dq_country__panama: `ปานามา`,
    dq_country__papua_new_guinea: `ปาปัวนิวกินี`,
    dq_country__paraguay: `ประเทศปารากวัย`,
    dq_country__peru: `เปรู`,
    dq_country__philippines: `ฟิลิปปินส์`,
    dq_country__pitcairn: `พิตแคร์น`,
    dq_country__poland: `โปแลนด์`,
    dq_country__portugal: `โปรตุเกส`,
    dq_country__puerto_rico: `เปอร์โตริโก้`,
    dq_country__qatar: `กาตาร์`,
    dq_country__reunion: `เรอูนียง`,
    dq_country__romania: `โรมาเนีย`,
    dq_country__russian_federation: `สหพันธรัฐรัสเซีย`,
    dq_country__rwanda: `รวันดา`,
    dq_country__saint_helena: `เซนต์เฮเลน่า`,
    dq_country__saint_kitts_and_nevis: `เซนต์คิตส์และเนวิส`,
    dq_country__saint_lucia: `เซนต์ลูเซีย`,
    dq_country__saint_pierre_and_miquelon: `เซนต์ปีเตอร์และมีเกอลง`,
    dq_country__saint_vincent_and_the_grenadines: `เซนต์วินเซนต์และเกรนาดีนส์`,
    dq_country__samoa: `ซามัว`,
    dq_country__san_marino: `ซานมาริโน`,
    dq_country__sao_tome_and_principe: `เซาตูเมและหลักการ`,
    dq_country__saudi_arabia: `ซาอุดิอาราเบีย`,
    dq_country__senegal: `เซเนกัล`,
    dq_country__serbia_and_montenegro: `เซอร์เบียและมอนเตเนโกร`,
    dq_country__seychelles: `เซเชลส์`,
    dq_country__sierra_leone: `เซียร์ราลีโอน`,
    dq_country__singapore: `สิงคโปร์`,
    dq_country__slovakia: `สโลวาเกีย`,
    dq_country__slovenia: `สโลวีเนีย`,
    dq_country__solomon_islands: `หมู่เกาะโซโลมอน`,
    dq_country__somalia: `โซมาเลีย`,
    dq_country__south_africa: `แอฟริกาใต้`,
    dq_country__south_georgia_and_the_south_sandwich_islands: `เกาะเซาท์จอร์เจียและหมู่เกาะเซาท์แซนด์วิช`,
    dq_country__spain: `สเปน`,
    dq_country__sri_lanka: `ศรีลังกา`,
    dq_country__sudan: `ซูดาน`,
    dq_country__suriname: `ซูรินาเม`,
    dq_country__svalbard_and_jan_mayen: `สวาลบาร์ดและยานไมเอน`,
    dq_country__swaziland: `สวาซิแลนด์`,
    dq_country__sweden: `สวีเดน`,
    dq_country__switzerland: `สวิตเซอร์แลนด์`,
    dq_country__syrian_arab_republic: `สาธารณรัฐอาหรับซีเรีย`,
    dq_country__taiwan_province_of_china: `ไต้หวัน มณฑลของจีน`,
    dq_country__tajikistan: `ทาจิกิสถาน`,
    dq_country__tanzania_united_republic_of: `แทนซาเนีย สหสาธารณรัฐ`,
    dq_country__thailand: `ประเทศไทย`,
    dq_country__timor_leste: `ติมอร์-เลสเต`,
    dq_country__togo: `ไป`,
    dq_country__tokelau: `โตเกเลา`,
    dq_country__tonga: `ตองกา`,
    dq_country__trinidad_and_tobago: `ตรินิแดดและโตเบโก`,
    dq_country__tunisia: `ตูนิเซีย`,
    dq_country__turkey: `ไก่งวง`,
    dq_country__turkmenistan: `เติร์กเมนิสถาน`,
    dq_country__turks_and_caicos_islands: `หมู่เกาะเติกส์และหมู่เกาะเคคอส`,
    dq_country__tuvalu: `ตูวาลู`,
    dq_country__uganda: `ยูกันดา`,
    dq_country__ukraine: `ยูเครน`,
    dq_country__united_arab_emirates: `สหรัฐอาหรับเอมิเรตส์`,
    dq_country__united_kingdom: `ประเทศอังกฤษ`,
    dq_country__united_states: `สหรัฐ`,
    dq_country__united_states_minor_outlying_islands: `เกาะเล็กรอบนอกของสหรัฐอเมริกา`,
    dq_country__uruguay: `อุรุกวัย`,
    dq_country__uzbekistan: `อุซเบกิสถาน`,
    dq_country__vanuatu: `วานูอาตู`,
    dq_country__venezuela: `เวเนซุเอลา`,
    dq_country__viet_nam: `เวียดนาม`,
    dq_country__virgin_islands_british: `หมู่เกาะเวอร์จิน ประเทศอังกฤษ`,
    dq_country__virgin_islands_us: `หมู่เกาะเวอร์จิน สหรัฐอเมริกา`,
    dq_country__wallis_and_futuna: `วาลลิสและฟุตูนา`,
    dq_country__western_sahara: `ซาฮาร่าตะวันตก`,
    dq_country__yemen: `เยเมน`,
    dq_country__zambia: `แซมเบีย`,
    dq_country__zimbabwe: `ซิมบับเว`,
    overview__dq_impact_rating: `คะแนนผลกระทบ DQ`,
    overview__impact_rating: `คะแนนผลกระทบ`,
    overview__out_of: `ออกจาก`,
    overview__program: `โปรแกรม`,
    overview__area: `พื้นที่`,
    overview__organisation: `องค์กร`,
    overview__target_pop: `ประชากรเป้าหมาย`,
    overview__group: `กลุ่ม`,
    overview__age: `อายุ`,
    overview__countries: `ประเทศ`,
    overview__content_quality: `คุณภาพเนื้อหา`,
    overview__program_reach: `การเข้าถึงโปรแกรม`,
    overview__comp_improve: `การปรับปรุงสมรรถนะ`,
    overview__program_name: `ชื่อโปรแกรม`,
    overview__contact_persons: `ผู้ติดต่อ`,
    overview__objectives: `วัตถุประสงค์ของโปรแกรม`,
    overview__program_delivery: `การจัดส่งโปรแกรม`,
    overview__venue: `สถานที่จัดงาน`,
    overview__target_audiences: `กลุ่มเป้าหมาย`,
    overview__time_taken: `เวลาที่ใช้เพื่อให้โปรแกรมเสร็จสมบูรณ์`,
    overview__cost_of_use: `ต้นทุนการใช้งาน`,
    overview__year_of_implement: `ปีที่ดำเนินการและสถานะ`,
    overview__ref_frameworks: `กรอบอ้างอิง`,
    overview__ref_frameworks_none: `ไม่มีใช้`,
    overview__mod_dq24: `โมดูลและ DQ24`,
    overview__mod_dq24_desc: `นักเรียนต้องกรอกสี่ในแปดโมดูลเพื่อรับใบอนุญาตดิจิทัล แต่ละโมดูลใช้เวลาประมาณ 1.5 ชั่วโมงในการทำให้เสร็จสมบูรณ์ และส่งมอบผลลัพธ์ของนักเรียนที่กำหนดไว้ล่วงหน้า 3 รายการ`,
    overview__learn_mod_id: `รหัสโมดูลการเรียนรู้`,
    overview__learn_title: `ชื่อหัวข้อการเรียนรู้`,
    overview__learn_objectives: `รายการวัตถุประสงค์การเรียนรู้`,
    overview__target_group_age: `กลุ่มเป้าหมายอายุ`,
    overview__dq24_align: `การจัดตำแหน่ง DQ24`,
    overview__about_report: `เกี่ยวกับรายงาน`,
    overview__dq_impact_report: `รายงานการจัดอันดับผลกระทบ DQ (รายงาน)`,
    overview__report_intro: `เป็นรายงานสรุปสำหรับโปรแกรมการศึกษา/การฝึกอบรมที่สอนความสามารถด้านดิจิทัลให้เข้าใจถึงผลกระทบ\nDQ Impact Rating เป็นคะแนนรวมที่ระบุระดับคุณภาพเนื้อหาของโปรแกรมและผลกระทบที่เกิดขึ้นจนถึงตอนนี้ในการพัฒนาทักษะด้านดิจิทัลของประเทศต่างๆ\nเป็นการวัดผลกระทบที่เป็นมาตรฐานตามเกณฑ์มาตรฐานระดับโลกโดยใช้มาตรฐานระดับโลก IEEE DQ สำหรับความรู้ด้านดิจิทัล ทักษะด้านดิจิทัล และความพร้อมด้านดิจิทัล (IEEE 3527.1)\nโปรแกรมได้รับการตรวจสอบและวิเคราะห์โดยนักวิจัยของสถาบัน DQ ตามเอกสารที่ส่งโดยเจ้าของโปรแกรมและ/หรือเอกสารที่เปิดเผยต่อสาธารณะ`,
    overview__report_design: `รายงานถูกออกแบบมาสำหรับ`,
    overview__prog_owner: `เจ้าของโปรแกรม`,
    overview__prog_owner_reason_1: `เพื่อทำความเข้าใจระดับคุณภาพของโปรแกรมและกลยุทธ์การใช้งาน`,
    overview__prog_owner_reason_2: `เพื่อระบุจุดแข็งและเพื่อปรับปรุงโปรแกรมต่อไป`,
    overview__stakeholder: `ผู้มีส่วนได้ส่วนเสียที่สำคัญ ได้แก่ ผู้กำหนดนโยบาย และผู้ให้ทุน/ผู้ลงทุน`,
    overview__stakeholder_reason_1: `มีการประเมินข้อมูลที่มีประโยชน์เกี่ยวกับคุณภาพของโปรแกรมและผลกระทบ เพื่ออำนวยความสะดวกในการตัดสินใจในการเพิ่มความปลอดภัยทางออนไลน์และความสามารถด้านดิจิทัลของประชากรเป้าหมาย`,
    overview__report_composition: `รายงานประกอบด้วยสามส่วน`,
    overview__section_1: `ส่วนที่ 1`,
    overview__section_2: `ส่วนที่ 2`,
    overview__section_3: `ส่วนที่ 3`,
    overview__baselining_desc: `การทำความเข้าใจระดับความสามารถทางดิจิทัลและความเสี่ยงทางไซเบอร์ของประชากรเป้าหมายของโปรแกรม`,
    overview__assessment_desc: `ทำความเข้าใจเกี่ยวกับการจัดอันดับผลกระทบ DQ ของโปรแกรม`,
    overview__enhancement_desc: `ทำความเข้าใจกับพื้นที่ที่มีศักยภาพในการปรับปรุงโปรแกรม`,
    overview__key_terms: `ข้อกำหนดที่สำคัญ`,
    overview__skill_acquire: `การได้มาซึ่งทักษะ`,
    overview__skill_acquire_desc: `เมื่อโมดูลหรือการประเมินบรรลุระดับหนึ่งหรือสูงกว่า`,
    overview__skill_improve: `การพัฒนาทักษะ`,
    overview__skill_improve_desc: `ได้รับการตัดสินว่ามีการปรับปรุงเมื่อมีการปรับปรุงในระดับหนึ่งอันเป็นผลมาจากการเปรียบเทียบคำถามก่อน/หลังของโมดูล`,
    overview__intention: `ความตั้งใจในการเปลี่ยนแปลง`,
    overview__intention_desc: `ดำเนินการผ่านโปรแกรมและในตอนท้ายของแต่ละโมดูลจะถามผู้ใช้ว่าโมดูลนี้ทำให้เขาคิดเกี่ยวกับการเปลี่ยนแปลงพฤติกรรมของตนหรือไม่`,
    overview__following_programs: `ต่อไปนี้เป็นรายละเอียดเกี่ยวกับโปรแกรมที่โรงเรียนของคุณเข้าร่วม`,
    overview__about: `เกี่ยวกับ`,
    overview__type: `พิมพ์`,
    overview__time_to_complete: `เวลาที่จะเสร็จสมบูรณ์`,
    overview__dct_title: `การทดสอบการเป็นพลเมืองดิจิทัล (DCT)`,
    overview__dct_desc: `DCT เป็นแบบทดสอบวัด DQ ของเด็กที่ง่ายและรวดเร็ว คะแนนของนักเรียนจะถูกรวบรวมสำหรับโรงเรียนเพื่อดูว่าพวกเขาเปรียบเทียบกับค่าเฉลี่ยระดับประเทศและระดับโลกอย่างไร`,
    overview__dct_type: `การประเมิน`,
    overview__dct_time: `30 นาที`,
    overview__dqw_title: `ดีคิว เวิลด์ (DQW)`,
    overview__dqw_desc: `DQ World เป็นแพลตฟอร์มการเรียนรู้ออนไลน์สำหรับเด็กๆ เพื่อเรียนรู้และพัฒนาทักษะการเป็นพลเมืองดิจิทัลทั้ง 8 ทักษะผ่านบทเรียนเพื่อการศึกษา วิดีโอแอนิเมชัน และมินิเกม`,
    overview__dqw_type: `การศึกษา`,
    overview__dqw_time: `8-10 ชม`,
    overview__about_dq_score: `เกี่ยวกับคะแนน DQ`,
    overview__following_breakdown: `ต่อไปนี้เป็นรายละเอียดความหมายของคะแนน DQ`,
    overview__knowledge: `ความรู้`,
    overview__skills: `ทักษะ`,
    overview__attitude: `ทัศนคติและค่านิยม`,
    overview__excellent: `ยอดเยี่ยม`,
    overview__satisfy: `น่าพอใจ`,
    overview__less_satisfy: `น้อยกว่าที่น่าพอใจ`,
    overview__attention: `ต้องการความสนใจ`,
    overview__excellent_criteria: `สูงกว่าหรือเท่ากับ 115`,
    overview__satisfy_criteria: `มากกว่าหรือเท่ากับ 100 แต่ต่ำกว่า 115`,
    overview__less_satisfy_criteria: `สูงกว่าหรือเท่ากับ 85 แต่ต่ำกว่า 100`,
    overview__attention_criteria: `ต่ำกว่า 85`,
    overview__knowledge_excellent: `มีความเข้าใจอย่างดีเยี่ยมเกี่ยวกับวิธีการทำงานของอินเทอร์เน็ตและสื่อดิจิทัล และผลกระทบต่อการสร้างตัวตนออนไลน์ที่ดี`,
    overview__knowledge_satisfy: `มีความเข้าใจค่อนข้างดีเกี่ยวกับวิธีการทำงานของอินเทอร์เน็ตและสื่อดิจิทัล และผลกระทบต่อการสร้างตัวตนออนไลน์ที่ดี`,
    overview__knowledge_less_satisfy: `มีความเข้าใจที่ค่อนข้างอ่อนแอเกี่ยวกับวิธีการทำงานของอินเทอร์เน็ตและสื่อดิจิทัล และผลกระทบต่อการสร้างตัวตนออนไลน์ที่ดี`,
    overview__knowledge_attention: `มีความเข้าใจอย่างจำกัดเกี่ยวกับวิธีการทำงานของอินเทอร์เน็ตและสื่อดิจิทัล และผลกระทบต่อการสร้างตัวตนออนไลน์ที่ดี`,
    overview__skills_excellent: `มีความสามารถสูงในการสร้างตัวตนออนไลน์ที่ดีและสอดคล้องกัน แสดงให้เห็นถึงความพร้อมในการใช้เทคโนโลยีเพื่อแก้ปัญหาหรือบรรลุเป้าหมาย`,
    overview__skills_satisfy: `สามารถสร้างตัวตนบนโลกออนไลน์ที่ดีและสอดคล้องกันได้ดีกว่าคนอื่นๆ มีความพร้อมกว่าเพื่อนในการใช้เทคโนโลยีเพื่อแก้ปัญหาหรือบรรลุเป้าหมาย`,
    overview__skills_less_satisfy: `มีความสามารถน้อยกว่าเพื่อนในการสร้างตัวตนออนไลน์ที่ดีและสอดคล้องกัน มีความพร้อมน้อยกว่าเพื่อนในการใช้เทคโนโลยีเพื่อแก้ปัญหาหรือบรรลุเป้าหมาย`,
    overview__skills_attention: `ขาดความสามารถที่สำคัญในการสร้างตัวตนทางออนไลน์ที่ดีและสอดคล้องกัน และ/หรือแสดงนิสัยต่อต้านการสร้างสรรค์ทางออนไลน์ (เช่น ทำตัวแตกต่างเพื่อให้เป็นที่นิยมหรือเพื่อหลีกเลี่ยงความรับผิดชอบ) ไม่พร้อมที่จะใช้เทคโนโลยีเพื่อแก้ปัญหาหรือบรรลุเป้าหมาย`,
    overview__attitude_excellent: `แสดงให้เห็นถึงความซื่อสัตย์ที่โดดเด่นโดยปลูกฝังตัวตนออนไลน์ที่สอดคล้องและมีความรับผิดชอบ มีแนวโน้มมากที่จะแสดงความอดทนและความเคารพในการสื่อสารออนไลน์ และปลูกฝังความคิดของพลเมืองดิจิทัลทั่วโลก`,
    overview__attitude_satisfy: `มีความสมบูรณ์ค่อนข้างมากกว่าเพื่อนในแง่ของตัวตนออนไลน์ มีแนวโน้มมากกว่าเพื่อนที่จะแสดงความอดทนและความเคารพในการสื่อสารออนไลน์`,
    overview__attitude_less_satisfy: `มีความสมบูรณ์น้อยกว่าเพื่อนในเรื่องเกี่ยวกับตัวตนออนไลน์ มีโอกาสน้อยที่จะแสดงความอดทนและความเคารพในการสื่อสารออนไลน์น้อยกว่าเพื่อนคนอื่นๆ`,
    overview__attitude_attention: `มีความสมบูรณ์เพียงเล็กน้อยเกี่ยวกับตัวตนออนไลน์ ไม่น่าจะแสดงความอดทนและความเคารพต่อผู้อื่นในการสื่อสารออนไลน์`,
    baselining_only_dig_desc: `การทำความเข้าใจระดับความสามารถทางดิจิทัลของประชากรเป้าหมายของโปรแกรม`,
    baselining__dig_comp_lvl: `ระดับความสามารถด้านดิจิทัล`,
    baselining__dig_comp_desc: `ความสามารถทางดิจิทัลของผู้เข้าร่วมโปรแกรมอยู่ในระดับใดเมื่อเทียบกับค่าเฉลี่ยระดับประเทศและระดับโลก`,
    baselining__dig_comp_range: `คะแนนความสามารถด้านดิจิทัลมีตั้งแต่ 65-135`,
    baselining__covered_by: `ครอบคลุมโดย`,
    baselining__prog_avg: `ค่าเฉลี่ยของโปรแกรม`,
    baselining__countries_avg: `ค่าเฉลี่ยของประเทศที่เลือก`,
    baselining__glob_avg: `ค่าเฉลี่ยทั่วโลก`,
    baselining__nation_avg: `ค่าเฉลี่ยของประเทศ`,
    baselining__company_avg: `ค่าเฉลี่ยของบริษัท`,
    baselining__dq_comp_scores: `คะแนนตามความสามารถ DQ`,
    baselining__annual_score: `แนวโน้มคะแนนประจำปี`,
    baselining__cyber_risk_lvl: `ระดับความเสี่ยงทางไซเบอร์`,
    baselining__cyber_risk_desc: `ระดับความเสี่ยงทางไซเบอร์ของประชากรเป้าหมายเทียบกับค่าเฉลี่ยระดับประเทศและระดับโลกคือเท่าใด`,
    baselining__in_past_year: `ในปีที่ผ่านมา (%)`,
    baselining__annual_trend: `แนวโน้มประจำปี`,
    baselining__no_risks: `ไม่มีความเสี่ยง`,
    baselining__max_added_error: `ถึงขีดจำกัดการเพิ่มสูงสุดแล้ว เอาออกก่อน.`,
    baselining__program: `โปรแกรม`,
    baselining__company: `บริษัท`,
    baselining__national: `ระดับชาติ`,
    baselining__global: `ทั่วโลก`,
    baselining__dig_skills_lvl: `ระดับทักษะดิจิทัล`,
    baselining__school_desc: `ทำความเข้าใจเกี่ยวกับระดับทักษะดิจิทัลและความเสี่ยงทางไซเบอร์ของประชากรเป้าหมายของโรงเรียน`,
    baselining__school_dig_comp_desc: `โรงเรียนของคุณมีความสามารถด้านดิจิทัลในระดับใดเมื่อเทียบกับค่าเฉลี่ยระดับประเทศและระดับโลก`,
    baselining__dq_score: `คะแนน DQ`,
    baselining__school_avg: `ค่าเฉลี่ยของโรงเรียน`,
    baselining__dq_score_break: `รายละเอียดคะแนน DQ`,
    baselining__mod_overview: `ภาพรวมโมดูล`,
    baselining__mods: `โมดูล`,
    baselining__avg_score: `คะแนนเฉลี่ย`,
    baselining__completion_rate: `อัตราความสำเร็จ`,
    baselining__school_cyber_risk_desc: `นักเรียนของคุณมีความเสี่ยงทางไซเบอร์อยู่ในระดับใดเมื่อเทียบกับค่าเฉลี่ยระดับประเทศและระดับโลก`,
    baselining__cyber_risk_country_compare: `ความเสี่ยงทางไซเบอร์ระดับประเทศเมื่อเทียบกับประเทศอื่นๆ`,
    baselining__percent_risk: `% ของความเสี่ยง`,
    baselining__school: `โรงเรียน`,
    assessment__content_desc: `เนื้อหาการเรียนรู้ของโครงการมีคุณภาพเป็นอย่างไร?`,
    assessment__comprehensive: `ความครอบคลุม`,
    assessment__comprehensive_score: `คะแนนที่ครอบคลุม`,
    assessment__comprehensive_desc: `เนื้อหาการเรียนรู้ของโปรแกรมครอบคลุมและเหมาะสมครบถ้วนกับความสามารถและความฉลาดทางดิจิทัลใน IEEE DQ Global Standards (IEEE 3527.1) หรือไม่`,
    assessment__curriculum_cov: `ความครอบคลุมของหลักสูตร`,
    assessment__less_cov: `ความคุ้มครองน้อยลง`,
    assessment__more_cov: `ครอบคลุมมากขึ้น`,
    assessment__cov_percent: `ความครอบคลุม %`,
    assessment__dig_citizen: `พลเมืองดิจิทัล`,
    assessment__dig_creative: `ความคิดสร้างสรรค์ทางดิจิทัล`,
    assessment__footer: `เรียนรู้เพิ่มเติมเกี่ยวกับผลการวิเคราะห์อย่างละเอียด โปรดตรวจสอบ`,
    assessment__align_analysis: `ส่วนที่ 3.1 การวิเคราะห์การจัดตำแหน่ง`,
    assessment__reach_desc: `ประชากรเป้าหมายยอมรับโปรแกรมอย่างกว้างขวาง มีประสิทธิภาพ และครอบคลุมมากน้อยเพียงใด`,
    assessment__participation_desc: `จำนวนคนที่สำเร็จอย่างน้อยหนึ่งโมดูลของโปรแกรม`,
    assessment__participation: `การมีส่วนร่วม`,
    assessment__total_participation: `การมีส่วนร่วมทั้งหมด`,
    assessment__participants: `ผู้เข้าร่วม`,
    assessment__inclusive_ses_desc: `มีการใช้โปรแกรมนี้อย่างครอบคลุมในเพศ อายุ และกลุ่ม SES ที่แตกต่างกันหรือไม่`,
    assessment__inclusive_desc: `มีการใช้โปรแกรมนี้ในเพศและวัยที่แตกต่างกันหรือไม่?`,
    assessment__by_adii: `โดย ADII`,
    assessment__by_ses: `โดย SES`,
    assessment__by_indigenous: `โดยคนพื้นเมือง`,
    assessment__by_gender: `ตามเพศ`,
    assessment__by_age: `ตามอายุ`,
    assessment__by_school_lvl: `ตามระดับโรงเรียน`,
    assessment__enhancement_reach: `ส่วนที่ 3.2 การเข้าถึงการปรับปรุง`,
    assessment__school_participation_desc: `มีนักเรียนเข้าร่วมโครงการกี่คน?`,
    assessment__inclusive_school_lvl_desc: `มีการใช้โปรแกรมอย่างครอบคลุมในเพศ อายุ และระดับโรงเรียนที่แตกต่างกันหรือไม่`,
    assessment__efficacy_desc: `โปรแกรมช่วยปรับปรุงความสามารถด้านดิจิทัลของผู้เข้าร่วมในด้านความรู้ ทักษะ และทัศนคติได้ดีเพียงใด`,
    assessment__acq: `การได้มาซึ่งความสามารถ`,
    assessment__acq_desc: `สัดส่วนของผู้เข้าร่วมที่ได้รับอย่างน้อยหนึ่งความสามารถทางดิจิทัลผ่านโปรแกรมคือเท่าใด`,
    assessment__acq_complete: `ความสามารถที่ได้รับ`,
    assessment__acq_rate: `อัตราการได้มาซึ่งความสามารถ`,
    assessment__imp: `การพัฒนาความสามารถ`,
    assessment__imp_desc: `สัดส่วนของผู้เข้าร่วมที่แสดงการพัฒนาอย่างมีนัยสำคัญของความสามารถทางดิจิทัลอย่างน้อยหนึ่งรายการผ่านโปรแกรมคือเท่าใด`,
    assessment__imp_complete: `พัฒนาความสามารถแล้ว`,
    assessment__imp_rate: `อัตราการพัฒนาความสามารถ`,
    assessment__int: `ความตั้งใจในการเปลี่ยนแปลง`,
    assessment__int_desc: `สัดส่วนของผู้เข้าร่วมที่แสดงความตั้งใจที่จะเปลี่ยนพฤติกรรมในความสามารถทางดิจิทัลอย่างน้อยหนึ่งอย่างในเชิงบวกหลังจากจบโครงการคือเท่าใด`,
    assessment__int_complete: `ความตั้งใจในการเปลี่ยนแปลง`,
    assessment__int_rate: `อัตราความตั้งใจในการเปลี่ยนแปลง`,
    assessment__no_region: `ไม่มีภูมิภาค`,
    assessment__no_province: `ไม่มีจังหวัด`,
    assessment__no_category: `ไม่มีหมวดหมู่`,
    assessment__mod_score: `คะแนนโมดูล`,
    assessment__mod_score_desc: `คะแนนเฉลี่ยตามโมดูลของผู้เข้าร่วมคือเท่าใด`,
    assessment__mod_score_complete: `คะแนนโมดูล`,
    enhancement__content_desc: `โปรแกรมช่วยปรับปรุงความสามารถด้านดิจิทัลของผู้เข้าร่วมในด้านความรู้ ทักษะ และทัศนคติได้ดีเพียงใด`,
    enhancement__degree_align: `ระดับการจัดข้อความการเรียนรู้ของโปรแกรมด้วย DQ (%)`,
    enhancement__total_align: `การจัดตำแหน่งทั้งหมด`,
    enhancement__associate_mod: `โมดูลที่เกี่ยวข้อง`,
    enhancement__reach_desc: `ตรวจสอบการวิเคราะห์โดยละเอียดต่อไปนี้ของการเข้าถึงโปรแกรมและค้นหาพื้นที่สำหรับการพัฒนา พื้นที่ที่มีศักยภาพที่ต้องการการเอาใจใส่เพื่อเพิ่มการเข้าถึงจะถูกเน้นด้วยสีชมพู`,
    enhancement__by_country: `ตามประเทศ`,
    enhancement__by_month: `ตามเดือน`,
    enhancement__by_period: `ตามระยะเวลา`,
    enhancement__by_module: `ตามโมดูล`,
    enhancement__by_school: `ตามโรงเรียน`,
    enhancement__participants_count: `จำนวนของผู้เข้าร่วม`,
    enhancement__participants_percent: `% ของผู้เข้าร่วม`,
    enhancement__title: `หัวข้อ`,
    enhancement__region: `ภูมิภาค`,
    enhancement__province: `จังหวัด`,
    enhancement__postal: `รหัสไปรษณีย์`,
    enhancement__inst: `รหัสสถาบัน`,
    enhancement__month: `เดือน`,
    enhancement__module: `โมดูล`,
    enhancement__gender: `เพศ`,
    enhancement__age_group: `กลุ่มอายุ`,
    enhancement__adii: `ADII`,
    enhancement__ses: `SES`,
    enhancement__school: `โรงเรียน`,
    enhancement__class: `ชั้นเรียน`,
    enhancement__region_need: `เลือกภูมิภาคเพื่อแสดงข้อมูล`,
    enhancement__province_need: `เลือกจังหวัดเพื่อแสดงข้อมูล`,
    enhancement__postal_need: `เลือกรหัสไปรษณีย์เพื่อแสดงข้อมูล`,
    enhancement__inst_need: `เลือกรหัสสถาบันเพื่อแสดงข้อมูล`,
    enhancement__mod_need: `เลือกโมดูลเพื่อแสดงข้อมูล`,
    enhancement__school_need: `เลือกโรงเรียนเพื่อแสดงข้อมูล`,
    enhancement__class_need: `เลือกชั้นเรียนเพื่อแสดงข้อมูล`,
    enhancement__indigenous: `สถานะชนพื้นเมือง`,
    enhancement__school_level: `ระดับโรงเรียน`,
    enhancement__by_region_footer: `หากจำนวนผู้เข้าร่วมในภูมิภาคน้อยกว่า 10% ของผู้เข้าร่วมทั้งหมด`,
    enhancement__by_province_footer: `หากจำนวนผู้เข้าร่วมในจังหวัดน้อยกว่า 10% ของจำนวนผู้เข้าร่วมทั้งหมด`,
    enhancement__by_month_footer: `หากจำนวนผู้เข้าร่วมในเดือนที่ระบุน้อยกว่า 100 คน`,
    enhancement__by_module_footer: `หากจำนวนผู้เข้าร่วมของโมดูลที่ระบุน้อยกว่า 10% ของผู้เข้าร่วมทั้งหมด`,
    enhancement__by_gender_footer: `หากผู้เข้าร่วมหญิงหรือชายน้อยกว่า 30% ของผู้เข้าร่วมทั้งหมด`,
    enhancement__by_subgroup_footer: `หากจำนวนผู้เข้าร่วมภายในกลุ่มย่อยของโมดูลที่ระุบน้อยกว่า 50 คน`,
    enhancement__efficacy_desc: `ตรวจสอบการวิเคราะห์โดยละเอียดต่อไปนี้เกี่ยวกับประสิทธิภาพของโปรแกรม`,
    enhancement__acq_desc: `เปอร์เซ็นต์ของประชากรแต่ละคนที่ได้รับความสามารถ DQ อย่างน้อยหนึ่งอย่างผ่านโปรแกรม`,
    enhancement__imp_desc: `เปอร์เซ็นต์ของประชากรแต่ละกลุ่มที่แสดงการพัฒนาที่สำคัญในความสามารถ DQ อย่างน้อยหนึ่งรายการผ่านโปรแกรม`,
    enhancement__int_desc: `เปอร์เซ็นต์ของประชากรแต่ละคนที่แสดงความตั้งใจที่จะเปลี่ยนแปลงพฤติกรรมในเชิงบวกในความสามารถ DQ อย่างน้อยหนึ่งรายการหลังจากจบโปรแกรม`,
    enhancement__composite_total: `ผลรวมประกอบ`,
    enhancement__totals: `ผลรวม`,
    enhancement__mod_score_desc: `คะแนนโมดูลเฉลี่ยของผู้เข้าร่วมตามข้อมูลประชากรคือเท่าใด`,
    analysis__in_depth: `การวิเคราะห์เชิงลึก`,
    analysis__in_depth_desc: `ต่อไปนี้คือการวิเคราะห์เชิงลึกของนักเรียนของคุณจากโปรแกรม DQ World`,
    analysis__in_depth_desc_2: `คลิกลิงค์ต่อไปนี้เพื่อดูผลของโรงเรียน`,
    analysis__view: `ดูการวิเคราะห์`,
    progress__school_name: `ชื่อโรงเรียน`,
    progress__school_lvl: `ระดับโรงเรียน`,
    progress__student_count: `จำนวนนักเรียน`,
    progress__school_view_detail: `ดูรายละเอียดโรงเรียน`,
    progress__school_info: `ข้อมูลโรงเรียน`,
    progress__school_head_name: `ชื่อครูใหญ่โรงเรียน`,
    progress__school_head_email: `อีเมล์ครูใหญ่โรงเรียน`,
    progress__class_name: `ชื่อชั้นเรียน`,
    progress__class_lvl: `ระดับชั้นเรียน`,
    progress__class_download_data: `ดาวน์โหลดข้อมูลชั้นเรียน`,
    progress__student_id: `รหัสนักเรียน`,
    progress__student_view_detail: `ดูรายละเอียดนักเรียน`,
    progress__student_info: `ข้อมูลนักเรียน`,
    progress__parent_email: `อีเมล์ผู้ปกครอง`,
    progress__dob: `วันเดือนปีเกิด`,
    progress__reset_pass: `ตั้งรหัสผ่านใหม่`,
    progress__link_gen: `สร้างลิงค์แล้ว`,
    progress__clipboard_to_copy: `คลิกคลิปบอร์ดเพื่อคัดลอกลิงค์!`,
    activation__activation_link: `ลิงค์เปิดใช้งาน`,
    activation__revoke: `ถอน`,
    activation__resend_email: `ส่งอีเมลอีกครั้ง`,
    activation__search_by_email: `ค้นหาทางอีเมล`,
    activation__unable_revoke: `เพิกถอนไม่ได้: ไม่มีชื่อผู้ใช้ที่เชื่อมโยงกับผู้ใช้รายนี้`,
    activation__no_username: `ไม่มีชื่อผู้ใช้ที่เกี่ยวข้องกับผู้ใช้รายนี้`,
    licenses__total_licenses: `ใบอนุญาตทั้งหมด`,
    licenses__used_licenses: `ใบอนุญาตที่ใช้`,
    licenses__unused_licenses: `ใบอนุญาตที่ไม่ได้ใช้`,
    licenses__add_users: `เพิ่มผู้ใช้`,
    licenses__download_csv: `ดาวน์โหลดไฟล์`,
    licenses__upload_csv: `อัพโหลดไฟล์`,
    licenses__download_the_csv_file: `1. ดาวน์โหลดไฟล์ excel (.xlsx)`,
    licenses__fill_out_the_csv_file_completely: `2. กรอกไฟล์ excel ให้ครบถ้วน อย่าปล่อยให้ช่องใดว่าง คุณจะต้องให้พนักงานของคุณชื่อและนามสกุล แผนก ตำแหน่งงาน วันเกิด ที่อยู่อีเมล เพศและรหัสไปรษณีย์`,
    licenses__fill_out_the_csv_file_completely__school: `2. กรอกไฟล์ excel ให้ครบถ้วน อย่าปล่อยให้ช่องใดว่าง คุณจะต้องมีชื่อและนามสกุล ชื่อชั้นเรียน ระดับชั้นเรียน วันเกิด ที่อยู่อีเมลผู้ปกครอง เพศ และรหัสไปรษณีย์ของนักเรียน`,
    licenses__upload_the_csv_file: `3. อัปโหลดไฟล์ excel (.xlsx)`,
    licenses__the_system_will_then_send_out_activation_emails: `4. ระบบจะส่งอีเมลเปิดใช้งานไปยังผู้ใช้ที่คุณระบุไว้ในไฟล์ excel เมื่อผู้ใช้ไปที่อีเมล พวกเขาจะเข้าสู่ระบบด้วยลิงก์เปิดใช้งานและตั้งรหัสผ่านเพื่อดำเนินการต่อไป`,
    licenses__the_system_will_then_send_out_activation_emails__school: `4. จากนั้นระบบจะส่งอีเมลยืนยันการใช้งานไปยังผู้ปกครองของนักเรียนที่คุณระบุไว้ในไฟล์ excel เมื่อผู้ปกครองได้รับอีเมลแล้ว พวกเขาจะต้องเข้าสู่ระบบด้วยลิงก์เปิดใช้งานและตั้งรหัสผ่านใหม่สำหรับบุตรหลานเพื่อดำเนินการต่อไป โปรดอย่าลืมให้ผู้ปกครองส่งชื่อผู้ใช้และรหัสผ่านให้กับบุตรหลานเพื่อเข้าสู่ระบบ`,
    licenses__as_long_as_you_have_unused_licenses_p1: `5. ตราบใดที่คุณมีใบอนุญาตที่ไม่ได้ใช้ คุณก็สามารถเพิ่มบัญชีใหม่ไปยังไฟล์ excel เก่าและอัปโหลดได้ คุณยังสามารถปรับข้อมูลผู้ใช้ในไฟล์ excel ยกเว้นที่อยู่อีเมล สำหรับการเปลี่ยนแปลงที่อยู่อีเมล โปรดติดต่อเราที่`,
    licenses__as_long_as_you_have_unused_licenses_p2: ``,
    licenses__in_case_you_no_longer_possess: `6. ในกรณีที่คุณไม่มีไฟล์ excel เก่าแล้ว ให้ดาวน์โหลดข้อมูลที่มีอยู่จากเว็บไซต์ของเรา`,
    licenses__errors: `ข้อผิดพลาด`,
    licenses__invalid_records: `พบบันทึกที่ไม่ถูกต้อง! เลื่อนหน้าลงเพื่อดูรายละเอียด`,
    licenses__upload_success: `อัปโหลดไฟล์สำเร็จแล้ว`,
    licenses__upload_invalid_type: `ประเภทไฟล์ไม่ถูกต้อง! โปรดอัปโหลดไฟล์ .xlsx/.csv เท่านั้น`,
    licenses__allowed_domain_hint: "เฉพาะอีเมล Open-ID ที่อนุญาตเท่านั้นที่มาจากโดเมน",
    resources__title: `ทรัพยากร`,
    resources__dq_world: `ดีคิว เวิลด์ รีซอร์สเซส`,
    resources__intro_to_dqw: `แนะนำ DQ World`,
    resources__intro_to_dqw_full: `รู้เบื้องต้นเกี่ยวกับ DQ World`,
    resources__parent_consent_letter: `หนังสือยินยอมจากผู้ปกครอง`,
    resources__skill_packs: `ชุดทักษะ`,
    resources__teacher_mats: `วัสดุของครู`,
    resources__goto_dqw_student: `ไปที่ไซต์นักเรียน DQ World`,
    resources__watch_short_vid: `ดูวิดีโอสั้นๆ ทางด้านขวาเพื่อดูว่า DQ World คืออะไร คุณยังสามารถแชร์วิดีโอนี้กับนักเรียนของคุณเพื่อแนะนำสั้นๆ ว่าโปรแกรมของเราทำอะไรได้บ้าง`,
    resources__vid_time: `เวลาวิดีโอ`,
    resources__dqw_curriculum: `หลักสูตร DQ World - 8 ทักษะชีวิตดิจิทัล`,
    resources__dci: `ตัวตนของพลเมืองดิจิทัล`,
    resources__dci_desc: `ความสามารถในการสร้างและจัดการข้อมูลประจำตัวที่สอดคล้องกันทั้งทางออนไลน์และออฟไลน์ด้วยความซื่อสัตย์`,
    resources__stm: `การจัดการเวลาหน้าจอ`,
    resources__stm_desc: `ความสามารถในการจัดการเวลาหน้าจอ การทำงานหลายอย่างพร้อมกัน และการมีส่วนร่วมในเกมออนไลน์และโซเชียลมีเดียด้วยการควบคุมตนเอง`,
    resources__cbm: `การจัดการการกลั่นแกล้งในโลกไซเบอร์`,
    resources__cbm_desc: `ความสามารถในการตรวจจับสถานการณ์การกลั่นแกล้งในโลกไซเบอร์และจัดการอย่างชาญฉลาด`,
    resources__csm: `การจัดการความปลอดภัยทางไซเบอร์`,
    resources__csm_desc: `ความสามารถในการปกป้องข้อมูลด้วยการสร้างรหัสผ่านที่รัดกุมและจัดการการโจมตีทางไซเบอร์ต่างๆ`,
    resources__pm: `การจัดการความเป็นส่วนตัว`,
    resources__pm_desc: `ความสามารถในการจัดการข้อมูลส่วนบุคคลทั้งหมดที่แชร์ออนไลน์เพื่อปกป้องความเป็นส่วนตัวของตนเองและของผู้อื่น`,
    resources__ct: `การคิดอย่างมีวิจารณญาณ`,
    resources__ct_desc: `ความสามารถในการแยกแยะระหว่างข้อมูลจริงและเท็จ เนื้อหาที่ดีและเป็นอันตราย และผู้ติดต่อที่น่าเชื่อถือและน่าสงสัยทางออนไลน์`,
    resources__df: `รอยเท้าดิจิทัล`,
    resources__df_desc: `ความสามารถในการเข้าใจธรรมชาติของรอยเท้าดิจิทัลและผลที่ตามมาในชีวิตจริง และจัดการรอยเท้าเหล่านี้อย่างมีความรับผิดชอบ`,
    resources__de: `การเอาใจใส่แบบดิจิทัล`,
    resources__de_desc: `ความสามารถในการเห็นอกเห็นใจต่อความต้องการและความรู้สึกของตนเองและผู้อื่นทางออนไลน์`,
    resources__parental_consent: `ยินยอมจากผู้ปกครอง`,
    resources__parental_consent_desc_p1: `จดหมายยินยอมจากผู้ปกครองมีไว้เพื่อให้แน่ใจว่าผู้ปกครองรู้ว่าบุตรหลานของตนจะเรียนรู้อะไรใน DQ World ในฐานะส่วนหนึ่งของการวิจัยที่กำลังดำเนินอยู่ สิ่งสำคัญคือผู้ปกครองต้องมีความชัดเจนว่าโปรแกรมนี้เกี่ยวข้องกับอะไร`,
    resources__parental_consent_desc_p2: `เราได้แนบเทมเพลตจดหมายยินยอมจากผู้ปกครองเพื่อช่วยอธิบาย ครูควรพิมพ์เอกสารเหล่านี้และขอให้ผู้ปกครองของนักเรียนเซ็นและส่งคืนหากพวกเขาเลือกที่จะไม่เข้าร่วม ครูยังสามารถแก้ไขได้ตามต้องการสำหรับโรงเรียนของตน คลิกปุ่มต่อไปนี้เพื่อดาวน์โหลด`,
    resources__get_started_guide: `คู่มือเริ่มต้นใช้งาน`,
    resources__skill_packs_desc: `DQ Skill Packs ช่วยให้คุณสอน DQ ให้กับนักเรียนของคุณ คลิกที่ชุดทักษะแต่ละชุดเพื่อดูภาพรวม วัตถุประสงค์หลัก และเวลาบทเรียนโดยประมาณสำหรับแต่ละโซน แม้ว่าเราจะแนะนำให้ครูดำเนินการผ่านโซนตามลำดับโดยเริ่มจากโซน 1 แต่ครูอาจเลือกที่จะเน้นโซนใดก็ได้ตามความต้องการในการสอนของพวกเขา`,
    resources__objective: `วัตถุประสงค์`,
    resources__learn_points: `จุดเรียนรู้ที่สำคัญ`,
    resources__time_to_complete: `เวลาที่จะเสร็จสมบูรณ์`,
    resources__guide_title: `เริ่มต้นใช้งาน DQ World`,
    resources__guide_obj: `คู่มือนี้ช่วยให้นักการศึกษาตั้งค่าและจัดการบัญชี DQWorld.net ของโรงเรียนได้ หลังจากอ่านแล้ว คุณควรรู้วิธีจัดการชั้นเรียน กำหนดชั้นเรียนให้กับครู ตั้งรหัสผ่านใหม่ และอื่นๆ`,
    resources__guide_point_1: `1. การลงทะเบียน`,
    resources__guide_point_2: `2. แผงผู้ดูแลระบบของครู`,
    resources__guide_point_3: `3. หนังสือแจ้งข้อมูลผู้ปกครองและแบบฟอร์มยินยอม`,
    resources__guide_point_4: `4. การเพิ่มชั้นเรียน`,
    resources__guide_point_5: `5. การกระจายบัญชีนักเรียน`,
    resources__guide_point_6: `6. การดูความคืบหน้าของนักเรียน`,
    resources__guide_point_7: `7. การเพิ่มนักเรียนเพิ่มเติม`,
    resources__guide_point_8: `8. การตั้งรหัสผ่านใหม่ของนักเรียน`,
    resources__guide_point_9: `9. การแก้ไขปัญหาและคำถามที่พบบ่อย (FAQs)`,
    resources__stm_obj: `เป็นทักษะด้านดิจิทัลที่ต้องมีที่ช่วยให้เด็กใช้เทคโนโลยีดิจิทัลด้วยความตระหนักรู้และควบคุมตนเองได้ นักเรียนจะได้เรียนรู้เกี่ยวกับหัวข้อต่างๆ เช่น การควบคุมการทำงานหลายอย่างพร้อมกัน การรักษาสมดุลเวลาหน้าจอ การป้องกันการติดเกม และอื่นๆ`,
    resources__stm_point_1: `1. ความสมดุลระหว่างความเป็นจริงทางกายภาพและเสมือนจริง`,
    resources__stm_point_2: `2. ใช้เทคโนโลยีดิจิทัลอย่างมีการควบคุมตนเองและเข้าใจผลข้างเคียงต่างๆของการใช้เวลาหน้าจอมากเกินไป การทำงานหลายอย่างพร้อมกัน และการใช้สื่อดิจิทัลจนเสพติด`,
    resources__stm_point_3: `3. จัดการเวลาและจำกัดปริมาณในการใช้ดิจิทัลส่วนบุคคล ไม่ให้การใช้ดิจิทัลครอบงำชีวิตของพวกเขา`,
    resources__pm_obj: `เป็นทักษะทางดิจิทัลที่ต้องมีที่ช่วยให้เด็ก ๆ สามารถปกป้องข้อมูลส่วนตัวของพวกเขาและผู้ติดต่อได้ นักเรียนจะได้เรียนรู้เกี่ยวกับหัวข้อต่างๆ เช่น ข้อมูลส่วนบุคคล การรักษาความเป็นส่วนตัวบนโซเชียลมีเดีย การปกป้องความเป็นส่วนตัวของผู้อื่น และอื่นๆ`,
    resources__pm_point_1: `1. จัดการข้อมูลส่วนบุคคลที่แบ่งปันทางออนไลน์ด้วยดุลยพินิจ`,
    resources__pm_point_2: `2. รับรองและปกป้องความเป็นส่วนตัวของตนเองและผู้ติดต่อ`,
    resources__pm_point_3: `3. เข้าใจถึงความสำคัญของความเป็นส่วนตัวและเป็นสิทธิมนุษยชนขั้นพื้นฐาน`,
    resources__cbm_obj: `เป็นทักษะทางดิจิทัลที่ต้องมีที่ช่วยให้เด็กสามารถตรวจจับสถานการณ์การกลั่นแกล้งในโลกไซเบอร์และจัดการกับสถานการณ์อย่างชาญฉลาด นักเรียนจะได้เรียนรู้เกี่ยวกับหัวข้อต่างๆ เช่น การตรวจจับการกลั่นแกล้งในโลกไซเบอร์ ประเภทของการกลั่นแกล้งในโลกไซเบอร์ การลุกลามของสถานการณ์การกลั่นแกล้งในโลกไซเบอร์ และอื่นๆ`,
    resources__cbm_point_1: `1. จัดการสื่อดิจิทัลอย่างปลอดภัยและมีความรับผิดชอบ`,
    resources__cbm_point_2: `2. ตรวจจับสถานการณ์การกลั่นแกล้งในโลกไซเบอร์และรับมืออย่างใจเย็น`,
    resources__cbm_point_3: `3. รู้วิธีขอความช่วยเหลืออย่างปลอดภัยก่อนที่ปัญหาการกลั่นแกล้งในโลกไซเบอร์จะเกินการควบคุม`,
    resources__dci_obj: `เป็นทักษะทางดิจิทัลที่ต้องมีซึ่งช่วยให้เด็ก ๆ สร้างตัวตนบนโลกออนไลน์ด้วยความซื่อสัตย์ นักเรียนจะได้เรียนรู้เกี่ยวกับหัวข้อต่างๆ เช่น วิธีการทำงานของอินเทอร์เน็ต ผู้นำทางดิจิทัล ตัวตนออฟไลน์และออนไลน์ที่สอดคล้องกัน และอื่นๆ อีกมากมาย`,
    resources__dci_point_1: `1. เข้าใจธรรมชาติของโลกดิจิทัลและใช้เทคโนโลยีและสื่อดิจิทัลอย่างเชี่ยวชาญ`,
    resources__dci_point_2: `2. มีความรู้และทักษะในการสร้างและจัดการตัวตนออนไลน์และออฟไลน์ที่สมบูรณ์และสอดคล้องกัน`,
    resources__dci_point_3: `3. ตระหนักถึงความสำคัญของการเป็นพลเมืองโลกในพื้นที่ดิจิทัล`,
    resources__dfm_title: `การจัดการรอยเท้าดิจิทัล`,
    resources__dfm_obj: `เป็นทักษะทางดิจิทัลที่ต้องมี ซึ่งช่วยให้เด็กๆ ตระหนักรู้และจัดการร่องรอยของข้อมูลที่พวกเขาทิ้งไว้ในโลกออนไลน์ได้ นักเรียนจะได้เรียนรู้เกี่ยวกับหัวข้อต่างๆ เช่น รอยเท้าดิจิทัลคืออะไร การคงอยู่ของรอยเท้าดิจิทัล ผลกระทบต่อชื่อเสียงทางดิจิทัล และอื่นๆ`,
    resources__dfm_point_1: `1. เข้าใจธรรมชาติของการสื่อสารออนไลน์`,
    resources__dfm_point_2: `2. รู้ว่าทุกสิ่งที่พูดและทำออนไลน์ทิ้งร่องรอยที่เรียกว่ารอยเท้าดิจิทัล`,
    resources__dfm_point_3: `3. ตระหนักถึงลักษณะถาวรของรอยเท้าดิจิทัลและผลที่ตามมาในชีวิตจริง รวมถึงการสร้างชื่อเสียงทางออนไลน์โดยไม่ได้ตั้งใจ`,
    resources__dfm_point_4: `4. ใช้ทักษะในการจัดการรอยเท้าดิจิทัลอย่างมีความรับผิดชอบ`,
    resources__csm_obj: `เป็นทักษะทางดิจิทัลที่ต้องมี ซึ่งช่วยให้เด็กๆ สร้างและรักษารหัสผ่านที่รัดกุม และหลีกเลี่ยงการโจมตีทางไซเบอร์ นักเรียนจะได้เรียนรู้เกี่ยวกับหัวข้อต่างๆ เช่น การสร้างรหัสผ่านที่รัดกุม การจัดการสแปมและการหลอกลวง การสังเกตความปลอดภัยของมือถือ และอื่นๆ`,
    resources__csm_point_1: `1. ตรวจจับและป้องกันตนเองและผู้อื่นจากการโจมตีทางไซเบอร์ต่างๆ เช่น สแปม สแกม และฟิชชิง`,
    resources__csm_point_2: `2. สร้างและจดจำรหัสผ่านที่รัดกุมและเก็บเป็นความลับ`,
    resources__csm_point_3: `3. ตระหนักถึงแนวทางปฏิบัติที่ปลอดภัยสำหรับการใช้อุปกรณ์พกพา เช่น การอัปเดตซอฟต์แวร์และการปิดบริการระบุตำแหน่งเมื่อไม่ได้ใช้งาน`,
    resources__ct_obj: `เป็นทักษะดิจิทัลที่ต้องมี ซึ่งช่วยให้เด็กๆ สามารถแยกแยะระหว่างเนื้อหาที่ดีและเป็นอันตราย ตลอดจนการติดต่อทางออนไลน์ที่น่าเชื่อถือและน่าสงสัย นักเรียนจะได้เรียนรู้เกี่ยวกับหัวข้อต่างๆ เช่น ข้อมูลจริงกับข้อมูลเท็จ เพื่อนออนไลน์ วิธีวิจารณ์เนื้อหา และอื่นๆ`,
    resources__ct_point_1: `1. ประเมินข้อมูลออนไลน์ เนื้อหา และการติดต่ออย่างมีวิจารณญาณ`,
    resources__ct_point_2: `2. เข้าใจผลเสียของข้อมูลที่เป็นเท็จ ความรุนแรง เนื้อหาที่ไม่เหมาะสม และความเสี่ยงที่เกี่ยวข้องกับคนแปลกหน้าทางออนไลน์`,
    resources__ct_point_3: `3. ใช้ความคิดอย่างมีวิจารณญาณเพื่อแยกแยะข้อมูลจริงและเท็จ เนื้อหาที่ดีและเป็นอันตราย และผู้ติดต่อออนไลน์ที่น่าเชื่อถือและน่าสงสัย`,
    resources__de_obj: `เป็นทักษะทางดิจิทัลที่ต้องมี ซึ่งส่งเสริมให้เด็กๆ เห็นอกเห็นใจต่อความต้องการและความรู้สึกของตนเองและผู้อื่นทางออนไลน์ นักเรียนจะได้เรียนรู้เกี่ยวกับหัวข้อต่าง ๆ เช่น ผู้ยืนดูกับผู้ยืนหยัด การรับฟังความเห็นอกเห็นใจ การเห็นอกเห็นใจผู้ที่ตกเป็นเหยื่อในโลกไซเบอร์ และอีกมากมาย`,
    resources__de_point_1: `1. ไวต่อความต้องการและความรู้สึกของตนเองและผู้อื่นเมื่อออนไลน์ แม้ว่าจะไม่มีการโต้ตอบแบบเห็นหน้ากันก็ตาม`,
    resources__de_point_2: `2. เต็มใจเป็นกระบอกเสียงให้กับผู้ที่ต้องการความช่วยเหลือและพูดแทนพวกเขา`,
    resources__de_point_3: `3. ละเว้นจากการตัดสินทางออนไลน์`,
    resources__de_point_4: `4. สร้างความสัมพันธ์ที่ดีกับผู้ปกครอง ครู และเพื่อน ๆ ทั้งทางออนไลน์และออฟไลน์`,
    resources__upon_completion: `เมื่อเสร็จสิ้น นักเรียนควรจะสามารถ:`,
    resources__time_30: `30 นาที`,
    resources__time_40_60: `40-60 นาทีของการมีส่วนร่วมทางออนไลน์ที่มุ่งเน้น`,
    resources__time_50_75: `50-75 นาทีของการมีส่วนร่วมทางออนไลน์ที่มุ่งเน้น`,
    resources__time_60_90: `60-90 นาทีของการมีส่วนร่วมทางออนไลน์ที่มุ่งเน้น`,
    resources__time_90_120: `90-120 นาทีของการมีส่วนร่วมทางออนไลน์ที่มุ่งเน้น`,
    resources__lesson_pdf: `ดาวน์โหลดบทเรียน PDF`,
    resources__ppt_slides: `ดาวน์โหลดสไลด์ PPT`,
    resources__dqw_intro_vid: `วิดีโอแนะนำ DQ World`,
    resources__student_workbook: `สมุดงานนักเรียนฉบับเต็ม`,
    resources__quiz_ans_key: `คีย์คำตอบแบบทดสอบ`,
    resources__workbook_ans_key: `คีย์คำตอบสมุดงาน`,
    contact__req_submit: `ส่งคำขอแล้ว`,
    contact__success: `ส่งเรียบร้อยแล้ว จะมีเจ้าหน้าที่ติดต่อกลับในไม่ช้าเกี่ยวกับคำถามของคุณ\nขอบคุณ`,
}

const lc_ja = {
        general__data_unavailable: `データがありません。`,
        general__download_data: `データをダウンロードする`,
        general__server_response: `サーバー応答`,
        general__error: `エラー`,
        general__total: `合計`,
        general__rows_per_page: `1ページあたりの行数`,
        general__actions: `オプション`,
        general__email: `メール`,
        general__email_address: `メールアドレス`,
        general__username: `ユーザー名`,
        general__name: `ニックネーム`,
        general__last_name: `名字`,
        general__first_name: `名前`,
        general__gender: `性別`,
        general__copy: `コピー`,
        general__copied: `コピーしました`,
        general__unable_copy: `コピーできません`,
        general__send: `送信`,
        general__download: `ダウンロード`,
        general__submit: `送信`,
        general__copyright: `コピーライト`,
        navigation__analytics_dashboard: `学校管理ダッシュボード`,
        navigation__program_overview: `プログラムの概要`,
        navigation__baselining: `ベースライン`,
        navigation__digital_competencies: `デジタルコンピテンシー`,
        navigation__cyber_risks: `サイバーリスク`,
        navigation__assessment: `アセスメント`,
        navigation__enhancement: `エンハンスメント`,
        navigation__content: `コンテンツ`,
        navigation__reach: `リーチ`,
        navigation__efficacy: `エフィカシー`,
        navigation__in_depth_analysis: `詳細分析`,
        navigation__progress: `進捗`,
        navigation__schools: `学校`,
        navigation__classes: `クラス`,
        navigation__students: `児童生徒`,
        navigation__employees: `雇用者`,
        navigation__licenses: `権限`,
        navigation__add_licenses: `権限を追加する`,
        navigation__pending_activations: `アカウントの有効化を待っています`,
        navigation__program_resources: `プログラムのリソース`,
        navigation__contact_us: `お問い合わせ`,
        header__change_password: `パスワードの変更`,
        header__dark_mode: `夜間モード`,
        header__sign_out: `ログアウトする`,
        footer__support: `サポート`,
        footer__help_center: `ヘルプセンター`,
        footer__privacy: `プライバシー`,
        footer__terms_of_service: `利用規約`,
        footer__dq_lab_pte_ltd_all_rights_reserved: `DQ LAB Pte Ltd. All rights reserved.`,
        auth__login: `ログインする`,
        auth__sign_in: `サインインする`,
        auth__pass: `パスワード`,
        auth__remember_me: `情報を保存する`,
        auth__forgot_pass: `パスワードの再設定はこちら`,
        auth__dont_have_acc: `アカウントの新規作成`,
        auth__register: `登録`,
        auth__sign_up: `アカウントの作成`,
        auth__reg_school: `学校を登録する`,
        auth__school_type: `学校の種類`,
        auth__school_size: `学校の規模`,
        auth__country: `国`,
        auth__city: `市`,
        auth__your_first_name: `名前`,
        auth__your_last_name: `名字`,
        auth__your_position: `役職`,
        auth__your_email: `メールアドレス`,
        auth__create_pass: `パスワード`,
        auth__confirm_pass: `パスワードを再入力`,
        auth__keep_me_login: `ログインしたままにする`,
        auth__already_have_acc: `アカウントをすでにお持ちの場合はログインしてください。`,
        auth__err_pass_mismatch: `パスワードが間違っています。`,
        auth__provide_same_pass: `同じパスワードを入力してください。`,
        auth__school_primary: `小学校`,
        auth__school_secondary: `中学校`,
        auth__school_high: `高等学校`,
        auth__reg_company: `会社を登録する`,
        auth__company_name: `会社名`,
        auth__indus_or_sec: `業界`,
        auth__company_size: `会社の規模`,
        auth__company_it: `IT`,
        auth__company_edu: `教育機関`,
        auth__company_corporate: `企業`,
        auth__reg_as_company: `会社として登録`,
        auth__reg_as_school: `学校として登録`,
        auth__req_reset_pass: `パスワードの変更`,
        auth__send_reset_code: `パスワードの再登録用コードを送る`,
        auth__back_to_login: `ログインページに戻る`,
        auth__already_receive_code: `再登録用コードをすでに受け取りましたか？`,
        auth__token_sent: `コードを送信しました`,
        auth__token_sent_success: `再登録用コードをメールに送りました`,
        auth__reset_code: `再登録用コード`,
        auth__set_pass: `パスワードを登録`,
        auth__pass_updated: `パスワードを更新しました`,
        auth__pass_change_success: `パスワードの再登録が完了しました`,
        auth__activate_acc: `アカウントを有効化する`,
        auth__enter_access_code: `アクセスコードを入力`,
        auth__check_email_code: `コードを送りました。メールボックスを確認してください。`,
        auth__access_code: `アクセスコード`,
        auth__resend_code: `コードを再送信しますか？`,
        auth__resent_code: `コードを再送信しました`,
        auth__resend_code_success: `メールアドレスにコードを送信しました`,
        auth__confirmed: `承認しました`,
        auth__confirmed_email: `メールアドレスが承認されました`,
        auth__logout: `ログアウトする`,
        auth__logout_in_process: `ログアウトしています`,
        side_panel__select_country: `国を選んでください`,
        side_panel__add_country: `国を追加する`,
        side_panel__select_dig_comp: `デジタルコンピテンシーを選ぶ`,
        side_panel__select_cyber_risk: `サイバーリスクを選ぶ`,
        side_panel__select_time: `タイムゾーンを選ぶ`,
        side_panel__start_month: `開始月`,
        side_panel__end_month: `終了月`,
        side_panel__select_comp_cat: `コンピテンシーのカテゴリーを選ぶ`,
        side_panel__select_mod_cat: `モジュールカテゴリーを選ぶ`,
        side_panel__select_region: `地域を選ぶ`,
        side_panel__add_region: `地域を追加する`,
        side_panel__select_province: `都道府県を選ぶ`,
        side_panel__add_province: `都道府県を追加する`,
        side_panel__select_school: `学校を選ぶ`,
        side_panel__add_school: `学校を追加する`,
        side_panel__select_class: `クラスを選ぶ`,
        side_panel__add_class: `クラスを追加する`,
        side_panel__select_postal: `郵便番号を選ぶ`,
        side_panel__add_postal: `郵便番号を追加する`,
        side_panel__select_dq_comp: `DQコンピテンシーを選ぶ`,
        side_panel__select_inst: `組織コードを選ぶ`,
        side_panel__add_inst: `組織を追加する`,
        side_panel__select_mod: `モジュールを選ぶ`,
        side_panel__add_mod: `モジュールを追加する`,
        overview__dq_impact_rating: `DQ効果測定`,
        overview__impact_rating: `効果測定`,
        overview__out_of: `外`,
        overview__program: `プログラム`,
        overview__area: `エリア`,
        overview__organisation: `組織`,
        overview__target_pop: `対象人口`,
        overview__group: `グループ`,
        overview__age: `年齢`,
        overview__countries: `国`,
        overview__content_quality: `コンテンツの質`,
        overview__program_reach: `プログラムの到達度`,
        overview__comp_improve: `コンピテンシーの改善度`,
        overview__program_name: `プログラム名`,
        overview__contact_persons: `問い合わせる`,
        overview__objectives: `プログラムの目的`,
        overview__program_delivery: `プログラムの提供`,
        overview__venue: `場所`,
        overview__target_audiences: `ターゲットとなるユーザー`,
        overview__time_taken: `プログラム修了にかかる時間`,
        overview__cost_of_use: `コスト`,
        overview__year_of_implement: `実施期間と現状`,
        overview__ref_frameworks: `参考フレームワーク`,
        overview__ref_frameworks_none: `未使用`,
        overview__mod_dq24: `モジュールとDQ24`,
        overview__mod_dq24_desc: `デジタルライセンスを取得するには、8つのモジュールのうち4つを修了する必要があります。各モジュールの所要時間は約1.5時間で、あらかじめ設定された3つの成果を得ることを目的としています。`,
        overview__learn_mod_id: `モジュールIDを学ぶ`,
        overview__learn_title: `学習テーマの題名`,
        overview__learn_objectives: `学習目的のリスト`,
        overview__target_group_age: `ターゲットとなるグループの年齢`,
        overview__dq24_align: `DQ24認証`,
        overview__about_report: `レポートについて`,
        overview__dq_impact_report: `DQ効果測定レポート`,
        overview__report_intro: `は、デジタルコンピテンシーを育成する教育／研修プログラムのインパクトを把握するためのレポートです。DQ効果測定とは、プログラムのコンテンツの質の程度と、プログラムによりデジタルスキル向上のために生み出されたインパクトを示す複合スコアです。これは、デジタルリテラシー、デジタルスキル、デジタルレディネスに関するIEEE DQグローバルスタンダード（IEEE 3527.1）のグローバルベンチマークに基づく標準化されたインパクトの指標です。本プログラムは、プログラムの実施者から提出された資料および一般に入手可能な資料に基づき、DQ Instituteの研究員がレビューおよび分析を行っています。`,
        overview__report_design: `このレポートは、`,
        overview__prog_owner: `プログラムの実施者が以下に項目について把握することを目的としています。`,
        overview__prog_owner_reason_1: `プログラムの質と実施方針のレベルを理解すること`,
        overview__prog_owner_reason_2: `プログラムの長所とさらなる改善のためのポイントを特定すること`,
        overview__stakeholder: `プログラムの主要なステークホルダーが以下に項目について把握することを目的としています。`,
        overview__stakeholder_reason_1: `プログラムの質と影響に関する有用なデータに基づく評価を得ることで、オンライン上の安全性とデジタルコンピテンシーの向上に関する意思決定を促進すること`,
        overview__report_composition: `報告書は、以下の3つのセクションで構成されています。`,
        overview__section_1: `セクション1`,
        overview__section_2: `セクション2`,
        overview__section_3: `セクション3`,
        overview__baselining_desc: `プログラム対象者のデジタルコンピテンシーとサイバーリスクのレベル`,
        overview__assessment_desc: `プログラムのインパクト評価`,
        overview__enhancement_desc: `プログラムの改善点`,
        overview__key_terms: `キーワード`,
        overview__skill_acquire: `スキルの習得`,
        overview__skill_acquire_desc: `プログラムまたはアセスメントが一定のレベル以上を達成した場合`,
        overview__skill_improve: `スキルの向上`,
        overview__skill_improve_desc: `プログラム実施の前後を比較した結果、ある程度課題が改善したと判断した場合`,
        overview__intention: `変化への意思`,
        overview__intention_desc: `プログラムの進行中、各学習項目の最後に、その項目を終えた結果、自分の行動を変えようと思ったかどうかを対象者に尋ねる`,
        overview__following_programs: `以下はあなたの学校が導入する学習プログラムの詳細です。`,
        overview__about: `学習プログラムについて`,
        overview__type: `教材のタイプ`,
        overview__time_to_complete: `完了までの時間`,
        overview__dct_title: `DQアセスメント（DQA）`,
        overview__dct_desc: `DQアセスメントは、児童生徒のDQを測定するための手軽で簡単なテストです。児童生徒のスコアは学校ごとに集計され、全国平均や世界平均との比較によって表示されます。`,
        overview__dct_type: `オンラインテスト`,
        overview__dct_time: `30分`,
        overview__dqw_title: `DQ World (DQW)`,
        overview__dqw_desc: `DQ Worldは、スライドレッスン、アニメーション動画、ミニゲームなどを通して、子どもたちが8つのデジタル・シティズンシップスキルを学び、DQスコアを向上させるためのオンライン学習プラットフォームです。`,
        overview__dqw_type: `オンライン学習プラットフォーム`,
        overview__dqw_time: `8～10時間`,
        overview__about_dq_score: `DQスコアについて`,
        overview__following_breakdown: `以下はDQスコアの数値が示す到達度の詳細です。`,
        overview__knowledge: `知識`,
        overview__skills: `スキル`,
        overview__attitude: `態度と価値観`,
        overview__excellent: `素晴らしい`,
        overview__satisfy: `満足できる`,
        overview__less_satisfy: `努力が必要`,
        overview__attention: `注意が必要`,
        overview__excellent_criteria: `115以上`,
        overview__satisfy_criteria: `100以上、115未満`,
        overview__less_satisfy_criteria: `85以上、100未満`,
        overview__attention_criteria: `85未満`,
        overview__knowledge_excellent: `インターネットとデジタルメディアがどのように機能し、健全なオンライン上での人格の構築にどのような影響を与えるかをよく理解しています。`,
        overview__knowledge_satisfy: `インターネットとデジタルメディアがどのように機能し、健全なオンライン上の人格の構築にどのような影響を与えるかについて、比較的よく理解しています。`,
        overview__knowledge_less_satisfy: `インターネットとデジタルメディアがどのように機能し、健全なオンライン上の人格の構築にどのような影響を与えるかについて、比較的理解度が低いです。`,
        overview__knowledge_attention: `インターネットとデジタルメディアがどのように機能し、健全なオンライン上の人格の構築にどのような影響を与えるかについて、限られた理解しか出来ていません。`,
        overview__skills_excellent: `健全で調和のとれたオンライン上での人格を構築することが出来ます。これは、問題解決や目標達成のためにテクノロジーを活用する準備が出来ていることを示します。`,
        overview__skills_satisfy: `他の児童生徒と比較すると、より健全で調和のとれたオンライン上での人格を構築することが出来ます。これは、問題解決や目標達成のためにテクノロジーを活用する準備が出来ていることを示します。`,
        overview__skills_less_satisfy: `他の児童生徒と比較すると、健全で調和のとれたオンライン上での人格を構築することが出来ていません。これは、問題解決や目標達成のためにテクノロジーを活用する準備が出来ていないことを示します。`,
        overview__skills_attention: `健全で調和のとれたオンライン上での人格を構築する能力が欠けています。これは、問題解決や目標達成のためにテクノロジーを活用する準備が出来ていないといえます。`,
        overview__attitude_excellent: `一貫した責任あるオンラインの人格を培い、とても誠実に振舞えています。オンライン上のコミュニケーションにおいて、他者への寛容と敬意を示し、世界を舞台に活躍するデジタル市民としての態度を養えています。`,
        overview__attitude_satisfy: `他の児童生徒と比較すると、より一貫した責任あるオンラインの人格を培い、とても誠実に振舞えています。オンライン上のコミュニケーションにおいて、他者への寛容と敬意を示し、世界を舞台に活躍するデジタル市民としての態度を養えている傾向があります。`,
        overview__attitude_less_satisfy: `他の児童生徒と比較すると、一貫した責任あるオンラインの人格を培い、とても誠実に振舞えていないようです。オンライン上のコミュニケーションにおいて、他者への寛容と敬意を示し、世界を舞台に活躍するデジタル市民としての態度を養えていない可能性があります。`,
        overview__attitude_attention: `一貫した責任あるオンラインの人格を培い、とても誠実に振舞えていません。オンライン上のコミュニケーションにおいて、他者への寛容と敬意を示し、世界を舞台に活躍するデジタル市民としての態度を養うことが出来ていないようです。`,
        baselining_only_dig_desc: `プログラム対象者のデジタルコンピテンシーのレベルの理解`,
        baselining__dig_comp_lvl: `デジタルコンピテンシーのレベル`,
        baselining__dig_comp_desc: `プログラム対象者のデジタルコンピテンシーのレベルは、国内平均や世界平均と比較してどの程度か`,
        baselining__dig_comp_range: `デジタルコンピテンシーのスコアは、65～135までの数値で表すことが出来ます。`,
        baselining__covered_by: `スコアは、以下の数値をカバーしています。`,
        baselining__prog_avg: `プログラムの平均`,
        baselining__countries_avg: `選択した国の平均`,
        baselining__glob_avg: `世界の平均`,
        baselining__nation_avg: `国内の平均`,
        baselining__company_avg: `会社の平均`,
        baselining__dq_comp_scores: `DQコンピテンシーごとのスコア`,
        baselining__annual_score: `年間のスコアの推移`,
        baselining__cyber_risk_lvl: `サイバーリスクの程度`,
        baselining__cyber_risk_desc: `プログラム対象者はサイバーリスクにどの程度さらされているか`,
        baselining__in_past_year: `過去一年間のパーセンテージ`,
        baselining__annual_trend: `年ごとの推移`,
        baselining__no_risks: `リスクはありません。`,
        baselining__max_added_error: `これ以上追加することが出来ません。`,
        baselining__program: `プログラム`,
        baselining__company: `会社`,
        baselining__national: `国`,
        baselining__global: `世界`,
        baselining__dig_skills_lvl: `デジタルスキルのレベル`,
        baselining__school_desc: `対象となる児童生徒のデジタルスキルとサイバーリスクのレベルを理解する`,
        baselining__school_dig_comp_desc: `学校のデジタルコンピテンシーのレベルは全国平均や世界平均と比べてどの程度か`,
        baselining__dq_score: `DQスコアについて`,
        baselining__school_avg: `学校の平均`,
        baselining__dq_score_break: `DQスコアの詳細`,
        baselining__mod_overview: `モジュールの概要`,
        baselining__mods: `モジュール`,
        baselining__avg_score: `平均スコア`,
        baselining__completion_rate: `完了率`,
        baselining__school_cyber_risk_desc: `児童生徒は、全国や世界の平均と比べてどの程度サイバーリスクにさらされているか`,
        baselining__cyber_risk_country_compare: `他の国と比較した際の国内のサイバーリスク`,
        baselining__percent_risk: `％のリスク`,
        baselining__school: `学校`,
        assessment__content_desc: `プログラムの学習コンテンツの質はどの程度か`,
        assessment__comprehensive: `包括性`,
        assessment__comprehensive_score: `総合スコア`,
        assessment__comprehensive_desc: `プログラムの学習内容は、IEEE DQグローバルスタンダード（IEEE 3527.1）のデジタルインテリジェンスのコンピテンシーを包括的かつ適切にカバーしているか`,
        assessment__curriculum_cov: `カリキュラムの到達度`,
        assessment__less_cov: `低い到達度`,
        assessment__more_cov: `高い到達度`,
        assessment__cov_percent: `到達度％`,
        assessment__dig_citizen: `デジタル・シティズンシップテスト`,
        assessment__dig_creative: `デジタルクリエイティビティ`,
        assessment__footer: `分析結果の詳細については、以下をご覧ください。`,
        assessment__align_analysis: `セクション3.1　認定分析`,
        assessment__reach_desc: `プログラムの対象にどれだけ広く、効率的に、そして包括的にプログラムが実施されたか`,
        assessment__participation_desc: `本プログラムの少なくとも1つのモジュールを修了した人数`,
        assessment__participation: `参加率`,
        assessment__total_participation: `総合参加率`,
        assessment__participants: `参加者`,
        assessment__inclusive_ses_desc: `本プログラムは、性別、年齢、社会経済的地位の異なる人々の間で包括的に利用されているか`,
        assessment__inclusive_desc: `プログラムは、性別や年齢を越えて包括的に利用されているか`,
        assessment__by_adii: `ADII`,
        assessment__by_ses: `社会経済的グループ`,
        assessment__by_indigenous: `民族グループ`,
        assessment__by_gender: `性別`,
        assessment__by_age: `年齢`,
        assessment__by_school_lvl: `学校のレベル`,
        assessment__enhancement_reach: `セクション3.2. リーチの強化`,
        assessment__school_participation_desc: `これまでに何人の児童生徒がこのプログラムに参加したか`,
        assessment__inclusive_school_lvl_desc: `本プログラムは、性別、年齢、学校のレベルを超えて、包括的に利用されているか`,
        assessment__efficacy_desc: `本プログラムは、参加者の知識、スキル、態度におけるデジタル・コンピテンシーの向上にどの程度役立っているか`,
        assessment__acq: `コンピテンシーの習得`,
        assessment__acq_desc: `プログラムを通じて少なくとも1つのデジタルコンピテンシーを習得した参加者の割合はどの程度か`,
        assessment__acq_complete: `習得したコンピテンシー`,
        assessment__acq_rate: `コンピテンシーの習得度`,
        assessment__imp: `コンピテンシーの向上`,
        assessment__imp_desc: `プログラムを通じて、少なくとも1つのデジタルコンピテンシーが大幅に向上した参加者の割合はどの程度か`,
        assessment__imp_complete: `向上したコンピテンシー`,
        assessment__imp_rate: `コンピテンシーの向上率`,
        assessment__int: `変化への意思`,
        assessment__int_desc: `プログラム終了後、少なくとも1つのデジタルコンピテンシーにおいて、積極的に行動を変える意向を示した参加者の割合はどの程度か`,
        assessment__int_complete: `変化への意思`,
        assessment__int_rate: `変化への意思を示した参加者の割合`,
        assessment__no_region: `地域なし`,
        assessment__no_province: `都道府県なし`,
        assessment__no_category: `カテゴリーなし`,
        assessment__mod_score: `モジュールのスコア`,
        assessment__mod_score_desc: `参加者のモジュール別平均スコアは何点か`,
        assessment__mod_score_complete: `モジュールのスコア`,
        enhancement__content_desc: `本プログラムは、参加者の知識、スキル、態度に渡る一連のデジタル・コンピテンシーの向上にどの程度役立っているか`,
        enhancement__degree_align: `プログラムの学習通じて伝えられているメッセージとDQの整合性(%)`,
        enhancement__total_align: `総合的な整合性`,
        enhancement__associate_mod: `関連するモジュール`,
        enhancement__reach_desc: `本プログラムのリーチに関する詳細なカテゴリーごとの分析をチェックし、改善点を確認しましょう。リーチを強化するために必要な可能性のある領域は、ピンク色で強調されています。`,
        enhancement__by_country: `国`,
        enhancement__by_month: `月`,
        enhancement__by_period: `期間`,
        enhancement__by_module: `モジュール`,
        enhancement__by_school: `学校`,
        enhancement__participants_count: `参加者の`,
        enhancement__participants_percent: `参加者の％`,
        enhancement__title: `タイトル`,
        enhancement__region: `地域`,
        enhancement__province: `都道府県`,
        enhancement__postal: `郵便番号`,
        enhancement__inst: `組織コード`,
        enhancement__month: `月`,
        enhancement__module: `モジュール`,
        enhancement__gender: `性別`,
        enhancement__age_group: `年齢グループ`,
        enhancement__adii: `ADII`,
        enhancement__ses: `社会経済的グループ`,
        enhancement__school: `学校`,
        enhancement__class: `クラス`,
        enhancement__region_need: `データを表示する地域を選択する`,
        enhancement__province_need: `データを表示する都道府県を選択する`,
        enhancement__postal_need: `データを表示する郵便番号を選択する`,
        enhancement__inst_need: `データを表示する組織コードを選択する`,
        enhancement__mod_need: `データを表示するモジュールを選択する`,
        enhancement__school_need: `データを表示する学校を選択する`,
        enhancement__class_need: `データを表示するクラスを選択する`,
        enhancement__indigenous: `民族グループ`,
        enhancement__school_level: `学校のレベル`,
        enhancement__by_region_footer: `地域の参加者数が全参加者数の10％未満の場合`,
        enhancement__by_province_footer: `都道府県内の参加者数が全参加者数の10％未満の場合`,
        enhancement__by_month_footer: `特定の月の参加者数が100人未満の場合`,
        enhancement__by_module_footer: `特定のモジュールの参加者数が全参加者数の10％未満の場合`,
        enhancement__by_gender_footer: `女性または男性の参加者が全体の30％未満の場合`,
        enhancement__by_subgroup_footer: `特定のモジュールのサブグループ内の参加者数が50人未満の場合`,
        enhancement__efficacy_desc: `プログラムの効果については、以下の詳細な分析を参照してください。`,
        enhancement__acq_desc: `プログラムを通じて1つ以上のDQコンピテンシーを習得した参加者の割合`,
        enhancement__imp_desc: `プログラムを通じて、1つ以上のDQコンピテンシーにおいて著しい向上を示した参加者の割合`,
        enhancement__int_desc: `プログラム終了後、1つまたは複数のDQコンピテンシーにおいて積極的に行動を変える意向を示した参加者の割合`,
        enhancement__composite_total: `総合`,
        enhancement__totals: `合計`,
        enhancement__mod_score_desc: `参加者の属性別のモジュール平均スコア`,
        analysis__in_depth: `詳細分析`,
        analysis__in_depth_desc: `以下は、DQ Worldプログラムで学んだ児童生徒の詳細な分析です。`,
        analysis__in_depth_desc_2: `以下のリンクをクリックすると、学校全体の結果を見ることができます。`,
        analysis__view: `分析を見る`,
        progress__school_name: `学校名`,
        progress__school_lvl: `学校のレベル`,
        progress__student_count: `児童生徒数`,
        progress__school_view_detail: `学校の詳細を見る`,
        progress__school_info: `学校の情報`,
        progress__school_head_name: `管理者の名前`,
        progress__school_head_email: `管理者のメールアドレス`,
        progress__class_name: `クラス名`,
        progress__class_lvl: `学年`,
        progress__class_download_data: `クラスのデータをダウンロードする`,
        progress__student_id: `児童生徒のID`,
        progress__student_view_detail: `児童生徒の詳細を見る`,
        progress__student_info: `児童生徒の情報`,
        progress__parent_email: `保護者のメールアドレス`,
        progress__dob: `生年月日`,
        progress__reset_pass: `パスワードを再登録する`,
        progress__link_gen: `リンクを作成しました`,
        progress__clipboard_to_copy: `クリックボードを押してリンクをコピーしてください`,
        activation__activation_link: `有効化リンク`,
        activation__revoke: `戻る`,
        activation__resend_email: `メールをもう一度送る`,
        activation__search_by_email: `メールアドレスで探す`,
        activation__unable_revoke: `このメールアドレスに関連するユーザー名がありません`,
        activation__no_username: `このユーザーに関連するユーザー名がありません`,
        licenses__total_licenses: `全てのライセンス`,
        licenses__used_licenses: `使用済みのライセンス`,
        licenses__unused_licenses: `未使用のライセンス`,
        licenses__add_users: `ユーザーを追加する`,
        licenses__download_csv: `ファイルをダウンロードする`,
        licenses__upload_csv: `ファイルをアップロードする`,
        licenses__download_the_csv_file: `1. エクセルファイル（.xlsx）をダウンロードします。`,
        licenses__fill_out_the_csv_file_completely: `2. エクセルファイルの全て項目を記入してください。社員の姓名、部署、役職、生年月日、メールアドレス、性別、郵便番号の情報が必要です。`,
        licenses__fill_out_the_csv_file_completely__school: `2. エクセルファイルの全ての項目を記入してください。児童生徒の氏名、クラス名、学年、生年月日、保護者のメールアドレス、性別、郵便番号が必要です。`,
        licenses__upload_the_csv_file: `3. エクセルファイル（.xlsx）をアップロードします。`,
        licenses__the_system_will_then_send_out_activation_emails: `4. システムはエクセルファイルに記入したユーザーのメールアドレスにアクティベーションメールを送信します。ユーザーはそのメールに記載された有効化リンクでログインし、パスワードを設定する必要があります。`,
        licenses__the_system_will_then_send_out_activation_emails__school: `4. システムはエクセルファイルに記載されている児童生徒の保護者のメールアドレスに有効化メールを送信します。メールを受け取ったら、有効化リンクからログインし、新しいパスワードを設定してください。`,
        licenses__as_long_as_you_have_unused_licenses_p1: `5. 購入済みのライセンス数に残りがある限り、同じエクセルファイルに新しいアカウントを追加してアップロードするだけでアカウントを追加することが出来ます。アカウント情報の変更などについては、各営業担当までお問い合わせください。`,
        licenses__as_long_as_you_have_unused_licenses_p2: ``,
        licenses__in_case_you_no_longer_possess: `6. エクセルファイルを紛失された場合は、再度ダッシュボードよりダウンロードしてください。`,
        licenses__errors: `エラー`,
        licenses__invalid_records: `無効なレコードが見つかりました！ページをスクロールして詳細をご覧ください。`,
        licenses__upload_success: `ファイルが正常にアップロードされました`,
        licenses__upload_invalid_type: `無効なファイル形式です。.xlsxもしくは.csv形式のファイルをアップロードしてください。`,
        licenses__allowed_domain_hint: "許可されるのはドメインからの Open-ID メールのみです",
        resources__title: `リソース`,
        resources__dq_world: `DQ Worldの補助教材`,
        resources__intro_to_dqw: `DQ Worldの導入`,
        resources__intro_to_dqw_full: `DQ Worldを始めるにあたって`,
        resources__parent_consent_letter: `保護者の同意書`,
        resources__skill_packs: `スキルパック（指導書）`,
        resources__teacher_mats: `先生向け資料（ワークブック）`,
        resources__goto_dqw_student: `DQ Worldに移動する`,
        resources__watch_short_vid: `DQ Worldの概要について、右側の短いビデオをご覧ください。児童の皆さんに学習内容を簡単に紹介しましょう。`,
        resources__vid_time: `動画を見る時間`,
        resources__dqw_curriculum: `DQ Worldのカリキュラム - 8つのデジタルライフに必要なスキル`,
        resources__dci: `デジタル市民のアイデンティティ`,
        resources__dci_desc: `現実世界とネット世界の両方で、健全で誠実なアイデンティティを構築・管理する能力`,
        resources__stm: `スクリーンタイムの扱い`,
        resources__stm_desc: `娯楽のためにスクリーンを見ている時間や宿題やゲームなどのマルチタスク、SNSを自制心を持って管理する能力`,
        resources__cbm: `ネットいじめの扱い`,
        resources__cbm_desc: `ネットいじめの役割を理解し、状況をすばやく察知し、賢く対処する能力`,
        resources__csm: `サイバーセキュリティの扱い`,
        resources__csm_desc: `強力なパスワードの作成方法やサイバー攻撃の種類と対処法を知り、自分のデータを守る能力`,
        resources__pm: `プライバシーの扱い`,
        resources__pm_desc: `自分や他者のプライバシーを守るために、インターネット上に共有される全ての個人情報を、分別を持って管理する能力`,
        resources__ct: `クリティカルシンキング`,
        resources__ct_desc: `インターネット上の情報の真偽やコンテンツの有害性、人間関係の信頼性を正しく判断する能力`,
        resources__df: `デジタルフットプリント`,
        resources__df_desc: `インターネットの性質やオンラインでの言動と現実世界での帰結を理解し、責任を持って行動する能力`,
        resources__de: `デジタル共感力`,
        resources__de_desc: `インターネット上での自分と他者のニーズや感情に対して、共感を示す能力`,
        resources__parental_consent: `保護者同意書`,
        resources__parental_consent_desc_p1: `保護者同意書は、児童生徒がDQ Worldで何を学ぶのかを保護者の皆様に知っていただくためのものです。`,
        resources__parental_consent_desc_p2: `保護者同意書のテンプレートをご用意しています。DQ Worldの学習開始を知らせたり、同意を得たりすることが必要な場合、これを児童生徒に配布し、保護者に署名の上、返送してもらってください。また、学校の必要に応じて内容を調整することもできます。次のボタンをクリックしてダウンロードしてください。`,
        resources__get_started_guide: `DQ Worldスタートガイド`,
        resources__skill_packs_desc: `DQスキルパックはDQ Worldを授業で扱う際に役立ちます。各スキルパックをクリックすると、各ゾーンの概要、主な目標、レッスン時間の目安が表示されます。ゾーン1から順番に学習を進めることをお勧めしますが、指導の必要に応じて、どのゾーンからでも学習いただけます。`,
        resources__objective: `目的`,
        resources__learn_points: `重要な学習のポイント`,
        resources__time_to_complete: `完了までの時間`,
        resources__guide_title: `DQ Worldを始めましょう`,
        resources__guide_obj: `このガイドは、教職員の方が学校管理アカウントを管理するのに役立ちます。このガイドを読むことで、クラスの管理、担当教員のへのクラスの割り当て、パスワードのリセットなどの方法を理解することが出来ます。`,
        resources__guide_point_1: `1. サインアップ`,
        resources__guide_point_2: `2. 学校向け管理ダッシュボード`,
        resources__guide_point_3: `3. 保護者へのお便りと同意書`,
        resources__guide_point_4: `4. クラスの追加`,
        resources__guide_point_5: `5. 児童生徒アカウントの配布`,
        resources__guide_point_6: `6. 児童生徒の学習進捗の確認`,
        resources__guide_point_7: `7. 児童生徒の追加`,
        resources__guide_point_8: `8. 児童生徒アカウントのパスワードのリセット`,
        resources__guide_point_9: `9. トラブルシューティングとよくあるご質問 (FAQ)`,
        resources__stm_obj: `は、子どもたちが意識的に自制心を持ってデジタル機器や技術を利用できるようにするために必須のデジタルスキルです。`,
        resources__stm_point_1: `1. 現実世界とデジタル世界のバランスをとる`,
        resources__stm_point_2: `2. 自制心を持ってデジタル機器や技術を使用し、過度のスクリーンタイム、マルチタスク、デジタルメディアの依存的な使用によるさまざまな悪影響を理解する`,
        resources__stm_point_3: `3. 時間を管理し、学習以外のデジタル利用の制限を設ける`,
        resources__pm_obj: `は、子どもたちが自分自身や他者の個人情報を守るために必要なデジタルスキルです。児童生徒は、個人情報、ソーシャルメディアにおけるプライバシーの保護、他人のプライバシーの保護などのトピックについて学びます。`,
        resources__pm_point_1: `1. オンラインで共有される個人情報を慎重に扱う`,
        resources__pm_point_2: `2. 自分自身と自分が持つ他人連絡先のプライバシーを確保し、保護する`,
        resources__pm_point_3: `3. プライバシーの重要性とそれが基本的人権であることを理解する`,
        resources__cbm_obj: `は、ネットいじめの状況を察知し、賢く対処できるようにするために必要なデジタルスキルです。ネットいじめの状況の認識、ネットいじめの種類、ネットいじめの状況に対処する方法などについて学びます。`,
        resources__cbm_point_1: `1. デジタルメディアを安全かつ責任を持って扱う`,
        resources__cbm_point_2: `2. いじめの状況を察知し、冷静に対処する`,
        resources__cbm_point_3: `3. ネットいじめの問題が制御不能になる前に、安全に助けを求める方法を知る`,
        resources__dci_obj: `は、子どもたちがオンラインで誠実な人格を築けるようにするために必要なデジタルスキルです。インターネットの仕組み、デジタルリーダー、オフラインとオンラインで統合された人格を保つ重要性などについて学びます。`,
        resources__dci_point_1: `1. デジタル世界の本質を理解し、デジタル技術とメディアを使いこなす`,
        resources__dci_point_2: `2. オンラインとオフラインの両方で、健全で調和のとれた人格を築き、それを管理するための知識とスキルを身につける`,
        resources__dci_point_3: `3. デジタル空間におけるグローバル・シティズンシップの重要性を認識する`,
        resources__dfm_title: `デジタルフットプリントの扱い`,
        resources__dfm_obj: `デジタルフットプリントの扱いは、子どもたちがオンライン上に残した情報の跡を意識し、適切に管理できるようになるために必要なデジタルスキルです。デジタルフットプリントとは何か、デジタルフットプリントの永続性、デジタルレピュテーションへの影響などについて学びます。`,
        resources__dfm_point_1: `1. オンライン上でのコミュニケーションの本質を理解する`,
        resources__dfm_point_2: `2. オンラインでの言動はすべて、デジタルフットプリントと呼ばれる跡を残すことを知る`,
        resources__dfm_point_3: `3. デジタルの足跡の永続的な性質と、意図しないネット上の評判を作り出すことを含む、現実世界への影響に留意する`,
        resources__dfm_point_4: `4. デジタルフットプリントを責任を持って管理するスキルを身につける`,
        resources__csm_obj: `は、子どもたちが強力なパスワードを作成・維持し、サイバー攻撃を回避できるようにするために必須のデジタルスキルです。児童生徒は、強力なパスワードの作成方法、迷惑メールや詐欺にあった際の対処法、モバイルセキュリティの遵守などのトピックについて学びます。`,
        resources__csm_point_1: `1. SPAM、SCAM、フィッシングなどの様々なサイバー攻撃を認識し、自分自身や他人を守る`,
        resources__csm_point_2: `2. 強力なパスワードを作成し、記憶し、重要な情報を守る`,
        resources__csm_point_3: `3. ソフトウェアのアップデートや、使用していないときは位置情報サービスをオフにするなど、モバイルデバイスの安全な使用方法を認識する`,
        resources__ct_obj: `は、子どもたちがネット上で良いコンテンツと有害なコンテンツ、信頼できる人物と疑わしい人物を区別できるようにするために必要なデジタルスキルです。児童生徒は、情報の真偽、オンライン上で出会う人物やコンテンツの批評の仕方などのトピックについて学びます。`,
        resources__ct_point_1: `1. ネット上の情報、コンテンツ、関わる人を見分ける`,
        resources__ct_point_2: `2. 虚偽の情報、暴力、不適切なコンテンツ、ネット上で出会う他人に関連するリスクの有害性を理解する`,
        resources__ct_point_3: `3. クリティカルシンキング（批判的思考）を使って、ネット上の真実と虚偽の情報、良いコンテンツと有害なコンテンツ、信頼できる人物と疑わしい人物を見分けることができる`,
        resources__de_obj: `は、子どもたちがオンライン上で自分や他人のニーズや感情に対して共感できるようになるために必要なデジタルスキルです。バイスタンダー（傍観する人）とアップスタンダー（立ち上がる人）、共感を持って聞くこと、ネット被害者への共感などのトピックについて学びます。`,
        resources__de_point_1: `1. ネット上では、たとえ対面でなくても、自分や他人のニーズや感情に敏感になる重要性を知る`,
        resources__de_point_2: `2. 助けを必要としている人のために、進んで声を上げる`,
        resources__de_point_3: `3. ネット上では批判的な態度は慎む`,
        resources__de_point_4: `4. オンライン、オフラインを問わず、保護者、先生、友人と良好な関係を築く`,
        resources__upon_completion: `学習修了時には、以下のことができるようになります。`,
        resources__time_30: `30分`,
        resources__time_40_60: `40～60分の集中的なオンライン学習`,
        resources__time_50_75: `50～75分の集中的なオンライン学習`,
        resources__time_60_90: `60～90分の集中的なオンライン学習`,
        resources__time_90_120: `90～120分の集中的なオンライン学習`,
        resources__lesson_pdf: `レッスンPDFをダウンロード`,
        resources__ppt_slides: `PPTスライドをダウンロード`,
        resources__dqw_intro_vid: `DQ Worldの紹介動画`,
        resources__student_workbook: `演習用ワークブック`,
        resources__quiz_ans_key: `クイズ解答のヒント`,
        resources__workbook_ans_key: `ワークブックの解答ポイント`,
        contact__req_submit: `リクエストの送信`,
        contact__success: `送信に成功しました。お問い合わせについて、担当者がすぐにご連絡いたします。`,
        side_panel__dq_comp_score: `総合スコア`,
        side_panel__entire_nation: `全国`,
        side_panel__all_year: `全ての年`,
        side_panel__all_region: `全ての地域`,
        side_panel__all_province: ``,
        side_panel__all_postal: `全ての郵便番号`,
        side_panel__all_inst: `全ての組織コード`,
        side_panel__all_mod: `全ての内容`,
        side_panel__all_school: `全ての学校`,
        side_panel__all_class: `全てのクラス`,
        side_panel__selected_region: `選択した地域`,
        side_panel__selected_province: ``,
        side_panel__selected_postal: `選択した郵便番号`,
        side_panel__selected_inst: `選択した組織コード`,
        side_panel__selected_time: `選択した時間`,
        side_panel__selected_mod: `選択した内容`,
        side_panel__selected_school: `選択した学校`,
        side_panel__selected_class: `選択したクラス`,
        side_panel__prog_mods: `プログラムの構成`,
        stats__highly_excluded: `あまり考慮されていない`,
        stats__excluded: `考慮されていない`,
        stats__included: `考慮されている`,
        stats__highly_included: `よく考慮されている`,
        stats__no: `いいえ`,
        stats__yes: `はい`,
        stats__unknown: `分かりません`,
        stats__female: `女性`,
        stats__male: `男性`,
        stats__other: `その他`,
        stats__junior: ``,
        stats__senior: ``,
        stats__below_9: `9歳以下`,
        stats__below_9_years_old: `9歳以下`,
        stats__10_12_years_old: `10～12歳`,
        stats__13_14_years_old: `13～14歳`,
        stats__15_16_years_old: `15～16際`,
        stats__above_16_years_old: `16歳以上`,
        stats__jan: `1月`,
        stats__feb: `2月`,
        stats__mar: `3月`,
        stats__apr: `4月`,
        stats__may: `5月`,
        stats__jun: `6月`,
        stats__jul: `7月`,
        stats__aug: `8月`,
        stats__sep: `9月`,
        stats__oct: `10月`,
        stats__nov: `11月`,
        stats__dec: `12月`,
        dq_code__before_the_dq_world_edu: `DQ Worldで学習前`,
        dq_code__after_the_dq_world_edu: `DQ Worldで学習後`,
        dq_code__digital_citizen_identity: `デジタル市民としての価値観`,
        dq_code__balanced_use_of_technology: `バランスのとれたテクノロジーの使用`,
        dq_code__behavioural_cyber_risk_management: `オンライン上の行動におけるサイバーリスクの管理`,
        dq_code__personal_cyber_security_management: `個人に関するサイバーセキュリティの扱い`,
        dq_code__digital_empathy: `デジタル上での共感力`,
        dq_code__digital_footprint_management: `デジタルフットプリントの扱い`,
        dq_code__media_and_information_literacy: `メディアと情報に関するリテラシー`,
        dq_code__privacy_management: `プライバシーの扱い`,
        dq_code__digital_co_creator_identity: `デジタル世界の共創者としての価値観`,
        dq_code__healthy_use_of_technology: `健全なテクノロジーの使用`,
        dq_code__content_cyber_risk_management: `コンテンツにおけるサイバーリスクの管理`,
        dq_code__network_security_management: `ネットワークセキュリティの扱い`,
        dq_code__self_awareness_and_management: `自己認識と自己管理`,
        dq_code__online_communication_and_collaboration: `オンラインコミュニケーションと協働`,
        dq_code__content_creation_and_computational_literacy: `コンテンツの作成とITリテラシー`,
        dq_code__intellectual_property_rights_management: `知的財産権の管理`,
        dq_code__digital_changemaker_identity: `デジタル世界の改革者としての価値観`,
        dq_code__civic_use_of_technology: `デジタル市民としてのテクノロジーの使用`,
        dq_code__commercial_and_community_cyber_risk_management: `ビジネスと地域社会におけるサイバーリスクの管理`,
        dq_code__organisational_cyber_security_management: `組織におけるサイバーリスクの管理`,
        dq_code__relationship_management: `対人関係の扱い`,
        dq_code__public_and_mass_communication: `公共マスコミュニケーション`,
        dq_code__data_and_ai_literacy: `データとAIに関するリテラシー`,
        dq_code__participatory_rights_management: `参画権の扱い`,
        dq_def__digital_citizen_identity: `デジタル市民として健全なアイデンティティを構築し、誠実に価値観を保つ能力`,
        dq_def__balanced_use_of_technology: `スクリーンタイム、マルチタスク、デジタルメディアやデバイスを自制心を持って管理することで、オンラインとオフラインの両方の生活をバランスよく保つする能力`,
        dq_def__behavioural_cyber_risk_management: `個人のオンライン上の行動に関連するサイバーリスク（ネットいじめ、ハラスメント、ストーカー行為など）を特定、回避、管理する能力`,
        dq_def__personal_cyber_security_management: `個人情報やデジタルデバイスに対するサイバー攻撃（ハッキング、詐欺、マルウェアなど）を検知し、適切なセキュリティ戦略や保護ツールを使用する能力`,
        dq_def__digital_empathy: `オンライン上で自分自身や他者の感情、ニーズ、懸念に気づき、サポートすることが出来る能力`,
        dq_def__digital_footprint_management: `デジタルフットプリントの性質と現実世界への影響を理解し、責任を持って積極的にポジティブな評価を構築する能力`,
        dq_def__media_and_information_literacy: `メディアや情報を探し、整理し、分析し、批判的に評価する能力`,
        dq_def__privacy_management: `自分や他人のプライバシーを守るために、オンラインで共有されるすべての個人情報を慎重に扱う能力`,
        dq_def__digital_co_creator_identity: `デジタル世界の共創者としての意識を持ち、自己成長できる能力`,
        dq_def__healthy_use_of_technology: `テクノロジーが心身の健康にもたらす利益と害を理解し、健康とウェルビーイングを優先しながらテクノロジーを利用する能力`,
        dq_def__content_cyber_risk_management: `オンライン上のコンテンツに関するサイバーリスク（有害なユーザー生成コンテンツ、人種差別的／憎悪的コンテンツ、不正使用など）を認識、回避、管理する能力`,
        dq_def__network_security_management: `クラウド上の共同編集可能なデジタル環境に対するサイバー攻撃を検出、回避、管理する能力`,
        dq_def__self_awareness_and_management: `自分の周りのデジタル環境の中で、自分の価値観とデジタル・コンピテンシーをどのように活かせるかを認識し、管理する能力`,
        dq_def__online_communication_and_collaboration: `テクノロジーを活用し、遠隔地においてもコミュニケーションや共同作業を効果的に行う能力`,
        dq_def__content_creation_and_computational_literacy: `革新的かつ創造的な方法で、情報、メディア、テクノロジーを統合、創造し、生み出す能力`,
        dq_def__intellectual_property_rights_management: `コンテンツやテクノロジーを使用・作成する際に、知的財産権（著作権、商標権、特許権など）を理解し、管理する能力`,
        dq_def__digital_changemaker_identity: `デジタル社会における有能な改革者としての自覚を持ち、自己成長できる能力`,
        dq_def__civic_use_of_technology: `テクノロジーを用いて、地域社会、国家、国際社会の福利や発展のために市民参加する能力`,
        dq_def__commercial_and_community_cyber_risk_management: `オンラインで金銭的および思想上の誘導により個人を搾取しようとする組織的な試み（組み込みマーケティング、オンラインプロパガンダ、ギャンブルなど）を理解し、回避する能力`,
        dq_def__organisational_cyber_security_management: `組織としてサイバーセキュリティ対策を検討し、計画し、実施する能力`,
        dq_def__relationship_management: `協調、対立回避、説得を通じて、オンライン上の人間関係を巧みに管理する能力`,
        dq_def__public_and_mass_communication: `より広範なビジネスや社会の潮流を反映したメッセージ、アイデア、意見を交換するために、効果的にコミュニケーションをとる能力`,
        dq_def__data_and_ai_literacy: `データから重要な情報を生成、処理、分析、提示し、人工知能（AI）および関連するアルゴリズムツールや戦略を開発、使用、適用することで、情報に裏打ちされ、最適化され、文脈に即した意思決定プロセスを導く能力`,
        dq_def__participatory_rights_management: `インターネットにアクセスすることに関する自分の権限や権利（個人情報保護、表現の自由、忘れられる権利など）を理解し、行使する能力`,
}
const lc_vi = {
    //general
    general__data_unavailable: "Không có dữ liệu",
    general__download_data: "Tải xuống dữ liệu",
    general__server_response: "Phản hồi của máy chủ",
    general__error: "Lỗi",
    general__total: "Tổng cộng",
    general__rows_per_page: "Hàng trên mỗi trang",
    general__actions: "Hành động",
    general__email: "Email",
    general__email_address: "Địa chỉ email",
    general__username: "Tên tài khoản",
    general__name: "Tên",
    general__last_name: "Họ",
    general__first_name: "Tên",
    general__gender: "Giới tính",
    general__copy: "Sao chép",
    general__copied: "Đã sao chép!",
    general__unable_copy: "Không thể sao chép!",
    general__send: "Gửi",
    general__download: "Tải xuống",
    general__submit: "Nộp",
    general__copyright: "Bản quyền",

    //navigation
    navigation__analytics_dashboard: "Trang tổng quan phân tích",
    navigation__program_overview: "Tổng quan chương trình",
    navigation__baselining: "Đường cơ sở",
    navigation__digital_competencies: "Năng lực kỹ thuật số",
    navigation__cyber_risks: "Rủi ro mạng",
    navigation__assessment: "Đánh giá",
    navigation__enhancement: "Sự nâng cao",
    navigation__content: "Nội dung",
    navigation__reach: "Đạt tới",
    navigation__efficacy: "Hiệu quả",
    navigation__in_depth_analysis: "Phân tích sâu",
    navigation__progress: "Tiến triển",
    navigation__schools: "Trường học",
    navigation__classes: "Lớp học",
    navigation__students: "Sinh viên",
    navigation__employees: "Người lao động",
    navigation__licenses: "Giấy phép",
    navigation__licenses_info: "Licenses Info",
    navigation__add_licenses: "Thêm giấy phép",
    navigation__pending_activations: "Kích hoạt đang chờ xử lý",
    navigation__program_resources: "Tài nguyên chương trình",
    navigation__contact_us: "Liên hệ chúng tôi",

    //header
    header__change_password: "Đổi mật khẩu",
    header__dark_mode: "Chế độ tối",
    header__sign_out: "Đăng xuất",

    //footer
    footer__support: "Trợ giúp",
    footer__help_center: "Trung tâm trợ giúp",
    footer__privacy: "Sự riêng tư",
    footer__terms_of_service: "Điều khoản dịch vụ",
    footer__dq_lab_pte_ltd_all_rights_reserved: "Công ty tư nhân TNHH DQ LAB. Mọi quyền được bảo lưu.",

    //auth
    auth__login: "Đăng nhập",
    auth__sign_in: "Đăng nhập",
    auth__pass: "Mật khẩu",
    auth__remember_me: "Xác thực đăng nhập liên tục",
    auth__forgot_pass: "Quên mật khẩu?",
    auth__dont_have_acc: "Bạn chưa có tài khoản? Đăng ký!",
    auth__register: "Đăng ký",
    auth__sign_up: "Đăng ký",
    auth__reg_school: "Đăng ký [Trường học]",
    auth__school_type: "Loại trường",
    auth__school_size: "Quy mô trường học",
    auth__country: "Quốc gia",
    auth__city: "Thành phố",
    auth__your_first_name: "Tên của bạn",
    auth__your_last_name: "Họ của bạn",
    auth__your_position: "Vị trí của bạn",
    auth__your_email: "Email của bạn",
    auth__create_pass: "Tạo một mật khẩu",
    auth__confirm_pass: "Xác nhận mật khẩu",
    auth__keep_me_login: "Giữ tôi luôn đăng nhập",
    auth__already_have_acc: "Bạn đã có tài khoản? Đăng nhập",
    auth__err_pass_mismatch: "Mật khẩu không khớp",
    auth__provide_same_pass: "Vui lòng cung cấp cùng một mật khẩu trong cả hai trường mật khẩu!",
    auth__school_primary: "Tiểu học",
    auth__school_secondary: "Trung học",
    auth__school_high: "Trung học phổ thông",
    auth__reg_company: "Đăng ký [Công ty]",
    auth__company_name: "Tên công ty",
    auth__indus_or_sec: "Ngành/Lĩnh vực",
    auth__company_size: "Quy mô công ty",
    auth__company_it: "Tin học",
    auth__company_edu: "Giáo dục",
    auth__company_corporate: "Công ty",
    auth__reg_as_company: "Đăng ký làm Công ty",
    auth__reg_as_school: "Đăng ký làm trường học",
    auth__req_reset_pass: "Yêu cầu đặt lại mật khẩu",
    auth__send_reset_code: "Gửi mã đặt lại",
    auth__back_to_login: "Quay lại trang đăng nhập",
    auth__already_receive_code: "Bạn đã nhận được mã chưa?",
    auth__token_sent: "Mã thông báo đã gửi",
    auth__token_sent_success: "Đã gửi mã thông báo đặt lại qua email thành công",
    auth__reset_code: "Đặt lại mã",
    auth__set_pass: "Đặt mật khẩu",
    auth__pass_updated: "Mật khẩu được cập nhật",
    auth__pass_change_success: "Đã đổi mật khẩu thành công",
    auth__activate_acc: "Kích hoạt tài khoản",
    auth__enter_access_code: "Nhập mã truy cập",
    auth__check_email_code: "Vui lòng kiểm tra email của bạn, mã thông báo đã được gửi!",
    auth__access_code: "Mã truy cập",
    auth__resend_code: "Gửi lại mã?",
    auth__resent_code: "Mã đã được gửi lại",
    auth__resend_code_success: "Đã gửi mã thành công tới địa chỉ email của bạn",
    auth__confirmed: "Đã xác nhận",
    auth__confirmed_email: "Đã xác nhận thành công địa chỉ email",
    auth__logout: "Đăng xuất",
    auth__logout_in_process: "Đăng xuất người dùng...",

    //side panel
    side_panel__select_country: "Chọn một quốc gia",
    side_panel__add_country: "Thêm quốc gia",
    side_panel__select_dig_comp: "Chọn năng lực kỹ thuật số",
    side_panel__select_cyber_risk: "Chọn rủi ro mạng",
    side_panel__select_time: "Chọn khoảng thời gian",
    side_panel__start_month: "Tháng bắt đầu",
    side_panel__end_month: "Tháng kết thúc",
    side_panel__select_comp_cat: "Chọn hạng mục năng lực",
    side_panel__select_mod_cat: "Chọn một danh mục mô-đun",
    side_panel__select_region: "Chọn khu vực",
    side_panel__add_region: "Thêm khu vực",
    side_panel__select_province: "Chọn một tỉnh",
    side_panel__add_province: "Thêm tỉnh",
    side_panel__select_school: "Chọn trường",
    side_panel__add_school: "Thêm trường học",
    side_panel__select_class: "Chọn một lớp",
    side_panel__add_class: "Thêm lớp",
    side_panel__select_postal: "Chọn một mã bưu chính",
    side_panel__add_postal: "Thêm mã bưu chính",
    side_panel__select_dq_comp: "Chọn năng lực DQ",
    side_panel__select_inst: "Chọn một mã Viện",
    side_panel__add_inst: "Thêm Viện",
    side_panel__select_mod: "Chọn một mô-đun",
    side_panel__add_mod: "Thêm mô-đun",
    side_panel__dq_comp_score: `DQ Composite Score`,
    side_panel__entire_nation: `Entire Nation`,
    side_panel__all_year: `All Years`,
    side_panel__all_region: `All Regions`,
    side_panel__all_province: `All Provinces`,
    side_panel__all_postal: `All Postal Codes`,
    side_panel__all_inst: `All Institute Codes`,
    side_panel__all_mod: `All Modules`,
    side_panel__all_school: `All Schools`,
    side_panel__all_class: `All Classes`,
    side_panel__selected_region: `Selected Region`,
    side_panel__selected_province: `Selected Province`,
    side_panel__selected_postal: `Selected Postal`,
    side_panel__selected_inst: `Selected Institute Code`,
    side_panel__selected_time: `Selected Time`,
    side_panel__selected_mod: `Selected Module`,
    side_panel__selected_school: `Selected School`,
    side_panel__selected_class: `Selected Class`,
    side_panel__prog_mods: `Program Modules`,

    //graph
    stats__highly_excluded: `Highly Excluded`,
    stats__excluded: `Excluded`,
    stats__included: `Included`,
    stats__highly_included: `Highly Included`,
    stats__no: `No`,
    stats__yes: `Yes`,
    stats__unknown: `Unknown`,
    stats__female: `Female`,
    stats__male: `Male`,
    stats__other: `Other`,
    stats__junior: 'Junior',
    stats__senior: 'Senior',
    stats__below_9: `Below 9`,
    stats__below_9_years_old: `Below 9 Years Old`,
    stats__10_12_years_old: `10-12 Years Old`,
    stats__13_14_years_old: `13-14 Years Old`,
    stats__15_16_years_old: `15-16 Years Old`,
    stats__above_16_years_old: `Above 16 Years Old`,
    stats__jan: `Jan`,
    stats__feb: `Feb`,
    stats__mar: `Mar`,
    stats__apr: `Apr`,
    stats__may: `May`,
    stats__jun: `Jun`,
    stats__jul: `Jul`,
    stats__aug: `Aug`,
    stats__sep: `Sep`,
    stats__oct: `Oct`,
    stats__nov: `Nov`,
    stats__dec: `Dec`,

    //dq code and def
    dq_code__before_the_dq_world_edu: `Before the DQ World Education`,
    dq_code__after_the_dq_world_edu: `After the DQ World Education`,
    dq_code__digital_citizen_identity: `Digital Citizen Identity`,
    dq_code__balanced_use_of_technology: `Balanced Use of Technology`,
    dq_code__behavioural_cyber_risk_management: `Behavioural Cyber-Risk Management`,
    dq_code__personal_cyber_security_management: `Personal Cyber Security Management`,
    dq_code__digital_empathy: `Digital Empathy`,
    dq_code__digital_footprint_management: `Digital Footprint Management`,
    dq_code__media_and_information_literacy: `Media and Information Literacy`,
    dq_code__privacy_management: `Privacy Management`,
    dq_code__digital_co_creator_identity: `Digital Co-Creator Identity`,
    dq_code__healthy_use_of_technology: `Healthy Use of Technology`,
    dq_code__content_cyber_risk_management: `Content Cyber-Risk Management`,
    dq_code__network_security_management: `Network Security Management`,
    dq_code__self_awareness_and_management: `Self-Awareness and Management`,
    dq_code__online_communication_and_collaboration: `Online Communication and Collaboration`,
    dq_code__content_creation_and_computational_literacy: `Content Creation and Computational Literacy`,
    dq_code__intellectual_property_rights_management: `Intellectual Property Rights Management`,
    dq_code__digital_changemaker_identity: `Digital Changemaker Identity`,
    dq_code__civic_use_of_technology: `Civic Use of Technology`,
    dq_code__commercial_and_community_cyber_risk_management: `Commercial and Community Cyber-Risk Management`,
    dq_code__organisational_cyber_security_management: `Organisational Cyber Security Management`,
    dq_code__relationship_management: `Relationship Management`,
    dq_code__public_and_mass_communication: `Public and Mass Communication`,
    dq_code__data_and_ai_literacy: `Data and AI Literacy`,
    dq_code__participatory_rights_management: `Participatory Rights Management`,

    dq_def__digital_citizen_identity: `The ability to build and manage a healthy identity as a digital citizen with integrity.`,
    dq_def__balanced_use_of_technology: `The ability to manage one's life both online and offline in a balanced way by exercising self-control to manage screen time, multitasking, and one's engagement with digital media and devices.`,
    dq_def__behavioural_cyber_risk_management: `The ability to identify, mitigate, and manage cyber risks (e.g., cyberbullying, harassment, and stalking) that relate to personal online behaviors.`,
    dq_def__personal_cyber_security_management: `The ability to detect cyber threats (e.g., hacking, scams, and malware) against personal data and device, and to use suitable security strategies and protection tools.`,
    dq_def__digital_empathy: `The ability to be aware of, be sensitive to, and be supportive of one's own and other's feelings, needs and concerns online.`,
    dq_def__digital_footprint_management: `The ability to understand the nature of digital footprints and their real-life consequences, to manage them responsibly, and to actively build a positive digital reputation.`,
    dq_def__media_and_information_literacy: `The ability to find, organize, analyze, and evaluate media and information with critical reasoning.`,
    dq_def__privacy_management: `The ability to handle with discretion all personal information shared online to protect one's and others' privacy.`,
    dq_def__digital_co_creator_identity: `The ability to identify and develop oneself as a co-creator of the digital ecosystem.`,
    dq_def__healthy_use_of_technology: `The ability to understand the benefits and harms of technology on one's mental and physical health and to use technology use while prioritizing health and well-being.`,
    dq_def__content_cyber_risk_management: `The ability to identify, mitigate, and manage content cyber risks online (e.g., harmful usergenerated content, racist/hateful content, image-based abuse).`,
    dq_def__network_security_management: `The ability to detect, avoid, and manage cyber-threats to cloud-based collaborative digital environments.`,
    dq_def__self_awareness_and_management: `The ability to recognize and manage how one's value system and digital competencies fits with one's digital environment.`,
    dq_def__online_communication_and_collaboration: `The ability to use technology effectively to communicate and collaborate collectively, including at a distance.`,
    dq_def__content_creation_and_computational_literacy: `The ability to synthesise, create, and produce information, media, and technology in an innovative and creative manner.`,
    dq_def__intellectual_property_rights_management: `The ability to understand and manage intellectual property rights (e.g., copyrights, trademarks, and patents) when using and creating content and technology.`,
    dq_def__digital_changemaker_identity: `The ability to identify and develop oneself as a competent changemaker in the digital economy.`,
    dq_def__civic_use_of_technology: `The ability to engage in civic participation for the well-being and growth of local, national, and global communities using technology.`,
    dq_def__commercial_and_community_cyber_risk_management: `The ability to understand, mitigate, and manage commercial or community cyber-risks online, which is an organisational attempt to exploit individuals financially and/or through ideological persuasion (e.g., embedded marketing, online propaganda, and gambling).`,
    dq_def__organisational_cyber_security_management: `The ability to recognise, plan, and implement organisational cyber security defences.`,
    dq_def__relationship_management: `The ability to skilfully manage one's online relationships through collaboration, conflict management, and persuasion.`,
    dq_def__public_and_mass_communication: `The ability to communicate with an online audience effectively to exchange messages, ideas, and opinions reflecting wider business or societal discourses.`,
    dq_def__data_and_ai_literacy: `The ability to generate, process, analyze, present meaningful information from data and develop, use, and apply artificial intelligence (AI) and related algorithmic tools and strategies in order to guide informed, optimized, and contextually relevant decision-making processes.`,
    dq_def__participatory_rights_management: `The ability to understand and exercise one's powers and right to online participation (e.g., their rights to personal data protection, freedom of expression, or to be forgotten).`,

    //country
    dq_country__afghanistan: `Afghanistan`,
    dq_country__aland_islands: `Åland Islands`,
    dq_country__albania: `Albania`,
    dq_country__algeria: `Algeria`,
    dq_country__american_samoa: `American Samoa`,
    dq_country__andorra: `AndorrA`,
    dq_country__angola: `Angola`,
    dq_country__anguilla: `Anguilla`,
    dq_country__antarctica: `Antarctica`,
    dq_country__antigua_and_barbuda: `Antigua and Barbuda`,
    dq_country__argentina: `Argentina`,
    dq_country__armenia: `Armenia`,
    dq_country__aruba: `Aruba`,
    dq_country__australia: `Australia`,
    dq_country__austria: `Austria`,
    dq_country__azerbaijan: `Azerbaijan`,
    dq_country__bahamas: `Bahamas`,
    dq_country__bahrain: `Bahrain`,
    dq_country__bangladesh: `Bangladesh`,
    dq_country__barbados: `Barbados`,
    dq_country__belarus: `Belarus`,
    dq_country__belgium: `Belgium`,
    dq_country__belize: `Belize`,
    dq_country__benin: `Benin`,
    dq_country__bermuda: `Bermuda`,
    dq_country__bhutan: `Bhutan`,
    dq_country__bolivia: `Bolivia`,
    dq_country__bosnia_and_herzegovina: `Bosnia and Herzegovina`,
    dq_country__botswana: `Botswana`,
    dq_country__bouvet_island: `Bouvet Island`,
    dq_country__brazil: `Brazil`,
    dq_country__british_indian_ocean_territory: `British Indian Ocean Territory`,
    dq_country__brunei_darussalam: `Brunei Darussalam`,
    dq_country__bulgaria: `Bulgaria`,
    dq_country__burkina_faso: `Burkina Faso`,
    dq_country__burundi: `Burundi`,
    dq_country__cambodia: `Cambodia`,
    dq_country__cameroon: `Cameroon`,
    dq_country__canada: `Canada`,
    dq_country__cape_verde: `Cape Verde`,
    dq_country__cayman_islands: `Cayman Islands`,
    dq_country__central_african_republic: `Central African Republic`,
    dq_country__chad: `Chad`,
    dq_country__chile: `Chile`,
    dq_country__china: `China`,
    dq_country__christmas_island: `Christmas Island`,
    dq_country__cocos_keeling_islands: `Cocos (Keeling) Islands`,
    dq_country__colombia: `Colombia`,
    dq_country__comoros: `Comoros`,
    dq_country__congo: `Congo`,
    dq_country__congo_the_democratic_republic_of_the: `Congo, The Democratic Republic of the`,
    dq_country__cook_islands: `Cook Islands`,
    dq_country__costa_rica: `Costa Rica`,
    dq_country__cote_divoire: `Cote D'Ivoire`,
    dq_country__croatia: `Croatia`,
    dq_country__cuba: `Cuba`,
    dq_country__cyprus: `Cyprus`,
    dq_country__czech_republic: `Czech Republic`,
    dq_country__denmark: `Denmark`,
    dq_country__djibouti: `Djibouti`,
    dq_country__dominica: `Dominica`,
    dq_country__dominican_republic: `Dominican Republic`,
    dq_country__ecuador: `Ecuador`,
    dq_country__egypt: `Egypt`,
    dq_country__el_salvador: `El Salvador`,
    dq_country__equatorial_guinea: `Equatorial Guinea`,
    dq_country__eritrea: `Eritrea`,
    dq_country__estonia: `Estonia`,
    dq_country__ethiopia: `Ethiopia`,
    dq_country__falkland_islands_malvinas: `Falkland Islands (Malvinas)`,
    dq_country__faroe_islands: `Faroe Islands`,
    dq_country__fiji: `Fiji`,
    dq_country__finland: `Finland`,
    dq_country__france: `France`,
    dq_country__french_guiana: `French Guiana`,
    dq_country__french_polynesia: `French Polynesia`,
    dq_country__french_southern_territories: `French Southern Territories`,
    dq_country__gabon: `Gabon`,
    dq_country__gambia: `Gambia`,
    dq_country__georgia: `Georgia`,
    dq_country__germany: `Germany`,
    dq_country__ghana: `Ghana`,
    dq_country__gibraltar: `Gibraltar`,
    dq_country__greece: `Greece`,
    dq_country__greenland: `Greenland`,
    dq_country__grenada: `Grenada`,
    dq_country__guadeloupe: `Guadeloupe`,
    dq_country__guam: `Guam`,
    dq_country__guatemala: `Guatemala`,
    dq_country__guernsey: `Guernsey`,
    dq_country__guinea: `Guinea`,
    dq_country__guinea_bissau: `Guinea-Bissau`,
    dq_country__guyana: `Guyana`,
    dq_country__haiti: `Haiti`,
    dq_country__heard_island_and_mcdonald_islands: `Heard Island and Mcdonald Islands`,
    dq_country__holy_see_vatican_city_state: `Holy See (Vatican City State)`,
    dq_country__honduras: `Honduras`,
    dq_country__hong_kong: `Hong Kong`,
    dq_country__hungary: `Hungary`,
    dq_country__iceland: `Iceland`,
    dq_country__india: `India`,
    dq_country__indonesia: `Indonesia`,
    dq_country__iran_islamic_republic_of: `Iran, Islamic Republic Of`,
    dq_country__iraq: `Iraq`,
    dq_country__ireland: `Ireland`,
    dq_country__isle_of_man: `Isle of Man`,
    dq_country__israel: `Israel`,
    dq_country__italy: `Italy`,
    dq_country__jamaica: `Jamaica`,
    dq_country__japan: `Japan`,
    dq_country__jersey: `Jersey`,
    dq_country__jordan: `Jordan`,
    dq_country__kazakhstan: `Kazakhstan`,
    dq_country__kenya: `Kenya`,
    dq_country__kiribati: `Kiribati`,
    dq_country__korea_democratic_peoples_republic_of: `Korea, Democratic People's Republic of`,
    dq_country__korea_republic_of: `Korea, Republic of`,
    dq_country__kuwait: `Kuwait`,
    dq_country__kyrgyzstan: `Kyrgyzstan`,
    dq_country__lao_peoples_democratic_republic: `Lao People's Democratic Republic`,
    dq_country__latvia: `Latvia`,
    dq_country__lebanon: `Lebanon`,
    dq_country__lesotho: `Lesotho`,
    dq_country__liberia: `Liberia`,
    dq_country__libyan_arab_jamahiriya: `Libyan Arab Jamahiriya`,
    dq_country__liechtenstein: `Liechtenstein`,
    dq_country__lithuania: `Lithuania`,
    dq_country__luxembourg: `Luxembourg`,
    dq_country__macao: `Macao`,
    dq_country__macedonia_the_former_yugoslav_republic_of: `Macedonia, The Former Yugoslav Republic of`,
    dq_country__madagascar: `Madagascar`,
    dq_country__malawi: `Malawi`,
    dq_country__malaysia: `Malaysia`,
    dq_country__maldives: `Maldives`,
    dq_country__mali: `Mali`,
    dq_country__malta: `Malta`,
    dq_country__marshall_islands: `Marshall Islands`,
    dq_country__martinique: `Martinique`,
    dq_country__mauritania: `Mauritania`,
    dq_country__mauritius: `Mauritius`,
    dq_country__mayotte: `Mayotte`,
    dq_country__mexico: `Mexico`,
    dq_country__micronesia_federated_states_of: `Micronesia, Federated States of`,
    dq_country__moldova_republic_of: `Moldova, Republic of`,
    dq_country__monaco: `Monaco`,
    dq_country__mongolia: `Mongolia`,
    dq_country__montserrat: `Montserrat`,
    dq_country__morocco: `Morocco`,
    dq_country__mozambique: `Mozambique`,
    dq_country__myanmar: `Myanmar`,
    dq_country__namibia: `Namibia`,
    dq_country__nauru: `Nauru`,
    dq_country__nepal: `Nepal`,
    dq_country__netherlands: `Netherlands`,
    dq_country__netherlands_antilles: `Netherlands Antilles`,
    dq_country__new_caledonia: `New Caledonia`,
    dq_country__new_zealand: `New Zealand`,
    dq_country__nicaragua: `Nicaragua`,
    dq_country__niger: `Niger`,
    dq_country__nigeria: `Nigeria`,
    dq_country__niue: `Niue`,
    dq_country__norfolk_island: `Norfolk Island`,
    dq_country__northern_mariana_islands: `Northern Mariana Islands`,
    dq_country__norway: `Norway`,
    dq_country__oman: `Oman`,
    dq_country__pakistan: `Pakistan`,
    dq_country__palau: `Palau`,
    dq_country__palestinian_territory_occupied: `Palestinian Territory, Occupied`,
    dq_country__panama: `Panama`,
    dq_country__papua_new_guinea: `Papua New Guinea`,
    dq_country__paraguay: `Paraguay`,
    dq_country__peru: `Peru`,
    dq_country__philippines: `Philippines`,
    dq_country__pitcairn: `Pitcairn`,
    dq_country__poland: `Poland`,
    dq_country__portugal: `Portugal`,
    dq_country__puerto_rico: `Puerto Rico`,
    dq_country__qatar: `Qatar`,
    dq_country__reunion: `Reunion`,
    dq_country__romania: `Romania`,
    dq_country__russian_federation: `Russian Federation`,
    dq_country__rwanda: `RWANDA`,
    dq_country__saint_helena: `Saint Helena`,
    dq_country__saint_kitts_and_nevis: `Saint Kitts and Nevis`,
    dq_country__saint_lucia: `Saint Lucia`,
    dq_country__saint_pierre_and_miquelon: `Saint Pierre and Miquelon`,
    dq_country__saint_vincent_and_the_grenadines: `Saint Vincent and the Grenadines`,
    dq_country__samoa: `Samoa`,
    dq_country__san_marino: `San Marino`,
    dq_country__sao_tome_and_principe: `Sao Tome and Principe`,
    dq_country__saudi_arabia: `Saudi Arabia`,
    dq_country__senegal: `Senegal`,
    dq_country__serbia_and_montenegro: `Serbia and Montenegro`,
    dq_country__seychelles: `Seychelles`,
    dq_country__sierra_leone: `Sierra Leone`,
    dq_country__singapore: `Singapore`,
    dq_country__slovakia: `Slovakia`,
    dq_country__slovenia: `Slovenia`,
    dq_country__solomon_islands: `Solomon Islands`,
    dq_country__somalia: `Somalia`,
    dq_country__south_africa: `South Africa`,
    dq_country__south_georgia_and_the_south_sandwich_islands: `South Georgia and the South Sandwich Islands`,
    dq_country__spain: `Spain`,
    dq_country__sri_lanka: `Sri Lanka`,
    dq_country__sudan: `Sudan`,
    dq_country__suriname: `Suriname`,
    dq_country__svalbard_and_jan_mayen: `Svalbard and Jan Mayen`,
    dq_country__swaziland: `Swaziland`,
    dq_country__sweden: `Sweden`,
    dq_country__switzerland: `Switzerland`,
    dq_country__syrian_arab_republic: `Syrian Arab Republic`,
    dq_country__taiwan_province_of_china: `Taiwan, Province of China`,
    dq_country__tajikistan: `Tajikistan`,
    dq_country__tanzania_united_republic_of: `Tanzania, United Republic of`,
    dq_country__thailand: `Thailand`,
    dq_country__timor_leste: `Timor-Leste`,
    dq_country__togo: `Togo`,
    dq_country__tokelau: `Tokelau`,
    dq_country__tonga: `Tonga`,
    dq_country__trinidad_and_tobago: `Trinidad and Tobago`,
    dq_country__tunisia: `Tunisia`,
    dq_country__turkey: `Turkey`,
    dq_country__turkmenistan: `Turkmenistan`,
    dq_country__turks_and_caicos_islands: `Turks and Caicos Islands`,
    dq_country__tuvalu: `Tuvalu`,
    dq_country__uganda: `Uganda`,
    dq_country__ukraine: `Ukraine`,
    dq_country__united_arab_emirates: `United Arab Emirates`,
    dq_country__united_kingdom: `United Kingdom`,
    dq_country__united_states: `United States`,
    dq_country__united_states_minor_outlying_islands: `United States Minor Outlying Islands`,
    dq_country__uruguay: `Uruguay`,
    dq_country__uzbekistan: `Uzbekistan`,
    dq_country__vanuatu: `Vanuatu`,
    dq_country__venezuela: `Venezuela`,
    dq_country__viet_nam: `Viet Nam`,
    dq_country__virgin_islands_british: `Virgin Islands, British`,
    dq_country__virgin_islands_us: `Virgin Islands, U.S.`,
    dq_country__wallis_and_futuna: `Wallis and Futuna`,
    dq_country__western_sahara: `Western Sahara`,
    dq_country__yemen: `Yemen`,
    dq_country__zambia: `Zambia`,
    dq_country__zimbabwe: `Zimbabwe`,

    //partner program overview
    overview__dq_impact_rating: "Đánh giá tác động DQ",
    overview__impact_rating: "Đánh giá tác động",
    overview__out_of: "Ra khỏi",
    overview__program: "Chương trình",
    overview__area: "Khu vực",
    overview__organisation: "Tổ chức",
    overview__target_pop: "Dân số mục tiêu",
    overview__group: "Nhóm",
    overview__age: "Tuổi",
    overview__countries: "Quốc gia",
    overview__content_quality: "Chất lượng nội dung",
    overview__program_reach: "Phạm vi tiếp cận của chương trình",
    overview__comp_improve: "Cải thiện năng lực",
    overview__program_name: "Tên chương trình",
    overview__contact_persons: "Người liên hệ",
    overview__objectives: "Mục tiêu của chương trình",
    overview__program_delivery: "Phân phối chương trình",
    overview__venue: "Địa điểm",
    overview__target_audiences: "Đối tượng mục tiêu",
    overview__time_taken: "Thời gian thực hiện để hoàn thành chương trình",
    overview__cost_of_use: "Chi phí sử dụng",
    overview__year_of_implement: "Năm thực hiện và hiện trạng",
    overview__ref_frameworks: "Khung tham chiếu",
    overview__ref_frameworks_none: "Không được sử dụng",
    overview__mod_dq24: "Mô-đun và DQ24",
    overview__mod_dq24_desc: "Sinh viên phải hoàn thành bốn trong số tám học phần để lấy được Giấy phép Kỹ thuật số. Mỗi mô-đun mất khoảng 1,5 giờ để hoàn thành và mang lại cho sinh viên 3 kết quả được xác định trước.",
    overview__learn_mod_id: "ID mô-đun học tập",
    overview__learn_title: "Tiêu đề chủ đề học tập",
    overview__learn_objectives: "Danh sách mục tiêu học tập",
    overview__target_group_age: "Độ tuổi nhóm mục tiêu",
    overview__dq24_align: "Căn chỉnh DQ24",
    overview__about_report: "Giới thiệu về báo cáo",
    overview__dq_impact_report: "Báo cáo đánh giá tác động của DQ (Báo cáo)",
    overview__report_intro: "là báo cáo tóm tắt về một chương trình giáo dục/đào tạo dạy về năng lực kỹ thuật số để hiểu tác động của nó. Xếp hạng tác động DQ là điểm tổng hợp cho biết mức độ chất lượng nội dung của chương trình và tác động được tạo ra cho đến nay trong việc nâng cao kỹ năng kỹ thuật số của các quốc gia. Đây là thước đo tác động được tiêu chuẩn hóa dựa trên tiêu chuẩn toàn cầu sử dụng Tiêu chuẩn toàn cầu IEEE DQ về kiến thức kỹ thuật số, kỹ năng kỹ thuật số và mức độ sẵn sàng kỹ thuật số (IEEE 3527.1). Chương trình đã được các nhà nghiên cứu của Viện DQ xem xét và phân tích dựa trên các tài liệu do chủ sở hữu Chương trình gửi và/hoặc các tài liệu có sẵn công khai.",
    overview__report_design: "Báo cáo được thiết kế cho",
    overview__prog_owner: "Chủ sở hữu chương trình",
    overview__prog_owner_reason_1: "để hiểu mức độ chất lượng chương trình của họ và chiến lược thực hiện",
    overview__prog_owner_reason_2: "để xác định các lĩnh vực thế mạnh và để cải thiện hơn nữa chương trình",
    overview__stakeholder: "Các bên liên quan chính bao gồm các nhà hoạch định chính sách và nhà tài trợ/nhà đầu tư",
    overview__stakeholder_reason_1: "có được đánh giá hữu ích dựa trên dữ liệu về chất lượng và tác động của chương trình nhằm hỗ trợ họ đưa ra quyết định về việc nâng cao năng lực kỹ thuật số và an toàn trực tuyến cho nhóm đối tượng mục tiêu.",
    overview__report_composition: "Báo cáo gồm ba phần",
    overview__section_1: "Phần 1",
    overview__section_2: "Phần 2",
    overview__section_3: "Phần 3",
    overview__baselining_desc: "Tìm hiểu mức độ năng lực kỹ thuật số và rủi ro mạng của đối tượng mục tiêu của chương trình",
    overview__assessment_desc: "Tìm hiểu Đánh giá tác động DQ của chương trình",
    overview__enhancement_desc: "Hiểu các lĩnh vực tiềm năng để nâng cao chương trình",
    overview__key_terms: "Điều khoản quan trọng",
    overview__skill_acquire: "Kỹ năng học được",
    overview__skill_acquire_desc: "khi một học phần hoặc bài đánh giá đạt được một mức độ nhất định hoặc cao hơn.",
    overview__skill_improve: "Cải thiện kỹ năng",
    overview__skill_improve_desc: "được đánh giá là đã tiến bộ khi điểm số đã được cải thiện ở một mức độ nào đó nhờ so sánh các câu hỏi trước/sau của học phần.",
    overview__intention: "Ý định thay đổi",
    overview__intention_desc: "tiếp tục chương trình và ở cuối mỗi mô-đun sẽ hỏi người dùng xem mô-đun này có khiến họ suy nghĩ về việc thay đổi hành vi của mình hay không.",

    //school program overview
    overview__following_programs: "Sau đây là thông tin chi tiết về các chương trình mà (các) trường của bạn đang tham gia.",
    overview__about: "Về",
    overview__type: "Kiểu",
    overview__time_to_complete: "Thời gian hoàn thành",
    overview__dct_title: "Bài kiểm tra quyền công dân kỹ thuật số (DCT)",
    overview__dct_desc: "DCT là một bài kiểm tra nhanh chóng và dễ dàng để đo DQ của trẻ. Điểm số của học sinh sẽ được tổng hợp cho các trường để so sánh với điểm trung bình quốc gia và toàn cầu.",
    overview__dct_type: "Đánh giá",
    overview__dct_time: "30 phút",
    overview__dqw_title: "Thế giới DQ (DQW)",
    overview__dqw_desc: "DQ World là nền tảng học tập trực tuyến dành cho trẻ em học hỏi và nâng cao 8 kỹ năng công dân kỹ thuật số thông qua các bài học mang tính giáo dục, video hoạt hình và trò chơi nhỏ.",
    overview__dqw_type: "Giáo dục",
    overview__dqw_time: "8-10 giờ",
    overview__about_dq_score: "Về điểm DQ",
    overview__following_breakdown: "Sau đây là bảng phân tích ý nghĩa của Điểm DQ.",
    overview__knowledge: "Kiến thức",
    overview__skills: "Kỹ năng",
    overview__attitude: "Thái độ và giá trị",
    overview__excellent: "Xuất sắc",
    overview__satisfy: "Đạt yêu cầu",
    overview__less_satisfy: "Kém",
    overview__attention: "Yêu cầu chú ý",
    overview__excellent_criteria: "Trên hoặc bằng 115",
    overview__satisfy_criteria: "Trên hoặc bằng 100 nhưng dưới 115",
    overview__less_satisfy_criteria: "Trên hoặc bằng 85 nhưng dưới 100",
    overview__attention_criteria: "Dưới 85",
    overview__knowledge_excellent: "Hiểu biết sâu sắc về cách thức hoạt động của Internet và các phương tiện truyền thông kỹ thuật số cũng như tác động của chúng trong việc xây dựng danh tính trực tuyến lành mạnh.",
    overview__knowledge_satisfy: "Hiểu biết tương đối sâu sắc về cách thức hoạt động của Internet và phương tiện truyền thông kỹ thuật số cũng như tác động của chúng trong việc xây dựng danh tính trực tuyến lành mạnh.",
    overview__knowledge_less_satisfy: "Hiểu biết tương đối yếu về cách thức hoạt động của Internet và phương tiện truyền thông kỹ thuật số cũng như tác động của chúng đối với việc xây dựng danh tính trực tuyến lành mạnh.",
    overview__knowledge_attention: "Hiểu biết hạn chế về cách thức hoạt động của Internet và phương tiện truyền thông kỹ thuật số cũng như tác động của chúng trong việc xây dựng danh tính trực tuyến lành mạnh.",
    overview__skills_excellent: "Có khả năng cao trong việc xây dựng danh tính trực tuyến lành mạnh, phù hợp. Thể hiện sự sẵn sàng kỹ lưỡng trong việc sử dụng công nghệ để giải quyết vấn đề hoặc hoàn thành mục tiêu.",
    overview__skills_satisfy: "Có khả năng tốt hơn các đồng nghiệp trong việc xây dựng danh tính trực tuyến lành mạnh và phù hợp. Sẵn sàng hơn các đồng nghiệp trong việc sử dụng công nghệ để giải quyết vấn đề hoặc hoàn thành mục tiêu.",
    overview__skills_less_satisfy: "Ít có khả năng hơn các đồng nghiệp trong việc xây dựng danh tính trực tuyến lành mạnh và phù hợp. Ít sẵn sàng hơn các đồng nghiệp trong việc sử dụng công nghệ để giải quyết vấn đề hoặc hoàn thành mục tiêu.",
    overview__skills_attention: "Thiếu các khả năng quan trọng để xây dựng danh tính trực tuyến lành mạnh, phù hợp và/hoặc thể hiện các thói quen phản tác dụng trên mạng (tức là cư xử khác biệt để được mọi người yêu thích hoặc để trốn tránh trách nhiệm). Chưa sẵn sàng sử dụng công nghệ để giải quyết vấn đề hoặc hoàn thành mục tiêu.",
    overview__attitude_excellent: "Thể hiện tính chính trực vượt trội bằng cách nuôi dưỡng danh tính trực tuyến nhất quán và có trách nhiệm. Rất có khả năng thể hiện sự khoan dung và tôn trọng trong giao tiếp trực tuyến và nuôi dưỡng tư duy của một công dân kỹ thuật số toàn cầu.",
    overview__attitude_satisfy: "Có tính toàn vẹn tương đối cao hơn so với các đồng nghiệp liên quan đến danh tính trực tuyến. Có nhiều khả năng thể hiện sự khoan dung và tôn trọng hơn so với các đồng nghiệp trong giao tiếp trực tuyến.",
    overview__attitude_less_satisfy: "Có tính toàn vẹn tương đối kém hơn so với các đồng nghiệp liên quan đến danh tính trực tuyến. Ít có khả năng thể hiện sự khoan dung và tôn trọng trong giao tiếp trực tuyến hơn các đồng nghiệp.",
    overview__attitude_attention: "Có ít tính toàn vẹn liên quan đến danh tính trực tuyến. Không thể hiện sự khoan dung và tôn trọng người khác trong giao tiếp trực tuyến.",

    //partner baselining
    baselining_only_dig_desc: "Hiểu mức độ năng lực kỹ thuật số của đối tượng mục tiêu của chương trình",
    baselining__dig_comp_lvl: "Cấp độ năng lực kỹ thuật số",
    baselining__dig_comp_desc: "Mức độ năng lực kỹ thuật số của người tham gia Chương trình so với mức trung bình quốc gia và toàn cầu là bao nhiêu?",
    baselining__dig_comp_range: "Điểm năng lực kỹ thuật số nằm trong khoảng từ 65-135",
    baselining__covered_by: "được bao phủ bởi",
    baselining__prog_avg: "Trung bình chương trình",
    baselining__countries_avg: "Trung bình của các quốc gia được chọn",
    baselining__glob_avg: "Trung bình toàn cầu",
    baselining__nation_avg: "Trung bình Quốc gia",
    baselining__company_avg: "Trung bình Công ty",
    baselining__dq_comp_scores: "Điểm theo năng lực DQ",
    baselining__annual_score: "Xu hướng điểm hàng năm",
    baselining__cyber_risk_lvl: "Mức độ rủi ro mạng",
    baselining__cyber_risk_desc: "Mức độ phơi nhiễm với rủi ro mạng của nhóm đối tượng mục tiêu so với mức trung bình quốc gia và toàn cầu là bao nhiêu?",
    baselining__in_past_year: "trong năm qua (%)",
    baselining__annual_trend: "Xu hướng hàng năm",
    baselining__no_risks: "Không có rủi ro",
    baselining__max_added_error: "Đã đạt đến giới hạn thêm tối đa. Loại bỏ đầu tiên.",
    baselining__program: "Chương trình",
    baselining__company: "Công ty",
    baselining__national: "Quốc gia",
    baselining__global: "Toàn cầu",

    //school baselining
    baselining__dig_skills_lvl: "Cấp độ kỹ năng số",
    baselining__school_desc: "Tìm hiểu mức độ kỹ năng kỹ thuật số và rủi ro mạng của đối tượng mục tiêu của trường học",
    baselining__school_dig_comp_desc: "Mức độ năng lực kỹ thuật số của trường bạn so với mức trung bình quốc gia và toàn cầu là bao nhiêu?",
    baselining__dq_score: "Điểm DQ",
    baselining__school_avg: "Trung bình của trường",
    baselining__dq_score_break: "Phân tích điểm DQ",
    baselining__mod_overview: "Tổng quan về mô-đun",
    baselining__mods: "Mô-đun",
    baselining__avg_score: "Điểm trung bình",
    baselining__completion_rate: "Tỷ lệ hoàn thành",
    baselining__school_cyber_risk_desc: "Mức độ tiếp xúc với rủi ro mạng của học sinh so với mức trung bình quốc gia và toàn cầu là bao nhiêu?",
    baselining__cyber_risk_country_compare: "Rủi ro mạng quốc gia so với các quốc gia khác",
    baselining__percent_risk: "% rủi ro",
    baselining__school: "Trường học",

    //assessment content
    assessment__content_desc: "Chất lượng nội dung học tập của Chương trình như thế nào?",
    assessment__comprehensive: "Tính toàn diện",
    assessment__comprehensive_score: "Điểm tổng hợp",
    assessment__comprehensive_desc: "Nội dung học tập của chương trình có bao gồm các năng lực trí tuệ kỹ thuật số một cách toàn diện và phù hợp theo Tiêu chuẩn Toàn cầu IEEE DQ (IEEE 3527.1) không?",
    assessment__curriculum_cov: "Nội dung chương trình giảng dạy",
    assessment__less_cov: "Bao phủ ít hơn",
    assessment__more_cov: "Bao phủ nhiều hơn",
    assessment__cov_percent: "% Phủ sóng",
    assessment__dig_citizen: "Công dân số",
    assessment__dig_creative: "Sáng tạo kỹ thuật số",
    assessment__footer: "Tìm hiểu thêm kết quả chi tiết của phân tích, vui lòng kiểm tra",
    assessment__align_analysis: "Mục 3.1. Phân tích căn chỉnh",
    //assessment reach
    assessment__reach_desc: "Chương trình đã được nhóm đối tượng mục tiêu áp dụng rộng rãi, hiệu quả và toàn diện như thế nào",
    assessment__participation_desc: "Có bao nhiêu cá nhân đã hoàn thành ít nhất một học phần của Chương trình",
    assessment__participation: "Sự tham gia",
    assessment__total_participation: "Tổng số người tham gia",
    assessment__participants: "Những người tham gia",
    assessment__inclusive_ses_desc: "Chương trình có được sử dụng toàn diện cho các giới tính, độ tuổi và nhóm SES khác nhau không?",
    assessment__inclusive_desc: "Chương trình có được sử dụng cho mọi giới tính và độ tuổi khác nhau không?",
    assessment__by_adii: "Bởi ADII",
    assessment__by_ses: "Bởi SES",
    assessment__by_indigenous: "Bởi người bản địa",
    assessment__by_gender: "Theo giới tính",
    assessment__by_age: "Theo độ tuổi",
    assessment__by_school_lvl: "Theo cấp trường",
    assessment__enhancement_reach: "Mục 3.2. Phạm vi tiếp cận nâng cao",
    //assessment reach school
    assessment__school_participation_desc: "Có bao nhiêu học sinh đã tham gia chương trình?",
    assessment__inclusive_school_lvl_desc: "Chương trình có được sử dụng cho mọi giới tính, độ tuổi và cấp học khác nhau không?",

    //assessment efficacy
    assessment__efficacy_desc: "Chương trình giúp cải thiện năng lực kỹ thuật số của người tham gia như thế nào về kiến thức, kỹ năng và thái độ",
    assessment__acq: "Tiếp thu năng lực",
    assessment__acq_desc: "Tỷ lệ người tham gia đã đạt được ít nhất một năng lực kỹ thuật số thông qua Chương trình là bao nhiêu?",
    assessment__acq_complete: "Năng lực có được",
    assessment__acq_rate: "Tỷ lệ tiếp thu năng lực",
    assessment__imp: "Cải thiện năng lực",
    assessment__imp_desc: "Tỷ lệ người tham gia cho thấy sự cải thiện đáng kể về ít nhất một năng lực kỹ thuật số thông qua Chương trình là bao nhiêu?",
    assessment__imp_complete: "Năng lực được cải thiện",
    assessment__imp_rate: "Tỷ lệ cải thiện năng lực",
    assessment__int: "Ý định thay đổi",
    assessment__int_desc: "Tỷ lệ người tham gia thể hiện ý định thay đổi hành vi ở ít nhất một năng lực kỹ thuật số một cách tích cực sau khi hoàn thành Chương trình là bao nhiêu?",
    assessment__int_complete: "Ý định thay đổi",
    assessment__int_rate: "Tỷ lệ ý định thay đổi",
    assessment__no_region: "Không có khu vực",
    assessment__no_province: "Không có tỉnh",
    assessment__no_category: "không có thể loại",
    //assessment efficacy school
    assessment__mod_score: "Điểm học phần",
    assessment__mod_score_desc: "Điểm trung bình của người tham gia theo học phần là bao nhiêu?",
    assessment__mod_score_complete: "Điểm học phần",

    //enhancement content
    enhancement__content_desc: "Chương trình giúp cải thiện năng lực kỹ thuật số của người tham gia về kiến thức, kỹ năng và thái độ tốt đến mức nào?",
    enhancement__degree_align: "Mức độ thống nhất giữa các thông điệp học tập của chương trình với DQ (%)",
    enhancement__total_align: "Tổng số liên kết",
    enhancement__associate_mod: "Các học phần liên kết",
    //enhancement reach
    enhancement__reach_desc: "Kiểm tra các phân tích chi tiết sau đây về phạm vi tiếp cận của Chương trình và tìm ra các lĩnh vực cần cải thiện. Các lĩnh vực tiềm năng cần được chú ý để nâng cao phạm vi tiếp cận được đánh dấu bằng màu hồng.",
    enhancement__by_country: "Theo quốc gia",
    enhancement__by_month: "Theo tháng",
    enhancement__by_period: "Theo thời gian",
    enhancement__by_module: "Theo học phần",
    enhancement__by_school: "Theo trường học",
    enhancement__participants_count: "Số người tham gia",
    enhancement__participants_percent: "% người tham gia",
    enhancement__title: "Tiêu đề",
    enhancement__region: "Vùng đất",
    enhancement__province: "Tỉnh",
    enhancement__postal: "Mã bưu điện",
    enhancement__inst: "Mã Viện",
    enhancement__month: "Tháng",
    enhancement__module: "Học phần",
    enhancement__gender: "Giới tính",
    enhancement__age_group: "Nhóm tuổi",
    enhancement__adii: "ADII",
    enhancement__ses: "SES",
    enhancement__school: "Trường học",
    enhancement__class: "Lớp học",
    enhancement__region_need: "Chọn vùng để hiển thị dữ liệu",
    enhancement__province_need: "Chọn tỉnh để hiển thị dữ liệu",
    enhancement__postal_need: "Chọn mã bưu chính để hiển thị dữ liệu",
    enhancement__inst_need: "Chọn mã viện để hiển thị dữ liệu",
    enhancement__mod_need: "Chọn một học phần để hiển thị dữ liệu",
    enhancement__school_need: "Chọn trường để hiển thị dữ liệu",
    enhancement__class_need: "Chọn lớp để hiển thị dữ liệu",
    enhancement__indigenous: "Tình trạng bản địa",
    enhancement__school_level: "Cấp trường",
    enhancement__by_region_footer: "nếu số lượng người tham gia trong khu vực ít hơn 10% tổng số người tham gia",
    enhancement__by_province_footer: "nếu số lượng người tham gia trên địa bàn tỉnh ít hơn 10% tổng số người tham gia",
    enhancement__by_month_footer: "nếu số lượng người tham gia trong tháng cụ thể ít hơn 100",
    enhancement__by_module_footer: "nếu số lượng người tham gia mô-đun cụ thể ít hơn 10% tổng số người tham gia",
    enhancement__by_gender_footer: "nếu số lượng người tham gia là nữ hoặc nam ít hơn 30% tổng số người tham gia",
    enhancement__by_subgroup_footer: "nếu số lượng người tham gia trong nhóm phụ của mô-đun cụ thể ít hơn 50",
    //enhancement efficacy
    enhancement__efficacy_desc: "Kiểm tra các phân tích chi tiết sau đây về hiệu quả của Chương trình.",
    enhancement__acq_desc: "Tỷ lệ phần trăm mỗi nhóm nhân khẩu học đã đạt được một hoặc nhiều năng lực DQ thông qua Chương trình.",
    enhancement__imp_desc: "Tỷ lệ phần trăm mỗi nhóm nhân khẩu học đã thể hiện sự cải thiện đáng kể về một hoặc nhiều năng lực DQ thông qua Chương trình.",
    enhancement__int_desc: "Tỷ lệ phần trăm mỗi nhóm nhân khẩu học thể hiện ý định thay đổi hành vi theo hướng tích cực về một hoặc nhiều năng lực DQ sau khi hoàn thành Chương trình.",
    enhancement__composite_total: "Tổng cộng",
    enhancement__totals: "Tổng số",
    //enhancement efficacy school
    enhancement__mod_score_desc: "Điểm học phần trung bình của người tham gia theo nhân khẩu học là bao nhiêu?",

    //in-depth analysis
    analysis__in_depth: "Phân tích sâu",
    analysis__in_depth_desc: "Sau đây là những phân tích chuyên sâu về học viên của bạn từ chương trình DQ World",
    analysis__in_depth_desc_2: "Bấm vào liên kết sau để xem kết quả của trường",
    analysis__view: "Xem phân tích",

    //progress
    progress__school_name: "Tên trường",
    progress__school_lvl: "Cấp trường",
    progress__student_count: "Số học sinh",
    progress__school_view_detail: "Xem chi tiết trường học",
    progress__school_info: "Thông tin trường học",
    progress__school_head_name: "Tên hiệu trưởng trường",
    progress__school_head_email: "Email hiệu trưởng trường",
    progress__class_name: "Tên lớp",
    progress__class_lvl: "Cấp lớp",
    progress__class_download_data: "Tải xuống dữ liệu lớp học",
    progress__student_id: "Định danh học viên",
    progress__student_view_detail: "Xem chi tiết học viên",
    progress__student_info: "Thông tin học viên",
    progress__parent_email: "Thư điện tử của bố mẹ",
    progress__dob: "Ngày sinh",
    progress__reset_pass: "Đặt lại mật khẩu",
    progress__link_gen: "Đã tạo liên kết",
    progress__clipboard_to_copy: "Bấm vào clipboard để sao chép liên kết!",

    //pending activation
    activation__activation_link: "Liên kết kích hoạt",
    activation__revoke: "Thu hồi",
    activation__resend_email: "Gửi lại email",
    activation__search_by_email: "Tìm kiếm bằng email",
    activation__unable_revoke: "Không thể thu hồi: Không có tên người dùng nào được liên kết với người dùng này",
    activation__no_username: "Không có tên người dùng được liên kết với người dùng này",

    //licenses
    licenses__total_licenses: "Tổng số giấy phép",
    licenses__used_licenses: "Giấy phép đã sử dụng",
    licenses__unused_licenses: "Giấy phép chưa sử dụng",
    licenses__add_users: "Thêm người dùng",
    licenses__download_csv: "Tải tập tin",
    licenses__upload_csv: "Tải lên tập tin",
    licenses__download_the_csv_file: "1. Tải xuống tệp excel (.xlsx).",
    licenses__fill_out_the_csv_file_completely: "2. Điền đầy đủ file excel. Đừng để trống bất kì chỗ nào. Bạn sẽ cần họ và tên nhân viên, bộ phận, chức danh, ngày sinh, địa chỉ email, giới tính và mã bưu chính.",
    licenses__fill_out_the_csv_file_completely__school: "2. Điền đầy đủ file excel. Đừng để trống bất kì chỗ nào. Bạn sẽ cần họ và tên, tên lớp, cấp lớp, ngày sinh, địa chỉ email của phụ huynh, giới tính và mã bưu chính của học sinh.",
    licenses__upload_the_csv_file: "3. Tải tệp excel (.xlsx) lên.",
    licenses__the_system_will_then_send_out_activation_emails: "4. Sau đó, hệ thống sẽ gửi email kích hoạt đến những người dùng bạn đã liệt kê trong tệp excel. Khi người dùng truy cập email, họ sẽ đăng nhập bằng liên kết kích hoạt và đặt mật khẩu để tiếp tục.",
    licenses__the_system_will_then_send_out_activation_emails__school: "4. Sau đó, hệ thống sẽ gửi email kích hoạt đến phụ huynh của học sinh mà bạn đã liệt kê trong tệp excel. Sau khi phụ huynh nhận được email, họ sẽ cần đăng nhập bằng liên kết kích hoạt và đặt lại mật khẩu mới để con họ tiếp tục. Hãy nhớ yêu cầu phụ huynh chuyển tên người dùng và mật khẩu cho trẻ để đăng nhập.",
    licenses__as_long_as_you_have_unused_licenses_p1: '5. Miễn là bạn có giấy phép chưa sử dụng, bạn chỉ cần thêm tài khoản mới vào tệp excel cũ và tải nó lên. Bạn cũng có thể điều chỉnh bất kỳ thông tin người dùng nào trong tệp excel ngoại trừ địa chỉ email. Để thay đổi địa chỉ email, vui lòng liên hệ với chúng tôi tại',
    licenses__as_long_as_you_have_unused_licenses_p2: '.',
    licenses__in_case_you_no_longer_possess: "6. Trong trường hợp bạn không còn file excel cũ, hãy tải xuống dữ liệu hiện có từ trang web của chúng tôi.",
    licenses__errors: 'Lỗi',
    licenses__invalid_records: "Đã tìm thấy hồ sơ không hợp lệ! Cuộn xuống trang để xem chi tiết.",
    licenses__upload_success: "Tải tài liệu thành công",
    licenses__upload_invalid_type: "Loại tệp không hợp lệ! Vui lòng chỉ tải lên tệp .xlsx/.csv",
    licenses__allowed_domain_hint: "Chỉ các email Open-ID được phép là từ các tên miền",

    //resources
    resources__title: "Tài nguyên",
    resources__dq_world: "Tài nguyên DQ World",
    resources__intro_to_dqw: "Giới thiệu về DQ World",
    resources__intro_to_dqw_full: "Giới thiệu về DQ World",
    resources__parent_consent_letter: "Thư đồng ý của phụ huynh",
    resources__skill_packs: "Gói kỹ năng",
    resources__teacher_mats: "Tài liệu dành cho giáo viên",
    resources__goto_dqw_student: "Tới trang web sinh viên DQ World",
    resources__watch_short_vid: "Xem đoạn video ngắn bên phải để tìm hiểu nội dung của DQ World. Bạn cũng có thể chia sẻ video này với học sinh của mình để giới thiệu ngắn gọn về hoạt động của chương trình của chúng tôi.",
    resources__vid_time: "Thời gian Video",
    resources__dqw_curriculum: "Giáo trình DQ World - 8 Kỹ năng sống số",
    resources__dci: "Định danh công dân kỹ thuật số",
    resources__dci_desc: "Khả năng xây dựng và quản lý danh tính phù hợp lành mạnh trực tuyến và ngoại tuyến một cách chính trực.",
    resources__stm: "Quản lý thời gian sử dụng thiết bị",
    resources__stm_desc: "Khả năng quản lý thời gian sử dụng thiết bị, đa nhiệm và sự tham gia của một người vào các trò chơi trực tuyến và phương tiện truyền thông xã hội với khả năng tự kiểm soát.",
    resources__cbm: "Quản lý bắt nạt trên mạng",
    resources__cbm_desc: "Khả năng phát hiện các tình huống bắt nạt trên mạng và xử lý chúng một cách khôn ngoan.",
    resources__csm: "Quản lý an ninh mạng",
    resources__csm_desc: "Khả năng bảo vệ dữ liệu của một người bằng cách tạo mật khẩu mạnh và quản lý các cuộc tấn công mạng khác nhau.",
    resources__pm: "Quản lý quyền riêng tư",
    resources__pm_desc: "Khả năng xử lý một cách thận trọng tất cả thông tin cá nhân được chia sẻ trực tuyến để bảo vệ quyền riêng tư của một người và của người khác.",
    resources__ct: "Tư duy phản biện",
    resources__ct_desc: "Khả năng phân biệt giữa thông tin đúng và sai, nội dung tốt và có hại cũng như các liên hệ đáng tin cậy và nghi vấn trực tuyến.",
    resources__df: "Dấu chân kỹ thuật số",
    resources__df_desc: "Khả năng hiểu bản chất của dấu chân kỹ thuật số và hậu quả thực tế của chúng cũng như quản lý chúng một cách có trách nhiệm.",
    resources__de: "Đồng cảm kỹ thuật số",
    resources__de_desc: "Khả năng đồng cảm với nhu cầu và cảm xúc của chính mình và của người khác trên mạng.",
    resources__parental_consent: "Sự đồng ý của cha mẹ",
    resources__parental_consent_desc_p1: "Thư đồng ý của phụ huynh nhằm đảm bảo phụ huynh biết con mình sẽ học gì trên DQ World. Là một phần của cuộc nghiên cứu đang diễn ra, điều quan trọng là phụ huynh phải hiểu rõ chương trình đòi hỏi những gì.",
    resources__parental_consent_desc_p2: "Chúng tôi đã đính kèm mẫu Thư đồng ý của phụ huynh để giúp giải thích. Giáo viên nên in những thông tin này ra và yêu cầu phụ huynh học sinh ký và gửi lại nếu họ chọn không tham gia. Giáo viên cũng có thể sửa đổi khi cần thiết cho trường học của mình. Bấm vào nút sau để tải về.",
    resources__get_started_guide: "Hướng dẫn bắt đầu",
    resources__skill_packs_desc: "Gói kỹ năng DQ giúp bạn dạy DQ cho học sinh của mình. Nhấp vào từng Gói kỹ năng để xem tổng quan, mục tiêu chính và thời gian học ước tính cho từng Vùng. Mặc dù chúng tôi khuyên giáo viên nên tiến bộ qua các Vùng để bắt đầu với Vùng 1, nhưng giáo viên có thể chọn tập trung vào bất kỳ Vùng nào tùy theo nhu cầu giảng dạy của họ.",
    resources__objective: "Mục tiêu",
    resources__learn_points: "Điểm học tập chính",
    resources__time_to_complete: "Thời gian hoàn thành",

    resources__guide_title: "Bắt đầu với DQ World",
    resources__guide_obj: "Hướng dẫn này giúp các nhà giáo dục chính thiết lập và quản lý tài khoản trường học DQWorld.net của họ. Sau khi đọc xong, bạn sẽ biết cách quản lý lớp học, phân lớp cho giáo viên, đặt lại mật khẩu và hơn thế nữa.",
    resources__guide_point_1: "1. Đăng ký",
    resources__guide_point_2: "2. Bảng quản trị của giáo viên",
    resources__guide_point_3: "3. Thư thông tin của phụ huynh và mẫu chấp thuận",
    resources__guide_point_4: "4. Thêm lớp học",
    resources__guide_point_5: "5. Phân phối tài khoản học viên",
    resources__guide_point_6: "6. Xem tiến độ của học viên",
    resources__guide_point_7: "7. Thêm học viên",
    resources__guide_point_8: "8. Đặt lại mật khẩu học viên",
    resources__guide_point_9: "9. Khắc phục sự cố và Câu hỏi thường gặp (FAQ)",

    resources__stm_obj: "là một kỹ năng kỹ thuật số bắt buộc phải có giúp trẻ em có thể sử dụng công nghệ kỹ thuật số một cách có nhận thức và tự chủ. Học viên sẽ tìm hiểu về các chủ đề như kiểm soát đa nhiệm, cân bằng thời gian sử dụng thiết bị, ngăn ngừa chứng nghiện game và hơn thế nữa.",
    resources__stm_point_1: "1. Cân bằng giữa thực tế vật lý và thực tế ảo.",
    resources__stm_point_2: "2. Sử dụng công nghệ kỹ thuật số với khả năng tự chủ và hiểu các tác dụng phụ khác nhau của việc sử dụng quá nhiều thời gian trước màn hình, làm nhiều việc cùng lúc và gây nghiện phương tiện kỹ thuật số.",
    resources__stm_point_3: "3. Quản lý thời gian và đặt ra giới hạn cho việc sử dụng kỹ thuật số cá nhân; không cho phép việc sử dụng kỹ thuật số chiếm lấy cuộc sống của họ.",

    resources__pm_obj: "là một kỹ năng kỹ thuật số bắt buộc phải có giúp trao quyền cho trẻ em bảo vệ thông tin cá nhân của chúng và những người liên hệ của chúng. Học sinh sẽ tìm hiểu về các chủ đề như thông tin cá nhân, giữ quyền riêng tư trên mạng xã hội, bảo vệ quyền riêng tư của người khác và hơn thế nữa.",
    resources__pm_point_1: "1. Xử lý thông tin cá nhân được chia sẻ trực tuyến một cách thận trọng.",
    resources__pm_point_2: "2. Đảm bảo và bảo vệ quyền riêng tư của bản thân và những người liên hệ.",
    resources__pm_point_3: "3. Hiểu tầm quan trọng của quyền riêng tư và đó là quyền cơ bản của con người.",

    resources__cbm_obj: "là một kỹ năng kỹ thuật số bắt buộc phải có, giúp trẻ phát hiện các tình huống bắt nạt trên mạng và xử lý chúng một cách khôn ngoan. Học sinh sẽ tìm hiểu về các chủ đề như phát hiện bắt nạt trên mạng, các kiểu bắt nạt trên mạng, giải quyết tình huống bắt nạt trên mạng, v.v.",
    resources__cbm_point_1: "1. Xử lý phương tiện kỹ thuật số một cách an toàn và có trách nhiệm.",
    resources__cbm_point_2: "2. Phát hiện tình huống bắt nạt trên mạng và bình tĩnh xử lý.",
    resources__cbm_point_3: "3. Biết cách tìm kiếm sự trợ giúp một cách an toàn trước khi vấn đề bắt nạt trên mạng vượt quá tầm kiểm soát.",

    resources__dci_obj: "là một kỹ năng kỹ thuật số bắt buộc phải có để giúp trẻ xây dựng tính cách trực tuyến một cách chính trực. Học viên sẽ tìm hiểu về các chủ đề như cách hoạt động của Internet, các nhà lãnh đạo kỹ thuật số, nhận dạng trực tuyến và ngoại tuyến phù hợp, v.v.",
    resources__dci_point_1: "1. Hiểu bản chất của thế giới số và sử dụng thành thạo công nghệ, phương tiện số.",
    resources__dci_point_2: "2. Có kiến thức và kỹ năng để xây dựng và quản lý danh tính trực tuyến và ngoại tuyến lành mạnh và phù hợp.",
    resources__dci_point_3: "3. Luôn nhận thức được tầm quan trọng của quyền công dân toàn cầu trong không gian kỹ thuật số.",

    resources__dfm_title: "Quản lý dấu chân kỹ thuật số",
    resources__dfm_obj: "là một kỹ năng kỹ thuật số bắt buộc phải có giúp trẻ em nhận thức và quản lý các dấu vết thông tin mà chúng để lại trên mạng. Học viên sẽ tìm hiểu về các chủ đề như dấu chân kỹ thuật số là gì, sự tồn tại của dấu chân kỹ thuật số, tác động đến danh tiếng kỹ thuật số và hơn thế nữa.",
    resources__dfm_point_1: "1. Hiểu bản chất của giao tiếp trực tuyến.",
    resources__dfm_point_2: "2. Biết rằng mọi thứ được nói và làm trực tuyến đều để lại dấu vết gọi là dấu chân kỹ thuật số.",
    resources__dfm_point_3: "3. Luôn nhận thức được bản chất dai dẳng của dấu chân kỹ thuật số và hậu quả thực tế của chúng, bao gồm cả việc tạo ra danh tiếng trực tuyến ngoài ý muốn.",
    resources__dfm_point_4: "4. Sử dụng các kỹ năng để quản lý dấu chân kỹ thuật số một cách có trách nhiệm.",

    resources__csm_obj: "là một kỹ năng kỹ thuật số bắt buộc phải có giúp trẻ có thể tạo và duy trì mật khẩu mạnh cũng như tránh các cuộc tấn công mạng. Học sinh sẽ tìm hiểu về các chủ đề như tạo mật khẩu mạnh, quản lý SPAM và lừa đảo, quan sát bảo mật thiết bị di động và hơn thế nữa.",
    resources__csm_point_1: "1. Phát hiện và bảo vệ bản thân cũng như những người khác khỏi các cuộc tấn công mạng khác nhau như SPAM, SCAM và lừa đảo.",
    resources__csm_point_2: "2. Tạo và ghi nhớ mật khẩu mạnh và giữ bí mật chúng.",
    resources__csm_point_3: "3. Nhận biết các biện pháp an toàn khi sử dụng thiết bị di động, chẳng hạn như cập nhật phần mềm và tắt dịch vụ định vị khi không sử dụng.",

    resources__ct_obj: "là một kỹ năng kỹ thuật số bắt buộc phải có, giúp trẻ em có thể phân biệt giữa nội dung tốt và nội dung có hại cũng như những liên hệ đáng tin cậy và đáng ngờ trên mạng. Học viên sẽ tìm hiểu về các chủ đề như thông tin đúng và sai, bạn bè trực tuyến, cách phê bình nội dung và hơn thế nữa.",
    resources__ct_point_1: "1. Đánh giá thông tin, nội dung và liên hệ trực tuyến một cách sáng suốt.",
    resources__ct_point_2: "2. Hiểu được tác hại của thông tin sai sự thật, bạo lực, nội dung không phù hợp và những rủi ro liên quan đến người lạ trên mạng.",
    resources__ct_point_3: "3. Sử dụng tư duy phê phán để phân biệt thông tin đúng và sai, nội dung tốt và có hại cũng như những liên hệ đáng tin cậy và đáng nghi ngờ trên mạng.",

    resources__de_obj: "là một kỹ năng kỹ thuật số bắt buộc phải có, giúp trẻ em có thể đồng cảm với nhu cầu và cảm xúc của chính mình và của người khác trên mạng. Học viên sẽ tìm hiểu về các chủ đề như người ngoài cuộc và người trong cuộc, lắng nghe với sự đồng cảm, đồng cảm với nạn nhân mạng và hơn thế nữa.",
    resources__de_point_1: "1. Hãy nhạy cảm với nhu cầu và cảm xúc của bản thân và người khác khi trực tuyến, ngay cả khi không có sự tương tác trực tiếp.",
    resources__de_point_2: "2. Sẵn sàng lên tiếng cho những người cần giúp đỡ và lên tiếng thay họ.",
    resources__de_point_3: "3. Tránh phán xét trực tuyến.",
    resources__de_point_4: "4. Xây dựng mối quan hệ tốt với phụ huynh, giáo viên và bạn bè, cả trực tuyến và ngoại tuyến.",

    resources__upon_completion: "Sau khi hoàn thành, học viên phải có khả năng:",
    resources__time_30: "30 phút",
    resources__time_40_60: "40-60 phút tham gia trực tuyến tập trung",
    resources__time_50_75: "50-75 phút tham gia trực tuyến tập trung",
    resources__time_60_90: "60-90 phút tham gia trực tuyến tập trung",
    resources__time_90_120: "90-120 phút tham gia trực tuyến tập trung",
    resources__lesson_pdf: "Tải xuống bài học PDF",
    resources__ppt_slides: "Tải xuống các slide PPT",

    resources__dqw_intro_vid: "Video giới thiệu DQ World",
    resources__student_workbook: "Sách bài tập đầy đủ của học viên",
    resources__quiz_ans_key: "Đáp án bài kiểm tra",
    resources__workbook_ans_key: "Đáp án bài tập",

    contact__req_submit: "Yêu cầu đã gửi",
    contact__success: " Đã gửi thành công, sẽ sớm có người liên hệ lại với bạn về thắc mắc của bạn.\\nCảm ơn bạn",

    //old translations
    home__analytics_dashboard: "Analytics Dashboard",
    main__overview: "Overview",
    contact_us: "Contact Us",
    home__company_percentile: "Company Percentile",
    home__overall_dq_score: "Overall DQ Score",
    home__cyber_risk_vulnerability: "Cyber-Risk Vulnerability",
    home__cyber_risk: "Cyber-Risks",
    home__dq_microbadges: "DQ MicroBadges",
    home__dq_modules: "DQ Modules",
    home__dq_assessments: "DQ Assessments",
    home__average_score: "Average Score",
    home__company_national: "(Company/National/Global)",
    home__school_national: "(School/National/Global)",
    home__completion_rate: "Completion Rate",
    main__dark_mode: "Dark Mode",
    main__sign_out: "Sign Out",
    main__change_password: "Change Password",
    home__details: "Details",
    main__footer_support: "Support",
    main__footer_help_center: "Help Center",
    main__employees: "Employees",
    main__list: "List",
    main__pending_approvals: "Pending Approvals",
    main__licenses: "Licenses",
    main__orders: "Orders",
    main__employee_list: "Employee List",
    main__name: "Name",
    employees__list: "List",
    employees__approve_all: "Approve All",
    employees__approve: "Approve",
    employees__reject: "Reject",
    orders__orders: "Orders",
    orders__order_id: "Order ID",
    orders__licenses_count: "Licenses Count",
    orders__status: "Status",
    orders__withdraw: "Withdraw",
    orders__not_applicable: "Not Applicable",
    orders__approved: "Approved",
    orders__create_order: "Create Order",
    orders__create: "Create",
    orders__create_new_order: "Create New Order",
    orders__order_details: "Order Details",
    contact_us__message: "Message",
    const__user_access_level_na: "N/A",
    const__user_access_level_no_access: "No Access",
    const__user_access_level_partial_access_admin: "Partial Access (Admin)",
    const__user_access_level_full_access_admin: "Full Access (Admin)",
    const__user_access_level_partial_access_analytics: "Partial Access (Analytics)",
    const__user_access_level_full_access_analytics: "Full Access (Analytics)",
    const__org_type_partner: "PARTNER",
    const__org_type_company: "COMPANY",
    const__org_type_school: "SCHOOL",
    const__licenses_order_status_not_set: "Not Set",
    const__licenses_order_status_pending: "Pending",
    const__licenses_order_status_approved: "Approved",
    const__licenses_order_status_rejected: "Rejected",
    const__licenses_order_status_withdrawn: "Withdrawn",
    home__company_avg: "Company Avg.",
    home__national_avg: "National Avg.",
    home__global_avg: "Global Avg.",
    home__singtel_avg: "Singtel Avg.",
    main__footer_privacy: "Privacy",
    main__footer_terms_of_service: "Terms of Service",
    main__footer_all_rights_reserved: "DQ LAB Pte Ltd. All rights reserved.",
    home__top: "Top",
    home__risk: "Risk",
    main__dq_stats: "DQ Stats",
    main__profiles: "Profiles",
    main__stats: "Stats",
    main__students: "Students",
    main__pending_activation: "Pending Activation",
    employees__identifier: "Identifier",
    employees__name: "Name",
    employees__gender: "Gender",
    employees__dq_score: "DQ Score",
    employees__grade: "Grade",
    employees__actions: "Actions",
    employees__email: "Email",
    employees__first_name: "First Name",
    employees__last_name: "Last Name",
    employees__country: "Country",
    employees__postal_code: "Postal Code",
    employees__employee: "User",
    employees__student: "Student",
    employees__rows_per_page: "Rows per page:",
    profiles__profile_list: "Profiles List",
    profiles__identifier: "Identifier",
    profiles__first_name: "First Name",
    profiles__last_name: "Last Name",
    profiles__country: "Country",
    profiles__year_of_birth: "Year of Birth",
    profiles__gender: "Gender",
    profiles__email: "Email",
    profiles__org: "Organization",
    profiles__rows_per_page: "Rows per page:",
    stats_dq_stats: "DQ Stats",
    stats_total_users: "Total Users",
    stats_users_without_demographics: "Users without Demographics",
    stats_male_users: "Male Users",
    stats_female_users: "Female Users",
    stats_users_by_country: "Users By Country",
    stats_country_name: "Country Name",
    stats_users: "Users",

}
export const LC_VALUES_DASH = {
    'en': lc_en,
    'ko': lc_ko,
    'th': lc_th,
    'ja': lc_ja,
    'vi': lc_vi
}

validateKeysSyncing(LC_VALUES_DASH, 'dashboard');

