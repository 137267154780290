import React, {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet";

import {
    Box,
    Grid,
    Card as MuiCard,
    Divider as MuiDivider,
    Typography as MuiTypography, CardContent,
    Link
} from "@material-ui/core";

import { Tab } from "../../../components"
import TabChild from "../../../components/Tabs/header";

import {useAuth} from "../../../context/auth";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";
import {showCyberRisk} from "../../../utils/utils";


import DigitalSkillsTab from "./components/DigitalSkills.tab";
import CyberRisksTab from "./components/CyberRisks.tab";
import {spacing} from "@material-ui/system";
import ProgramImpactRatingTab from "./components/ProgramImpactRating.tab";

const Typography = styled(MuiTypography)(spacing);

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
`

const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.palette.tertiary.main};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
`

const TabsComponents = (pageLc, org_type) => [
    {
        title: "Program DQ Impact Ratings",
        link: `/${pageLc}/${org_type}/home/impactrating`,
        tag: <ProgramImpactRatingTab/>,
    },
    {
        title: GET_APP_LOCALIZE_TEXT(pageLc, "baselining__dig_comp_lvl"),
        link: `/${pageLc}/${org_type}/home/competencies`,
        tag: <DigitalSkillsTab/>,
    },
    {
        title: GET_APP_LOCALIZE_TEXT(pageLc, "baselining__cyber_risk_lvl"),
        link: `/${pageLc}/${org_type}/home/cyber`,
        tag: <CyberRisksTab/>,
    },
]


function Baselining() {

    const { authUser } = useAuth();

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];
    const org_type = location.pathname.split('/')[2];
    const tab_name = location.pathname.split('/')[4];
    const org_code = authUser.org_code;

    const [loading, setLoading] = useState(false);
    const [modulesInfo, setModulesInfo] = useState([]);

    useEffect( () => {
    }, []);

    return (
        <React.Fragment>
            <Helmet title={GET_APP_LOCALIZE_TEXT(pageLc, "navigation__baselining")} />
            <Box mx={"3vw"}>
                <Box mb={5}>
                    <DefaultColouredTypography variant="h2" fontWeight="fontWeightBold">
                        {GET_APP_LOCALIZE_TEXT(pageLc, "overview__section_1")}: {GET_APP_LOCALIZE_TEXT(pageLc, "navigation__baselining")}
                    </DefaultColouredTypography>
                    <ColouredTypography variant={"body1"} size={"16px"}>
                        {
                            showCyberRisk(org_code) ?
                                GET_APP_LOCALIZE_TEXT(pageLc, "overview__baselining_desc")
                            :
                                GET_APP_LOCALIZE_TEXT(pageLc, "baselining_only_dig_desc")
                        }
                    </ColouredTypography>
                </Box>
                <Tab
                    titles={TabsComponents(pageLc, org_type).filter((component) => !(component.link.includes("cyber") && !showCyberRisk(org_code))).map((component) => component.title)}
                    links={TabsComponents(pageLc, org_type).filter((component) => !(component.link.includes("cyber") && !showCyberRisk(org_code))).map((component) => component.link)}
                    value={TabsComponents(pageLc, org_type).filter((component) => !(component.link.includes("cyber") && !showCyberRisk(org_code))).findIndex((x) => x.link.split('/')[4] === tab_name)}
                >
                    {TabsComponents(pageLc, org_type).filter((component) => !(component.link.includes("cyber") && !showCyberRisk(org_code))).map((component) => component.tag)}
                </Tab>
            </Box>
            <LoaderWithBackDrop loading={loading} />
        </React.Fragment>
    );
}

export default Baselining;