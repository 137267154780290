import React, {useEffect, useMemo, useState} from "react";
import {useLocation} from "react-router-dom";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet";

import {
    Grid,
    Card as MuiCard,
    Divider as MuiDivider,
    Typography as MuiTypography,
    CardContent as MuiCardContent,
    Box,
    Paper as MuiPaper,
    Container, Button as MuiButton,

} from "@material-ui/core";


import {useAuth} from "../../../../../context/auth";
import LoaderWithBackDrop from "../../../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {GET_APP_LOCALIZE_TEXT} from "../../../../../utils/dq_lc_service/lc_service";

import {SidePanel} from "../../../../../components/SidePanel/SidePanel.comp";
import {spacing} from "@material-ui/system";
import MonthRangePicker from "../../../../../components/DatePickers/MonthRangePicker.comp";
import {Badge} from "../../components/Badge.comp";
import BarChart from "../../components/BarChart";
import XLSX from "xlsx";
import {COUNTRY_CONFIGS} from "../../../../../utils/constants";
import {GenericSidePanel} from "../../../../../components/SidePanel/GenericSidePanel.comp";
import {
    alphaNumericSort,
    createLcKey,
    getMapFromObjectListByKey,
    predefinedSort,
    toastDanger
} from "../../../../../utils/utils";
import {getAssessmentAdoptionv2} from "../../../../../services/assessment.service";
import {CustomSelect} from "../../../Baselining/components/Select.comp";

const Card = styled(MuiCard)(spacing);

const Typography = styled(MuiTypography)(spacing);

const Button = styled(MuiButton)(spacing);

const Paper = styled(MuiPaper)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;
`;

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-weight: ${(props) => props.weight ? props.weight : "default"};
`

const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.palette.tertiary.main};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
  font-weight: ${(props) => props.weight ? props.weight : "default"};
`

const Spacer = styled.div(spacing);
const ColouredCard = styled(Card)`
  background-color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  padding: 10px 10px 10px 10px;
`

const CheckboxItem = (props) => {
    return (
        <>

        </>
    );
}

const Bold = (props) => <Box component={'span'} fontWeight="fontWeightBold" {...props}></Box>
const Italicize = (props) => <Box component={'span'} fontStyle="italic" {...props}></Box>
const Font = (props) => <Box component={'span'} fontFamily={props.font} {...props}></Box>

const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

const adii = [
    "Highly Excluded",
    "Highly excluded",
    "Excluded",
    "Included",
    "Highly included",
    "Highly Included",
];

const genders = [
    "Female",
    "Male",
    "Other",
]

const indigenous_statuses = [
    "Yes",
    "No",
    "Unknown"
]

const age_groups = {
    'Below 9yo' : "Below 9",
    '10-12yo': "10-12",
    '13-14yo': "13-14",
    '15-16yo': "15-16",
    'above 16yo': "16+",
}

function Adoption() {

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const { authUser } = useAuth();
    const { org_code, org_obj } = authUser;
    const { org_country } = org_obj;
    const countryConfigKey = COUNTRY_CONFIGS[org_country] ? org_country : "default";
    const countryConfigKeyForIncludedCountries = COUNTRY_CONFIGS[org_country] && COUNTRY_CONFIGS[org_country].included_countries ? org_country : "default";
    const countriesFromConfig = [org_country, ...COUNTRY_CONFIGS[countryConfigKeyForIncludedCountries].included_countries]
    const {administrative_sector, socio_economic_class, indigenous} = COUNTRY_CONFIGS[countryConfigKey];

    //global data
    const [selectorsData, setSelectorsData] = useState(undefined);
    const [pageData, setPageData] = useState(undefined);

    //selectors data
    const [countries, setCountries] = useState([]);
    const [regions, setRegions] = useState([]);
    const [timePeriods, setTimePeriods] = useState([]);

    //selector states
    const [selectedCountry, setSelectedCountry] = useState([]);
    const [selectedRegions, setSelectedRegions] = useState([]);
    const [selectedTime, setSelectedTime] = useState([undefined, undefined]);

    //misc states
    const [minDate, setMinDate] = useState(undefined);
    const [maxDate, setMaxDate] = useState(undefined);
    const [datePicker, setDatePicker] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [firstLoad, setFirstLoad] = useState(true);
    const [loading, setLoading] = useState(false);

    const [mainChart, setMainChart] = useState(undefined);
    const [secCharts, setSecCharts] = useState([]);

    const countryHeader = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_country")
    const primarySideHeader = GET_APP_LOCALIZE_TEXT(pageLc, `side_panel__select_`+administrative_sector.toLowerCase())
    const secondarySideHeader = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_time")

    const selectAllRegionsOption = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__entire_nation")
    const selectAllTimesOption = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__all_year")

    const [selectedDCPrograms, setSelectedDCPrograms]  = useState([]);

    const [defaultDCProgram, setDefaultDCProgram] = useState([]);
    const [additionalDCProgram, setAdditionalDCProgram] = useState([]);

    const addItemToState = (setState, item, sort = false) => {
        if(sort)
            setState((prevState) => ([...prevState, item].sort()));
        else
            setState((prevState) => ([...prevState, item]));
    }

    const removeItemFromState = (setState, item) => {
        setState((prevState) => {
            const index = prevState.indexOf(item);
            const temp = [...prevState];
            temp.splice(index, 1);
            return temp;
        });
    }

    useEffect(() => {
        if(pageData)
        {
            setMainChart({
                labels: pageData.created_month_year ? pageData.created_month_year
                    .map((item) => `${GET_APP_LOCALIZE_TEXT(pageLc, `stats__${createLcKey(months[item._id.month - 1])}`, months[item._id.month - 1])} '${item._id.year.toString().slice(-2)}`) : [],
                data: pageData.created_month_year ? pageData.created_month_year.map((item) => item.count) : [],
            })
            setSecCharts([
                {
                    header: socio_economic_class ?
                        GET_APP_LOCALIZE_TEXT(pageLc, `assessment__by_${socio_economic_class.toLowerCase()}`)
                    : undefined,
                    labels: pageData.ses_rubric ? pageData.ses_rubric.filter(item => item._id).sort(predefinedSort(adii, "_id")).map((item) => GET_APP_LOCALIZE_TEXT(pageLc, `stats__${createLcKey(item._id)}`, item._id)) : [],
                    data: pageData.ses_rubric ? pageData.ses_rubric.filter(item => item._id).sort(predefinedSort(adii, "_id")).map((item) => item.count) : []
                },
                {
                    header: indigenous ? GET_APP_LOCALIZE_TEXT(pageLc, "assessment__by_indigenous") : undefined,
                    labels: pageData.indigenous ? pageData.indigenous.filter(item => item._id).map((item) => GET_APP_LOCALIZE_TEXT(pageLc, `stats__${createLcKey(item._id)}`, item._id)) : [],
                    data: pageData.indigenous ? pageData.indigenous.filter(item => item._id).map((item) => item.count) : []
                },
                {
                    header: GET_APP_LOCALIZE_TEXT(pageLc, "assessment__by_gender"),
                    labels: pageData.gender ? pageData.gender.filter(item => item._id).map((item) => GET_APP_LOCALIZE_TEXT(pageLc, `stats__${createLcKey(item._id)}`, item._id)) : [],
                    data: pageData.gender ? pageData.gender.filter(item => item._id).map((item) => item.count) : []
                },
                {
                    header: GET_APP_LOCALIZE_TEXT(pageLc, "assessment__by_age"),
                    labels: pageData.age_cat ? pageData.age_cat.filter(item => item._id).map((item) => age_groups[item._id] ? age_groups[item._id] : item._id).map((item) => GET_APP_LOCALIZE_TEXT(pageLc, `stats__${createLcKey(item)}`, item)) : [],
                    data: pageData.age_cat ? pageData.age_cat.filter(item => item._id).map((item) => item.count) : []
                }
            ])
        }
        else if(!firstLoad)
        {
            setMainChart(undefined);
            setSecCharts([]);
        }
    }, [pageData])

    useEffect(() => {
        if(selectorsData)
        {

        }
        else if(!firstLoad)
        {
            setSelectedRegions([]);
            setSelectedTime([undefined, undefined]);
            setRegions([]);
            setTimePeriods([]);
            setMinDate(undefined);
            setMaxDate(undefined);
        }
    }, [selectorsData])

    useEffect(() => {
        if(selectorsData)
        {
            setSelectedRegions(regions);
        }
    }, [regions])

    useEffect(() => {
        if(timePeriods.length > 0)
        {
            const [firstYear, lastYear] = [timePeriods[0], timePeriods[timePeriods.length - 1]]
            setMinDate(new Date(`${firstYear}-01-01`))
            setMaxDate(new Date(`${lastYear}-12-31`))
            selectAllMonthsOfYears(timePeriods);
        }
    },[timePeriods])

    useEffect(() => {
        if(selectorsData && selectedCountry)
        {
            initializeRegionsAndTimePeriods(selectorsData, selectedCountry)
        }
    }, [selectedCountry])

    useEffect(() => {
        if(selectorsData && selectedRegions && selectedTime)
        {
            if(!pageData || !areRegionEqual(selectedRegions, pageData.selected_states) || !areDateRangesEqual(selectedTime, pageData.selected_date_range))
            {
                loadPageData().then(d => console.log('Assessment Adoption Data Fetched', d));
            }
            else
            {
                console.log("Fetching did not occur")
            }
        }
    }, [selectedRegions, selectedTime])

    useEffect(() => {
        if(firstLoad) {
            loadSelectors().then(d => console.log('Assessment Adoption Selectors Fetched', d))
            setFirstLoad(false);
        }
    }, []);

    const loadSelectors = async () => {
        setLoading(true);
        try {
            const result = await getAssessmentAdoptionv2(org_code);
            if(result.data)
            {
                const {selectors, data} = result.data;
                const {selected_country} = data;
                const {countries: _countries} = selectors;
                setSelectorsData(selectors);

                if(_countries && Array.isArray(_countries))
                {
                    const filtered_countries = _countries.filter(ct => countriesFromConfig.includes(ct));
                    setCountries(filtered_countries);
                    if(filtered_countries[0] === selected_country)
                    {
                        setPageData(data);
                        setSelectedCountry(selected_country);
                        //initializeRegionsAndTimePeriods called here because it may not get called by useEffect of selectedCountry state due to undefined selectorsData state
                        initializeRegionsAndTimePeriods(selectors, selected_country);
                    }
                    else
                    {
                        setSelectedCountry(filtered_countries[0]);
                        initializeRegionsAndTimePeriods(selectors, filtered_countries[0]);
                    }
                }
            }
        }
        catch (e) {
            console.log("Some error occurred while fetching assessment adoption selectors")
        }
        const dcProgramsArray = [];
        const additionalDCProgramArray = [];
        dcProgramsArray.push(`All Programs`);

        for (let i = 0; i < 3; i++) {
            dcProgramsArray.push(`DC Program ${i + 1}`);
        }

        for (let i = 3; i < 8; i++) {
            additionalDCProgramArray.push(`DC Program ${i + 1}`);
        }

        setDefaultDCProgram(dcProgramsArray);
        setAdditionalDCProgram(additionalDCProgramArray)
        setSelectedDCPrograms(dcProgramsArray);
        setTimeout(() => {setLoading(false)}, 200);
        return true;
    }

    const loadPageData = async (country = selectedCountry, regions = selectedRegions, time = selectedTime) => {
        setLoading(true);
        try {
            setPageData(undefined);
            const result = await getAssessmentAdoptionv2(org_code, country, regions, time);
            if (result.data) {
                const {data} = result.data;
                setPageData(data);
            }
            else {
                setErrorMessage(GET_APP_LOCALIZE_TEXT(pageLc, "general__data_unavailable"));
            }
        } catch (e) {
            console.log("Some error occurred while fetching assessment adoption data")
        }
        setTimeout(() => {setLoading(false)}, 200);
        return true;
    }

    function initializeRegionsAndTimePeriods(selectors, country)
    {
        const {states: _states, years: _years} = selectors;
        if(_states && _years && _states[country] && _years[country])
        {
            setRegions(_states[country]);
            setTimePeriods(_years[country].map((year) => year.toString()));
        }
    }

    function areRegionEqual(regions1, regions2)
    {
        if(!Array.isArray(regions1) || !Array.isArray(regions2)) return false;
        if(regions1.length !== regions2.length) return false;
        for(let index in regions1)
        {
            if(regions1[index] !== regions2[index]) return false;
        }
        return true;
    }

    function areDateRangesEqual(range1, range2)
    {
        if(!Array.isArray(range1) || !Array.isArray(range2)) return false;
        if(range1.length !== range2.length) return false;
        for(let index in range1)
        {
            if(range1[index] !== range2[index]) return false;
        }
        return true;
    }

    function onSelectCountry (item, isChecked, state, setState) {
        if(isChecked)
        {
            setSelectedCountry(item);
            setState(Object.fromEntries(countries.map(
                (country) => country === item ? [country, true] : [country, false]
            )));
        }
        else
        {
            setState({
                ...state,
                [item]: true,
            });
        }
    }

    function onSelectRegion (item, isChecked) {

        if(isChecked) {
            if(item == selectAllRegionsOption)
            {
                setSelectedRegions([...regions])
            }
            else
            {
                if(selectedRegions.includes(item)) //if already selected, then all regions was selected before, clear all
                {
                    setSelectedRegions([item])
                }
                else
                {
                    setSelectedRegions([...selectedRegions, item])
                }
            }
        }
        else
        {
            if(item == selectAllRegionsOption)
            {
                setSelectedRegions([])
            }
            else {
                if (selectedRegions.includes(item)) {
                    let copyArray = [...selectedRegions]
                    copyArray.splice(copyArray.indexOf(item), 1)
                    setSelectedRegions(copyArray)
                }
            }
        }
    }

    function onSelectTimePeriod (item, isChecked, state, setState) {
        //setDatePicker(false)
        if(isChecked)
        {
            if(item === selectAllTimesOption)
            {
                selectAllMonthsOfYears(timePeriods);
            }
            else
            {
                selectAllMonthsOfYears([item]);
            }
            setState(Object.fromEntries([selectAllTimesOption, ...timePeriods].map(
                (time) => time === item ? [time, true] : [time, false]
            )));
        }
        else
        {
            setState({
                ...state,
                [item]: true,
            });
        }
    }

    function onClickDatePicker(event, state, setState) {
        setState(Object.fromEntries([selectAllTimesOption, ...timePeriods].map(
            (time) => [time, false]
        )));
    }

    function onSelectDateRange(dates) {

        const start_date = new Date(Date.parse(dates[0])).toISOString();
        const end_date = new Date(Date.parse(dates[1])).toISOString();

        console.log(start_date, end_date);

        setSelectedTime([start_date, end_date]);
    }

    const selectAllMonthsOfYears = (years) => {
        const [startYear, endYear] = [years[0], years[years.length-1]];
        const [startDate, endDate] = [new Date(`${startYear}-01-01`).toISOString(), new Date(`${endYear}-12-31`).toISOString()]
        setSelectedTime([startDate, endDate]);
    }

    const onClickDownload = (event) => {

        const workbook = XLSX.utils.book_new();
        let column_widths = []

        const selection_array = [
            ["Selected Country:", selectedCountry],
            ["Selected Regions:", ...selectedRegions],
            ["Selected Time:", new Date(selectedTime[0]).toDateString(), new Date(selectedTime[1]).toDateString()],
            [" "],
            ["Participants by Month"],
            mainChart.labels.map((item) => item.toString()),
            mainChart.data.map((item) => item.toString()),
        ]

        secCharts.forEach((chart) => {
            selection_array.push(...[
                [""],
                [`Participants by ${chart.header}`],
                chart.labels.map((item) => item.toString()),
                chart.data.map((item) => item.toString())
            ])
        })

        selection_array.forEach((array) => {
            array.forEach(((item, index) => column_widths[index] =  column_widths[index] ? Math.max(item.toString().length, column_widths[index], 10) : item.length))
        })

        const worksheet = XLSX.utils.aoa_to_sheet(selection_array);
        worksheet["!cols"] = new Array(column_widths.length).fill(null).map((_, index) => {
            return { wch: column_widths[index] }
        });
        XLSX.utils.book_append_sheet(workbook, worksheet, "Reach");
        XLSX.writeFile(workbook, "Assessment Reach.xlsx");
    }

    function onSelectDCProgram(item, isChecked) {
        setLoading(true);
        const itemID = item;

        if (isChecked) {
            if (!selectedDCPrograms.includes(itemID)) {
                addItemToState(setSelectedDCPrograms, itemID);
            }
        } else {
            if (selectedDCPrograms.includes(itemID) && !defaultDCProgram.includes(itemID)) {
                removeItemFromState(setSelectedDCPrograms, itemID);
                addItemToState(setAdditionalDCProgram, itemID);
            }
        }
        setTimeout(() => {setLoading(false)}, 200);
    }

    function onAddDCProgram(event, state, setState) {
        setLoading(true);
        if(selectedDCPrograms.length === 8)
        {
            toastDanger(GET_APP_LOCALIZE_TEXT(pageLc, "general__error"), GET_APP_LOCALIZE_TEXT(pageLc,"baselining__max_added_error"));
        } else {
            const program = event.target.value;
            removeItemFromState(setAdditionalDCProgram, program)
            // addItemToState(setSelectedDCPrograms, program)
            onSelectDCProgram(program, true)
            setState((prevState) => ({
                ...prevState,
                [program]: true,
            }));
        }
        setTimeout(() => {
            setLoading(false)
        }, 200);
    }

    return (
        <>
            <Helmet title={`${GET_APP_LOCALIZE_TEXT(pageLc, "navigation__assessment")} ${GET_APP_LOCALIZE_TEXT(pageLc, "navigation__reach")}`}/>
            <Box mx={"3vw"}>
                <Box mb={5}>
                    <DefaultColouredTypography variant="h2">
                        {GET_APP_LOCALIZE_TEXT(pageLc, "overview__section_2")}: {GET_APP_LOCALIZE_TEXT(pageLc, "navigation__assessment")}
                    </DefaultColouredTypography>
                    <ColouredTypography variant={"body1"} size={"16px"}>
                        {GET_APP_LOCALIZE_TEXT(pageLc, "overview__assessment_desc")}
                    </ColouredTypography>
                </Box>
                <Box mb={7}>
                    <ColouredTypography variant="h6" color={"#25b7db"} size={"16px"} weight={"bold"}>
                        {`2.2 ${GET_APP_LOCALIZE_TEXT(pageLc, "navigation__reach")} ${GET_APP_LOCALIZE_TEXT(pageLc, "navigation__assessment")}: `}
                        <ColouredTypography variant="body1" fontWeight="fontWeightRegular" component={"span"} size={"16px"}>
                            {GET_APP_LOCALIZE_TEXT(pageLc, "assessment__reach_desc")}
                        </ColouredTypography>
                    </ColouredTypography>
                </Box>
            </Box>
            <Paper container>
                <Grid container>
                    <Grid item xs={12} sm={12} md={3}>
                        <GenericSidePanel
                            data={[selectedDCPrograms, [selectAllRegionsOption, ...regions], [selectAllTimesOption, ...timePeriods]]}
                            headers = {[countryHeader, primarySideHeader, secondarySideHeader]}
                            onCheckCallbacks = {[onSelectDCProgram, onSelectRegion, onSelectTimePeriod]}
                            colourize = {[false, false, false]}
                            footers={[CustomSelect, undefined, MonthRangePicker]}
                            footersData = {[{title: "Add Program", data: additionalDCProgram}, undefined, {onChangeDate: onSelectDateRange, minDate, maxDate}]}
                            footersCallbacks = {[{onChange: onAddDCProgram}, undefined, {onOpen: onClickDatePicker}]}
                            defaultCheckIndices = {[undefined, 0, 0]}
                            singleSelectIndices = {[undefined, 0, undefined]}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={9} alignItems={!pageData ? 'center' : ''} container={!pageData} justify={'center'}>
                        {
                            pageData ?
                                <Card>
                                    <CardContent>
                                        <Box sx={{flexGrow: 1}} align={"right"}>
                                            <ColouredTypography variant={"body1"} size={"16px"} color={"dimgrey"}
                                                                align={"center"} alignText={"justify"} my={5}>
                                                {GET_APP_LOCALIZE_TEXT(pageLc, "assessment__participation_desc")} (<Box
                                                component={"span"} fontWeight={"fontWeightBold"}>{GET_APP_LOCALIZE_TEXT(pageLc, "assessment__participation").toLowerCase()}</Box>)?
                                            </ColouredTypography>
                                            <ColouredTypography mt={5} mb={5} variant={"h4"} align={"center"}>
                                                {GET_APP_LOCALIZE_TEXT(pageLc, "assessment__participation")}
                                            </ColouredTypography>
                                            <Grid container spacing={3} align={"center"} alignItems={"center"}>
                                                { pageData.Total ? pageData.Total.map((badge) => (
                                                        <Grid item xs={12} sm={5} md={12} lg={3}>
                                                            <Badge
                                                                title={GET_APP_LOCALIZE_TEXT(pageLc, "assessment__total_participation")}
                                                                value={badge.count}
                                                            />
                                                        </Grid>
                                                    )
                                                ) : null}
                                                <Grid item xs={12} sm={7} md={12} lg={9}>
                                                    <BarChart
                                                        labels={mainChart ? mainChart.labels : []}
                                                        values={mainChart ? mainChart.data : []}
                                                        //line_values={mainChart.line_data}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <ColouredTypography variant={"body1"} size={"16px"} color={"dimgrey"}
                                                                align={"center"} alignText={"justify"} my={5}>
                                                {
                                                    socio_economic_class ?
                                                        GET_APP_LOCALIZE_TEXT(pageLc, "assessment__inclusive_ses_desc")
                                                    :
                                                        GET_APP_LOCALIZE_TEXT(pageLc, "assessment__inclusive_desc")
                                                }
                                            </ColouredTypography>
                                            <Spacer my={5}/>
                                            <Grid container spacing={5} align={"center"} alignItems={"center"} justify={"center"}>
                                                {secCharts.filter((chart) => chart.header).map((chart,_, arr) => (
                                                        <Grid item xs={12} sm={12} md={6} lg={Math.round(12/(arr.length-1))} xl={Math.round(12/arr.length)}>
                                                            <Paper variant={"elevation"} py={5}>
                                                                <BarChart
                                                                    header={chart.header}
                                                                    labels={chart.labels}
                                                                    values={chart.data}
                                                                    legend={false}
                                                                />
                                                            </Paper>
                                                        </Grid>
                                                    )
                                                )}
                                            </Grid>
                                            <Button mt={10} variant="contained" onClick={onClickDownload}>
                                                {GET_APP_LOCALIZE_TEXT(pageLc, "general__download_data")}
                                            </Button>
                                        </Box>

                                    </CardContent>
                                </Card>
                            :
                                <DefaultColouredTypography variant={"h3"} align={'center'}>{errorMessage}</DefaultColouredTypography>
                        }
                    </Grid>
                </Grid>
                <LoaderWithBackDrop loading={loading}/>
            </Paper>
            <Box mx={"3vw"} my={"2vw"}>
                <ColouredTypography variant={"body1"} size={"16px"} align={"center"}>
                    {`${GET_APP_LOCALIZE_TEXT(pageLc, "assessment__footer")} `}
                    <a href={"/partner/enhancement/reach"} style={{color: "#25b7db"}}>
                        {GET_APP_LOCALIZE_TEXT(pageLc, "assessment__enhancement_reach")}
                    </a>.
                </ColouredTypography>
            </Box>
            <LoaderWithBackDrop loading={loading}/>
        </>
    );
}

export default Adoption;
