import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";

import {
    Box,
    Card as MuiCard,
    CardContent as MuiCardContent, Checkbox,
    Divider as MuiDivider, FormControlLabel,
    Typography as MuiTypography,
} from "@material-ui/core";


import { spacing } from "@material-ui/system";
import {withStyles, makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const Card = styled(MuiCard)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;
  background-color: ${(props) => props.theme.palette.background.paper};
`;


const ColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
`

const ColouredCard = styled(Card)`
  background-color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  padding: 10px 10px 10px 10px;
  height: 100%;
  border-radius: 10px 0px 0px 10px;
`

const useCheckBoxStyles = makeStyles({
    root: {
        '&.MuiCheckbox-root': {
            color: (props) => props.color,
        },
        '&.Mui-checked': {
            color: (props) => props.color,
        },
    },
    checked: {},
});

const Divider = styled(MuiDivider)(spacing);

const Bold = (props) => <Box component={'span'} fontWeight="fontWeightBold" {...props}></Box>

export const GenericSidePanel = (props) => {

    const checkBoxClasses = [
        useCheckBoxStyles({color: "#C71585"}),
        useCheckBoxStyles({color: "purple"}),
        useCheckBoxStyles({color: "darkblue"}),
        useCheckBoxStyles({color: "skyblue"}),
        useCheckBoxStyles({color: "#0D98BA"}),
        useCheckBoxStyles({color: "seagreen"}),
        useCheckBoxStyles({color: "#9ACD32"}),
        useCheckBoxStyles({color: "#FFCC00"}),
        useCheckBoxStyles({color: "orange"}),
        useCheckBoxStyles({color: "#FF5349"}),
        useCheckBoxStyles({color: "red"}),
    ]

    const defaultCheckBoxClasses = useCheckBoxStyles({color: "#233044"})

    let {
        visible = [],
        data = [],
        onCheckCallbacks = [],
        headers = [],
        colourize = [],
        actions = [],
        actionsData = [],
        actionsCallbacks = [],
        footers = [],
        footersData = [],
        footersCallbacks = [],
        singleSelectIndices = [],
        defaultCheckIndices = [],
        stateCallbacks = [],
    } = props;

    const [checkboxStates, setCheckboxStates] = useState([])

    useEffect(() => {
        //Filling empties
        if(visible.length === 0)
            visible = new Array(data.length).fill( true)
        if(onCheckCallbacks.length === 0)
            onCheckCallbacks = new Array(data.length).fill( () => {})
        if(headers.length === 0)
            headers = new Array(data.length).fill("")
        if(colourize.length === 0)
            colourize = new Array(data.length).fill(false);
        if(footers.length === 0)
            footers = new Array(data.length).fill(undefined);
        if(defaultCheckIndices.length === 0)
            defaultCheckIndices = new Array(data.length).fill(0);
        if(singleSelectIndices.length === 0)
            singleSelectIndices = new Array(data.length).fill(undefined)
        const stateList = []
        if(checkboxStates.length === 0) {
            data.forEach((list, index) => {
                stateList.push(Object.fromEntries(list.map((title, subIndex) => subIndex == defaultCheckIndices[index] ? [title, true] : [title, false])))
            })
            setCheckboxStates([...stateList]);
        }
        else {
            data.forEach((list, index) => {
                //Checking if the list was altered
                let is_exact = true;
                const previous_list = Object.keys(checkboxStates[index]);
                list.forEach((title) => {
                    if(!previous_list.includes(title)) {
                        is_exact = false;
                    }
                })
                //If the list is same as previous one, restore previous checkbox state
                if(is_exact) {
                    stateList.push(Object.fromEntries(list.map((title) => checkboxStates[index][title] ? [title, checkboxStates[index][title]] : [title, false])))
                }
                //If the list has been changed, re-initialize state to default
                else {
                    stateList.push(Object.fromEntries(list.map((title, subIndex) => subIndex == defaultCheckIndices[index] ? [title, true] : [title, false])))
                }
            })
            setCheckboxStates([...stateList]);
        }

    }, [data])

    useEffect(() => {
        stateCallbacks.forEach((stateObject, index) => {
            if(stateObject)
            {
                const {state, setState} = stateObject;
                if(state && setState)
                {
                    setSinglePanelState(index, state)
                    setState(undefined);
                }
            }
        })
    }, [stateCallbacks])

    const singlePanelState = (index) => {
        return checkboxStates[index]
    }

    const setSinglePanelState = (index, state) => {
        const local_copy = [...checkboxStates];
        local_copy.splice(index, 1, state)
        setCheckboxStates(local_copy);
    }

    const handleCheckboxChange = (event, index) =>
    {
        const singleState = singlePanelState(index);
        setSinglePanelState(index, { ...singleState, [event.target.name]: event.target.checked });
        if(singleSelectIndices[index] !== undefined) //Single selection should override all other selections or not
        {
            if(event.target.checked)
            {
                if(data[index].indexOf(event.target.name) === singleSelectIndices[index]) {
                    setSinglePanelState(index, Object.fromEntries(data[index].map(
                        (item, subIndex) => subIndex === singleSelectIndices[index] ? [item, true] : [item, false]
                    )));
                }
                else
                {
                    const single_select_element = data[index][singleSelectIndices[index]]
                    setSinglePanelState(index, {
                        ...singleState,
                        [single_select_element]: false,
                        [event.target.name]: event.target.checked,
                    })
                }
            }
        }
        if(onCheckCallbacks[index])
        {
            onCheckCallbacks[index](event.target.name, event.target.checked, singleState, (state) => setSinglePanelState(index, state), setCheckboxStates)
        }
    }

    const handleFooterCallback = (event, callback, index) => {
        callback(event, singlePanelState(index), (state) => setSinglePanelState(index, state));
    }

    const handleActionButton = (value, index) => {
        actionsCallbacks[index](value, singlePanelState(index), (state) => setSinglePanelState(index, state));
    }

    function anyIndexVisible(min_index, max_index) {
        for(let i = min_index; i <= max_index; i++)
        {
            if(visible[i] !== false) return true;
        }
        return false;
    }

    return checkboxStates.length > 0 ?
    <ColouredCard>
        <CardContent id={"SidePanelContainer"} style={{alignItems: "center"}}>
            {data.map((list, index) => {
                const Action = actions[index];
                const Footer = footers[index];
                return visible[index] !== false ? <div className={`Panel ${index}`}>
                    {
                        index !== 0 && anyIndexVisible(0, index-1) ?
                            <Divider mt={5} mb={5}/>
                        : null
                    }
                    <ColouredTypography mb={3} variant="body1" align={"center"}>
                        <Bold>
                            {headers[index]}
                        </Bold>
                    </ColouredTypography>
                    {
                        list.map(
                            (title, subIndex) => {
                                return <Grid container justify="space-between" alignItems={"center"}>
                                    <Grid item xs={Action ? 10 : 12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    className={colourize[index] ? checkBoxClasses[subIndex].root : defaultCheckBoxClasses.root}
                                                    name={title}
                                                    checked={checkboxStates[index][title] || false}
                                                    onChange={(event) => handleCheckboxChange(event, index)}
                                                />
                                            }
                                            label={title}
                                        />
                                    </Grid>
                                    {
                                        Action ?
                                            <Grid item xs={2}>
                                                <Action {...actionsData[index]} value={title} onClick={() => handleActionButton(title, index)}></Action>
                                            </Grid>
                                            : null
                                    }
                                </Grid>
                            }
                        )
                    }
                    {
                        Footer ?
                            // <Footer {...footersData[index]} onChange={(event) => handleFooterChange(event, index)}/>
                            <Footer
                                {...footersData[index]}
                                {
                                    ...Object.fromEntries(
                                        Object.entries(footersCallbacks[index]).map(([property, callback]) =>
                                            [property, (event) => handleFooterCallback(event, callback, index)]
                                        )
                                    )
                                }
                            />
                            : null
                    }
                </div> : null
            })}
        </CardContent>
    </ColouredCard>
    : <></>;
}
