import {LOCAL_STORAGE_KEYS} from "../utils/constants";
import {invokeApi} from "../bl_libs/invokeApi";


export const submitContactUsRequest = async (name, email, message) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/misc/contact_us',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        name,
        email,
        message
    };
    return invokeApi(requestObj);
}

export const uploadMedia = (media, progressCb) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/admin/storage/put',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`,
            'content-type': 'multipart/form-data'
        },
        onUploadProgress: progressCb
    };
    requestObj['postData'] = media;

    return invokeApi(requestObj);
}