import React from "react";
import {useLocation} from "react-router-dom";
import styled, { withTheme } from "styled-components/macro";

import { CardContent, Card as MuiCard, Typography } from "@material-ui/core";
import orange from "@material-ui/core/colors/orange";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import { spacing } from "@material-ui/system";

import {Bar, Radar} from "react-chartjs-2";
import Button from "@material-ui/core/Button";
import {grey} from "@material-ui/core/colors";
import {calculateRgba} from "react-spinners/helpers";
import {splitEveryNthPos} from "../../../../utils/utils";

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

const ChartWrapper = styled.div`
  height: 300px;
`;

const colors = [
    "#C71585",
    "#800080",
    "#00008B",
    "#87CEEB",
    "#0D98BA",
    "#2E8B57",
    "#9ACD32",
    "#FFCC00",
    "#FFA500",
    "#FF5349",
    "#FF0000",
]

const RadarChart = React.forwardRef((props, ref) => {

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const {data, suggestedMin = 0} = props;

    let colorIndex = 0;

    const final_data = {
    labels: data.labels.map((label) => splitEveryNthPos(label == "DQ3: Behavioural Cyber-Risk Management" ? "DQ3: Beh. Cyber-Risk Management" : label, "", 20)),
    datasets: data.graphData ? data.graphData.map((dataItem) => {
        const color = dataItem.index < 0 || dataItem.index > (colors.length-1) ? "" : colors[dataItem.index];
        return {
            label: dataItem.country,
            backgroundColor: calculateRgba(color, dataItem.background_opacity),
            borderColor: calculateRgba(color, dataItem.border_opacity),
            pointBackgroundColor: calculateRgba(color, dataItem.border_opacity),
            pointBorderColor: "#ffffff",
            pointHoverBackgroundColor: "#ffffff",
            pointHoverBorderColor: calculateRgba(color, dataItem.border_opacity),
            pointRadius: 4,
            data: dataItem.data,
            hidden: !dataItem.show,
        }
    }) : [],
    };

    const options = {
    maintainAspectRatio: false,
    scale: {
      ticks: {
          suggestedMin: suggestedMin,
          beginAtZero: false,
      },
      pointLabels: {
         fontColor: data.selected.map((bool) => bool ? '#25b7dbff' : undefined),
      }
    },
    legend: {
      display: false,
      position: 'bottom',
      labels: {
        fontColor: '#666',
        fontStyle: 'bold',
        boxWidth: 40,
        usePointStyle: false,
      }
    },
    };

    return (
      <React.Fragment>
          <ChartWrapper>
            <Radar data={final_data} options={options} ref={ref} />
          </ChartWrapper>
      </React.Fragment>
    );
})

export default RadarChart;
