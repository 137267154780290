import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";
import { useAuth } from "../../../context/auth";

import {
    Paper,
    Tabs,
    Tab,
    Box,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Chip as MuiChip,
    Typography as MuiTypography,
} from "@material-ui/core";


import { spacing } from "@material-ui/system";
import {COUNTRY_CONFIGS} from "../../../utils/constants";
import {useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";

const Card = styled(MuiCard)(spacing);

const SpacedTypography = styled(MuiTypography)(spacing);

const SpacedUl = styled.ul(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;
  padding-left: ${(props) => props.theme.spacing(12)}px;
  padding-right: ${(props) => props.theme.spacing(12)}px;
  
  &:first-child {
    padding-top: ${(props) => props.theme.spacing(12)}px;
  }
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(12)}px;
  }
`;

const Typography = styled(SpacedTypography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
`
const Bold = (props) => <Box component={'span'} fontWeight="fontWeightBold" {...props}></Box>

const ColoredUl = styled(SpacedUl)`
  color: ${(props) => props.custom_color ? props.custom_color : props.theme.sidebar.background};
`

function AboutReportTab(props) {

    const { authUser } = useAuth();
    const { org_country } = authUser.org_obj;

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    return (<Card mb={3}>
        <CardContent>
            <Typography mb={5} variant="body1" color="grey" wrapped="true" size="14px">
                <Typography component="span" variant="h6">
                    <Bold>{GET_APP_LOCALIZE_TEXT(pageLc, "overview__dq_impact_report")} </Bold>
                </Typography>
                {GET_APP_LOCALIZE_TEXT(pageLc, "overview__report_intro")}
            </Typography>
            <Typography mb={5} variant="h6" color="grey" wrapped="true">
                <Bold>
                    {GET_APP_LOCALIZE_TEXT(pageLc, "overview__report_design")}:
                </Bold>
            </Typography>
            <Typography variant="h6" color="grey" wrapped="true" size="14px">
                {GET_APP_LOCALIZE_TEXT(pageLc, "overview__prog_owner")}
            </Typography>
            <ColoredUl custom_color={'grey'}>
                <li>
                    <Typography variant="body1" color="grey" wrapped="true" size="14px">
                        {GET_APP_LOCALIZE_TEXT(pageLc, "overview__prog_owner_reason_1")}
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1" color="grey" wrapped="true" size="14px">
                        {GET_APP_LOCALIZE_TEXT(pageLc, "overview__prog_owner_reason_2")}
                    </Typography>
                </li>
            </ColoredUl>
            {/*<Typography variant="body1" color="grey" wrapped="true" size="14px">*/}
            {/*    {GET_APP_LOCALIZE_TEXT(pageLc, "overview__stakeholder")}*/}
            {/*</Typography>*/}
            {/*<ColoredUl custom_color={'grey'}>*/}
            {/*    <li>*/}
            {/*        <Typography variant="body1" color="grey" wrapped="true" size="14px">*/}
            {/*            {GET_APP_LOCALIZE_TEXT(pageLc, "overview__stakeholder_reason_1")}*/}
            {/*        </Typography>*/}
            {/*    </li>*/}
            {/*</ColoredUl>*/}
            <Typography mt={5} variant="body1" color="grey" wrapped="true" size="14px">
                {GET_APP_LOCALIZE_TEXT(pageLc, "overview__report_composition")}:
            </Typography>
            <ColoredUl mb={5} custom_color={'grey'}>
                <li>
                    <Typography component="span" variant="body1" color="grey" wrapped="true" size="14px">
                        <Bold>{GET_APP_LOCALIZE_TEXT(pageLc, "overview__section_1")}: </Bold>
                    </Typography>
                    <Typography component="span" variant="body1" wrapped="true" size="14px">
                        <Bold>{GET_APP_LOCALIZE_TEXT(pageLc, "navigation__baselining").toUpperCase()} </Bold>
                    </Typography>
                    <Typography component="span" variant="body1" color="grey" wrapped="true" size="14px">
                        - {GET_APP_LOCALIZE_TEXT(pageLc, "overview__baselining_desc")}
                    </Typography>
                </li>
                <li>
                    <Typography component="span" variant="body1" color="grey" wrapped="true" size="14px">
                        <Bold>{GET_APP_LOCALIZE_TEXT(pageLc, "overview__section_2")}: </Bold>
                    </Typography>
                    <Typography component="span" variant="body1" wrapped="true" size="14px">
                        <Bold>{GET_APP_LOCALIZE_TEXT(pageLc, "navigation__assessment").toUpperCase()} </Bold>
                    </Typography>
                    <Typography component="span" variant="body1" color="grey" wrapped="true" size="14px">
                        - {GET_APP_LOCALIZE_TEXT(pageLc, "overview__assessment_desc")}
                    </Typography>
                </li>
                <li>
                    <Typography component="span" variant="body1" color="grey" wrapped="true" size="14px">
                        <Bold>{GET_APP_LOCALIZE_TEXT(pageLc, "overview__section_3")}: </Bold>
                    </Typography>
                    <Typography component="span" variant="body1" wrapped="true" size="14px">
                        <Bold>{GET_APP_LOCALIZE_TEXT(pageLc, "navigation__enhancement").toUpperCase()} </Bold>
                    </Typography>
                    <Typography component="span" variant="body1" color="grey" wrapped="true" size="14px">
                        - {GET_APP_LOCALIZE_TEXT(pageLc, "overview__enhancement_desc")}
                    </Typography>
                </li>
            </ColoredUl>
            <Typography mb={5} variant="h6" color="grey" wrapped="true">
                <Bold>
                    {GET_APP_LOCALIZE_TEXT(pageLc, "overview__key_terms")}:
                </Bold>
            </Typography>
            { (COUNTRY_CONFIGS[org_country] ? COUNTRY_CONFIGS[org_country].socio_economic_class : COUNTRY_CONFIGS.default.socio_economic_class) === 'ADII' ? <>
                <Typography variant="body1" color="grey" wrapped="true" size="14px">
                    ADII
                </Typography>
                <ColoredUl custom_color={'grey'}>
                    <li>
                        <Typography variant="body1" color="grey" wrapped="true" size="14px">
                            ADII is the Australian Digital Inclusion Index. ADII scores range from 0 to 100. The higher the score, the greater the level of digital inclusion. ADII scores are relative: they allow comparisons across different social groups and geographic areas, and over time.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" color="grey" wrapped="true" size="14px">
                            Each of the Index dimensions – Access, Affordability and Digital Ability – are equally weighted to derive the total Index score.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" color="grey" wrapped="true" size="14px">
                            Total Index scores have been classified into four groups: Highly excluded (45 or below), Excluded (above 45 and below 61), Included (61 and below 80) and Highly included (80 and above).
                        </Typography>
                    </li>
                </ColoredUl>
            </> : null }
            <Typography variant="body1" color="grey" wrapped="true" size="14px">
                {GET_APP_LOCALIZE_TEXT(pageLc, "overview__skill_acquire")}
            </Typography>
            <ColoredUl custom_color={'grey'}>
                <li>
                    <Typography variant="body1" color="grey" wrapped="true" size="14px">
                        {GET_APP_LOCALIZE_TEXT(pageLc, "overview__skill_acquire_desc")}
                    </Typography>
                </li>
            </ColoredUl>
            <Typography variant="body1" color="grey" wrapped="true" size="14px">
                {GET_APP_LOCALIZE_TEXT(pageLc, "overview__skill_improve")}
            </Typography>
            <ColoredUl custom_color={'grey'}>
                <li>
                    <Typography variant="body1" color="grey" wrapped="true" size="14px">
                        {GET_APP_LOCALIZE_TEXT(pageLc, "overview__skill_improve_desc")}
                    </Typography>
                </li>
            </ColoredUl>
            <Typography variant="body1" color="grey" wrapped="true" size="14px">
                {GET_APP_LOCALIZE_TEXT(pageLc, "overview__intention")}
            </Typography>
            <ColoredUl custom_color={'grey'}>
                <li>
                    <Typography variant="body1" color="grey" wrapped="true" size="14px">
                        {GET_APP_LOCALIZE_TEXT(pageLc, "overview__intention_desc")}
                    </Typography>
                </li>
            </ColoredUl>
        </CardContent>
    </Card>);
}

export default AboutReportTab;