import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";

import {
    Box,
    Card as MuiCard,
    CardContent as MuiCardContent, Checkbox,
    Divider as MuiDivider, FormControlLabel,
    Typography as MuiTypography,
} from "@material-ui/core";


import { spacing } from "@material-ui/system";
import {withStyles, makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const Card = styled(MuiCard)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;
  background-color: ${(props) => props.theme.palette.background.paper};
`;


const ColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
`

const ColouredCard = styled(Card)`
  background-color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  padding: 10px 10px 10px 10px;
  height: 100%;
  border-radius: 10px 0px 0px 10px;
`

const useCheckBoxStyles = makeStyles({
    root: {
        '&.MuiCheckbox-root': {
            color: (props) => props.color,
        },
        '&.Mui-checked': {
            color: (props) => props.color,
        },
    },
    checked: {},
});

const Divider = styled(MuiDivider)(spacing);

const Bold = (props) => <Box component={'span'} fontWeight="fontWeightBold" {...props}></Box>

export const SidePanel = (props) => {

    const {primaryData, secondaryData, onCheckPrimary = () => {}, onCheckSecondary = () => {}, primaryHeader = "", secondaryHeader = "",
    defaultCheckIndexPrimary = 0, defaultCheckIndexSecondary = 0, primaryColourize = false, primaryColourizeIndex = 0, secondaryColourize = false, secondaryColourizeIndex = 0,
    primaryFooter: PrimaryFooter, primaryFooterData, primaryFooterCallback,
    secondaryFooter: SecondaryFooter, secondaryFooterData, secondaryFooterCallback,
    primaryAction: PrimaryAction, primaryActionData, primaryActionCallback,
    primarySingleSelectIndex, secondarySingleSelectIndex,
    } = props;

    const [primaryCheckboxState, setPrimaryCheckboxState] = useState(
        Object.fromEntries(primaryData.map((primary, index) => index === defaultCheckIndexPrimary ? [primary, true] : [primary, false]))
    );

    const handlePrimaryCheckboxChange = (event) => {

        setPrimaryCheckboxState({ ...primaryCheckboxState, [event.target.name]: event.target.checked });
        onCheckPrimary(event.target.name, event.target.checked, primaryCheckboxState, setPrimaryCheckboxState);
        //Single selection should override all other selections or not

        if(primarySingleSelectIndex != undefined)
        {
            if(event.target.checked)
            {
                if(primaryData.indexOf(event.target.name) == primarySingleSelectIndex) {
                    setPrimaryCheckboxState(Object.fromEntries(primaryData.map(
                        (item, index) => index === primarySingleSelectIndex ? [item, true] : [item, false]
                    )));
                }
                else
                {
                    const first_element = primaryData[primarySingleSelectIndex]
                    setPrimaryCheckboxState((prevState) => ({
                        ...prevState,
                        [first_element]: false,
                    }));
                }
            }
        }
    };

    const [secondaryCheckboxState, setSecondaryCheckboxState] = useState(
        secondaryData ?
        Object.fromEntries(secondaryData.map((secondary, index) => index === defaultCheckIndexSecondary ? [secondary, true] : [secondary, false]))
            : {}
    );

    const handleSecondaryCheckboxChange = (event) => {
        setSecondaryCheckboxState({ ...secondaryCheckboxState, [event.target.name]: event.target.checked });
        onCheckSecondary(event.target.name, event.target.checked, secondaryCheckboxState, setSecondaryCheckboxState);
        //Single selection should override all other selections or not

        if(secondarySingleSelectIndex != undefined)
        {
            if(event.target.checked)
            {
                if(secondaryData.indexOf(event.target.name) == secondarySingleSelectIndex) {
                    setSecondaryCheckboxState(Object.fromEntries(secondaryData.map(
                        (item, index) => index === secondarySingleSelectIndex ? [item, true] : [item, false]
                    )));
                }
                else
                {
                    const first_element = secondaryData[secondarySingleSelectIndex]
                    setSecondaryCheckboxState((prevState) => ({
                        ...prevState,
                        [first_element]: false,
                    }));
                }
            }
        }
    };

    const handlePrimaryFooterChange = (event) => {
        primaryFooterCallback(event, setPrimaryCheckboxState);
    }

    const handleSecondaryFooterChange = (event) => {
        secondaryFooterCallback(event, setSecondaryCheckboxState);
    }

    const handlePrimaryActionButton = (value) => {
        primaryActionCallback(value, setPrimaryCheckboxState);
    }

    const checkBoxClasses = [
        useCheckBoxStyles({color: "#C71585"}),
        useCheckBoxStyles({color: "purple"}),
        useCheckBoxStyles({color: "darkblue"}),
        useCheckBoxStyles({color: "skyblue"}),
        useCheckBoxStyles({color: "#0D98BA"}),
        useCheckBoxStyles({color: "seagreen"}),
        useCheckBoxStyles({color: "#9ACD32"}),
        useCheckBoxStyles({color: "#FFCC00"}),
        useCheckBoxStyles({color: "orange"}),
        useCheckBoxStyles({color: "#FF5349"}),
        useCheckBoxStyles({color: "red"}),
    ]

    const defaultCheckBoxClasses = useCheckBoxStyles({color: "#233044"})

    return (
        <ColouredCard>
            <CardContent id={"PanelContainer"} style={{alignItems: "center"}}>
                <ColouredTypography mb={3} variant="body1" align={"center"}>
                    <Bold>
                        {primaryHeader}
                    </Bold>
                </ColouredTypography>
                {
                    props.primaryData.map(
                        (primary, index) => {
                            return <Grid container justify="space-between" alignItems={"center"}>
                                <Grid item xs={PrimaryAction ? 10 : 12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                className={primaryColourize ? checkBoxClasses[primaryColourizeIndex + index].root : defaultCheckBoxClasses.root}
                                                name={primary}
                                                checked={primaryCheckboxState[primary] || false}
                                                onChange={handlePrimaryCheckboxChange}
                                            />
                                        }
                                        label={primary}
                                    />
                                </Grid>
                                {
                                    PrimaryAction ?
                                        <Grid item xs={2}>
                                            <PrimaryAction {...primaryActionData} value={primary} onClick={() => handlePrimaryActionButton(primary)}></PrimaryAction>
                                        </Grid>
                                        : null
                                }
                            </Grid>
                        },
                    )
                }
                {
                    PrimaryFooter ?
                        <PrimaryFooter {...primaryFooterData} onChange={handlePrimaryFooterChange}/>
                        : null
                }
                {
                    secondaryData && secondaryData.length !== 0?
                    <>
                        {primaryData.length !== 0 || primaryHeader !== "" || PrimaryAction || PrimaryFooter ?
                            <Divider mt={5} mb={5}/>
                            : null
                        }
                        <ColouredTypography mb={3} variant="body1" align={"center"}>
                            <Bold>
                                {secondaryHeader}
                            </Bold>
                        </ColouredTypography>
                        { secondaryData &&
                            secondaryData.map(
                                (secondary, index) => {
                                    return <Box>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    className={secondaryColourize ? checkBoxClasses[secondaryColourizeIndex + index].root : defaultCheckBoxClasses.root}
                                                    name={secondary}
                                                    checked={secondaryCheckboxState[secondary] || false}
                                                    onChange={handleSecondaryCheckboxChange}/>
                                            }
                                            label={secondary}
                                        />
                                    </Box>
                                }
                            )
                        }
                    </>: null
                }
                {
                    SecondaryFooter ?
                        <SecondaryFooter {...secondaryFooterData} onChange={handleSecondaryFooterChange}/>
                        : null
                }
            </CardContent>
        </ColouredCard>
    );
}
