import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";
import { useAuth } from "../../../../context/auth"
import {
    getComprehensivenessData,
} from "../../../../services/partner.service";
import LoaderWithBackDrop from "../../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {
    Paper,
    Box,
    Grid as MuiGrid,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Typography as MuiTypography,
    makeStyles, Button as MuiButton, Divider,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import {Badge} from "../components/Badge.comp";
import LineChart from "../components/LineChart";
import HorizontalBarChart from "../components/HorizontalBarChart";
import XLSX from "xlsx";
import {useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT} from "../../../../utils/dq_lc_service/lc_service";
import {SidePanel} from "../../../../components/SidePanel/SidePanel.comp";
import {toastDanger} from "../../../../utils/utils";
import {CustomSelect} from "../../Baselining/components/Select.comp";

const useStyles = makeStyles(theme => ({
    stretch: { height: "100%" },
    item: { display: "flex", flexDirection: "column" } // KEY CHANGES
}));

const Grid = styled(MuiGrid)(spacing);

const Button = styled(MuiButton)(spacing);

const Card = styled(MuiCard)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.custom_color ? props.custom_color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  text-align: ${(props) => props.textalign ? props.textalign : "left"};
  font-weight: ${(props) => props.fontWeight ? props.fontWeight : "default"};
`

const Spacer = styled.div(spacing);

const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.custom_color ? props.custom_color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
  font-weight: ${(props) => props.weight ? props.weight : "default"};
`



const Bold = (props) => <Box component={'span'} fontWeight="fontWeightBold" {...props}></Box>
const Italicize = (props) => <Box component={'span'} fontStyle="italic" {...props}></Box>
const Font = (props) => <Box component={'span'} fontFamily={props.font} {...props}></Box>

function ComprehensiveTab() {

    const {authUser} = useAuth();

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState('');
    const primarySideHeader = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_country")
    const [selectedDCPrograms, setSelectedDCPrograms]  = useState([]);
    const [defaultDCProgram, setDefaultDCProgram] = useState([]);
    const [additionalDCProgram, setAdditionalDCProgram] = useState([]);

    const addItemToState = (setState, item, sort = false) => {
        if(sort)
            setState((prevState) => ([...prevState, item].sort()));
        else
            setState((prevState) => ([...prevState, item]));
    }

    const removeItemFromState = (setState, item) => {
        setState((prevState) => {
            const index = prevState.indexOf(item);
            const temp = [...prevState];
            temp.splice(index, 1);
            return temp;
        });
    }

    useEffect( () => {
        loadPageData().then(d => console.log('Program Overview Data Fetched!', d));
    }, []);

    const loadPageData = async () => {
        setLoading(true);
        try {
            const result = await getComprehensivenessData(authUser.org_code);
            if (result.data) {
                console.log('Result Data of Comprehensiveness --> ', result.data);
                setPageData(result.data);
            }
            else {
                setErrorMessage(GET_APP_LOCALIZE_TEXT(pageLc, "general__data_unavailable"));
            }

        } catch (e) {
            console.log("Some error occurred while fetching comprehensiveness data")
        }
        const dcProgramsArray = [];
        const additionalDCProgramArray = [];
        // dcProgramsArray.push(`All Programs`);

        for (let i = 0; i < 3; i++) {
            dcProgramsArray.push(`DC Program ${i + 1}`);
        }

        for (let i = 3; i < 8; i++) {
            additionalDCProgramArray.push(`DC Program ${i + 1}`);
        }

        setDefaultDCProgram(dcProgramsArray);
        setAdditionalDCProgram(additionalDCProgramArray)
        setSelectedDCPrograms(dcProgramsArray);
        setTimeout(() => {setLoading(false)}, 200);
    }

    const onClickDownload = (event) => {
        const workbook = XLSX.utils.book_new();
        const final_array = []
        let max_column_width = 10;
        const columns = ["Category", "DQ Code", "Comprehensiveness"]
        final_array.push(columns)
        pageData.digital.concat(pageData.beyond).filter((item) => item.Value !== 0).forEach((item) => {
            final_array.push([item["Comprehensiveness"], item["DQ#"], item["Value"]])
            max_column_width = Math.max(max_column_width, item["Comprehensiveness"].length, item["DQ#"].length)
        })
        const worksheet = XLSX.utils.aoa_to_sheet(final_array);
        worksheet["!cols"] = columns.map((column, index) => {
            return { wch: index == 0 ? max_column_width : column.length }
        });
        XLSX.utils.book_append_sheet(workbook, worksheet, "Comprehensiveness");
        XLSX.writeFile(workbook, "Assessment Content.xlsx");
    }

    const badge_data = [
        {
            title: GET_APP_LOCALIZE_TEXT(pageLc, "assessment__comprehensive_score"),
            value: pageData ? pageData.score : "0",
        },
    ]

    const mainChart = {
        header: "",
        labels: pageData ? pageData.digital.filter((item) => item.Value !== 0).map((item) => item["DQ#"]) : ["DQ 1", "DQ 2", "DQ 3", "DQ 4", "DQ 5", "DQ 6", "DQ 7", "DQ 8"],
        data: pageData ? pageData.digital.filter((item) => item.Value !== 0).map((item) => item.Value*100) : [0, 0, 0, 0, 0, 0, 0, 0, 0],
    }

    const secondChart = {
        header: "",
        labels: pageData ? pageData.beyond.filter((item) => item.Value !== 0).map((item) => item["DQ#"]) : ["DQ 9", "DQ 10", "DQ11", "DQ 12", "DQ 13", "DQ 14", "DQ 15", "DQ 16"],
        data: pageData ? pageData.beyond.filter((item) => item.Value !== 0).map((item) => item.Value*100) : [0, 0, 0, 0, 0, 0, 0],
    }

    function headers_callback(value, index) {
        if(index === 0)
            return GET_APP_LOCALIZE_TEXT(pageLc, "assessment__less_cov");
        else if(index === 10)
            return GET_APP_LOCALIZE_TEXT(pageLc, "assessment__more_cov");
        else
            return "";
    }

    function headers_callback2(value, index) {
        return value;
    }

    function onSelectDCProgram(item, isChecked) {
        setLoading(true);
        const itemID = item;

        if (isChecked) {
            if (!selectedDCPrograms.includes(itemID)) {
                addItemToState(setSelectedDCPrograms, itemID);
            }
        } else {
            if (selectedDCPrograms.includes(itemID) && !defaultDCProgram.includes(itemID)) {
                removeItemFromState(setSelectedDCPrograms, itemID);
                addItemToState(setAdditionalDCProgram, itemID);
            }
        }
        setTimeout(() => {setLoading(false)}, 200);
    }

    function onAddDCProgram(event, setState) {
        setLoading(true);
        if(selectedDCPrograms.length === 8)
        {
            toastDanger(GET_APP_LOCALIZE_TEXT(pageLc, "general__error"), GET_APP_LOCALIZE_TEXT(pageLc,"baselining__max_added_error"));
        } else {
            const program = event.target.value;
            removeItemFromState(setAdditionalDCProgram, program)
            // addItemToState(setSelectedDCPrograms, program)
            onSelectDCProgram(program, true)
            setState((prevState) => ({
                ...prevState,
                [program]: true,
            }));
        }
        setTimeout(() => {
            setLoading(false)
        }, 200);
    }

    return (
        pageData ?
        <Paper>
            <Grid container>
                <Grid item xs={12} sm={12} md={3}>
                    <SidePanel
                        primaryHeader={primarySideHeader}
                        primaryData={selectedDCPrograms}
                        onCheckPrimary={onSelectDCProgram}
                        primaryColourize={true}
                        primaryFooter={CustomSelect}
                        primaryFooterData={{
                            title: "Add Program",
                            data: additionalDCProgram,
                        }}
                        primaryFooterCallback={onAddDCProgram}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={9}>
            <Card>
                <CardContent>
                    <Box sx={{ flexGrow: 1 }} align={"right"}>
                        <Grid container align={"center"} alignItems={"center"}>
                            {/*{badge_data.map((badge, index) => (*/}
                            {/*        <Grid key={index} item xs={12} sm={5} md={4} lg={3}>*/}
                            {/*            <Badge*/}
                            {/*                title={badge.title}*/}
                            {/*                value={badge.value.toFixed(2)}*/}
                            {/*            />*/}
                            {/*            <Typography>*/}
                            {/*                {GET_APP_LOCALIZE_TEXT(pageLc, "overview__out_of")} 5.00*/}
                            {/*            </Typography>*/}
                            {/*        </Grid>*/}
                            {/*    )*/}
                            {/*)}*/}
                            <Grid item xs sm md lg>
                                <ColouredTypography my={5} mr={10} pl={8} variant={"body1"} custom_color={"dimgrey"} size={"16px"} textalign={"justify"}>
                                    {GET_APP_LOCALIZE_TEXT(pageLc, "assessment__comprehensive_desc")}
                                </ColouredTypography>
                            </Grid>
                        </Grid>
                        <Spacer mb={5}/>

                        {/*MainChart*/}
                        <Grid container alignItems={"center"}>
                            <Grid item xs={2} sm={3} md={4} lg={3}>
                                <ColouredTypography variant={"body1"} textalign={"right"} fontWeight={"bold"} mr={5}>
                                    {GET_APP_LOCALIZE_TEXT(pageLc, "assessment__dig_citizen")}
                                </ColouredTypography>
                            </Grid>
                            <Grid item xs={10} sm={9} md={8} lg={9}>
                                <ColouredTypography variant={"h4"} textalign={"center"}>
                                    {GET_APP_LOCALIZE_TEXT(pageLc, "assessment__curriculum_cov")}
                                </ColouredTypography>
                                <HorizontalBarChart
                                    labels={mainChart.labels}
                                    values={mainChart.data}
                                    // color={true}
                                    // threshold={50}
                                    datalabels={true}
                                    percentage={true}
                                    xtickslabels={true}
                                    suggestedMax={100}
                                    // barsGap={65}
                                    endGap={40}
                                    headers_callback={headers_callback}
                                />
                                <Spacer px={11.5}>
                                    <hr/>
                                </Spacer>
                            </Grid>
                        </Grid>
                        <Grid container alignItems={"center"}>
                            <Grid item xs={2} sm={3} md={4} lg={3}>
                                <ColouredTypography variant={"body1"} textalign={"right"} fontWeight={"bold"} mr={5}>
                                    {GET_APP_LOCALIZE_TEXT(pageLc, "assessment__dig_creative")}
                                </ColouredTypography>
                            </Grid>
                            <Grid item xs={10} sm={9} md={8} lg={9} mt={-2}>
                                <HorizontalBarChart
                                    labels={secondChart.labels}
                                    values={secondChart.data}
                                    // color={true}
                                    // threshold={50}
                                    datalabels={true}
                                    percentage={true}
                                    xtickslabels={false}
                                    suggestedMax={100}
                                    // barsGap={65}
                                    endGap={40}
                                    headers_callback={headers_callback2}
                                    titleX={GET_APP_LOCALIZE_TEXT(pageLc, "assessment__cov_percent")}
                                />
                            </Grid>
                        </Grid>
                        <Button mt={5} variant="contained" onClick={onClickDownload}>
                            {GET_APP_LOCALIZE_TEXT(pageLc, "general__download_data")}
                        </Button>
                    </Box>
                </CardContent>
            </Card>
            </Grid>
            </Grid>
            <LoaderWithBackDrop loading={loading} />
        </Paper>
        :
        <Paper>
            <DefaultColouredTypography variant={"h3"} align={'center'} py={5}>{errorMessage}</DefaultColouredTypography>
            <LoaderWithBackDrop loading={loading}/>
        </Paper>
    );
}

export default ComprehensiveTab;