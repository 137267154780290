import React, {useEffect, useMemo, useRef, useState} from "react";
import {useLocation} from "react-router-dom";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet";

import {
    Grid,
    Card as MuiCard,
    Divider as MuiDivider,
    Typography as MuiTypography,
    CardContent as MuiCardContent,
    Box,
    Paper as MuiPaper,
    Container, Button as MuiButton,

} from "@material-ui/core";


import {useAuth} from "../../../../../context/auth";
import LoaderWithBackDrop from "../../../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {GET_APP_LOCALIZE_TEXT} from "../../../../../utils/dq_lc_service/lc_service";

import {spacing} from "@material-ui/system";
import MonthRangePicker from "../../../../../components/DatePickers/MonthRangePicker.comp";
import {X} from "react-feather";
import {CustomSelect} from "../../components/Select.comp";
import {Badge} from "../../components/Badge.comp";
import BarChart from "../../components/BarChart";
import XLSX from "xlsx";
import {COUNTRY_CONFIGS} from "../../../../../utils/constants";
import {GenericSidePanel} from "../../../../../components/SidePanel/GenericSidePanel.comp";
import {
    alphaNumericSort,
    createLcKey,
    getMapFromObjectListByKey,
    getUniqueValues,
    isDQWEnabled
} from "../../../../../utils/utils";
import getDummyAdoptionData from "../../data/v2/adoption_data";
import {getAssessmentAdoption} from "../../../../../services/school.service";

const Card = styled(MuiCard)(spacing);

const Typography = styled(MuiTypography)(spacing);

const Button = styled(MuiButton)(spacing);

const Paper = styled(MuiPaper)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;
`;

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-weight: ${(props) => props.weight ? props.weight : "default"};
`

const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.palette.tertiary.main};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
  font-weight: ${(props) => props.weight ? props.weight : "default"};
`

const Spacer = styled.div(spacing);
const ColouredCard = styled(Card)`
  background-color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  padding: 10px 10px 10px 10px;
`

const Bold = (props) => <Box component={'span'} fontWeight="fontWeightBold" {...props}></Box>
const Italicize = (props) => <Box component={'span'} fontStyle="italic" {...props}></Box>
const Font = (props) => <Box component={'span'} fontFamily={props.font} {...props}></Box>

const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

const age_groups = {
    'Below 9yo' : "Below 9",
    '10-12yo': "10-12",
    '13-14yo': "13-14",
    '15-16yo': "15-16",
    'above 16yo': "16+",
}

function splitList(list, min_count)
{
    const sublist = []
    const length = list.length;

    sublist.push(list.slice(0, Math.min(min_count, list.length)));
    sublist.push(list.slice(Math.min(min_count, list.length), length));
    return sublist;
}

function Adoption() {

    const { authUser } = useAuth();
    const { org_code, institute_code, org_obj } = authUser;
    const dqwEnabled = isDQWEnabled(org_obj);

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    //global data
    const [selectorsData, setSelectorsData] = useState(undefined);
    const [pageData, setPageData] = useState(undefined);

    //selectors data
    const [participantTypes, setParticipantTypes] = useState([]);
    const [regions, setRegions] = useState([]);
    const [timePeriods, setTimePeriods] = useState([]);

    const subListDivision = 3;
    const [defaultRegions, setDefaultRegions] = useState([]);
    const [additionalRegions, setAdditionalRegions] = useState([]);

    //selector states
    const [selectedRegions, setSelectedRegions] = useState([]);
    const [selectedTime, setSelectedTime] = useState([undefined, undefined]);

    //misc states
    const [minDate, setMinDate] = useState(null);
    const [maxDate, setMaxDate] = useState(null);
    const [datePicker, setDatePicker] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [firstLoad, setFirstLoad] = useState(true);
    const [loading, setLoading] = useState(false);

    const [regionState, setRegionState] = useState(undefined);
    const [timeState, setTimeState] = useState(undefined);

    const [mainChart, setMainChart] = useState(undefined);
    const [secCharts, setSecCharts] = useState([]);

    const primarySideHeader = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_school")
    const secondarySideHeader= GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_time")

    const selectAllRegionsOption = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__all_school")
    const selectAllTimesOption = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__all_year")

    const getDateRangeOfYears = (years) => {
        const [startYear, endYear] = [years[0], years[years.length - 1]];
        const [startDate, endDate] = [new Date(`${startYear}-01-01`).toISOString(), new Date(`${endYear}-12-31`).toISOString()]
        return [startDate, endDate]
    }

    useEffect(() => {
        if(selectorsData)
        {

        }
        else if(!firstLoad)
        {
            setSelectedRegions([]);
            setSelectedTime([undefined, undefined]);
            setRegions([]);
            setTimePeriods([]);
            setMinDate(undefined);
            setMaxDate(undefined);
        }
    }, [selectorsData])

    useEffect(() => {
        if(firstLoad) {
            loadSelectors().then(d => console.log('Assessment Adoption Selectors Fetched', d))
            setFirstLoad(false);
        }
    }, []);

    const loadSelectors = async () => {
        setLoading(true);
        try {
            const result = await getAssessmentAdoption(org_code, institute_code);
            if(result.data)
            {
                const {selectors, data} = result.data;
                const {schools} = selectors;
                //Initialize Regions
                initializeRegions(schools);
                const {types} = selectRegions(schools, false, selectors);
                //Initialize Main Chart and Sec Charts
                loadCharts(data, types);
                //Set Selectors Data and Page Data
                setSelectorsData(selectors);
                setPageData(data);
            }
        }
        catch (e) {
            console.log("Some error occurred while fetching assessment adoption selectors", e)
        }
        setTimeout(() => {setLoading(false)}, 200);
        return true;
    }

    const loadPageData = async (_regions = selectedRegions, time = selectedTime, types = participantTypes) => {
        setLoading(true);
        try {
            setPageData(undefined);
            const result = await getAssessmentAdoption(org_code, _regions, time);
            if (result.data) {
                const {data} = result.data;
                loadCharts(data, types);
                setPageData(data);
            }
            else {
                setErrorMessage(GET_APP_LOCALIZE_TEXT(pageLc, "general__data_unavailable"));
            }
        } catch (e) {
            console.log("Some error occurred while fetching assessment adoption data")
        }
        setTimeout(() => {setLoading(false)}, 200);
        return true;
    }

    function loadCharts(page_data = pageData, participant_types = participantTypes)
    {
        if(page_data)
        {
            console.log("Charts Loaded For Page Data", page_data, participantTypes);
            setMainChart({
                labels: page_data.created_month_year ? page_data.created_month_year.map((item) => `${GET_APP_LOCALIZE_TEXT(pageLc, `stats__${createLcKey(months[item._id.month - 1])}`, months[item._id.month - 1])} '${item._id.year.toString().slice(-2)}`) : [],
                datasets: participant_types.map((type) => ({
                    label: type,
                    data: page_data.created_month_year ? page_data.created_month_year.map((item) => item.count[type] ? item.count[type] : 0) : []
                }))
            })
            setSecCharts([
                {
                    header: GET_APP_LOCALIZE_TEXT(pageLc, "assessment__by_school_lvl"),
                    labels: page_data.school_level ? page_data.school_level.filter(item => item._id).map((item) => GET_APP_LOCALIZE_TEXT(pageLc, `stats__${createLcKey(item._id)}`, item._id)) : [],
                    datasets: participant_types.map((type) => ({
                        label: type,
                        data: page_data.school_level ? page_data.school_level.filter(item => item._id).map((item) => item.count[type] ? item.count[type] : 0) : []
                    }))
                },
                {
                    header: GET_APP_LOCALIZE_TEXT(pageLc, "assessment__by_gender"),
                    labels: page_data.gender ? page_data.gender.filter(item => item._id).map((item) => GET_APP_LOCALIZE_TEXT(pageLc, `stats__${createLcKey(item._id)}`, item._id)) : [],
                    datasets: participant_types.map((type) => ({
                        label: type,
                        data: page_data.gender ? page_data.gender.filter(item => item._id).map((item) => item.count[type] ? item.count[type] : 0) : []
                    }))
                },
                {
                    header: GET_APP_LOCALIZE_TEXT(pageLc, "assessment__by_age"),
                    labels: page_data.age_cat ? page_data.age_cat.filter(item => item._id).map((item) => age_groups[item._id] ? age_groups[item._id] : item._id).map((item) => GET_APP_LOCALIZE_TEXT(pageLc, `stats__${createLcKey(item)}`, item)): [],
                    datasets: participant_types.map((type) => ({
                        label: type,
                        data: page_data.age_cat ? page_data.age_cat.filter(item => item._id).map((item) => item.count[type] ? item.count[type] : 0) : []
                    }))
                }
            ])
        }
        else if(!firstLoad)
        {
            setMainChart(undefined);
            setSecCharts([]);
        }
    }

    function initializeRegions(_regions) {
        setRegions(_regions);
        const regionSubLists = splitList([selectAllRegionsOption, ..._regions], subListDivision)
        setDefaultRegions(regionSubLists[0]);
        setAdditionalRegions(regionSubLists[1])
    }

    function initializeYears(schools, selectors = selectorsData) {
        const {years} = selectors;
        let date_range = [undefined, undefined];

        const unique_years = getUniqueValues(schools.map((school) => years[school])).map((year) => year.toString());
        setTimePeriods(unique_years);
        setTimeState(Object.fromEntries([selectAllTimesOption, ...timePeriods].map(
            (time) => time === selectAllTimesOption ? [time, true] : [time, false]
        )));
        if(unique_years.length > 0) {
            date_range = getDateRangeOfYears(unique_years);
            const [start_date_iso, end_date_iso] = date_range;
            setMinDate(new Date(start_date_iso));
            setMaxDate(new Date(end_date_iso));
            selectTimePeriod(date_range, false);
        }

        return date_range;
    }

    function initializeParticipantTypes(schools, selectors = selectorsData) {
        const {participant_types} = selectors

        const types = schools.map((school) => participant_types[school]);
        const unique_types = getUniqueValues(types).filter((type) => dqwEnabled || !type.toLowerCase().includes("dqw"));
        setParticipantTypes(unique_types);
        return unique_types;
    }

    function selectRegions(_regions, fetch = false, selectors = selectorsData) {
        setSelectedRegions(_regions);
        const types = initializeParticipantTypes(_regions, selectors);
        const date_range = initializeYears(_regions, selectors);
        if(fetch)
        {
            loadPageData(_regions, date_range, types).then(d => console.log('Assessment Adoption Data Fetched - By Selection of Region', d));
        }
        return {types, date_range};
    }

    function selectTimePeriod(_timePeriods, fetch = false)
    {
        setSelectedTime(_timePeriods);
        if(fetch)
        {
            loadPageData(selectedRegions, _timePeriods).then(d => console.log('Assessment Adoption Data Fetched - By Selection of Time Period', d));
        }
    }

    const addItemToState = (item, state, setState, sort = false) => {
        if(sort)
        {
            if(Array.isArray(item)) //in case item is an array, iterate
                setState([...state, ...item].sort());
            else
                setState([...state, item].sort());
        }
        else
        {
            if(Array.isArray(item)) //in case item is an array, iterate
                setState([...state, ...item]);
            else
                setState([...state, item]);
        }
    }

    const removeItemFromState = (item, state, setState) => {
        const temp = [...state];
        if(Array.isArray(item)) //in case item is an array, iterate
        {
            item.forEach((i) => {
                const index = temp.indexOf(i);
                if(index !== -1)
                    temp.splice(index, 1);
            })
        }
        else
        {
            const index = temp.indexOf(item);
            if(index !== -1)
                temp.splice(index, 1);
        }
        setState(temp);
        return temp;
    }

    function onAddRegion(event, state, setState) {
        const item = event.target.value
        removeItemFromState(item, additionalRegions, setAdditionalRegions);
        addItemToState(item, defaultRegions, setDefaultRegions);
        const newState = {...state, [item]: true}
        setState(newState);
        onSelectRegion(item, true, newState, setState);
    }

    function onRemoveRegion(item, state, setState) {
        removeItemFromState(item, defaultRegions, setDefaultRegions)
        addItemToState(item, additionalRegions, setAdditionalRegions, true)
        const newState = {...state, [item]: false}
        setState(newState);
        onSelectRegion(item, false, newState, setState);
    }

    function onSelectRegion (item, isChecked, state, setState) {

        function updateStateToSingleSelect() {
            setState({...state, ...Object.fromEntries(defaultRegions.map(
                (region) => region === item ? [region, true] : [region, false]
            ))});
        }

        if(isChecked) {
            if(item === selectAllRegionsOption)
            {
                selectRegions([...regions], true)
                updateStateToSingleSelect();
            }
            else
            {
                if(selectedRegions.includes(item)) //if already selected, then all regions was selected before, clear all
                {
                    selectRegions([item], true)
                    updateStateToSingleSelect();
                }
                else
                {
                    selectRegions([...selectedRegions, item], true)
                }
            }
        }
        else
        {
            if(item === selectAllRegionsOption)
            {
                selectRegions([], true);
            }
            else {
                if (selectedRegions.includes(item)) {
                    let copyArray = [...selectedRegions]
                    copyArray.splice(copyArray.indexOf(item), 1)
                    selectRegions(copyArray, true);
                }
            }
        }
    }

    function onSelectTimePeriod (item, isChecked, state, setState) {
        if(isChecked)
        {
            if(item === selectAllTimesOption)
            {
                const date_range = getDateRangeOfYears(timePeriods);
                selectTimePeriod(date_range, true)
            }
            else
            {
                const date_range = getDateRangeOfYears([item]);
                selectTimePeriod(date_range, true)
            }
            setState(Object.fromEntries([selectAllTimesOption, ...timePeriods].map(
                (time) => time === item ? [time, true] : [time, false]
            )));
        }
        else
        {
            setState({
                ...state,
                [item]: true,
            });
        }
    }

    function onClickDatePicker(event, state, setState) {
        setState(Object.fromEntries([selectAllTimesOption, ...timePeriods].map(
            (time) => [time, false]
        )));
    }

    function onSelectDateRange(dates) {

        const start_date = new Date(Date.parse(dates[0])).toISOString();
        const end_date = new Date(Date.parse(dates[1])).toISOString();
        // console.log(start_date, end_date);
        selectTimePeriod([start_date, end_date], true)
    }

    return (
        <>
            <Helmet title={`${GET_APP_LOCALIZE_TEXT(pageLc, "navigation__assessment")} ${GET_APP_LOCALIZE_TEXT(pageLc, "navigation__reach")}`}/>
            <Box mx={"3vw"}>
                <Box mb={5}>
                    <DefaultColouredTypography variant="h2">
                        {GET_APP_LOCALIZE_TEXT(pageLc, "overview__section_2")}: {GET_APP_LOCALIZE_TEXT(pageLc, "navigation__assessment")}
                    </DefaultColouredTypography>
                    <ColouredTypography variant={"body1"} size={"16px"}>
                        {GET_APP_LOCALIZE_TEXT(pageLc, "overview__assessment_desc")}
                    </ColouredTypography>
                </Box>
                <Box mb={7}>
                    <ColouredTypography variant="h6" color={"#25b7db"} size={"16px"} weight={"bold"}>
                        {`2.1 ${GET_APP_LOCALIZE_TEXT(pageLc, "navigation__reach")} ${GET_APP_LOCALIZE_TEXT(pageLc, "navigation__assessment")}: `}
                        <ColouredTypography variant="body1" fontWeight="fontWeightRegular" component={"span"} size={"16px"}>
                            {GET_APP_LOCALIZE_TEXT(pageLc, "assessment__reach_desc")}
                        </ColouredTypography>
                    </ColouredTypography>
                </Box>
            </Box>
            <Paper container>
                <Grid container>
                    <Grid item xs={12} sm={12} md={3}>
                        <GenericSidePanel
                            data={[defaultRegions, [selectAllTimesOption, ...timePeriods]]}
                            headers = {[primarySideHeader, secondarySideHeader]}
                            onCheckCallbacks = {[onSelectRegion, onSelectTimePeriod]}
                            colourize = {[false, false]}
                            actions = {[X, undefined]}
                            actionsData = {[{style: {cursor: "pointer"}}, undefined]}
                            actionsCallbacks = {[onRemoveRegion, undefined]}
                            footers={[CustomSelect, MonthRangePicker]}
                            footersData = {[
                                {
                                    title: GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__add_school"),
                                    data: additionalRegions,
                                },
                                {onChangeDate: onSelectDateRange, minDate, maxDate}
                            ]}
                            footersCallbacks = {[{onChange: onAddRegion}, {onOpen: onClickDatePicker}]}
                            defaultCheckIndices = {[0, 0]}
                            singleSelectIndices = {[undefined, undefined]}
                            stateCallbacks = {[
                                {
                                    state: regionState,
                                    setState: setRegionState
                                },
                                {
                                    state: timeState,
                                    setState: setTimeState
                                }
                            ]}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={9} alignItems={!pageData ? 'center' : ''} container={!pageData} justify={'center'}>
                        {
                            pageData ?
                                <Card>
                                    <CardContent>
                                        <Box sx={{flexGrow: 1}} align={"right"}>
                                            <ColouredTypography variant={"body1"} size={"16px"} color={"dimgrey"} align={"center"} alignText={"justify"} my={5}>
                                                {GET_APP_LOCALIZE_TEXT(pageLc, "assessment__school_participation_desc")}
                                            </ColouredTypography>
                                            <ColouredTypography my={5} variant={"h4"} align={"center"}>
                                                {GET_APP_LOCALIZE_TEXT(pageLc, "assessment__participation")}
                                            </ColouredTypography>
                                            <Grid container spacing={3} align={"center"} alignItems={"center"}>
                                                { pageData.Total ? pageData.Total.map((badge) => (
                                                        <Grid item xs={12} sm={5} md={12} lg={3}>
                                                            <Badge
                                                                title={GET_APP_LOCALIZE_TEXT(pageLc, "assessment__total_participation")}
                                                                value={badge.count}
                                                            />
                                                        </Grid>
                                                    )
                                                ) : null}
                                                <Grid item xs={12} sm={7} md={12} lg={9}>
                                                    <BarChart
                                                        labels={mainChart ? mainChart.labels : []}
                                                        datasets={mainChart ? mainChart.datasets: []}
                                                        //line_values={mainChart.line_data}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <ColouredTypography variant={"body1"} size={"16px"} color={"dimgrey"}
                                                                align={"center"} alignText={"justify"} my={5}>
                                                {GET_APP_LOCALIZE_TEXT(pageLc, "assessment__inclusive_school_lvl_desc")}
                                            </ColouredTypography>
                                            <Spacer my={5}/>
                                            <Grid container spacing={5} align={"center"} alignItems={"center"} justify={"center"}>
                                                {secCharts.map((chart,_, arr) => (
                                                        <Grid item xs={12} sm={12} md={6} lg={Math.round(12/(arr.length-1))} xl={Math.round(12/arr.length)}>
                                                            <Paper variant={"elevation"} py={5}>
                                                                <BarChart
                                                                    header={chart.header}
                                                                    labels={chart.labels}
                                                                    datasets={chart.datasets}
                                                                    legend={false}
                                                                />
                                                            </Paper>
                                                        </Grid>
                                                    )
                                                )}
                                            </Grid>
                                            {/*<Button mt={10} variant="contained" onClick={onClickDownload}>*/}
                                            {/*    Download Data*/}
                                            {/*</Button>*/}
                                        </Box>

                                    </CardContent>
                                </Card>
                                :
                                <DefaultColouredTypography variant={"h3"} align={'center'}>{errorMessage}</DefaultColouredTypography>
                        }
                    </Grid>
                </Grid>
            </Paper>
            <Box mx={"3vw"} my={"2vw"}>
                <ColouredTypography variant={"body1"} size={"16px"} align={"center"}>
                    {`${GET_APP_LOCALIZE_TEXT(pageLc, "assessment__footer")} `}
                    <a href={"/school/enhancement/reach"} style={{color: "#25b7db"}}>
                        {GET_APP_LOCALIZE_TEXT(pageLc, "assessment__enhancement_reach")}
                    </a>.
                </ColouredTypography>
            </Box>
            <LoaderWithBackDrop loading={loading}/>
        </>
    );
}

export default Adoption;
