import React from "react";
import {useLocation} from "react-router-dom";
import styled from "styled-components/macro";

import orange from "@material-ui/core/colors/orange";
import { CardContent, Card as MuiCard, Typography } from "@material-ui/core";
import { spacing } from "@material-ui/system";

import { HorizontalBar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {GET_APP_LOCALIZE_TEXT} from "../../../../utils/dq_lc_service/lc_service";

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

const ChartWrapper = styled.div`
  width: 100%;
  padding: 0px 0px 0px 0px;
`;

const BarChart = (props) => {

    const {
        labels,
        values,
        color= false,
        threshold= undefined,
        datalabels = false,
        percentage = false,
        xtickslabels = false,
        header = "",
        suggestedMax = 5,
        barsGap = 80,
        endGap = 0,
        headers_callback = () => {},
        titleX = undefined,
        titleY = undefined
    } = props;

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];


    const colors = []

    values.forEach((value) => {
        if(color && value < ( threshold ? threshold : (Math.max(...values)/2) ))
            colors.push("#ff69b4")
        else
            colors.push("#25b7db")
    })

    function splitString (n,str){
        let arr = str?.split(' ');
        let result=[]
        let subStr=arr[0]
        for(let i = 1; i < arr.length; i++){
            let word = arr[i]
            if(subStr.length + word.length + 1 <= n){
                subStr = subStr + ' ' + word
            }
            else{
                result.push(subStr);
                subStr = word
            }
        }
        if(subStr.length){result.push(subStr)}
        return result
    }


    const data = {
        labels: labels.map((label) => splitString(30, label)),
        // #FF1B0A
        datasets: [
            {
                label: '',
                backgroundColor: colors,
                borderColor: colors,
                hoverBackgroundColor: colors,
                hoverBorderColor: colors,
                data: values,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        legend: {
            display: false,
        },
        scales: {
            yAxes: [
                {
                    scaleLabel: {
                        display: !!titleY,
                        labelString: titleY,
                    },
                    gridLines: {
                        display: false,
                        // color: "transparent",
                    },
                    // display: false,
                    stacked: false,
                    barThickness: "25",
                },
            ],
            xAxes: [
                {
                    scaleLabel: {
                        display: !!titleX,
                        labelString: titleX,
                    },
                    stacked: false,
                    gridLines: {
                        display: true,
                        // color: "transparent",
                    },
                    position: xtickslabels ? 'top' : 'bottom',
                    ticks: {
                        stepSize: 10,
                        beginAtZero: true,
                        display: true,
                        suggestedMin: 0,
                        suggestedMax: suggestedMax,
                        maxRotation: 0,
                        minRotation: 0,
                        autoSkip: false,
                        callback: function(value, index, ticks) {
                            return headers_callback(value, index);
                        }
                    },
                },
            ],
        },
        layout: {
            padding: {
                right: 50,
            }
        },
        plugins: {
            datalabels: {
                display: datalabels,
                anchor: 'end',
                align: "end",
                formatter: function(value, context) {
                    return Math.round(value) + (percentage ? '%' : '');
                },
                font: {
                    weight: 'bold'
                }
            }
        }
    };

    // const chart_height = labels.length*barsGap-((labels.length - 1) * 30);
    const chart_height = (labels.length * 50) + endGap;

    return (
        <>
            <ChartWrapper style={{height: `${chart_height}px`}}>
                <Typography variant="h4" gutterBottom align={'center'}>
                    {header}
                </Typography>
                <HorizontalBar data={data} plugins={[ChartDataLabels]} options={options}/>
            </ChartWrapper>
        </>
    );
}

export default BarChart;
