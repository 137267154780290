import {store} from "react-notifications-component";

export const toast = (
    {
        type, title, message,
        insert = "bottom", container = "top-right",
        animationIn = ["animated", "fadeIn"], animationOut = ["animated", "fadeOut"],
        duration = 5000, showIcon = true
    }) => {
    store.addNotification({
        title,
        message,
        type,
        insert,
        container,
        animationIn,
        animationOut,
        dismiss: {
            duration,
            showIcon
        }
    });
}

export const toastSuccess = (message) => {
    toast({
        title: "Server Response!",
        message,
        type: "success",
    });
}

export const toastWarning = (message) => {
    toast({
        title: "Server Response!",
        message,
        type: "warning",
    });
}

export const toastDanger = (title, message) => {
    toast({
        title: title,
        message,
        type: "danger",
    });
}

export const getMapFromObjectListByKey = (objectList, mapKey) => {
    let dict = {};
    for (let item of objectList) {
        dict[item[mapKey]] = item;
    }
    return dict;
}

export const getArrayFromMap = (map) => {
    return Object.keys(map).map((k) => map[k])

}

export const getPresenceMap = (simple_list) => {
    let dict = {};
    for (let item of simple_list) {
        dict[item] = true;
    }
    return dict;
}

export function predefinedSort(order_list, key, reverse = false)
{
    const order = order_list.reduce((obj, key, idx) => Object.assign(obj, { [key]: idx + 1}), {});
    const getVal = item => {
        if(key)
            return  order[item[key]] || Infinity
        else //else is redundant here i.e. can be removed
            return order[item] || Infinity
    }

    return (a, b) => {
        const a_index = getVal(a);
        const b_index = getVal(b);

        if(a_index === Infinity && b_index === Infinity) {
            return 0;
        }
        else { //else is redundant here i.e. can be removed
            if(reverse)
            {
                return b_index - a_index;
            }
            else
            {
                return a_index - b_index;
            }
        }
    }
}

export function alphaNumericSort(key = undefined, numeric = false) //numeric makes numbers sort first than alphabets
{
    if(key)
    {
        return (a, b) => a[key].toString().localeCompare(b[key].toString(), undefined, {
            numeric: numeric,
            sensitivity: 'base'
        });
    }
    else
    {
        return (a, b) => a.toString().localeCompare(b.toString(), undefined, {
            numeric: numeric,
            sensitivity: 'base'
        });
    }
}

export function getUniqueValues(array) //also handles getting unique values from nested array
{
    return [...new Set([].concat(...array))];
}

export function argMax(array) //returns index of maximum value in array - fast solution
{
    const length = array.length;
    if (length === 0) {
        return -1;
    }

    let max = array[0];
    let maxIndex = 0;

    for (let i = 1; i < length; i++) {
        if (array[i] > max) {
            maxIndex = i;
            max = array[i];
        }
    }
    return maxIndex;
}

export function getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min) ) + min;
}

export const splitEveryNthPos = function (string, separator, position) {
    const split_array = string.split(separator)
    const split_array_len = split_array.length;
    const final_array = [];
    let buffer = "";
    for(let word_index in split_array){
        const word = split_array[word_index]
        buffer += (word);
        if(buffer.length >= position || parseInt(word_index) === split_array_len-1){ //if buffer has max length possible, or we have reached last index
            final_array.push(buffer)
            buffer = ""
        }
        else {
            buffer += separator
        }
    }
    return final_array;
}

export function showCyberRisk(org_code)
{
    return ![].includes(org_code.toLowerCase());
}

export function showAnalysis()
{
    return process.env.REACT_APP_ENABLE_INDEPTH_ANALYSIS === '1';
}

export function isDQWEnabled(org)
{
    return org && org.dqw_access;
}

export function isEnvDev()
{
    return process.env.REACT_APP_NAME === "LOCAL" || process.env.REACT_APP_NAME === "DEVELOP";
}

export function addItemToState(item, state, setState, sort = false)
{
    if(Array.isArray(state)) //is State an Array
    {
        const newArray = [...state, ...[].concat(item)]; //also handles in case item is an array
        if(sort) newArray.sort();
        setState(newArray);
    }
    else if(typeof state === 'object' && typeof item === 'object') //is State an Object & item an Object
    {
        const newObject = {...state, ...item};
        setState(newObject);
    }
}

export function removeItemFromState(item, state, setState)
{
    if(Array.isArray(state)) //is State an Array
    {
        const newArray = [...state];
        const itemArray = [].concat(item);
        itemArray.forEach((i) => {
            const index = newArray.indexOf(i);
            if(index !== -1) newArray.splice(index, 1);
        })
        setState(newArray);
        return newArray;
    }
    else if(typeof state === 'object') //is State an Object
    {
        const itemObject = typeof item === 'object' ? item : {[item]: undefined}
        const stateObject = {...state, ...itemObject};
        setState(stateObject);
        return stateObject;
    }
}

export function setSingleKeyTrueInState(keys, true_key, setState) {
    const true_obj = true_key ? {[true_key]: true} : {}
    setState({
        ...Object.fromEntries(keys.map((key) => [key, false])),
        ...true_obj
    });
}

export function addTrueKeyInState(true_key, state, setState) {
    const true_obj = true_key ? {[true_key]: true} : {}
    setState({
        ...state,
        ...true_obj
    });
}

export function splitList(list, min_count)
{
    const sublists = []
    const length = list.length;

    sublists.push(list.slice(0, Math.min(min_count, list.length)));
    sublists.push(list.slice(Math.min(min_count, list.length), length));
    return sublists;
}

export function copyToClipboard(textToCopy) {
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard api method
        return navigator.clipboard.writeText(textToCopy);
    } else {
        // text area method
        let textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        // make textarea invisible
        textArea.style.position = "absolute";
        textArea.style.opacity = 0;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
            // here the magic happens
            const result = document.execCommand('copy');
            textArea.remove();
            if(result) {
                res();
            }
            else {
                //fallback for explorer 11 or earlier
                if(window.clipboardData) {
                    window.clipboardData.setData("Text", textToCopy)
                    res();
                }
                else {
                    rej();
                }
            }
        });
    }
}

export function validateKeysSyncing(lc_keys, lc_cat) {
    const {en: en_keys = {}, ...other_lc_keys} = lc_keys;
    const standard_length = Object.keys(en_keys).length;
    for(const [locale, keys] of Object.entries(other_lc_keys)) {
        if (Object.keys(keys).length !== standard_length)
            return console.warn(`lc_values::${lc_cat}:: FATAL ISSUE : ${locale} keys not in sync`);
    }
    return console.warn(`lc_values::${lc_cat}:: keys are in sync`);
}

export function createLcKey(value, prefix = "") {
    return prefix + value.toLowerCase()
        .replaceAll('-', '_')
        .replaceAll(/[.,']/g, '')
        .split(' ').join('_');
}