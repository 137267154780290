import {validateKeysSyncing} from "../utils";

const lc_en = {
    cyber_risk__overall: "Overall Cyber Risks",
    cyber_risk__none: "No Risks",
    cyber_risk__expe_Cvbeh: "Experienced cyber victimization",
    cyber_risk__expo_CyberThreat: "Exposure to cyber threats",
    cyber_risk__risk_DigitalDis: "Have been at high risk for digital media disorder",
    cyber_risk__expe_CyberRisks: "Experienced at least 1 cyber risk",
    cyber_risk__invo_CyberBullying: "Involved with cyber bullying",
    cyber_risk__exce_illegTech: "Used excessively and illegally digital technology",
    cyber_risk__risk_DigitalDisM: "Have been at risk of digital media order",
    cyber_risk__expe_Cbbeh: "Experienced cyber bullying",
    cyber_risk__expo_SexMedia: "Exposed to sexual media content",
    cyber_risk__over_ScrTime: "More than 30 hours per week of screen time for entertainment use",
    cyber_risk__illeg_Social: "Illegally accessed social media",
    cyber_risk__expo_SexConvo: "Exposed to sexual online conversations",
    cyber_risk__risk_GameDisM: "Have been at risk of gaming disorder",
    cyber_risk__expe_OfflineMeeting: "Experienced offline meetings with online strangers",
    cyber_risk__expo_SexConvo_Female: "Exposed to sexual online conversations (Females)",
    cyber_risk__tell_CvNeedHelp: "Need helps when involved with cyber victimization",
    cyber_risk__expo_ViolMedia: "Exposed to violent media content",
    cyber_risk__risk_GameDis: "Have been at high risk for gaming disorder",
    cyber_risk__risk_SocialDis: "Have been at high risk for social media disorder",
    cyber_risk__expo_SexContact: "Exposed to unwanted sexual contact online",
    cyber_risk__risk_SocialDisM: "Have been at risk of social media disorder",
    cyber_risk__tell_NoBeforeMet: "Didn't tell anyone before meeting online strangers and met alone",
    cyber_risk__expo_SexContact_Female: "Exposed to unwanted sexual contact online (Females)"
}


const lc_ko = {

}

const lc_th = {
    cyber_risk__overall: `ความเสี่ยงทางไซเบอร์โดยรวม`,
    cyber_risk__none: `ไม่มีความเสี่ยง`,
    cyber_risk__expe_Cvbeh: `ประสบการณ์การตกเป็นเหยื่อทางไซเบอร์`,
    cyber_risk__expo_CyberThreat: `การเปิดรับภัยคุกคามทางไซเบอร์`,
    cyber_risk__risk_DigitalDis: `มีความเสี่ยงสูงต่อความผิดปกติของสื่อดิจิทัล`,
    cyber_risk__expe_CyberRisks: `มีประสบการณ์ความเสี่ยงทางไซเบอร์อย่างน้อย 1 ครั้ง`,
    cyber_risk__invo_CyberBullying: `เกี่ยวข้องกับการกลั่นแกล้งในโลกไซเบอร์`,
    cyber_risk__exce_illegTech: `ใช้เทคโนโลยีดิจิทัลมากเกินไปและผิดกฎหมาย`,
    cyber_risk__risk_DigitalDisM: `ได้รับความเสี่ยงจากการสั่งสื่อดิจิทัล`,
    cyber_risk__expe_Cbbeh: `ประสบการณ์การกลั่นแกล้งในโลกไซเบอร์`,
    cyber_risk__expo_SexMedia: `เปิดเผยเนื้อหาสื่อทางเพศ`,
    cyber_risk__over_ScrTime: `ใช้เวลาหน้าจอมากกว่า 30 ชั่วโมงต่อสัปดาห์เพื่อความบันเทิง`,
    cyber_risk__illeg_Social: `เข้าถึงสื่อสังคมออนไลน์อย่างผิดกฎหมาย`,
    cyber_risk__expo_SexConvo: `เปิดเผยการสนทนาออนไลน์ทางเพศ`,
    cyber_risk__risk_GameDisM: `มีความเสี่ยงเป็นโรคติดเกม`,
    cyber_risk__expe_OfflineMeeting: `ประสบการณ์การประชุมออฟไลน์กับคนแปลกหน้าออนไลน์`,
    cyber_risk__expo_SexConvo_Female: `เปิดเผยการสนทนาออนไลน์ทางเพศ (หญิง)`,
    cyber_risk__tell_CvNeedHelp: `ต้องการความช่วยเหลือเมื่อเกี่ยวข้องกับการตกเป็นเหยื่อทางไซเบอร์`,
    cyber_risk__expo_ViolMedia: `เปิดเผยเนื้อหาสื่อที่มีความรุนแรง`,
    cyber_risk__risk_GameDis: `มีความเสี่ยงสูงต่อโรคติดเกม`,
    cyber_risk__risk_SocialDis: `มีความเสี่ยงสูงต่อความผิดปกติของโซเชียลมีเดีย`,
    cyber_risk__expo_SexContact: `สัมผัสกับการติดต่อทางเพศที่ไม่พึงประสงค์ทางออนไลน์`,
    cyber_risk__risk_SocialDisM: `มีความเสี่ยงต่อโรคติดโซเชียล`,
    cyber_risk__tell_NoBeforeMet: `ไม่บอกใครก่อนเจอคนแปลกหน้าทางออนไลน์และเจอคนเดียว`,
    cyber_risk__expo_SexContact_Female: `เปิดเผยการติดต่อทางเพศที่ไม่พึงประสงค์ทางออนไลน์ (หญิง)`,
}

const lc_ja={
    cyber_risk__overall: `全体的なインターネットトラブルの概要`,
    cyber_risk__none: `リスクはありません。`,
    cyber_risk__expe_Cvbeh: `インターネット上で何らかの被害を経験したことがあるようです。`,
    cyber_risk__expo_CyberThreat: `インターネット上で何らかの攻撃や脅威にさらされているいるようです。`,
    cyber_risk__risk_DigitalDis: `デジタルメディアに関するトラブルに遭うリスクが高い状態です。`,
    cyber_risk__expe_CyberRisks: `過去に少なくとも1つのインターネットトラブルを経験しています。`,
    cyber_risk__invo_CyberBullying: `ネットいじめに何らかの形で関与している可能性があります。`,
    cyber_risk__exce_illegTech: `過度、もしくは違法なデジタル機器および技術の使用をしたことがあるようです。`,
    cyber_risk__risk_DigitalDisM: `デジタルメディアに関するトラブルに遭うリスクが高い状態です。`,
    cyber_risk__expe_Cbbeh: `ネットいじめの被害にあっている可能性があります。`,
    cyber_risk__expo_SexMedia: `性的なコンテンツにさらされている可能性があります。`,
    cyber_risk__over_ScrTime: `娯楽目的のスクリーンタイムが週30時間以上あります。`,
    cyber_risk__illeg_Social: `ソーシャルメディアに違法にアクセスしたことがあるようです。`,
    cyber_risk__expo_SexConvo: `性的なチャットなどの危険にさらされたことがあるようです。`,
    cyber_risk__risk_GameDisM: `ゲーム依存症などの危険があるようです。`,
    cyber_risk__expe_OfflineMeeting: `オンライン上で出会った他者と実際に会ったことがあるようです。`,
    cyber_risk__expo_SexConvo_Female: `性的なチャットなどの危険にさらされたことがあるようです。（女子の場合）`,
    cyber_risk__tell_CvNeedHelp: `インターネット上で何らかの被害を経験する時、助けが必要と感じているようです。`,
    cyber_risk__expo_ViolMedia: `暴力的なコンテンツにさらされたことがあるようです。`,
    cyber_risk__risk_GameDis: `ゲーム依存症などになる確率がかなり高い状態にあるようです。`,
    cyber_risk__risk_SocialDis: `ソーシャルメディア依存症などになる確率がかなり高い状態にあるようです。`,
    cyber_risk__expo_SexContact: `望まない性的なコメントや声かけなどを受けたことがあるようです。`,
    cyber_risk__risk_SocialDisM: `ソーシャルメディア依存症などになる確率がかなり高い状態にあるようです。`,
    cyber_risk__tell_NoBeforeMet: `誰にも伝えずにオンライン上で出会った他者と一人で実際に会ったことがあるようです。`,
    cyber_risk__expo_SexContact_Female: `望まない性的なコメントや声かけなどを受けたことがあるようです。（女子の場合）`,
}

export const LC_VALUES_BASELINING = {
    'en': lc_en,
    'ko': lc_ko,
    'th': lc_th,
    'ja':lc_ja
}

validateKeysSyncing(LC_VALUES_BASELINING, 'baselining');