import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";
import { useAuth } from "../../../../context/auth"
import {getDigitalSkillsData} from "../../../../services/partner.service";
import LoaderWithBackDrop from "../../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {createLcKey, predefinedSort, toastDanger} from "../../../../utils/utils";
import {DQC24_TITLES} from "../../../../utils/constants";


import {
    Paper,
    Box,
    Grid,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Typography as MuiTypography, Button as MuiButton,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Divider
} from "@material-ui/core";

import {
    X
} from "react-feather";


import {CustomSelect} from "./Select.comp"

import {Badge} from "./Badge.comp"

import { spacing } from "@material-ui/system";
import {GET_APP_LOCALIZE_TEXT} from "../../../../utils/dq_lc_service/lc_service";
import RadarChart from "./RadarChart";
import LineChart from "./LineChart";
import {SidePanel} from "../../../../components/SidePanel/SidePanel.comp";
import XLSX from "xlsx";
import writeFileXLSX from "xlsx";
import {useLocation} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        margin: '15px',
        marginBottom: '0px',
    },
    badge_image: {
        height: '90px',
        //padding: '10px 10px',
        //background: theme.palette.grey[200],
        display: 'block',
        marginRight: 'auto',
    },
    short_name: {
        textAlign: 'left',
        marginTop: '6px',
    },
    full_name: {
        // textAlign: 'left',
        marginTop: '6px',
        //fontSize: '16px',
        //fontWeight: 'bold',
    },
    tableRow: {
        border: '1px solid #ccc'
    },
}));

const Card = styled(MuiCard)(spacing);

const Typography = styled(MuiTypography)(spacing);

const Button = styled(MuiButton)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;
`;

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
`

const Spacer = styled.div(spacing);

const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
`

const ColouredCard = styled(Card)`
  background-color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  padding: 10px 10px 10px 10px;
`

const Bold = (props) => <Box component={'span'} fontWeight="fontWeightBold" {...props}></Box>
const Italicize = (props) => <Box component={'span'} fontStyle="italic" {...props}></Box>
const Font = (props) => <Box component={'span'} fontFamily={props.font} {...props}></Box>

function splitCountries(countries, default_countries = [])
{
    const sublist = []
    const length = countries.length;
    const min_count = 3;

    if(default_countries.length > 0)
    {
        sublist.push(default_countries)
        const skipped_countries_indices = default_countries.map((country) => countries.indexOf(country));
        const stripped_countries = countries.filter((_, index) => !skipped_countries_indices.includes(index))
        sublist.push(stripped_countries);
    }
    else
    {
        sublist.push(countries.slice(0, Math.min(min_count, countries.length)));
        sublist.push(countries.slice(Math.min(min_count, countries.length), length));
    }
    return sublist;
}

function getCountriesAverage(countries, avgData) {
    if(countries.length === 0)
        return 0;
    //Else
    let sum = 0;
    countries.forEach((country, index) => {
        sum += Number(avgData[index])
    })
    const avg = Number((sum / countries.length).toFixed(2));
    return avg;
}

let dq_24_aliases = {}
let dq_24_reverse_aliases = {}
const cat_reverse_translations = {};

const StarComponent = (props) => {
    const { rating, total = 3, ...rest } = props; // Ensure total is default to 3 if not provided
    // Adjusting the rating scale from 0-5 to 0-3
    let starsToDisplay;
    if (rating < 2) starsToDisplay = 0;
    else if (rating < 3) starsToDisplay = 1;
    else if (rating < 4) starsToDisplay = 2;
    else starsToDisplay = 3;

    let star_tags = [];
    // Whole stars
    for (let i = 0; i < starsToDisplay; i++) {
        star_tags.push(
            <div
                key={`star-${i}`}
                style={{ backgroundColor: "#ffc400", display: "inline-flex", width: "20px", height: "20px", clipPath: "polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)" }}
                {...rest}
            />
        );
    }

    // Remaining empty stars
    for (let i = 0; i < (total - starsToDisplay); i++) {
        star_tags.push(
            <div
                key={`empty-${i}`}
                style={{ backgroundColor: "grey", display: "inline-flex", width: "20px", height: "20px", clipPath: "polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)" }}
                {...rest}
            />
        );
    }

    return (
        <>
            {star_tags}
        </>
    );
}


function ProgramImpactRatingTab() {
    const classes = useStyles();

    const {authUser} = useAuth();
    const {org_country} = authUser.org_obj;

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState(undefined);

    const [defaultCountries, setDefaultCountries] = useState([]);
    const [additionalCountries, setAdditionalCountries] = useState([]);
    const [indicators, setIndicators] = useState([]);

    const [selectedIndicators, setSelectedIndicators] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const selectAllIndicatorsOption = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__dq_comp_score")
    const primarySideHeader = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_country")
    const secondarySideHeader= GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_dig_comp")

    const [selectedDCPrograms, setSelectedDCPrograms]  = useState([]);
    const [defaultDCProgram, setDefaultDCProgram] = useState([]);
    const [additionalDCProgram, setAdditionalDCProgram] = useState([]);
    const addItemToState = (setState, item, sort = false) => {
        if(sort)
            setState((prevState) => ([...prevState, item].sort()));
        else
            setState((prevState) => ([...prevState, item]));
    }

    const removeItemFromState = (setState, item) => {
        setState((prevState) => {
            const index = prevState.indexOf(item);
            const temp = [...prevState];
            temp.splice(index, 1);
            return temp;
        });
    }

    useEffect(() => {
        initializeDQCodes();
        loadPageData().then(d => console.log('Digital Competencies Data Fetched!', d));
    }, []);

    const initializeDQCodes = () => {
        const dq24_titles = DQC24_TITLES(pageLc);
        dq_24_aliases = {...Object.fromEntries(Object.keys(dq24_titles).map((key) => [key, dq24_titles[key]]))}
        dq_24_reverse_aliases = {...Object.fromEntries(Object.keys(dq24_titles).map((key) => [dq24_titles[key], key]))}
    }

    function initializeData(data) {
        setSelectedCategories(["Program", "National", "Global"])
        const countriesSubList = splitCountries(data.countries, data.default_countries);
        setDefaultCountries(countriesSubList[0])
        setAdditionalCountries(countriesSubList[1]);
        setSelectedCountries([countriesSubList[0][0]])
        const all_indicators_code = data.categories.find((i) => /.*\D$/.test(i));
        const sorted_indicators = data.categories.sort(predefinedSort([all_indicators_code], undefined, false));
        setSelectedIndicators([sorted_indicators[0]]);
        setIndicators(sorted_indicators);
        setPageData(data);
        const dcProgramsArray = [];
        const additionalDCProgramArray = [];
        dcProgramsArray.push(`All Programs`);

        for (let i = 0; i < 3; i++) {
            dcProgramsArray.push(`DC Program ${i + 1}`);
        }

        for (let i = 3; i < 8; i++) {
            additionalDCProgramArray.push(`DC Program ${i + 1}`);
        }

        setDefaultDCProgram(dcProgramsArray);
        setAdditionalDCProgram(additionalDCProgramArray)
        setSelectedDCPrograms(dcProgramsArray);
    }

    const loadPageData = async () => {
        setLoading(true);
        try {
            const result = await getDigitalSkillsData(authUser.org_code);
            if (result.data) {
                console.log('Result Data of Digital Competencies --> ', result.data);
                initializeData(result.data)
            }
            else {
                setErrorMessage(GET_APP_LOCALIZE_TEXT(pageLc, "general__data_unavailable"));
            }

        } catch (e) {
            console.log("Some error occurred while fetching digital competencies data")
        }
        setTimeout(() => {setLoading(false)}, 200);
    }

    const onClickDownload = (event) => {
        const workbook = XLSX.utils.book_new();
        pageData.categories.forEach((category, category_index) => {
            const final_array = []
            let max_column_width = 10;
            years = pageData.countries.map((country) => pageData.data[category].countries_data[country].annual_scores).reduce(
                (final, current) => {
                    const items_to_add = current.filter((item) => !final.includes(item.year)).map((item) => item.year)
                    return final.concat(items_to_add);
                }, [])
            years.sort(function(a, b){return a - b})
            const columns = ["Country", "Country Average", "Program Average", "National Average", "Global Average", `${category} Indicator Score`]
            columns.push(...years.map((year) => "Annual Score " + String(year)))
            final_array.push(columns)
            pageData.countries.forEach((country) => {
                const country_array = []
                country_array.push(country)
                country_array.push(pageData.data[category].countries_data[country].average)
                country_array.push(pageData.data[category].program_average)
                country_array.push(pageData.data[category].national_average)
                country_array.push(pageData.data[category].global_average)
                country_array.push(pageData.data[category].countries_data[country].indicator_score)
                years.forEach((year) => {
                    const index = pageData.data[category].countries_data[country].annual_scores.findIndex(x => x.year === year);
                    country_array.push( index === -1 ? 0 : pageData.data[category].countries_data[country].annual_scores[index].val)
                })
                final_array.push(country_array)
                max_column_width = Math.max(max_column_width, country.length)
            })
            const worksheet = XLSX.utils.aoa_to_sheet(final_array);
            worksheet["!cols"] = columns.map((column, index) => {
                return { wch: index == 0 ? max_column_width : column.length }
            });
            XLSX.utils.book_append_sheet(workbook, worksheet, category);
        })
        XLSX.writeFile(workbook, "Digital Competencies.xlsx");
    }

    const get_indicator_alias = (code) => {
        if(/.*\D$/.test(code)) return selectAllIndicatorsOption;
        else if(dq_24_aliases[code]) return dq_24_aliases[code];
        return code;
    }

    const get_indicator_reverse_alias = (alias) => {
        if(alias === selectAllIndicatorsOption)
        {
            const indicator = indicators.find((i) => /.*\D$/.test(i))
            if(indicator) return indicator;
        }
        else if(dq_24_reverse_aliases[alias]) return dq_24_reverse_aliases[alias];
        return alias;
    }

    const get_category_alias = (category) => {
        const cat_trans = GET_APP_LOCALIZE_TEXT(pageLc, `baselining__${createLcKey(category)}`, null);
        const country_trans = GET_APP_LOCALIZE_TEXT(pageLc, `dq_country__${createLcKey(category)}`, null);
        const translation = cat_trans || country_trans || category;
        cat_reverse_translations[translation] = category;
        return translation;
    }

    const get_category_reverse_alias = (alias) => {
        const reverse_alias = cat_reverse_translations[alias];
        return reverse_alias || alias;
    }
    const scaleToFive = value => (value / 25);

    const badge_data = [
        {
            title: GET_APP_LOCALIZE_TEXT(pageLc, "baselining__prog_avg"),
            value: pageData && selectedIndicators.length > 0 ? selectedIndicators.reduce((total, current) => {
                return parseFloat(total) + 117;
            }, 0.0) / selectedIndicators.length : 0,
        },
        {
            title: GET_APP_LOCALIZE_TEXT(pageLc, "baselining__nation_avg"),
            subtitle: pageData ? `(${pageData.country})` : undefined,
            value: pageData && selectedIndicators.length > 0 ? selectedIndicators.reduce((total, current) => {
                return parseFloat(total) + 108;
            }, 0.0) / selectedIndicators.length : 0,
        },
        // {
        //     title: GET_APP_LOCALIZE_TEXT(pageLc, "baselining__countries_avg"),
        //     value:
        //         pageData && selectedIndicators.length > 0 && selectedCountries.length > 0 ?
        //         getCountriesAverage(selectedCountries, selectedCountries.map(
        //             (country) => selectedIndicators.reduce(
        //                 (total, current) => {
        //                     return parseFloat(total) + parseFloat(pageData.data[current].countries_data[country].average);
        //                 }, 0.0) / selectedIndicators.length
        //             )
        //         ) : 0,
        // },
        {
            title: GET_APP_LOCALIZE_TEXT(pageLc, "baselining__glob_avg"),
            value: pageData && selectedIndicators.length > 0 ? selectedIndicators.reduce((total, current) => {
                return parseFloat(total) + 100;
            }, 0.0)/ selectedIndicators.length : 0,
        }
    ].map(item => {
        return {
            ...item,
            value: scaleToFive(item.value),
        };
    });

    function onAddCountry(event, setState) {
        const aliasedItem = event.target.value;
        if(selectedCategories.concat(defaultCountries).length === 11)
        {
            toastDanger(GET_APP_LOCALIZE_TEXT(pageLc, "general__error"), GET_APP_LOCALIZE_TEXT(pageLc,"baselining__max_added_error"));
        }
        else
        {
            const country = get_category_reverse_alias(aliasedItem);
            removeItemFromState(setAdditionalCountries, country)
            addItemToState(setDefaultCountries, country)
            onSelectCountry(aliasedItem, true)
            setState((prevState) => ({
                ...prevState,
                [aliasedItem]: true,
            }));
        }
    }

    function onRemoveCountry(aliasedItem, setState) {

        const country = get_category_reverse_alias(aliasedItem);
        removeItemFromState(setDefaultCountries, country)
        addItemToState(setAdditionalCountries, country, true)
        onSelectCountry(aliasedItem, false)

        setState((prevState) => ({
            ...prevState,
            [aliasedItem]: false,
        }));
    }

    function onSelectCountry(item, isChecked) {

        const itemID = get_category_reverse_alias(item);
        if(isChecked) {
            if(!selectedCountries.includes(itemID)) {
                addItemToState(setSelectedCountries, itemID)
            }
        }
        else
        {
            if(selectedCountries.includes(itemID)) {
                removeItemFromState(setSelectedCountries, itemID)
            }
        }
    }

    function onSelectIndicator(item, isChecked, state, setState) {

        const itemID = get_indicator_reverse_alias(item);
        if(isChecked) {
            setSelectedIndicators([itemID])
            setState(Object.fromEntries(indicators.map((indicator) => get_indicator_alias(indicator)).map(
                (indicator) => indicator == item ? [indicator, true] : [indicator, false]
            )));
        }
        else
        {
            setState((prevState) => ({
                ...prevState,
                [item]: true,
            }));
        }
    }

    const radarRef = React.createRef();
    const lineRef = React.createRef();

    function sumSelectedIndicatorsAnnualScores(category, country = "")
    {
        let temp = []
        selectedIndicators.forEach((indicator) => {
            const objs = []
            //Objects Initialization
            switch(category) {
                case "Global":
                    objs.push(...pageData.data[indicator].global_data.annual_scores)
                    break;
                case "Program":
                    objs.push(...pageData.data[indicator].program_data.annual_scores)
                    break;
                case "National":
                    objs.push(...pageData.data[indicator].national_data.annual_scores)
                    break;
                case "Country":
                    objs.push(...pageData.data[indicator].countries_data[country].annual_scores)
            }
            //Adding Count: 1 to Each Object
            if(temp.length == 0)
            {
                objs.forEach((obj) => temp.push({count: 1, ...obj}))
            }
            else
            {
                objs.forEach((obj) => {
                    const index = temp.findIndex((element) => element.year+'' === obj.year+'')
                    if(index === -1) {
                        temp.push({count: 1, ...obj})
                    } else {
                        const new_val = Number(parseFloat(temp[index].val) + parseFloat(obj.val))
                        const old_year = temp[index].year;
                        const old_count = temp[index].count;
                        temp.splice(index, 1, {year: old_year, val: new_val, count: old_count+1});
                    }
                })
            }
        })
        return temp;
    }

    let years = []
    const summed_scores = {};
    const indicator_scores = {};

    if(pageData)
    {
        //Data Sum For Annual Scores of Countries
        selectedCountries.forEach((country) => {
            summed_scores[country] = {}
            indicator_scores[country] = indicators.map((i) => pageData.data[i].countries_data[country].indicator_score);
            const temp = sumSelectedIndicatorsAnnualScores("Country", country)
            summed_scores[country].annual_scores = temp.map((obj) => {
                return {
                    year: obj.year,
                    val: Number((obj.val / obj.count).toFixed(2))
                }
            })
        })
        //Data Sum For Annual Scores of Program, Global and National
        selectedCategories.forEach((category) => {
            switch(category) {
                case "Global":
                    indicator_scores[category] = indicators.map((i) => pageData.data[i].global_data.indicator_score);
                    break;
                case "Program":
                    indicator_scores[category] = indicators.map((i) => pageData.data[i].program_data.indicator_score);
                    break;
                case "National":
                    indicator_scores[category] = indicators.map((i) => pageData.data[i].national_data.indicator_score);
                    break;
                default:
                    indicator_scores[category] = []
            }
            summed_scores[category] = {}
            const temp = sumSelectedIndicatorsAnnualScores(category)
            summed_scores[category].annual_scores = temp.map((obj) => {
                return {
                    year: obj.year,
                    val: Number((obj.val / obj.count).toFixed(2))
                }
            })
        })

        //Getting Years to Display on Line Chart Depending on Available Years from Summed_Scores
        years = Object.keys(summed_scores).map((key) => summed_scores[key].annual_scores).reduce(
            (final, current) => {
                const items_to_add = current.filter((item) => !final.includes(item.year)).map((item) => item.year)
                return final.concat(items_to_add);
        }, [])
        years.sort(function(a, b){return a - b})
    }

    const indicator_header = selectedIndicators.length == indicators.length ? selectAllIndicatorsOption : (selectedIndicators.length == 0 ? "No Indicators" : selectedIndicators.map((indicator) => get_indicator_alias(indicator)));

    function onSelectDCProgram(item, isChecked) {
        setLoading(true);
        const itemID = item;

        if (isChecked) {
            if (!selectedDCPrograms.includes(itemID)) {
                addItemToState(setSelectedDCPrograms, itemID);
            }
        } else {
            if (selectedDCPrograms.includes(itemID) && !defaultDCProgram.includes(itemID)) {
                removeItemFromState(setSelectedDCPrograms, itemID);
                addItemToState(setAdditionalDCProgram, itemID);
            }
        }
        setTimeout(() => {setLoading(false)}, 200);
    }

    function onAddDCProgram(event, setState) {
        setLoading(true);
        if(selectedDCPrograms.length === 8)
        {
            toastDanger(GET_APP_LOCALIZE_TEXT(pageLc, "general__error"), GET_APP_LOCALIZE_TEXT(pageLc,"baselining__max_added_error"));
        } else {
            const program = event.target.value;
            removeItemFromState(setAdditionalDCProgram, program)
            // addItemToState(setSelectedDCPrograms, program)
            onSelectDCProgram(program, true)
            setState((prevState) => ({
                ...prevState,
                [program]: true,
            }));
        }
        setTimeout(() => {
            setLoading(false)
        }, 200);
    }

    const scores = [4.58, 4.36, 4.22, 4.21, 3.86, 3.79, 3.73, 3.72];
    const participants = ['211,685', '666,156', '725,598', '361,487', '757,750', '776,851', '469,155', '601,511'];
    const orgs = ['GreenTech Innovations', 'Quantum Computing Corp', 'Global Health Initiative', 'Future Finance Group', 'EcoSolutions Inc', 'CyberSecure Networks', 'Renewable Resources Ltd', 'SpaceTech Enterprises'];

    return (
        pageData ?
            <Paper container>
                <Grid container>
                    <Grid item xs={12} sm={12} md={3}>
                        <SidePanel
                            primaryHeader={primarySideHeader}
                            secondaryHeader={secondarySideHeader}
                            primaryData={selectedDCPrograms}
                            secondaryData={[]}
                            onCheckPrimary={onSelectDCProgram}
                            onCheckSecondary={onSelectIndicator}
                            primaryColourize={true}
                            primaryColourizeIndex={selectedCategories.length}
                            primaryFooter={CustomSelect}
                            primaryFooterData={{
                                title: "Add Program",
                                data: additionalDCProgram,
                            }}
                            primaryFooterCallback={onAddDCProgram}
                            // primaryAction={X}
                            // primaryActionData={{style: {cursor: "pointer"}}}
                            // primaryActionCallback={onRemoveCountry}
                            // primarySingleSelectIndex={0}
                            secondarySingleSelectIndex={0}  
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={9}>
                        <Card>
                            <CardContent>
                                <Box sx={{flexGrow: 1}} align={"right"}>
                                    <ColouredTypography variant={"body1"} size={"16px"} color={"grey"} align={"left"} alignText={"justify"} p={10}>
                                        The following showcases your country's digital literacy programs impact rating scores. Score range (0-5) 0 being the worst and 5 being the best.
                                    </ColouredTypography>
                                    {/* <ColouredTypography variant={"body1"} size={"16px"} align={"center"} alignText={"justify"} pb={5}>
                                        <Italicize>
                                            ({GET_APP_LOCALIZE_TEXT(pageLc, "baselining__dig_comp_range")})
                                        </Italicize>
                                    </ColouredTypography>
                                    <ColouredTypography mt={5} mb={5} variant={"h4"} align={"center"}>
                                        {`${indicator_header}`} {GET_APP_LOCALIZE_TEXT(pageLc, "baselining__covered_by")}&nbsp;
                                        <ColouredTypography component="span" variant={"h4"} color="orange">
                                            {pageData.overview ? pageData.overview.program : ''}
                                        </ColouredTypography>
                                    </ColouredTypography> */}
                                    <Grid container spacing={0} align={"center"}>
                                        {badge_data
                                        // filter((b) =>
                                        //     authUser.org_code.toLowerCase() !== "amf" || b.title !== GET_APP_LOCALIZE_TEXT(pageLc, "baselining__nation_avg"))
                                            .map((badge,_, arr) => (
                                                <Grid item xs={6} sm={6} md={6} lg={12/arr.length}>
                                                    <Badge
                                                        title={badge.title}
                                                        subtitle={badge.subtitle}
                                                        value={badge.value.toFixed(2)}
                                                    />
                                                </Grid>
                                            )
                                        )}
                                    </Grid>
                                    <Spacer mb={5}/>
                                </Box>
                            </CardContent>
                        </Card>
                        <Grid container >
                            <Grid item style={{ margin: '0 auto', width: '90%' }}>
                                <TableContainer component={Paper}>
                                    <Table style={{ border: '1px solid #ccc' }}>
                                        <TableHead>
                                            <TableRow className={classes.tableRow}>
                                                <TableCell>
                                                    <DefaultColouredTypography variant="h6" align={'center'} fontWeight="fontWeightBold">
                                                        Program Name
                                                    </DefaultColouredTypography>
                                                </TableCell>
                                                <TableCell>
                                                    <DefaultColouredTypography variant="h6" align={'center'} fontWeight="fontWeightBold">
                                                        Organization
                                                    </DefaultColouredTypography>
                                                </TableCell>
                                                <TableCell>
                                                    <DefaultColouredTypography variant="h6" align={'center'} fontWeight="fontWeightBold">
                                                        Participants
                                                    </DefaultColouredTypography>
                                                </TableCell>
                                                <TableCell>
                                                    <DefaultColouredTypography variant="h6" align={'center'} fontWeight="fontWeightBold">
                                                        Impact Score
                                                    </DefaultColouredTypography>
                                                </TableCell>
                                                <TableCell>
                                                    <DefaultColouredTypography variant="h6" align={'center'} fontWeight="fontWeightBold">
                                                        Impact Rating
                                                    </DefaultColouredTypography>
                                                </TableCell>
                                            </TableRow>
                                            {/*<TableRow className={classes.tableRow}>*/}
                                            {/*    <TableCell colSpan={2} style={{ padding: 0 }}>*/}
                                            {/*        <Divider style={{*/}
                                            {/*            height: '4px',*/}
                                            {/*            marginTop: '-25px',*/}
                                            {/*            marginBottom: '20px',*/}
                                            {/*            marginRight: '16px'*/}
                                            {/*        }} />*/}
                                            {/*    </TableCell>*/}
                                            {/*</TableRow>*/}
                                        </TableHead>
                                        <TableBody>
                                            {
                                                // Create an array for DC Program 1 to DC Program 8
                                                Array.from({ length: 8 }, (_, i) => ({
                                                    name: `DC Program ${i + 1}`,
                                                    org: orgs[i],
                                                    participants: participants[i],
                                                    score: scores[i],
                                                }))
                                                    // Sort the programs by score in descending order
                                                    .sort((a, b) => b.score - a.score)
                                                    // Map over each program to create table rows
                                                    .map((program) => (
                                                        <TableRow key={program.name} className={classes.tableRow}>
                                                            <TableCell component="th" scope="row" align="center">
                                                                <Typography variant="h6" align={'center'}>
                                                                    {program.name}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" align="center">
                                                                <Typography variant="h6" align={'center'}>
                                                                    {program.org}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" align="center">
                                                                <Typography variant="h6" align={'center'}>
                                                                    {program.participants}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Typography variant="h6" align={'center'}>
                                                                    {program.score}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" align="center">
                                                                <Typography variant="h6" align={'center'}>
                                                                    <StarComponent rating={program.score} total={3}/>
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>

                    </Grid>
                    {/* Table at the bottom */}
                </Grid>

                <LoaderWithBackDrop loading={loading}/>
            </Paper>
            :
            <Paper container>
                <DefaultColouredTypography variant={"h3"} align={'center'} py={5}>{errorMessage}</DefaultColouredTypography>
                <LoaderWithBackDrop loading={loading}/>
            </Paper>
    );
}

export default ProgramImpactRatingTab;