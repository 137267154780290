import React, {useState, useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import {Copyright} from '../../../components';

import { store } from 'react-notifications-component';
import { withRouter } from "react-router";

import { useFormik } from 'formik';

import {registerSchool, registerUser} from '../../../services/auth.service';
import { useAuth } from "../../../context/auth";
import Helmet from "react-helmet";
import {FormControl, InputLabel, MenuItem} from "@material-ui/core";
import {toastWarning} from "../../../utils/utils";
import {LOCAL_STORAGE_KEYS} from "../../../utils/constants";
import {useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const school_types = (locale) => [
    GET_APP_LOCALIZE_TEXT(locale, "auth__school_primary"),
    GET_APP_LOCALIZE_TEXT(locale, "auth__school_secondary"),
    GET_APP_LOCALIZE_TEXT(locale, "auth__school_high")
];
const school_sizes = (locale) => [
    `<10 ${GET_APP_LOCALIZE_TEXT(locale, "navigation__students")}`,
    `10-100 ${GET_APP_LOCALIZE_TEXT(locale, "navigation__students")}`,
    `101-500 ${GET_APP_LOCALIZE_TEXT(locale, "navigation__students")}`,
    `501-1000 ${GET_APP_LOCALIZE_TEXT(locale, "navigation__students")}`,
    `>1000 ${GET_APP_LOCALIZE_TEXT(locale, "navigation__students")}`
];

const RegisterSchool = (props) => {
    const classes = useStyles();

    const { setAuthToken, setAuthUser } = useAuth();

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const school_types_en = school_types("en");
    const school_types_locale = school_types(pageLc);

    const school_sizes_en = school_sizes("en");
    const school_sizes_locale = school_sizes(pageLc);

    const formik = useFormik({
        initialValues: {
            school_name: '',
            school_type: '',
            school_size: '',
            country: '',
            city: '',
            postal_code: '',
            owner_first_name: '',
            owner_last_name: '',
            owner_position: '',
            owner_email: '',
            password: '',
            confirm_password: ''
        },
        onSubmit: async (values) => {
            console.log('Form data => ', values);
            if (values.password !== values.confirm_password) {
                return store.addNotification({
                    title: GET_APP_LOCALIZE_TEXT(pageLc, "auth__err_pass_mismatch"),
                    message: GET_APP_LOCALIZE_TEXT(pageLc, "auth__provide_same_pass"),
                    type: "warning",
                    insert: "bottom",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 2000,
                        showIcon: true
                    }
                });
            }

            try {
                const result = await registerSchool(values);
                if (result.data && result.data.user) {
                    const c_user = result.data.user;
                    c_user.org_obj = result.data.org_object;
                    localStorage.setItem(LOCAL_STORAGE_KEYS.active_org_type, c_user.org_obj.type);
                    localStorage.setItem(LOCAL_STORAGE_KEYS.active_org_code, c_user.org_obj.org_code);
                    localStorage.setItem(LOCAL_STORAGE_KEYS.refresh_token, JSON.stringify(result.data.refresh_token))
                    await setAuthToken(result.data.auth_token);
                    await setAuthUser(c_user);
                    props.history.push('/');
                }
            } catch (e) {
                // already toasted the error
            }
        }
    });

    return (
        <Container component="main" maxWidth="xs">
            <Helmet title={`DQ-PRO | ${GET_APP_LOCALIZE_TEXT(pageLc, "auth__reg_school")}`} />
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {GET_APP_LOCALIZE_TEXT(pageLc, "auth__reg_school")}
                </Typography>


                <form className={classes.form} noValidate onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="school_name"
                                label={GET_APP_LOCALIZE_TEXT(pageLc, "progress__school_name")}
                                name="school_name"
                                autoComplete="school_name"
                                onChange={formik.handleChange}
                                value={formik.values.school_name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined" m={2}>
                                <InputLabel

                                    id="demo-simple-select-outlined-label"
                                >{GET_APP_LOCALIZE_TEXT(pageLc, "auth__school_type")}</InputLabel>
                                <Select
                                    required
                                    fullWidth
                                    labelId="demo-simple-select-outlined-label"
                                    id="school_type"
                                    name="school_type"
                                    onChange={formik.handleChange}
                                    value={formik.values.school_type}
                                    label={GET_APP_LOCALIZE_TEXT(pageLc, "auth__school_type")}
                                >
                                    { school_types_locale.map((item_type, index) => <MenuItem value={school_types_en[index]}>{item_type}</MenuItem>) }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined" m={2}>
                                <InputLabel

                                    id="demo-simple-select-outlined-label"
                                >{GET_APP_LOCALIZE_TEXT(pageLc, "auth__school_size")}</InputLabel>
                                <Select
                                    required
                                    fullWidth
                                    labelId="demo-simple-select-outlined-label"
                                    id="school_size"
                                    name="school_size"
                                    onChange={formik.handleChange}
                                    value={formik.values.school_size}
                                    label={GET_APP_LOCALIZE_TEXT(pageLc, "auth__school_size")}
                                >
                                    { school_sizes_locale.map((item_type, index) => <MenuItem value={school_sizes_en[index]}>{item_type}</MenuItem>) }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="country"
                                label={GET_APP_LOCALIZE_TEXT(pageLc, "auth__country")}
                                name="country"
                                autoComplete="country"
                                onChange={formik.handleChange}
                                value={formik.values.country}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="city"
                                label={GET_APP_LOCALIZE_TEXT(pageLc, "auth__city")}
                                name="city"
                                autoComplete="city"
                                onChange={formik.handleChange}
                                value={formik.values.city}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="postal_code"
                                label={GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__postal")}
                                name="postal_code"
                                autoComplete="postal_code"
                                onChange={formik.handleChange}
                                value={formik.values.postal_code}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="owner_first_name"
                                label={GET_APP_LOCALIZE_TEXT(pageLc, "auth__your_first_name")}
                                name="owner_first_name"
                                autoComplete="owner_first_name"
                                onChange={formik.handleChange}
                                value={formik.values.owner_first_name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="owner_last_name"
                                label={GET_APP_LOCALIZE_TEXT(pageLc, "auth__your_last_name")}
                                name="owner_last_name"
                                autoComplete="owner_last_name"
                                onChange={formik.handleChange}
                                value={formik.values.owner_last_name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="owner_position"
                                label={GET_APP_LOCALIZE_TEXT(pageLc, "auth__your_position")}
                                name="owner_position"
                                autoComplete="owner_position"
                                onChange={formik.handleChange}
                                value={formik.values.owner_position}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="owner_email"
                                label={GET_APP_LOCALIZE_TEXT(pageLc, "auth__your_email")}
                                name="owner_email"
                                autoComplete="owner_email"
                                onChange={formik.handleChange}
                                value={formik.values.owner_email}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="password"
                                label={GET_APP_LOCALIZE_TEXT(pageLc, "auth__create_pass")}
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={formik.handleChange}
                                value={formik.values.password}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="confirm_password"
                                label={GET_APP_LOCALIZE_TEXT(pageLc, "auth__confirm_pass")}
                                type="password"
                                id="confirm_password"
                                autoComplete="current-password"
                                onChange={formik.handleChange}
                                value={formik.values.confirm_password}
                            />
                        </Grid>
                        <FormControlLabel
                            control={<Checkbox
                                name="remember_me"
                                id="remember_me"
                                value={formik.values.remember_me}
                                onChange={formik.handleChange}
                                color="primary"
                            />}
                            label={GET_APP_LOCALIZE_TEXT(pageLc, "auth__keep_me_login")}
                        />
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        {GET_APP_LOCALIZE_TEXT(pageLc, "auth__register")}
                    </Button>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Link href="/login" variant="body2">
                                {GET_APP_LOCALIZE_TEXT(pageLc, "auth__already_have_acc")}
                            </Link>
                        </Grid>
                    </Grid>
                </form>


            </div>
            <Box mt={5}>
                <Copyright />
            </Box>
        </Container>
    );
}

export default withRouter(RegisterSchool);