import {LOCAL_STORAGE_KEYS} from "../utils/constants";
import {invokeApi} from "../bl_libs/invokeApi";

export const getInstitutes = async (org_code) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/analysis/list_institutes',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code
    };
    return invokeApi(requestObj);
}

export const getClasses = async (org_code, institute_code) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/analysis/list_classes',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        institute_code
    };
    return invokeApi(requestObj);
}

export const getDQWReportRedirectLink = async (org_code, institute_code, redirect_back_link) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    const locale = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);
    let requestObj = {
        path   : '/analytics/analysis/dqw_report_redirect',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}`, 'xc-dq-locale': locale }
    };
    requestObj['postData'] = {
        org_code,
        institute_code,
        redirect_back_link
    };
    return invokeApi(requestObj);
}