import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";
import LoaderWithBackDrop from "../../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";

import {
    Paper,
    Table,
    Box,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Grid as MuiGrid,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Typography as MuiTypography,
    withStyles, Button as MuiButton,
} from "@material-ui/core";

import ColoredLinearProgress from "../components/ColouredLinearProgress.comp";

import { spacing } from "@material-ui/system";
import {useAuth} from "../../../../context/auth";
import XLSX from "xlsx";
import {alphaNumericSort, predefinedSort, toastDanger} from "../../../../utils/utils";
import {COUNTRY_CONFIGS} from "../../../../utils/constants";
import {GenericSidePanel} from "../../../../components/SidePanel/GenericSidePanel.comp";
import {getAssessmentEfficacyv2} from "../../../../services/assessment.service";
import {useLocation} from "react-router-dom";
import MonthRangePicker from "../../../../components/DatePickers/MonthRangePicker.comp";
import {GET_APP_LOCALIZE_TEXT} from "../../../../utils/dq_lc_service/lc_service";
import {CustomSelect} from "../../Baselining/components/Select.comp";

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#233044',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;

const Grid = styled(MuiGrid)(spacing);

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;
  padding-left: ${(props) => props.theme.spacing(12)}px;
  padding-right: ${(props) => props.theme.spacing(12)}px;

  &:first-child {
    padding-top: ${(props) => props.theme.spacing(12)}px;
  }
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(12)}px;
  }
`;

const LinearProgress = styled(ColoredLinearProgress)`
  height: 14px;
  border-radius: 3px;
`

function LinearProgressWithLabel(props) {
    return (
        <Grid container spacing={4}>
            <Grid item xs={8}>
                    <LinearProgress variant="determinate" {...props} />
            </Grid>
            <Grid item xs={4}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(props.value,)}%`}</Typography>
            </Grid>
        </Grid>
    );
}

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  text-align: ${(props) => props.textAlign ? props.textAlign : "left"};
`

const Spacer = styled.div(spacing);

const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
`

const Bold = (props) => <Box component={'span'} fontWeight="fontWeightBold" {...props}></Box>
const Italicize = (props) => <Box component={'span'} fontStyle="italic" {...props}></Box>
const Font = (props) => <Box component={'span'} fontFamily={props.font} {...props}></Box>

function EfficacyTab({skill, description, header1, header2}) {

    const { authUser } = useAuth();
    const { org_code, org_obj } = authUser;
    const { org_country } = org_obj;
    const countryConfigKey = COUNTRY_CONFIGS[org_country] ? org_country : "default";
    const countryConfigKeyForIncludedCountries = COUNTRY_CONFIGS[org_country] && COUNTRY_CONFIGS[org_country].included_countries ? org_country : "default";
    const countriesFromConfig = [org_country, ...COUNTRY_CONFIGS[countryConfigKeyForIncludedCountries].included_countries]
    const {administrative_sector} = COUNTRY_CONFIGS[countryConfigKey];

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const programMods = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__prog_mods")

    //global data
    const [selectorsData, setSelectorsData] = useState(undefined);
    const [pageData, setPageData] = useState(undefined);

    //selectors data
    const [countries, setCountries] = useState([]);
    const [regions, setRegions] = useState([]);
    const [categories, setCategories] = useState([programMods]);
    const [timePeriods, setTimePeriods] = useState([]);

    //selector states
    const [selectedCountry, setSelectedCountry] = useState([]);
    const [selectedRegions, setSelectedRegions] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(programMods);
    const [selectedTime, setSelectedTime] = useState([undefined, undefined]);

    //misc states
    const [minDate, setMinDate] = useState(undefined);
    const [maxDate, setMaxDate] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState('');
    const [firstLoad, setFirstLoad] = useState(true);
    const [loading, setLoading] = useState(false);

    const countryHeader = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_country")
    const primarySideHeader = GET_APP_LOCALIZE_TEXT(pageLc, `side_panel__select_`+administrative_sector.toLowerCase())
    const secondarySideHeader= categories.length > 1 ? GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_mod_cat")
        : GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_comp_cat")
    const timeSideHeader= GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_time")

    const selectAllRegionsOption = GET_APP_LOCALIZE_TEXT(pageLc, `side_panel__all_`+administrative_sector.toLowerCase())
    const selectAllTimesOption = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__all_year")
    const [selectedDCPrograms, setSelectedDCPrograms]  = useState([]);
    const [defaultDCProgram, setDefaultDCProgram] = useState([]);
    const [additionalDCProgram, setAdditionalDCProgram] = useState([]);

    const addItemToState = (setState, item, sort = false) => {
        if(sort)
            setState((prevState) => ([...prevState, item].sort()));
        else
            setState((prevState) => ([...prevState, item]));
    }

    const removeItemFromState = (setState, item) => {
        setState((prevState) => {
            const index = prevState.indexOf(item);
            const temp = [...prevState];
            temp.splice(index, 1);
            return temp;
        });
    }

    useEffect(() => {
        if(pageData)
        {
            console.log("I was set", pageData);
        }
        else if(!firstLoad)
        {

        }
    }, [pageData])

    useEffect(() => {
        if(selectorsData)
        {

        }
        else if(!firstLoad)
        {
            setSelectedRegions([]);
            setSelectedTime([undefined, undefined]);
            setRegions([]);
            setTimePeriods([]);
            setMinDate(undefined);
            setMaxDate(undefined);
        }
    }, [selectorsData])

    useEffect(() => {
        if(selectorsData)
        {
            setSelectedRegions(regions);
        }
    }, [regions])

    useEffect(() => {
        if(timePeriods.length > 0)
        {
            const [firstYear, lastYear] = [timePeriods[0], timePeriods[timePeriods.length - 1]]
            setMinDate(new Date(`${firstYear}-01-01`))
            setMaxDate(new Date(`${lastYear}-12-31`))
            selectAllMonthsOfYears(timePeriods);
        }
    },[timePeriods])

    useEffect(() => {
        if(selectorsData && selectedCountry)
        {
            initializeRegionsAndTimePeriods(selectorsData, selectedCountry)
        }
    }, [selectedCountry])

    useEffect(() => {
        if(selectorsData && selectedRegions)
        {
            if(!pageData || !areRegionEqual(selectedRegions, pageData.selected_states) || !areDateRangesEqual(selectedTime, pageData.selected_date_range))
            {
                loadPageData().then(d => console.log('Assessment Adoption Data Fetched', d));
            }
            else
            {
                console.log("Fetching did not occur")
            }
        }
    }, [selectedRegions, selectedTime])

    useEffect(() => {
        if(firstLoad) {
            loadSelectors().then(d => console.log('Assessment Adoption Selectors Fetched', d))
            setFirstLoad(false);
        }
    }, []);

    const loadSelectors = async () => {
        setLoading(true);
        try {
            const result = await getAssessmentEfficacyv2(org_code, skill);
            console.log(result);
            if(result.data)
            {
                const {selectors, data} = result.data;
                const {selected_country} = data;
                const {countries: _countries} = selectors;
                setSelectorsData(selectors);

                if(_countries && Array.isArray(_countries))
                {
                    const filtered_countries = _countries.filter(ct => countriesFromConfig.includes(ct));
                    setCountries(filtered_countries);
                    if(filtered_countries[0] === selected_country)
                    {
                        setPageData(data);
                        setSelectedCountry(selected_country);
                        //initializeRegionsAndTimePeriods called here because it may not get called by useEffect of selectedCountry state due to undefined selectorsData state
                        initializeRegionsAndTimePeriods(selectors, selected_country);
                    }
                    else
                    {
                        setSelectedCountry(filtered_countries[0]);
                        initializeRegionsAndTimePeriods(selectors, filtered_countries[0]);
                    }
                }
            }
        }
        catch (e) {
            console.log("Some error occurred while fetching assessment efficacy selectors")
        }
        const dcProgramsArray = [];
        const additionalDCProgramArray = [];
        dcProgramsArray.push(`All Programs`);

        for (let i = 0; i < 3; i++) {
            dcProgramsArray.push(`DC Program ${i + 1}`);
        }

        for (let i = 3; i < 8; i++) {
            additionalDCProgramArray.push(`DC Program ${i + 1}`);
        }

        setDefaultDCProgram(dcProgramsArray);
        setAdditionalDCProgram(additionalDCProgramArray)
        setSelectedDCPrograms(dcProgramsArray);
        setTimeout(() => {setLoading(false)}, 200);
        return true;
    }

    const loadPageData = async (country = selectedCountry, regions = selectedRegions, time = selectedTime) => {
        setLoading(true);
        try {
            setPageData(undefined);
            const result = await getAssessmentEfficacyv2(org_code, skill, country, regions, time);
            if (result.data) {
                const {data} = result.data;
                setPageData(data);
            }
            else {
                setErrorMessage(GET_APP_LOCALIZE_TEXT(pageLc, "general__data_unavailable"));
            }
        } catch (e) {
            console.log("Some error occurred while fetching assessment efficacy data")
        }
        setTimeout(() => {setLoading(false)}, 200);
        return true;
    }

    function initializeRegionsAndTimePeriods(selectors, country)
    {
        const {states: _states, years: _years} = selectors;
        if(_states && _years && _states[country] && _years[country])
        {
            setRegions(_states[country]);
            setTimePeriods(_years[country].map((year) => year.toString()));
        }
    }

    function areRegionEqual(regions1, regions2)
    {
        if(!Array.isArray(regions1) || !Array.isArray(regions2)) return false;
        if(regions1.length !== regions2.length) return false;
        for(let index in regions1)
        {
            if(regions1[index] !== regions2[index]) return false;
        }
        return true;
    }

    function areDateRangesEqual(range1, range2)
    {
        if(!Array.isArray(range1) || !Array.isArray(range2)) return false;
        if(range1.length !== range2.length) return false;
        for(let index in range1)
        {
            if(range1[index] !== range2[index]) return false;
        }
        return true;
    }

    function onSelectCountry (item, isChecked, state, setState) {
        if(isChecked)
        {
            setSelectedCountry(item);
            setState(Object.fromEntries(countries.map(
                (country) => country === item ? [country, true] : [country, false]
            )));
        }
        else
        {
            setState({
                ...state,
                [item]: true,
            });
        }
    }

    function onSelectRegion (item, isChecked) {

        if(isChecked) {
            if(item === selectAllRegionsOption)
            {
                setSelectedRegions([...regions])
            }
            else
            {
                if(selectedRegions.includes(item)) //if already selected, then all regions was selected before, clear all
                {
                    setSelectedRegions([item])
                }
                else
                {
                    setSelectedRegions([...selectedRegions, item])
                }
            }
        }
        else
        {
            if(item === selectAllRegionsOption)
            {
                setSelectedRegions([])
            }
            else {
                if (selectedRegions.includes(item)) {
                    let copyArray = [...selectedRegions]
                    copyArray.splice(copyArray.indexOf(item), 1)
                    setSelectedRegions(copyArray)
                }
            }
        }
    }

    function onSelectSkillCategory (selected_item, is_checked, state, setState) {
        if(is_checked)
        {
            setSelectedCategory(selected_item)
            setState(Object.fromEntries(categories.map(
                (category) => category === selected_item ? [category, true] : [category, false]
            )));
        }
        else
        {
            setState({
                ...state,
                [selected_item]: true,
            });
        }
    }

    function onSelectTimePeriod (item, isChecked, state, setState) {
        //setDatePicker(false)
        if(isChecked)
        {
            if(item === selectAllTimesOption)
            {
                selectAllMonthsOfYears(timePeriods);
            }
            else
            {
                selectAllMonthsOfYears([item]);
            }
            setState(Object.fromEntries([selectAllTimesOption, ...timePeriods].map(
                (time) => time === item ? [time, true] : [time, false]
            )));
        }
        else
        {
            console.log("Oops")
            setState({
                ...state,
                [item]: true,
            });
        }
    }

    function onClickDatePicker(event, state, setState) {
        setState(Object.fromEntries([selectAllTimesOption, ...timePeriods].map(
            (time) => [time, false]
        )));
    }

    function onSelectDateRange(dates) {

        const start_date = new Date(Date.parse(dates[0])).toISOString();
        const end_date = new Date(Date.parse(dates[1])).toISOString();

        console.log(start_date, end_date);

        setSelectedTime([start_date, end_date]);
    }

    const selectAllMonthsOfYears = (years) => {
        const [startYear, endYear] = [years[0], years[years.length-1]];
        const [startDate, endDate] = [new Date(`${startYear}-01-01`).toISOString(), new Date(`${endYear}-12-31`).toISOString()]
        setSelectedTime([startDate, endDate]);
    }

    const header = {
        region: selectedRegions.length === 0 ? GET_APP_LOCALIZE_TEXT(pageLc, `assessment__no_${administrative_sector.toLowerCase()}`) : selectedRegions.length === regions.length ? selectAllRegionsOption : selectedRegions,
        category: selectedCategory ? selectedCategory : GET_APP_LOCALIZE_TEXT(pageLc, "assessment__no_category"),
    }

    const onClickDownload = (event) => {

        const workbook = XLSX.utils.book_new();
        let column_widths = []

        const final_array = [
            ["Selected Country:", selectedCountry],
            ["Selected Regions:", ...selectedRegions],
            ["Selected Time:", new Date(selectedTime[0]).toDateString(), new Date(selectedTime[1]).toDateString()],
            [" "],
            ["Modules", "Participants", header1, header2]
        ]

        const items_array = pageData.data.sort(alphaNumericSort("topic", true)).sort(predefinedSort(["Total"], "topic", true)).map((item) => {
            return [item.topic, item.participants.toString(), item.skills.toString(), `${item.participants !== 0 ? Math.round(item.skills/item.participants*100) : 0}%`]
        })

        final_array.push(...items_array);

        final_array.forEach((array) => {
            array.forEach(((item, index) => column_widths[index] =  column_widths[index] ? Math.max(item.toString().length, column_widths[index], 10) : item.length))
        })

        const worksheet = XLSX.utils.aoa_to_sheet(final_array);
        worksheet["!cols"] = new Array(column_widths.length).fill(null).map((_, index) => {
            return { wch: column_widths[index] }
        });
        XLSX.utils.book_append_sheet(workbook, worksheet, skill);
        XLSX.writeFile(workbook, `Assessment Efficacy ${skill}.xlsx`);
    }

    function onSelectDCProgram(item, isChecked) {
        setLoading(true);
        const itemID = item;

        if (isChecked) {
            if (!selectedDCPrograms.includes(itemID)) {
                addItemToState(setSelectedDCPrograms, itemID);
            }
        } else {
            if (selectedDCPrograms.includes(itemID) && !defaultDCProgram.includes(itemID)) {
                removeItemFromState(setSelectedDCPrograms, itemID);
                addItemToState(setAdditionalDCProgram, itemID);
            }
        }
        setTimeout(() => {setLoading(false)}, 200);
    }

    function onAddDCProgram(event, state, setState) {
        setLoading(true);
        if(selectedDCPrograms.length === 8)
        {
            toastDanger(GET_APP_LOCALIZE_TEXT(pageLc, "general__error"), GET_APP_LOCALIZE_TEXT(pageLc,"baselining__max_added_error"));
        } else {
            const program = event.target.value;
            removeItemFromState(setAdditionalDCProgram, program)
            // addItemToState(setSelectedDCPrograms, program)
            onSelectDCProgram(program, true)
            setState((prevState) => ({
                ...prevState,
                [program]: true,
            }));
        }
        setTimeout(() => {
            setLoading(false)
        }, 200);
    }

    return (
        <Paper container>
            <Grid container>
                <Grid item xs={12} sm={12} md={3}>
                    <GenericSidePanel
                        data={[selectedDCPrograms, [selectAllRegionsOption, ...regions], categories, [selectAllTimesOption, ...timePeriods]]}
                        headers = {[countryHeader, primarySideHeader, secondarySideHeader, timeSideHeader]}
                        onCheckCallbacks = {[onSelectDCProgram, onSelectRegion, onSelectSkillCategory, onSelectTimePeriod]}
                        colourize = {[false, false, false, false]}
                        footers={[CustomSelect, undefined, undefined, MonthRangePicker]}
                        footersData = {[{title: "Add Program", data: additionalDCProgram}, undefined, undefined, {onChangeDate: onSelectDateRange, minDate, maxDate}]}
                        footersCallbacks = {[{onChange: onAddDCProgram}, undefined, undefined, {onOpen: onClickDatePicker}]}
                        defaultCheckIndices = {[undefined, 0, 0, 0]}
                        singleSelectIndices = {[undefined, 0, undefined, undefined]}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={9} alignItems={!pageData ? 'center' : ''} container={!pageData} justify={'center'}>
                    {
                        pageData ?
                            <Card mb={3}>
                                <CardContent>
                                    <ColouredTypography variant={"body1"} size={"16px"} color={"dimgrey"} align={"center"} alignText={"justify"} mb={10}>
                                        {description}
                                    </ColouredTypography>
                                    <ColouredTypography mb={10} variant="h4">
                                        {`${header.region} - ${header.category}`}
                                    </ColouredTypography>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <CustomTableCell align="center">
                                                    <DefaultColouredTypography variant={"h6"} color={"white"}>
                                                        {GET_APP_LOCALIZE_TEXT(pageLc, "baselining__mods")}
                                                    </DefaultColouredTypography>
                                                </CustomTableCell>
                                                <CustomTableCell align="center">
                                                    <DefaultColouredTypography variant={"h6"} color={"white"}>
                                                        {GET_APP_LOCALIZE_TEXT(pageLc, "assessment__participants")}
                                                    </DefaultColouredTypography>
                                                </CustomTableCell>
                                                <CustomTableCell align="center">
                                                    <DefaultColouredTypography variant={"h6"} color={"white"}>
                                                        {header1}
                                                    </DefaultColouredTypography>
                                                </CustomTableCell>
                                                <CustomTableCell align="center">
                                                    <DefaultColouredTypography variant={"h6"} color={"white"}>
                                                        {header2}
                                                    </DefaultColouredTypography>
                                                </CustomTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                pageData.data.sort(alphaNumericSort("topic", true)).sort(predefinedSort(["Total"], "topic", true)).map((item, index) => (
                                                    <CustomTableRow key={index}>
                                                        {
                                                            item.topic === "Total" ?
                                                                <>
                                                                    <CustomTableCell align="center">
                                                                        <Bold>{GET_APP_LOCALIZE_TEXT(pageLc, "general__total")}</Bold>
                                                                    </CustomTableCell>
                                                                    <CustomTableCell align="center">
                                                                        <Bold>{item.participants}</Bold>
                                                                    </CustomTableCell>
                                                                    <CustomTableCell align="center">
                                                                        <Bold>{item.skills}</Bold>
                                                                    </CustomTableCell>
                                                                </>
                                                                :
                                                                <>
                                                                    <CustomTableCell align="center">{item.topic}</CustomTableCell>
                                                                    <CustomTableCell align="center">{item.participants}</CustomTableCell>
                                                                    <CustomTableCell align="center">{item.skills}</CustomTableCell>
                                                                </>

                                                        }
                                                        <CustomTableCell align="center">
                                                            <LinearProgressWithLabel value={item.participants !== 0 ? Math.round(item.skills/item.participants*100) : 0}>
                                                            </LinearProgressWithLabel>
                                                        </CustomTableCell>
                                                    </CustomTableRow>
                                                ))
                                            }
                                        </TableBody>
                                    </Table>
                                    <Box textAlign={'right'}>
                                        <Button mt={5} variant="contained" onClick={onClickDownload}>
                                            {GET_APP_LOCALIZE_TEXT(pageLc, "general__download_data")}
                                        </Button>
                                    </Box>
                                </CardContent>
                            </Card>
                        :
                            <DefaultColouredTypography variant={"h3"} align={'center'}>{errorMessage}</DefaultColouredTypography>
                    }
                </Grid>
            </Grid>
            <LoaderWithBackDrop loading={loading} />
        </Paper>
    );
}

export default EfficacyTab;