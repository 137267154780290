import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";
import { useAuth } from "../../../../context/auth"
import {getCyberRisksData, getDigitalSkillsData} from "../../../../services/partner.service";
import LoaderWithBackDrop from "../../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";

import {
    Paper,
    Tabs,
    Tab,
    Box,
    Grid,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Chip as MuiChip,
    Typography as MuiTypography,
    Icon, makeStyles, Button as MuiButton,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import {SidePanel} from "../../../../components/SidePanel/SidePanel.comp";
import {Badge} from "./Badge.comp";
import LineChart from "./LineChart";
import {CustomSelect} from "./Select.comp";
import {X} from "react-feather";
import {createLcKey, predefinedSort, toastDanger} from "../../../../utils/utils";
import {useLocation} from "react-router-dom";
import {
    GET_APP_LOCALIZE_TEXT,
    GET_BASELINING_LOCALIZE_TEXT,
    MISSING_TRANSLATION_TEXT
} from "../../../../utils/dq_lc_service/lc_service";
import MixChart from "../../../SchoolDashboard/Baselining/components/MixChart";

const useStyles = makeStyles(theme => ({
    stretch: { height: "100%" },
    item: { display: "flex", flexDirection: "column" } // KEY CHANGES
}));

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
`

const Spacer = styled.div(spacing);

const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
`



const Bold = (props) => <Box component={'span'} fontWeight="fontWeightBold" {...props}></Box>
const Italicize = (props) => <Box component={'span'} fontStyle="italic" {...props}></Box>
const Font = (props) => <Box component={'span'} fontFamily={props.font} {...props}></Box>


function splitCountries(countries, default_countries = [])
{
    const sublist = []
    const length = countries.length;
    const min_count = 3;

    if(default_countries.length > 0)
    {
        sublist.push(default_countries)
        const skipped_countries_indices = default_countries.map((country) => countries.indexOf(country));
        const stripped_countries = countries.filter((_, index) => !skipped_countries_indices.includes(index))
        sublist.push(stripped_countries);
    }
    else
    {
        sublist.push(countries.slice(0, Math.min(min_count, countries.length)));
        sublist.push(countries.slice(Math.min(min_count, countries.length), length));
    }
    return sublist;
}

function getCountriesAverage(countries, avgData) {
    if(countries.length === 0)
        return 0;
    //Else
    let sum = 0;
    countries.forEach((country, index) => {
        sum += Number(avgData[index])
    })
    const avg = Number((sum / countries.length).toFixed(2));
    return avg;
}

const risk_reverse_translations = {};
const cat_reverse_translations = {};

function CyberRisksTab() {

    const {authUser} = useAuth();
    const {org_country} = authUser.org_obj;
    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState(undefined);

    const [defaultCountries, setDefaultCountries] = useState([]);
    const [additionalCountries, setAdditionalCountries] = useState([]);
    const [risks, setRisks] = useState([]);

    const [selectedRisks, setSelectedRisks] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([])
    const [errorMessage, setErrorMessage] = useState('');

    const selectAllRisksOption = GET_BASELINING_LOCALIZE_TEXT(pageLc, `cyber_risk__overall`);
    const primarySideHeader = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_country")
    const secondarySideHeader= GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_cyber_risk")
    const [selectedDCPrograms, setSelectedDCPrograms]  = useState([]);
    const [defaultDCProgram, setDefaultDCProgram] = useState([]);
    const [additionalDCProgram, setAdditionalDCProgram] = useState([]);

    function convertNumberToAlphabets(number) {
        const ALPHABETS = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const base = ALPHABETS.length;
        let result = "";
        number++;
        while (number > 0) {
            number--;
            result = ALPHABETS[number % base] + result;
            number = Math.floor(number / base);
        }
        return result;
    }

    const get_risk_alias = (code) => {
        let alias = GET_BASELINING_LOCALIZE_TEXT(pageLc, `cyber_risk__${code}`);
        if(alias === MISSING_TRANSLATION_TEXT) alias = code;
        risk_reverse_translations[alias] = code;
        return `${convertNumberToAlphabets(risks.findIndex((element) => element === code))}. ${alias}`;
    }

    const get_risk_reverse_alias = (alias) => {
        if(risk_reverse_translations[alias]) return risk_reverse_translations[alias];
        return alias;
    }

    const get_category_alias = (category) => {
        const cat_trans = GET_APP_LOCALIZE_TEXT(pageLc, `baselining__${createLcKey(category)}`, null);
        const country_trans = GET_APP_LOCALIZE_TEXT(pageLc, `dq_country__${createLcKey(category)}`, null);
        const translation = cat_trans || country_trans || category;
        cat_reverse_translations[translation] = category;
        return translation;
    }

    const get_category_reverse_alias = (alias) => {
        const reverse_alias = cat_reverse_translations[alias];
        return reverse_alias || alias;
    }

    const addItemToState = (setState, item, sort = false) => {
        if(sort)
            setState((prevState) => ([...prevState, item].sort()));
        else
            setState((prevState) => ([...prevState, item]));
    }

    const removeItemFromState = (setState, item) => {
        setState((prevState) => {
            const index = prevState.indexOf(item);
            const temp = [...prevState];
            temp.splice(index, 1);
            return temp;
        });
    }

    useEffect(() => {
        loadPageData().then(d => console.log('Cyber Risks Data Fetched!', d));
    }, []);


    function initializeData(data) {
        data.risks.sort(predefinedSort(["expe_CyberRisks"]))
        data.countries.sort();
        const first_risk = data.risks[0]
        if(first_risk) setSelectedRisks([first_risk]);
        setSelectedCategories(["National", "Global", "Program"])
        const countriesSubList = splitCountries(data.countries, data.default_countries);
        setDefaultCountries(countriesSubList[0])
        setAdditionalCountries(countriesSubList[1]);
        setSelectedCountries([countriesSubList[0][0]])
        const static_risks = [
            "expe_CyberRisks",
            "exce_illegTech",
            "expe_Cbbeh",
            "expe_Cvbeh",
            "expo_CyberThreat",
            "expo_SexMedia",
            "expo_SexConvo",
            "expe_OfflineMeeting",
            "risk_DigitalDis",
            "over_ScrTime",
            "risk_DigitalDisM",
            "illeg_Social",
            "expo_SexConvo_Female"
        ];
        setRisks(static_risks);
        setPageData(data);
        const dcProgramsArray = [];
        const additionalDCProgramArray = [];
        dcProgramsArray.push(`All Programs`);

        for (let i = 0; i < 3; i++) {
            dcProgramsArray.push(`DC Program ${i + 1}`);
        }

        for (let i = 3; i < 8; i++) {
            additionalDCProgramArray.push(`DC Program ${i + 1}`);
        }

        setDefaultDCProgram(dcProgramsArray);
        setAdditionalDCProgram(additionalDCProgramArray)
        setSelectedDCPrograms(dcProgramsArray);
    }

    const loadPageData = async () => {
        setLoading(true);
        try {
            const result = await getCyberRisksData(authUser.org_code);
            if (result.data) {
                console.log('Result Data of Cyber Risks --> ', result.data);
                initializeData(result.data)
            }
            else {
                setErrorMessage(GET_APP_LOCALIZE_TEXT(pageLc, "general__data_unavailable"));
            }

        } catch (e) {
            console.log("Some error occurred while fetching cyber risks data")
        }
        setTimeout(() => {setLoading(false)}, 200);
    }

    const badge_data = [
        {
            title: "Program Average",
            value: pageData && selectedRisks.length > 0 ? selectedRisks.reduce((total, current) => {
                return parseFloat(total) + parseFloat(pageData.data[current].national_data.average);
            }, 0.0) / selectedRisks.length : 29.30,
        },
        {
            title: "National Average",
            value:
                pageData && selectedRisks.length > 0 && selectedCountries.length > 0 ?
                    getCountriesAverage(selectedCountries, selectedCountries.map(
                            (country) => selectedRisks.reduce(
                                (total, current) => {
                                    return parseFloat(total) + parseFloat(pageData.data[current].countries_data[country] ? pageData.data[current].countries_data[country].average : 0);
                                }, 0.0) / selectedRisks.length
                        )
                    ) : 15.57,
        },
        {
            title: GET_APP_LOCALIZE_TEXT(pageLc, "baselining__glob_avg"),
            value: pageData && selectedRisks.length > 0 ? selectedRisks.reduce((total, current) => {
                return parseFloat(total) + parseFloat(pageData.data[current].global_data.average);
            }, 0.0) / selectedRisks.length : 24.55,
        }
    ]

    function onAddCountry(event, setState) {
        const aliasedItem = event.target.value;
        if(selectedCategories.concat(defaultCountries).length === 11)
        {
            toastDanger(GET_APP_LOCALIZE_TEXT(pageLc, "general__error"), GET_APP_LOCALIZE_TEXT(pageLc,"baselining__max_added_error"));
        }
        else
        {
            const country = get_category_reverse_alias(aliasedItem);
            removeItemFromState(setAdditionalCountries, country)
            addItemToState(setDefaultCountries, country)
            onSelectCountry(aliasedItem, true)

            setState((prevState) => ({
                ...prevState,
                [aliasedItem]: true,
            }));
        }
    }

    function onRemoveCountry(aliasedItem, setState) {

        const country = get_category_reverse_alias(aliasedItem);
        removeItemFromState(setDefaultCountries, country)
        addItemToState(setAdditionalCountries, country, true)
        onSelectCountry(aliasedItem, false)

        setState((prevState) => ({
            ...prevState,
            [aliasedItem]: false,
        }));
    }

    function onSelectCountry(aliasedItem, isChecked) {
        setLoading(true);
        const item = get_category_reverse_alias(aliasedItem);
        if(isChecked) {
            if(!selectedCountries.includes(item)) {
                addItemToState(setSelectedCountries, item)
            }
        }
        else
        {
            if(selectedCountries.includes(item)) {
                removeItemFromState(setSelectedCountries, item)
            }
        }
        setTimeout(() => {setLoading(false)}, 200);
    }

    function onSelectIndicator(aliasedItem, isChecked, state, setState) {
        setLoading(true);

        // const item = get_risk_reverse_alias(aliasedItem);
        // if(isChecked) {
        //     setSelectedRisks([item])
        //     setState(Object.fromEntries(risks.map((risk) => get_risk_alias(risk)).map(
        //         (risk) => risk === aliasedItem ? [risk, true] : [risk, false]
        //     )));
        // }
        // else
        // {
        //     setState((prevState) => ({
        //         ...prevState,
        //         [aliasedItem]: true,
        //     }));
        // }
        setTimeout(() => {setLoading(false)}, 200);

    }

    const lineRef = React.createRef();

    function sumSelectedRisksAnnualScores(category, country = "")
    {
        let temp = []
        selectedRisks.forEach((risk) => {
            let data = undefined;
            const objs = []
            //Objects Initialization
            switch(category) {
                case "Global":
                    data = pageData.data[risk].global_data;
                    break;
                case "National":
                    data = pageData.data[risk].national_data;
                    break;
                case "Country":
                    data = pageData.data[risk].countries_data[country];
            }
            if(data && data.annual_scores)
            {
                objs.push(...data.annual_scores)
            }
            //Adding Count: 1 to Each Object
            if(temp.length == 0)
            {
                objs.forEach((obj) => temp.push({count: 1, ...obj}))
            }
            else
            {
                objs.forEach((obj) => {
                    const index = temp.findIndex((element) => element.year+'' === obj.year+'')
                    if(index === -1) {
                        temp.push({count: 1, ...obj})
                    } else {
                        const new_val = Number(parseFloat(temp[index].val) + parseFloat(obj.val))
                        const old_year = temp[index].year;
                        const old_count = temp[index].count;
                        temp.splice(index, 1, {year: old_year, val: new_val, count: old_count+1});
                    }
                })
            }
        })
        return temp;
    }

    let years = []
    const summed_scores = {};

    if(pageData)
    {
        if(pageData)
        {
            //Data Sum For Annual Scores of Countries
            selectedCountries.forEach((country) => {
                summed_scores[country] = {}
                const temp = sumSelectedRisksAnnualScores("Country", country)
                summed_scores[country].annual_scores = temp.map((obj) => {
                    return {
                        year: obj.year,
                        val: Number((obj.val / obj.count).toFixed(2))
                    }
                })
            })
            //Data Sum For Annual Scores of Program, Global and National
            selectedCategories.forEach((category) => {
                summed_scores[category] = {}
                const temp = sumSelectedRisksAnnualScores(category)
                summed_scores[category].annual_scores = temp.map((obj) => {
                    return {
                        year: obj.year,
                        val: Number((obj.val / obj.count).toFixed(2))
                    }
                })
            })

            //Getting Years to Display on Line Chart Depending on Available Years from Summed_Scores
            years = Object.keys(summed_scores).map((key) => summed_scores[key].annual_scores).reduce(
                (final, current) => {
                    const items_to_add = current.filter((item) => !final.includes(item.year)).map((item) => item.year)
                    return final.concat(items_to_add);
                }, [])
            years.sort(function(a, b){return a - b})
        }
    }

    const indicator_header = selectedRisks.length === risks.length ? selectAllRisksOption : (selectedRisks.length === 0 ? GET_BASELINING_LOCALIZE_TEXT(pageLc, `cyber_risk__none`) : selectedRisks.map((risk) => get_risk_alias(risk)));

    function onSelectDCProgram(item, isChecked) {
        setLoading(true);
        const itemID = item;

        if (isChecked) {
            if(!selectedCountries.includes(item)) {
                addItemToState(setSelectedCountries, itemID)
            }
            if (!selectedDCPrograms.includes(itemID)) {
                addItemToState(setSelectedDCPrograms, itemID);
            }
        } else {
            if (selectedDCPrograms.includes(itemID) && !defaultDCProgram.includes(itemID)) {
                removeItemFromState(setSelectedDCPrograms, itemID);
                addItemToState(setAdditionalDCProgram, itemID);
            }
        }
        setTimeout(() => {setLoading(false)}, 200);
    }

    function onAddDCProgram(event, setState) {
        setLoading(true);
        if(selectedDCPrograms.length === 8)
        {
            toastDanger(GET_APP_LOCALIZE_TEXT(pageLc, "general__error"), GET_APP_LOCALIZE_TEXT(pageLc,"baselining__max_added_error"));
        } else {
            const program = event.target.value;
            removeItemFromState(setAdditionalDCProgram, program)
            // addItemToState(setSelectedDCPrograms, program)
            onSelectDCProgram(program, true)
            setState((prevState) => ({
                ...prevState,
                [program]: true,
            }));
        }
        setTimeout(() => {
            setLoading(false)
        }, 200);
    }

    const pageDataStatic = {
        data: {
            "expe_CyberRisks": { global_data: {average: 20}, national_data: {average: 30}, program_data: {average: 40} },
            "exce_illegTech": { global_data: {average: 50}, national_data: {average: 60}, program_data: {average: 70} },
            "expe_Cbbeh": { global_data: {average: 80}, national_data: {average: 70}, program_data: {average: 60} },
            "expe_Cvbeh": { global_data: {average: 40}, national_data: {average: 30}, program_data: {average: 20} },
            "expo_CyberThreat": { global_data: {average: 10}, national_data: {average: 20}, program_data: {average: 30} },
            "expo_SexMedia": { global_data: {average: 40}, national_data: {average: 50}, program_data: {average: 60} },
            "expo_SexConvo": { global_data: {average: 70}, national_data: {average: 80}, program_data: {average: 70} },
            "expe_OfflineMeeting": { global_data: {average: 60}, national_data: {average: 50}, program_data: {average: 40} },
            "risk_DigitalDis": { global_data: {average: 30}, national_data: {average: 20}, program_data: {average: 10} },
            "over_ScrTime": { global_data: {average: 25}, national_data: {average: 35}, program_data: {average: 45} },
            "risk_DigitalDisM": { global_data: {average: 55}, national_data: {average: 65}, program_data: {average: 75} },
            "illeg_Social": { global_data: {average: 80}, national_data: {average: 70}, program_data: {average: 60} },
            "expo_SexConvo_Female": { global_data: {average: 50}, national_data: {average: 40}, program_data: {average: 30} },
        }
    };

    const risks_dummy = Object.keys(pageDataStatic.data);

    return (
        pageData ?
            <Paper container>
                <Grid container>
                    <Grid item xs={12} sm={12} md={3}>
                        <SidePanel
                            primaryHeader={primarySideHeader}
                            secondaryHeader={secondarySideHeader}
                            primaryData={selectedDCPrograms}
                            secondaryData={risks.map((risk) => get_risk_alias(risk))}
                            onCheckPrimary={onSelectDCProgram}
                            onCheckSecondary={onSelectIndicator}
                            primaryColourize={true}
                            primaryColourizeIndex={selectedCategories.length}
                            primaryFooter={CustomSelect}
                            primaryFooterData={{
                                title: "Add Program",
                                data: additionalDCProgram,
                            }}
                            primaryFooterCallback={onAddDCProgram}
                            // primaryAction={X}
                            // primaryActionData={{style: {cursor: "pointer"}}}
                            // primaryActionCallback={onRemoveCountry}
                            secondarySingleSelectIndex={0}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={9}>
                        <Card>
                            <CardContent>
                                <Box sx={{flexGrow: 1}} align={"right"}>
                                    <ColouredTypography variant={"body1"} size={"16px"} color={"grey"} align={"center"} alignText={"justify"} py={10}>
                                        {"What is the level of the exposure to cyber-risks of the program users compared to the national and global averages?"}
                                    </ColouredTypography>
                                    <ColouredTypography mt={5} mb={5} variant={"h4"} align={"center"}>
                                        {indicator_header} {GET_APP_LOCALIZE_TEXT(pageLc, "baselining__in_past_year")}
                                    </ColouredTypography>
                                    <Grid container spacing={0} align={"center"}>
                                        {badge_data.map((badge) => (
                                                <Grid item xs={6} sm={6} md={6} lg={12/badge_data.length}>
                                                    <Badge
                                                        title={badge.title}
                                                        value={`${badge.value.toFixed(2)}%`}
                                                    />
                                                </Grid>
                                            )
                                        )}
                                    </Grid>
                                    <Spacer mb={5}/>
                                    <Grid container spacing={6} justifyContent={"space-between"}>
                                        <Grid item xs={12}>
                                            <ColouredTypography align={"center"} variant={"h4"}>
                                                National Cyber-risks Compared to Other Countries
                                            </ColouredTypography>
                                            <Spacer mb={10}/>
                                            <MixChart
                                                titleX="Cyber Risks" // Assuming GET_APP_LOCALIZE_TEXT returns a simple string for demonstration
                                                titleY="% of Risk"
                                                data={{
                                                    labels: risks.map((risk, index) => convertNumberToAlphabets(index)),
                                                    tooltips: risks.map((risk) => get_risk_alias(risk)),
                                                    graphData: selectedCategories.map((category) => {
                                                        const average_values = [];
                                                        switch (category) {
                                                            case "Global":
                                                                average_values.push(...risks_dummy.map((risk) => pageDataStatic.data[risk].global_data.average));
                                                                break;
                                                            case "National":
                                                                average_values.push(...risks_dummy.map((risk) => pageDataStatic.data[risk].national_data.average));
                                                                break;
                                                            case "Program":
                                                                average_values.push(...risks_dummy.map((risk) => pageDataStatic.data[risk].program_data.average));
                                                                break;
                                                        }
                                                        return {
                                                            type: category === "Program" ? 'bar' : 'line',
                                                            index: selectedCategories.indexOf(category),
                                                            category: get_category_alias(category),
                                                            data: average_values,
                                                            show: selectedCategories.includes(category),
                                                        };
                                                    }),
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Button mt={5} variant="contained">
                                        {GET_APP_LOCALIZE_TEXT(pageLc, "general__download_data")}
                                    </Button>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <LoaderWithBackDrop loading={loading}/>
            </Paper>
            :
            <Paper container>
                <DefaultColouredTypography variant={"h3"} align={'center'} py={5}>{errorMessage}</DefaultColouredTypography>
                <LoaderWithBackDrop loading={loading}/>
            </Paper>
    );
}

export default CyberRisksTab;